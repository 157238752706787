import { useEvidencePermission } from '@utils/permission-hook';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { grey, orange } from '@material-ui/core/colors';
import { DetailContext, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Licence } from '@models';
import { EvidenceApiUrl, ExportTag, LicenceState, Permission } from '@enums';
import { ActionBar } from './licence-actionbar';
import { useColumns } from './licence-columns';
import { Fields } from './licence-fields';
import { useValidationSchema } from './licence-schema';
import { Toolbar } from './licence-toolbar';

export function Licences() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_LICENCE_MANAGE]);

  const evidence = useAuthoredEvidence<Licence>({
    version: 8,
    identifier: 'LICENCE',
    apiProps: {
      url: EvidenceApiUrl.LICENCE,
    },
    tableProps: {
      columns,
      tableName: 'Licencie',
      showBulkActionButton: false,
      reportTag: ExportTag.LICENCE,
      createRowStyle: (l) =>
        l.state === LicenceState.STOPPED
          ? {
              color: grey[500],
              backgroundColor: grey[100],
            }
          : l.state === LicenceState.SUSPENDED
          ? {
              color: orange[500],
              backgroundColor: orange[50],
            }
          : {},
    },
    detailProps: {
      toolbarProps: {
        title: 'Licencia',
        ActionBar,
        disableBtn: function useDisableBtn(btn) {
          const { source } = useContext(DetailContext);

          if (
            source?.data?.state &&
            source?.data?.state !== LicenceState.ACTIVE
          ) {
            return !['SHARE', 'REFRESH', 'NEW'].includes(btn);
          }

          return false;
        },
        showBtn: (btn) => {
          if (['REMOVE', 'NEW'].includes(btn)) {
            return false;
          }

          return permissions(btn);
        },
        Before: Toolbar,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), state: LicenceState.ACTIVE }),
    },
  });

  return <Evidence {...evidence} />;
}
