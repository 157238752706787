import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  FormContext,
  FormPanel,
  FormTextArea,
} from '@eas/common-web';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { IdentifierField } from '@components/form/identifier-field/form-identifier-field';

export function Fields() {
  const { setFieldValues, getFieldValues } = useContext(FormContext);

  return (
    <>
      <FormPanel label="Obecné">
        <IdentifierField
          notifyChange={(values) => {
            const currentValues = getFieldValues();
            const { businessName, ...restValues } = values;

            setFieldValues({
              ...currentValues,
              ...restValues,
              name: businessName,
            });
          }}
        />
        <DictionaryEvidence.DictionaryFields />
      </FormPanel>
      <FormAddressPanel required />
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
