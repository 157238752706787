import { useEvidencePermission } from '@utils/permission-hook';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { green, red } from '@material-ui/core/colors';
import { Evidence, createItemFactory } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { Payment } from '@models';
import { EvidenceApiUrl, ExportTag, PaymentState, Permission } from '@enums';
import { ActionBar } from './payment-actionbar';
import { create } from './payment-api';
import { useColumns } from './payment-columns';
import { Fields } from './payment-fields';
import { useValidationSchema } from './payment-schema';
import { Toolbar } from './payment-toolbar';

export function Payments() {
  const { homeCurrency } = useContext(StaticDataContext);
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_PAYMENT_MANAGE]);

  const evidence = useAuthoredEvidence<Payment>({
    version: 3,
    identifier: 'PAYMENT',
    apiProps: {
      url: EvidenceApiUrl.PAYMENT,
      createItem: createItemFactory({
        createItemMethod: create,
      }),
    },
    tableProps: {
      columns,
      tableName: 'Platby',
      showBulkActionButton: false,
      reportTag: ExportTag.PAYMENT,
      toolbarProps: {
        after: <Toolbar />,
      },
      createRowStyle: (p) =>
        p.state === PaymentState.RECEIVED
          ? {
              color: green[500],
              backgroundColor: green[50],
            }
          : p.state === PaymentState.CANCELED
          ? {
              color: red[500],
              backgroundColor: red[50],
            }
          : {},
    },
    detailProps: {
      toolbarProps: {
        title: 'Platba',
        showBtn: function showBtn(btn) {
          if (['REMOVE'].includes(btn)) {
            return false;
          }

          return permissions(btn);
        },
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({
        id: uuidv4(),
        sendEmail: true,
        state: PaymentState.NEW,
        amount: {
          currency: homeCurrency,
        },
      }),
    },
  });

  return <Evidence {...evidence} />;
}
