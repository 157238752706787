import { getErrorMessage } from '@utils/error-message';
import { useEvidencePermission } from '@utils/permission-hook';
import React, { useContext } from 'react';
import { grey } from '@material-ui/core/colors';
import {
  DictionaryEvidence,
  Evidence,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { ErrorObject, User } from '@models';
import { EvidenceApiUrl, ExportTag, Messages, Permission } from '@enums';
import { useColumns } from './user-columns';
import { Fields } from './user-fields';
import { useValidationSchema } from './user-schema';

export function Users() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { hasPermission } = useContext(UserContext);

  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_UDVA_USER_MANAGE]);

  const evidence = useAuthoredEvidence<User>({
    version: 3,
    identifier: 'USER',
    apiProps: {
      url: EvidenceApiUrl.USER,
      handleCreateError: (err) => {
        const message = getErrorMessage(
          err as ErrorObject<string>,
          Messages.User.CREATE
        );

        if ((err as Error).name !== 'AbortError') {
          showSnackbar(...message);
        }
      },
      createMessages: {
        successMessage:
          'Používateľ bol úspešne vytvorený. Na e-mail bolo zaslané vygenerované heslo.',
      },
    },
    tableProps: {
      columns,
      tableName: 'Používatelia',
      showBulkActionButton: false,
      reportTag: ExportTag.USER,
      createRowStyle: (row) =>
        !row.active
          ? {
              color: grey[500],
              backgroundColor: grey[100],
            }
          : {},
    },
    detailProps: {
      toolbarProps: {
        title: 'Používateľ',
        showBtn: (btn) => {
          if (btn === 'REMOVE') {
            return false;
          }

          return permissions(btn);
        },
        ActionBar: DictionaryEvidence.dictionaryActionbarFactory({
          showBtn: () => hasPermission(Permission.BO_UDVA_USER_MANAGE),
        }),
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
