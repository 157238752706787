import React, { useContext } from 'react';
import {
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditors } from '@modules/auditor/auditor-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { AuditorAutocomplete, CalendarEvent } from '@models';
import { CalendarEventType } from '@enums';

export function Fields() {
  const auditors = useAuditors();

  const { type } = useFormSelector((data: CalendarEvent) => ({
    type: data.type,
  }));

  const { calendarEventTypes } = useContext(StaticDataContext);
  const typeSource = useStaticListSource(calendarEventTypes);

  return (
    <>
      <FormPanel label="Obecné">
        <FormTextField name="name" label="Názov udalosti" required />
        <FormSelect
          source={typeSource}
          name="type"
          label="Typ udalosti"
          valueIsId
          required
        />
        <FormLocalDateField name="date" label="Dátum udalosti" required />
        <FormLocalDateField
          name="reminderDate"
          label="Dátum pripomienky"
          required
        />
        {type === CalendarEventType.FOR_SPECIFIED_AUDITORS && (
          <FormAutocomplete<AuditorAutocomplete>
            source={auditors}
            name="auditors"
            label="Vybraní audítori"
            multiline
            multiple
            labelMapper={autocompleteLabelMapper}
            required
          />
        )}
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
