import * as Yup from 'yup';
import { AuditingEntity, GeneralSubmission } from '@models';
import { GeneralSubmissionAgenda, GeneralSubmissionType } from '@enums';

export function useValidationSchema() {
  return Yup.object<GeneralSubmission>().shape({
    matter: Yup.string().nullable().required(),
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    agenda: Yup.mixed<GeneralSubmissionAgenda>().nullable().required(),
    type: Yup.mixed<GeneralSubmissionType>().nullable().required(),
    text: Yup.string().nullable().required(),
  });
}
