import { UserBackoffice, UserPortal } from 'src/models/user';
import { Insuree, User } from '@models';
import { AccountType } from '@enums';

export const autocompleteLabelMapper = (
  props?: {
    label?: string;
    name?: string;
  } | null
) => {
  const { label, name } = props || {};

  if (label) {
    return label;
  } else if (name) {
    return name;
  }

  return '-';
};

export const objectArrayLabelMapper = ({
  value,
}: {
  value: { label: string }[];
}) => value?.map((v) => v.label)?.join(', ');

objectArrayLabelMapper.displayName = 'objectArrayLabelMapper';

export function insuredSubjectMapper({ value }: { value: Insuree[] }) {
  return value?.map((i) => i?.insuredSubject?.label).join(', ');
}

insuredSubjectMapper.displayName = 'insuredSubjectMapper';

export function firstNameMapper({ rowValue }: { rowValue: User }) {
  return rowValue?.type === AccountType.BACKOFFICE
    ? (rowValue as UserBackoffice)?.firstName
    : (rowValue as UserPortal)?.person?.firstName;
}

firstNameMapper.displayName = 'firstNameMapper';

export function lastNameMapper({ rowValue }: { rowValue: User }) {
  return rowValue?.type === AccountType.BACKOFFICE
    ? (rowValue as UserBackoffice)?.lastName
    : (rowValue as UserPortal)?.person?.lastName;
}

lastNameMapper.displayName = 'lastNameMapper';
