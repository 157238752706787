import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AnonymousSubmission } from '@models';
import { AnonymousSubmissionState, EvidenceApiUrl, Permission } from '@enums';

export function useProcessDialog() {
  const { source } =
    useContext<DetailHandle<AnonymousSubmission>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.ANONYMOUS_SUBMISSION}/${id}/process`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_ANONYMOUS_SUBMISSION_MANAGE) &&
    source.data?.state === AnonymousSubmissionState.NEW;

  return {
    apiCall,
    showProcessBtn: showBtn,
  };
}
