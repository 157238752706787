/* eslint-disable prettier/prettier */
import { useContext } from 'react';
import * as Yup from 'yup';
import { InternalSettingsContext } from '@eas/common-web';

export function usePasswordValidation() {
    const { settingGroups } = useContext(InternalSettingsContext);
  
    const requirements = settingGroups.find((g) => g.code === 'PASSWORD_REQUIREMENTS')?.settings;
    const minLength = requirements?.find((s) => s.code === 'PASSWORD_MINIMUM_LENGTH')?.value;
    const maxLength = requirements?.find((s) => s.code === 'PASSWORD_MAXIMUM_LENGTH')?.value;
    const requireLowerCase = requirements?.find((s) => s.code === 'PASSWORD_REQUIRE_LOWERCASE')?.value;
    const requireUpperCase = requirements?.find((s) => s.code === 'PASSWORD_REQUIRE_UPPERCASE')?.value;
    const requireDigit = requirements?.find((s) => s.code === 'PASSWORD_REQUIRE_DIGIT')?.value;
    const requireSpecial = requirements?.find((s) => s.code === 'PASSWORD_REQUIRE_SPECIAL')?.value;
  
    const characters = settingGroups.find((g) => g.code === 'PASSWORD_CHARACTERS')?.settings;
    const lowercaseChars = characters?.find(s => s.code === 'PASSWORD_CHARACTERS_LOWERCASE')?.value
    const uppercaseChars = characters?.find(s => s.code === 'PASSWORD_CHARACTERS_UPPERCASE')?.value
    const digits = characters?.find(s => s.code === 'PASSWORD_CHARACTERS_DIGIT')?.value
    const specialChars = characters?.find(s => s.code === 'PASSWORD_CHARACTERS_SPECIAL')?.value
  
    return Yup.string()
      .nullable()
      .required('Musí byť vyplnené')
      .test('', '', function (val) {
        const { createError } = this;
  
        const validLength =
          val && val.length >= minLength && val.length <= maxLength;
  
        if (!validLength) {
          return createError({
            message: `Musí obsahovať ${minLength} až ${maxLength} znakov`,
          });
        }
  
        const hasUpperCase = val && new RegExp(`(?=.*[${uppercaseChars}])`).test(val) ? 1 : 0;
  
        if (requireUpperCase && !hasUpperCase) {
          return createError({
            message: `Musí obsahovať veľké písmeno`,
          });
        }
  
        const hasLowerCase = val && new RegExp(`(?=.*[${lowercaseChars}])`).test(val) ? 1 : 0;
  
        if (requireLowerCase && !hasLowerCase) {
          return createError({
            message: 'Musí obsahovať malé písmeno',
          });
        }
  
        const hasDigit = val && new RegExp(`(?=.*[${digits}])`).test(val) ? 1 : 0;
  
        if (requireDigit && !hasDigit) {
          return createError({
            message: 'Musí obsahovať číslicu',
          });
        }
  
        const hasSpecial = val && new RegExp(`(?=.*[${specialChars}])`).test(val) ? 1 : 0;
  
        if (requireSpecial && !hasSpecial) {
          return createError({
            message: `Musí obsahovať špeciálny znak (${specialChars})`,
          });
        }
  
        return true;
      });
  }
  