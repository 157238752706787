import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Document } from '@models';

export function useColumns(): TableColumn<Document>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Document>();

  return [
    {
      datakey: 'name',
      name: 'Názov dokumentu',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'file.name',
      name: 'Názov súboru',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
