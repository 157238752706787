import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  FormAutocomplete,
  FormLocalDateTimeField,
  FormPanel,
  FormTableField,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useDocuments } from '@modules/document/document-api';
import { useColumns } from '@composite/payments/payment-columns';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DocumentAutocomplete, PaymentParse } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';
import { ParsedPaymentResultFields } from './dialog-actions/parse-xml/parse-xml-result-fields';

export function Fields() {
  const { parsedPaymentResults } = useContext(StaticDataContext);
  const { hasPermission } = useContext(UserContext);

  const paymentColumns = useColumns();
  const documents = useDocuments();

  const { inputFile } = useFormSelector((data: PaymentParse) => ({
    inputFile: data.inputFile,
  }));

  return (
    <>
      <FormPanel label="Obecné">
        <FormAutocomplete<DocumentAutocomplete>
          source={documents}
          name="inputFile"
          label="Vstupný súbor"
          labelMapper={autocompleteLabelMapper}
          after={
            inputFile?.id &&
            hasPermission(Permission.BO_DOCUMENT_READ) && (
              <Redirect id={inputFile.id} url={EvidenceBrowserUrl.DOCUMENT} />
            )
          }
        />
        <FormTextField
          name="confirmedPaymentsCount"
          label="Počet spárovaných platieb"
        />
        <FormTextField name="createdBy.name" label="Autor" />
        <FormLocalDateTimeField name="created" label="Vytvorenie" />
      </FormPanel>
      <FormPanel label="Spárované platby">
        <FormTableField
          name="confirmedPayments"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={paymentColumns}
          showToolbar={false}
          showDetailBtnCond={stubFalse}
        />
      </FormPanel>
      <FormPanel label="Štruktúra párovania">
        <ParsedPaymentResultFields
          parsedPaymentResults={parsedPaymentResults}
          showPairedPayments
        />
      </FormPanel>
    </>
  );
}
