import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { RegistryRecord } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { ActionBar } from './registry-record-actionbar';
import { useColumns } from './registry-record-columns';
import { Fields } from './registry-record-fields';
import { useValidationSchema } from './registry-record-schema';

export function RegistryRecords() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = useEvidencePermission([
    Permission.BO_REGISTRY_RECORD_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<RegistryRecord>({
    identifier: 'REGISTRY_RECORD',
    apiProps: {
      url: EvidenceApiUrl.REGISTRY_RECORD,
    },
    tableProps: {
      columns,
      tableName: 'Registratúrné záznamy',
      showBulkActionButton: false,
      reportTag: ExportTag.REGISTRY_RECORD,
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Registratúrny záznam',
        showBtn: (btn) => {
          if (['EDIT', 'REMOVE'].includes(btn)) {
            return false;
          }

          return permissions(btn);
        },
        ActionBar,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
