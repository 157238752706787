import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useReplyDialog } from './dialog-actions/reply-hook';

export function ActionBar() {
  const { showReplyBtn, ...replyProps } = useReplyDialog();

  return (
    <>
      {showReplyBtn && (
        <DetailActionButton
          promptKey="REPLY"
          buttonLabel="Odpovedať na podnet"
          dialogText="Naozaj chcete odpovedať na podnet?"
          dialogTitle="Odpoveď na všeobecný podnet"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          dialogDisableBackdrop
          {...replyProps}
        />
      )}
    </>
  );
}
