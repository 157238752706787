/**
 * Stav platby
 */
export enum PaymentState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Prijatá
   */
  RECEIVED = 'RECEIVED',

  /**
   * Zrušená
   */
  CANCELED = 'CANCELED',
}

/**
 * Hlavná špecifikácia platby
 */
export enum PaymentMainSpecification {
  /**
   * SVZ a OS
   */
  SVZ_AND_OS = 'SVZ_AND_OS',

  /**
   * AS
   */
  AS = 'AS',

  /**
   * ŠA
   */
  SA = 'SA',

  /**
   * INÉ
   */
  OTHER = 'OTHER',
}

/**
 * Pomocná špecifikácia platby
 */
export enum PaymentAdditionalSpecification {
  /**
   * B
   */
  B = 'B',

  /**
   * PZB
   */
  PZB = 'PZB',

  /**
   * EXIM
   */
  EXIM = 'EXIM',

  /**
   * P
   */
  P = 'P',

  /**
   * PP
   */
  PP = 'PP',

  /**
   * SS
   */
  SS = 'SS',

  /**
   * DSS
   */
  DSS = 'DSS',

  /**
   * DDS
   */
  DDS = 'DDS',

  /**
   * BCP
   */
  BCP = 'BCP',

  /**
   * ŽSR
   */
  ZSR = 'ZSR',

  /**
   * OS
   */
  OS = 'OS',

  /**
   * PZ
   */
  PZ = 'PZ',

  /**
   * zápisné ŠA
   */
  SA_ENROLLMENT_FEE = 'SA_ENROLLMENT_FEE',

  /**
   * zápisné AS
   */
  AS_ENROLLMENT_FEE = 'AS_ENROLLMENT_FEE',

  /**
   * pozastavenie licencie
   */
  LICENSE_SUSPENSION = 'LICENSE_SUSPENSION',

  /**
   * aktivovanie licencie
   */
  LICENSE_ACTIVATION = 'LICENSE_ACTIVATION',

  /**
   * opätovné vydanie licencie
   */
  LICENSE_REISSUE = 'LICENSE_REISSUE',

  /**
   * vyčiarknutie zo zoznamu
   */
  REMOVAL_FROM_LIST = 'REMOVAL_FROM_LIST',

  /**
   * audítorská skúška
   */
  AUDIT_EXAM = 'AUDIT_EXAM',

  /**
   * skúška spôsobilosti
   */
  CAPABILITY_TEST = 'CAPABILITY_TEST',

  /**
   * preskúšanie
   */
  RETEST = 'RETEST',
}

/**
 * Kategória platby podľa §68 ods. 1 zákona
 */
export enum PaymentCategory {
  /**
   * a) príspevok zo ŠR
   */
  STATE_BUDGET_CONTRIBUTION = 'STATE_BUDGET_CONTRIBUTION',

  /**
   * b) ročné príspevky
   */
  ANNUAL_CONTRIBUTIONS = 'ANNUAL_CONTRIBUTIONS',

  /**
   * c) ročný registračný poplatok
   */
  ANNUAL_REGISTRATION_FEE = 'ANNUAL_REGISTRATION_FEE',

  /**
   * d) pokuty
   */
  FINES = 'FINES',

  /**
   * e) náhrady za trovy konania
   */
  LEGAL_EXPENSES = 'LEGAL_EXPENSES',

  /**
   * f) úrok z omeškania
   */
  LATE_PAYMENT_INTEREST = 'LATE_PAYMENT_INTEREST',

  /**
   * g) poplatky za skúšky a preskúšanie
   */
  EXAMINATION_FEES = 'EXAMINATION_FEES',

  /**
   * h) poplatky za úkony na úrade
   */
  OFFICE_FEES = 'OFFICE_FEES',
}

export enum ParsedPaymentResult {
  /**
   * Found payments with matching VS and amount
   */
  FULL_MATCH = 'FULL_MATCH',

  /**
   * Found payments with matching VS or amount
   */
  PARTIAL_MATCH = 'PARTIAL_MATCH',

  /**
   * No matching payments found
   */
  NO_MATCH = 'NO_MATCH',
}
