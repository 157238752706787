import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { useEducationForms } from '@modules/dict-education-form/dict-education-form-api';
import { DictEducationFormAutocomplete, EducationalTraining } from '@models';

export function useColumns(): TableColumn<EducationalTraining>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<EducationalTraining>();

  return [
    {
      datakey: 'name',
      name: 'Názov školenia',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'form.label',
      sortkey: 'form.name',
      filterkey: 'form.id',
      name: 'Forma vzdelávania',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictEducationFormAutocomplete>(
          useEducationForms
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'educationField.label',
      sortkey: 'educationField.name',
      filterkey: 'educationField.id',
      name: 'Oblasť zamerania vzdelávania',
      width: 200,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory(useEducationFields),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'fromDate',
      name: 'Dátum od',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'toDate',
      name: 'Dátum do',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
