import React, { useContext } from 'react';
import {
  FormPanel,
  FormTableField,
  TableFieldCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { ContactDialog } from './contact-dialog';
import { useValidationSchema } from './contact-schema';

interface ContactFieldProps {
  label?: string;
  name?: string;
}

export function ContactField({
  label = 'Kontakty',
  name = 'contacts',
}: ContactFieldProps) {
  const validationSchema = useValidationSchema();

  const { personalContactTypes } = useContext(StaticDataContext);
  const useContactTypes = () => useStaticListSource(personalContactTypes);

  return (
    <FormPanel label={label} expandable={true} defaultExpanded={true}>
      <FormTableField
        name={name}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        columns={[
          {
            datakey: 'value',
            name: 'Hodnota',
            width: 250,
          },
          {
            datakey: 'type',
            name: 'Typ kontaktu',
            width: 200,
            CellComponent:
              TableFieldCells.useSelectCellFactory(useContactTypes),
          },
          {
            datakey: 'shownPublicly',
            name: 'Zobraziť verejne',
            width: 150,
            CellComponent: TableFieldCells.BooleanCell,
          },
          {
            datakey: 'created',
            name: 'Vytvorený',
            width: 150,
            CellComponent: TableFieldCells.DateTimeCell,
          },
          {
            datakey: 'updated',
            name: 'Zmenený',
            width: 150,
            CellComponent: TableFieldCells.DateTimeCell,
          },
        ]}
        FormFieldsComponent={ContactDialog}
        validationSchema={validationSchema}
      />
    </FormPanel>
  );
}
