import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  FormTextArea,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { LicenceApplication } from '@models';
import { EvidenceApiUrl, LicenceApplicationState, Permission } from '@enums';

export function useRejectDialog() {
  const { source } =
    useContext<DetailHandle<LicenceApplication>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.LICENCE_APPLICATION}/${id}/reject`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    source?.data?.state &&
    ![
      LicenceApplicationState.APPROVED,
      LicenceApplicationState.CLOSED,
    ].includes(source.data.state) &&
    hasPermission(Permission.BO_LICENCE_APPLICATION_MANAGE);

  const FormFields = () => <FormTextArea name="reason" label="Dôvod" />;

  const formValidationSchema = Yup.object().shape({
    reason: Yup.string().nullable().required(),
  });

  return {
    apiCall,
    showRejectBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
