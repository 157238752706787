import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { useSubjectHookedSources } from '@modules/subject/subject-hooked-sources';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { SubjectNatural } from '@models';
import { EvidenceApiUrl, ExportTag, Permission, SubjectType } from '@enums';
import { useColumns } from './natural-person-columns';
import { Fields } from './natural-person-fields';
import { SaveButton } from './natural-person-save-button';
import { useValidationSchema } from './natural-person-schema';

export function NaturalPersons() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_SUBJECT_MANAGE]);

  const evidence = useAuthoredEvidence<SubjectNatural>({
    version: 2,
    identifier: 'NATURAL_PERSON',
    apiProps: {
      url: EvidenceApiUrl.SUBJECT,
      hookedSources: useSubjectHookedSources,
    },
    tableProps: {
      columns,
      tableName: 'Fyzické osoby',
      showBulkActionButton: false,
      reportTag: ExportTag.SUBJECT,
      defaultPreFilters: [
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: SubjectType.NATURAL,
        },
      ],
    },
    detailProps: {
      toolbarProps: {
        title: 'Fyzická osoba',
        showBtn: permissions,
        SaveButton: SaveButton,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), type: SubjectType.NATURAL }),
    },
  });

  return <Evidence {...evidence} />;
}
