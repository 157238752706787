import React from 'react';
import { FilterComponentProps, TextField } from '@eas/common-web';

export function FilterTextNumberCell({
  disabled,
  value,
  onChange,
}: FilterComponentProps) {
  return (
    <TextField
      type="number"
      value={value}
      disabled={disabled}
      onChange={onChange}
    />
  );
}
