import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ActionButton,
  NotificationContext,
  SubscriptionType,
  TableFieldToolbarButton,
  updateNotificationPreferences,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';

export const useCustomStyles = makeStyles(() => ({
  tableActions: {
    justifyContent: 'end',
    backgroundColor: '#fff',
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
}));

interface NotificationsToolbarProps {
  editing: boolean;
  setEditing: (editing: boolean) => void;
  resetDefaultValues: () => void;
  notificationPreferences: any[] | undefined;
}

export function NotificationsFooter({
  editing,
  setEditing,
  resetDefaultValues,
  notificationPreferences,
}: NotificationsToolbarProps) {
  const intl = useIntl();
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const { notificationPreferenceUrl } = useContext(NotificationContext);

  return (
    <div className={customClasses.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {!editing && (
          <TableFieldToolbarButton
            IconComponent={() => <></>}
            show={true}
            disabled={false}
            title="Upravit"
            onClick={() => setEditing(true)}
            color="primary"
            variant="contained"
          >
            <Typography variant="body2">
              {intl.formatMessage({
                id: 'SVOZ__NOTIFICATION_TOOLBAR__EDIT__BUTTON',
                defaultMessage: 'Upraviť',
              })}
            </Typography>
          </TableFieldToolbarButton>
        )}
        {editing && (
          <>
            <ActionButton
              promptKey="NOTIFICATION_SUBSCRIPTION_CHANGE"
              apiCall={() => {
                const result: {
                  [key: string]: SubscriptionType;
                } = {};
                notificationPreferences?.forEach((preference) => {
                  result[preference.event.id] =
                    preference.type as SubscriptionType;
                });
                return updateNotificationPreferences(
                  notificationPreferenceUrl,
                  result
                );
              }}
              buttonLabel="Uložiť zmeny"
              dialogText="Skutočne chcete zmeniť spôsob odoberania notifikácií?"
              dialogTitle="Varovanie"
              ButtonComponent={function Button({ label, onClick }) {
                return (
                  <TableFieldToolbarButton
                    IconComponent={() => <></>}
                    show={true}
                    disabled={false}
                    title={label}
                    onClick={onClick}
                    color="primary"
                    variant="contained"
                  >
                    <Typography variant="body2">Uložiť</Typography>
                  </TableFieldToolbarButton>
                );
              }}
              onSuccess={async () => setEditing(false)}
            />
            <TableFieldToolbarButton
              IconComponent={() => <></>}
              show={true}
              disabled={false}
              title="Zrušit"
              onClick={() => {
                resetDefaultValues();
                setEditing(false);
              }}
              color="secondary"
              variant="contained"
            >
              <Typography variant="body2">
                {intl.formatMessage({
                  id: 'SVOZ__NOTIFICATION_TOOLBAR__CANCEL__BUTTON',
                  defaultMessage: 'Zrušiť',
                })}
              </Typography>
            </TableFieldToolbarButton>
          </>
        )}
      </ButtonGroup>
    </div>
  );
}
