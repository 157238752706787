import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  DictCountryAutocomplete,
  Licence,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { licenceStates, licenceTypes } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const auditingEntities = useAuditingEntities();
  const countries = useCountries();
  const licenceStateSource = useStaticListSource(licenceStates);
  const licenceTypeSource = useStaticListSource(licenceTypes);

  const { subject } = useFormSelector((data: Licence) => ({
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Licencia">
        <FormTextField
          type="number"
          name="number"
          label="Číslo licencie"
          required
        />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" required />
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="subject"
          label="Licencovaný subjekt"
          labelMapper={autocompleteLabelMapper}
          required
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  subject?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={subject.id}
              />
            )
          }
        />
        <FormSelect
          source={licenceStateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
        <FormSelect
          source={licenceTypeSource}
          name="type"
          label="Typ licencie"
          valueIsId
          disabled
        />
      </FormPanel>
      <FormPanel label="Pôvod licencie">
        <FormAutocomplete<DictCountryAutocomplete>
          source={countries}
          name="origin.country"
          label="Krajina pôvodu"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          required
        />
      </FormPanel>
      <ActionField />
        <OfficialRecordField />
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}

function ActionField() {
  const { licenceStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(licenceStates);

  const StateCell = TableFieldCells.useSelectCellFactory(useStates);

  return (
    <FormPanel label="Vykonané akcie na licencii">
      <FormTableField
        name="actions"
        columns={[
          {
            datakey: 'date',
            name: 'Dátum akcie',
            width: 200,
            CellComponent: TableFieldCells.DateTimeCell,
          },
          {
            datakey: 'newState',
            name: 'Nový stav licencie',
            width: 200,
            CellComponent: StateCell,
          },
          {
            datakey: 'reason',
            name: 'Dôvod akcie',
            width: 400,
          },
        ]}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        showToolbar={false}
        showDetailBtnCond={stubFalse}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
}

function OfficialRecordField() {
  return (
    <FormPanel label="Úradný záznam">
      <FormTableField
        name="officialRecords"
        columns={[
          {
            datakey: 'date',
            name: 'Dátum záznamu',
            width: 200,
            CellComponent: TableFieldCells.DateTimeCell,
          },
          {
            datakey: 'reason',
            name: 'Dôvod záznamu',
            width: 400,
          },
        ]}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        showToolbar={false}
        showDetailBtnCond={stubFalse}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
}