import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  FormLocalDateField,
  FormTextField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { RegistryRecord } from '@models';
import { EvidenceApiUrl, Permission, RegistryRecordState } from '@enums';

export function useRecordDialog() {
  const { source } = useContext<DetailHandle<RegistryRecord>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.REGISTRY_RECORD}/${id}/record`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_REGISTRY_RECORD_MANAGE) &&
    source.data?.state === RegistryRecordState.UNRECORDED;

  const FormFields = () => (
    <>
      <FormTextField
        name="number"
        label="Číslo evidencie z registraturného systému"
      />
      <FormLocalDateField
        name="registrationDate"
        label="Dátum zápisu do registraturného systému"
      />
    </>
  );

  return {
    apiCall,
    showRecordBtn: showBtn,
    FormFields,
  };
}
