import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import {
    FormContext,
    FormLocalDateTimeField,
    FormTextArea,
    UserContext,
    abortableFetch,
    useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission } from '@enums';

export function useAddOfficialRecordDialog() {
    const { hasPermission } = useContext(UserContext);
    const tzoffset = new Date().getTimezoneOffset() * 60000;

    const apiCall = useEventCallback((id: string, formData) =>
        abortableFetch(`${EvidenceApiUrl.LICENCE}/${id}/official-record`, {
            headers: new Headers({
                'Content-Type': 'application/json',
            }),
            method: 'POST',
            body: JSON.stringify(formData),
        })
    );

    const showBtn = hasPermission(Permission.BO_LICENCE_MANAGE);

    const FormFields = () => {
        const { setFieldValue } = useContext(FormContext);

        useEffect(() => {
            // -60000 - substract one minute from default time, so it won't collide with validation schema
            const now = new Date(Date.now() - 60000 - tzoffset)
                .toISOString()
                .slice(0, -1);
            setFieldValue('date', now);
        }, []);

        return (
            <>
                <FormLocalDateTimeField
                    name="date"
                    label="Dátum úradného záznamu"
                    maxDatePicker={new Date().toISOString()}
                    required
                />
                <FormTextArea name="reason" label="Dôvod" />
            </>
        );
    };

    const formValidationSchema = Yup.object().shape({
        reason: Yup.string().required('Povinné'),
        date: Yup.date()
            .nullable()
            .max(
                new Date(Date.now() - tzoffset).toISOString().slice(0, -1),
                'Dátum úradného záznamu nesmie byť v budúcnosti.'
            )
            .required('Povinné'),
    });

    return {
        apiCall,
        showOfficialRecordBtn: showBtn,
        FormFields,
        formValidationSchema,
    };
}