import React from 'react';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  ActionButton,
  FormAutocomplete,
  PrimaryDetailActionbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  EducationCycleAutocomplete,
  EducationOverviewPerAuditorDto,
} from '@models';
import { useEditNoteDialog } from './dialog-actions/edit-note-hook';

const useStyles = makeStyles((theme) => ({
  cycleLabel: {
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 20,
  },
}));

export function Toolbar({
  items,
  selectedIndex,
  cycle,
  setCycle,
  refresh,
}: TableFieldToolbarProps & {
  items: EducationOverviewPerAuditorDto[];
  cycle?: EducationCycleAutocomplete;
  setCycle: (cycle: EducationCycleAutocomplete) => void;
  refresh: () => void;
}) {
  const classes = useStyles();

  const {
    FormFields,
    formValidationSchema,
    createNoteApiCall,
    updateNoteApiCall,
  } = useEditNoteDialog();

  const selectedItem = (
    selectedIndex !== undefined ? items[selectedIndex] : {}
  ) as EducationOverviewPerAuditorDto;

  return (
    <>
      <div className={classes.actions}>
        <Typography variant="body1" className={classes.cycleLabel}>
          {cycle?.label ? `Cyklus: ${cycle?.label}` : ''}
        </Typography>

        <div>
          <ActionButton
            promptKey="SET_CYCLE"
            buttonLabel="Zmeniť cyklus"
            dialogTitle="Zmena cyklu"
            dialogText={null}
            ButtonComponent={PrimaryDetailActionbarButton}
            formInitialValues={{ cycle: cycle }}
            FormFields={SetCycleFields}
            formValidationSchema={Yup.object().shape({
              cycle: Yup.mixed<EducationCycleAutocomplete>()
                .nullable()
                .required(),
            })}
            submitCallback={(values) => setCycle({ ...values?.cycle })}
            dialogWidth={800}
          />
          <ActionButton
            promptKey="EDIT_NOTE"
            dialogText={null}
            apiCall={
              selectedItem.note
                ? (data) => updateNoteApiCall(selectedItem.note?.id, data)
                : (data) => createNoteApiCall(data)
            }
            formInitialValues={{
              note: '',
              auditor: selectedItem?.auditor,
              cycle,
              ...selectedItem?.note,
            }}
            onSuccess={async () => refresh()}
            FormFields={FormFields}
            formValidationSchema={formValidationSchema}
            buttonLabel="Upraviť poznámku"
            dialogTitle="Úprava poznámky"
            buttonDisabled={selectedIndex === undefined}
            ButtonComponent={PrimaryDetailActionbarButton}
          />
        </div>
      </div>
    </>
  );
}

const SetCycleFields = () => {
  const cycles = useEducationCycles();

  return (
    <>
      <FormAutocomplete<EducationCycleAutocomplete>
        source={cycles}
        name="cycle"
        label="Cyklus"
        labelMapper={autocompleteLabelMapper}
      />
    </>
  );
};
