import * as Yup from 'yup';
import { DictCountry } from '@models';

export function useValidationSchema() {
  return Yup.object<DictCountry>().shape({
    name: Yup.string().nullable().required(),
    alpha2: Yup.string().test('', '', function (value) {
      const { path, createError } = this;

      if (value?.length !== 2) {
        return createError({
          path,
          message: 'Musí sa skladať z dvoch znakov.',
        });
      }

      return true;
    }),
    alpha3: Yup.string().test('', '', function (value) {
      const { path, createError } = this;

      if (value?.length !== 3) {
        return createError({
          path,
          message: 'Musí sa skladať z troch znakov.',
        });
      }

      return true;
    }),
  });
}
