import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ExamApplication } from '@models';
import { EvidenceApiUrl, ExamApplicationState, Permission } from '@enums';

export function useRegisterDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<ExamApplication>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.EXAM_APPLICATION}/${id}/register`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    })
  );

  const showBtn =
    hasPermission(Permission.BO_EXAM_APPLICATION_MANAGE) &&
    source?.data?.state === ExamApplicationState.READY_FOR_EXAM_REGISTRATION;

  return { showRegisterBtn: showBtn, apiCall };
}
