/**
 * Typ udalosti v kalendári
 */
export enum CalendarEventType {
  /**
   * Udalosť týkajúca sa všetkých štatutárných adítorov
   */
  FOR_AUDITORS_WITHOUT_EMPLOYMENT = 'FOR_AUDITORS_WITHOUT_EMPLOYMENT',

  /**
   * Udalosť týkajúca sa všetkých adítorských spoločností
   */
  FOR_AUDIT_COMPANIES = 'FOR_AUDIT_COMPANIES',

  /**
   * Udalosť týkajúca sa všetkých
   */
  FOR_EVERYONE = 'FOR_EVERYONE',

  /**
   * Udalosť týkajúca sa vybraných adítorov
   */
  FOR_SPECIFIED_AUDITORS = 'FOR_SPECIFIED_AUDITORS',
}
