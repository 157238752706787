import { intersection } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';
import { FormContext, useEventCallback, useMeasure } from '@eas/common-web';
import { useStyles } from './tabs-styles';
import { TabsConfig } from './tabs-types';

function useView() {
  const { search } = useLocation();

  const params = new URLSearchParams(search);

  return params.get('view');
}

export function Tabs({
  config,
  onClick,
}: {
  config: TabsConfig;
  onClick?: (setActiveTab: any, tab: any) => void;
}) {
  const classes = useStyles('#fafafa', 10)();

  const [activeTab, setActiveTab] = useState(0);
  const { errors } = useContext(FormContext);

  const [tabsWrapperRef] = useMeasure();
  const route = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const view = useView();

  useEffect(() => {
    const index = config.findIndex((tab) => tab.key === view);

    setActiveTab(index === -1 ? 0 : index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleTabChange = useEventCallback((_, index: number) => {
    const tab = config[index];

    if (onClick) {
      onClick(setActiveTab, tab);
    } else {
      history.push(`${route.url}?view=${tab.key}`, location.state);
    }
  });

  return (
    <>
      <div className={classes.tabsWrapper} ref={tabsWrapperRef}>
        <MuiTabs
          className={classes.tabs}
          variant="scrollable"
          value={activeTab}
          onChange={handleTabChange}
          classes={{
            indicator: classes.indicator,
            root: classes.tabsRoot,
            scrollButtons: classes.scrollButtons,
          }}
        >
          {config.map((tab, i) => {
            const validationErrorsCount = intersection(
              errors.map((error) => error.key),
              config[i].validationKeys ?? []
            ).length;

            return (
              !config[i].hide && (
                <MuiTab
                  key={i}
                  label={
                    <div className={classes.tabLabel}>
                      {tab.label}
                      {validationErrorsCount > 0 && (
                        <span className={classes.errorCount}>
                          {validationErrorsCount}
                        </span>
                      )}
                    </div>
                  }
                  classes={{
                    root: classes.tabRoot,
                  }}
                />
              )
            );
          })}
        </MuiTabs>
      </div>
      {config[activeTab].content}
    </>
  );
}

export function SimpleTabs({ config }: { config: TabsConfig }) {
  const classes = useStyles('#fafafa', 10)();

  const [activeTab, setActiveTab] = useState(0);
  const { errors } = useContext(FormContext);

  const [tabsWrapperRef] = useMeasure();

  const handleTabChange = useEventCallback((_, index: number) => {
    setActiveTab(index);
  });

  return (
    <>
      <div className={classes.tabsWrapper} ref={tabsWrapperRef}>
        <MuiTabs
          className={classes.tabs}
          variant="scrollable"
          value={activeTab}
          onChange={handleTabChange}
          classes={{
            indicator: classes.indicator,
            root: classes.tabsRoot,
            scrollButtons: classes.scrollButtons,
          }}
        >
          {config.map((tab, i) => {
            const validationErrorsCount = intersection(
              errors.map((error) => error.key),
              config[i].validationKeys ?? []
            ).length;

            return (
              !config[i].hide && (
                <MuiTab
                  key={i}
                  label={
                    <div className={classes.tabLabel}>
                      {tab.label}
                      {validationErrorsCount > 0 && (
                        <span className={classes.errorCount}>
                          {validationErrorsCount}
                        </span>
                      )}
                    </div>
                  }
                  classes={{
                    root: classes.tabRoot,
                  }}
                />
              )
            );
          })}
        </MuiTabs>
      </div>
      {config[activeTab].content}
    </>
  );
}
