import { DictionaryEvidence, TableCells, TableColumn } from '@eas/common-web';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import { DictInsuranceCompany } from '@models';

export function useColumns(): TableColumn<DictInsuranceCompany>[] {
  const { dictionaryColumns } =
    DictionaryEvidence.useDictionaryColumns<DictInsuranceCompany>();

  return [
    {
      datakey: 'identifier',
      name: 'IČO',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...dictionaryColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
