import React, { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useSubjects } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Auditor, SubjectAutocomplete } from '@models';
import { EvidenceBrowserUrl, Permission, SubjectType } from '@enums';

export function GeneralFields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const { subject } = useFormSelector((data: Auditor) => ({
    subject: data.subject,
  }));

  const subjects = useSubjects({
    params: {
      filters: [
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: SubjectType.NATURAL,
        },
      ],
    },
  });

  return (
    <>
      <FormPanel label="Audítor">
        <FormAutocomplete<SubjectAutocomplete>
          name="subject"
          label="Fyzická osoba"
          source={subjects}
          labelMapper={autocompleteLabelMapper}
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                id={subject.id}
                url={EvidenceBrowserUrl.NATURAL_PERSON}
              />
            )
          }
          disabled={mode !== DetailMode.NEW}
          required
        />
        <FormLocalDateField
          name="examPassedDate"
          label="Audítorská skúška zložená"
          required
        />
        <FormLocalDateField name="reviewedDate" label="Dátum preskúšania" />
        <FormTextField name="reviewedResult" label="Výsledok preskúšania" />
        <FormLocalDateField
          name="recordDate"
          label="Do zoznamu aud. zapísaný"
          required
        />
        <FormTextField name="website" label="Internetová adresa" />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
      <FormPanel label="Obecné">
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
