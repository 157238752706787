import * as Yup from 'yup';
import { AuditingEntity, DictCountry, Licence } from '@models';

export function useValidationSchema() {
  return Yup.object<Licence>().shape({
    number: Yup.string().nullable().required(),
    issuedDate: Yup.string().nullable().required(),
    subject: Yup.mixed<AuditingEntity>().nullable().required(),
    origin: Yup.object().shape({
      country: Yup.mixed<DictCountry>().nullable().required(),
      institution: Yup.string().nullable().required(),
    }),
  });
}
