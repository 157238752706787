import { get } from 'lodash';
import React, { Ref, forwardRef, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import {
  TableFieldCells,
  TableFieldContext,
  TableFieldRowProps,
  useEventCallback,
} from '@eas/common-web';
import { ParsedPaymentResultDto } from '@models';

const useStyles = makeStyles((theme) => ({
  rowWrapper: {
    cursor: 'pointer',
    background: '#e0e2e3',
    '&:hover': {
      backgroundColor: theme.palette.highlight,
    },
  },
  row: {
    boxSizing: 'border-box',
    minWidth: '100%',
    minHeight: 30,
    padding: '2px 16px',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  rowDetails: {
    width: '100%',
    padding: '4px 10px 4px 50px',
    borderBottom: '1px solid #cdcdcd',
  },
  cellWrapper: {
    flexShrink: 0,
    display: 'flex',
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    fontWeight: 500,
    marginRight: 20,
    marginLeft: 50,
  },
  content: {
    display: 'flex',
  },
  icon: {
    float: 'left',
    marginTop: -4,
    marginRight: 10,
  },
  radioButton: {
    padding: 0,
  },
  detailSectionLabel: {
    fontWeight: 600,
    fontSize: '13px',
  },
  detailSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
  },
}));

export const PaymentRow = forwardRef(function PaymentTableFieldRow(
  { index, value }: TableFieldRowProps<ParsedPaymentResultDto>,
  ref: Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const { filteredColumns, onSelect, selectRow, createRowStyle } =
    useContext(TableFieldContext);

  const handleSelectClick = useEventCallback(() => {
    onSelect?.(value, index);
    selectRow(index);
  });

  const rowStyle = createRowStyle(value);

  return (
    <div ref={ref} onClick={handleSelectClick} className={classes.rowWrapper}>
      <div className={classes.row} style={rowStyle}>
        {filteredColumns.map((column, i) => {
          const { CellComponent = TableFieldCells.TextCell } = column;
          return (
            <div
              key={i}
              className={classes.cellWrapper}
              style={{
                width: column.width,
              }}
            >
              <CellComponent
                index={index}
                value={get(value, column.datakey, '')}
                rowValue={value}
                column={column as any}
              />
            </div>
          );
        })}
      </div>
      <div className={classes.rowDetails} style={rowStyle}>
        {(value?.parsedPayment?.notes?.length ?? []) > 0 && (
          <>
            <span className={classes.detailSectionWrapper}>
              <SubdirectoryArrowRightIcon
                fontSize="small"
                className={classes.icon}
              />
              <Typography
                variant="body1"
                className={classes.detailSectionLabel}
              >
                Správa pre príjemcu:
              </Typography>
            </span>
            <ul>
              {value?.parsedPayment?.notes?.map((n, idx) => (
                <span className={classes.label} key={idx}>
                  {`${n}`}
                </span>
              ))}
            </ul>
          </>
        )}
        {(value?.parsedPayment?.addressLines?.length ?? []) > 0 && (
          <>
            <span className={classes.detailSectionWrapper}>
              <SubdirectoryArrowRightIcon
                fontSize="small"
                className={classes.icon}
              />
              <Typography
                variant="body1"
                className={classes.detailSectionLabel}
              >
                Adresa:
              </Typography>
            </span>
            <ul>
              <span className={classes.label}>
                {value?.parsedPayment?.address && `${value.parsedPayment.address}`}
              </span>
            </ul>
          </>
        )}
        {(value?.matchedPayments?.length ?? []) > 0 && (
          <>
            <span className={classes.detailSectionWrapper}>
              <SubdirectoryArrowRightIcon
                fontSize="small"
                className={classes.icon}
              />
              <Typography
                variant="body1"
                className={classes.detailSectionLabel}
              >
                Nájdené platby:
              </Typography>
            </span>
            <ul>
              {value?.matchedPayments?.map((p, idx) => (
                <span className={classes.label} key={p.id}>
                  {`${idx + 1}) ${p.extendedLabel}`}
                </span>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
});
