import {
  ApiFilterOperation,
  DictionaryObject,
  Params,
  eqFilterParams,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { SubjectAutocomplete } from '@models';
import { EvidenceApiUrl, SubjectType } from '@enums';

export function useSubjectTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.SUBJECT}/types/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

export function useSubjects(props?: {
  params?: Params;
  apiUrl?: EvidenceApiUrl;
}) {
  return useAutocompleteSource<SubjectAutocomplete>({
    url: `${EvidenceApiUrl.SUBJECT}/autocomplete`,
    ...props,
  });
}

export function useNaturalPersons(props?: {
  apiUrl?: EvidenceApiUrl;
  params?: Params;
}) {
  return useAutocompleteSource<SubjectAutocomplete>({
    url: `${EvidenceApiUrl.SUBJECT}/autocomplete`,
    params: {
      ...props?.params,
      filters: [
        ...(props?.params?.filters ?? []),
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: SubjectType.NATURAL,
        },
      ],
    },
    apiUrl: props?.apiUrl,
  });
}

export function useLegalPersons(apiUrl?: EvidenceApiUrl) {
  return useAutocompleteSource<SubjectAutocomplete>({
    url: `${EvidenceApiUrl.SUBJECT}/autocomplete`,
    params: eqFilterParams({ field: 'type.id', value: SubjectType.LEGAL }),
    apiUrl,
  });
}
