import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useInsuranceBrokerages } from '@modules/dict-insurance-brokerage/dict-insurance-brokerage-api';
import { useInsuranceCompanies } from '@modules/dict-insurance-company/dict-insurance-company-api';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { InsureeField } from '@composite/insurees/insuree-field';
import { Redirect } from '@composite/redirect/redirect';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ValidityFields } from '@components/form/validity/validity-fields';
import {
  DictInsuranceBrokerageAutocomplete,
  DictInsuranceCompanyAutocomplete,
  DictMonetaryCurrencyAutocomplete,
  Insurance,
} from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { hasPermission } = useContext(UserContext);

  return (
    <>
      <InsuranceFields />
      <CostFields />
      <InsuranceCompanyFields />
      <FormAddressPanel
        name="insuranceCompany.address"
        label="Adresa poisťovne"
        disabled
      />
      {hasPermission(Permission.BO_INSURANCE_READ) && (
        <InsureeField
          disabled={!hasPermission(Permission.BO_INSURANCE_MANAGE)}
        />
      )}
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}

export function InsuranceFields() {
  const brokerages = useInsuranceBrokerages();

  return (
    <FormPanel label="Poistenie">
      <FormAutocomplete<DictInsuranceBrokerageAutocomplete>
        source={brokerages}
        name="brokerage"
        label="Sprostredkovanie poistenia"
        required
        freeSolo
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField name="number" label="Číslo poistnej zmluvy" />
      <FormLocalDateField
        name="contractConcludedDate"
        label="Deň uzavretia zmluvy"
      />
      <ValidityFields
        fromLabel="Zmluva platná od"
        toLabel="Zmluva platná do"
        indefinitePeriodLabel="Poistený na dobu neurčitú"
      />
    </FormPanel>
  );
}

export function CostFields() {
  const currencies = useMonetaryCurrencies();

  return (
    <FormPanel label="Výška poistného">
      <FormTextField
        name="cost.amount"
        label="Čiastka"
        type="number"
        required
      />
      <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
        source={currencies}
        name="cost.currency"
        label="Mena"
        labelMapper={autocompleteLabelMapper}
        required
      />
    </FormPanel>
  );
}

export function InsuranceCompanyFields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const insuranceCompanies = useInsuranceCompanies({
    apiUrl: EvidenceApiUrl.DICT_INSURANCE_COMPANY,
  });

  const { insuranceCompany } = useFormSelector((data: Insurance) => ({
    insuranceCompany: data.insuranceCompany,
  }));

  return (
    <FormPanel label="Poisťovna">
      <FormAutocomplete<DictInsuranceCompanyAutocomplete>
        source={insuranceCompanies}
        name="insuranceCompany"
        label="Názov poisťovne"
        after={
          mode === DetailMode.VIEW &&
          insuranceCompany?.id &&
          hasPermission(Permission.BO_INSURANCE_COMPANY_READ) && (
            <Redirect
              id={insuranceCompany.id}
              url={EvidenceBrowserUrl.DICT_INSURANCE_COMPANY}
            />
          )
        }
        labelMapper={autocompleteLabelMapper}
        required
      />
      <FormTextField
        name="insuranceCompany.identifier"
        label="IČO poisťovne"
        disabled
      />
    </FormPanel>
  );
}
