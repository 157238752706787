import React, { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  FormContext,
  FormLocalDateTimeField,
  FormTextArea,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, LicenceState, Permission } from '@enums';

export function useExpireDialog() {
  const { source } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const tzoffset = new Date().getTimezoneOffset() * 60000;

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.LICENCE}/${id}/expire`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    source?.data?.state !== LicenceState.EXPIRED &&
    hasPermission(Permission.BO_LICENCE_MANAGE);

  const FormFields = () => {
    const { setFieldValue } = useContext(FormContext);
    
    useEffect(() => {
      // -60000 - substract one minute from default time, so it won't collide with validation schema
      const now = new Date(Date.now() - 60000 - tzoffset)
        .toISOString()
        .slice(0, -1);
      setFieldValue('date', now);
    }, []);

    return (
      <>
        <FormLocalDateTimeField
          name="date"
          label="Dátum zaniknutia licencie"
          maxDatePicker={new Date().toISOString()}
          required
        />
        <FormTextArea name="reason" label="Dôvod" />
      </>
    );
  };

  const formValidationSchema = Yup.object().shape({
    reason: Yup.string().nullable(),
    date: Yup.date()
      .nullable()
      .max(
        new Date(Date.now() - tzoffset).toISOString().slice(0, -1),
        'Dátum zaniknutia nesmie byť v budúcnosti.'
      )
      .required('Povinné'),
  });

  return {
    apiCall,
    showExpireBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
