import {
  DictionaryObject,
  useFetch,
  useStaticListSource,
} from '@eas/common-web';
import { EvidenceApiUrl, LicenceApplicationType } from '@enums';

export function useLicenceApplicationStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.LICENCE_APPLICATION}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useLicenceApplicationTypes() {
  return useStaticListSource([
    { id: LicenceApplicationType.AUDITOR, name: 'Žiadosť pre zápis Audítora' },
    {
      id: LicenceApplicationType.AUDIT_COMPANY,
      name: 'Žiadosť pre zápis Audítorskej spoločnosti',
    },
  ]);
}
