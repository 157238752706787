import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { NavigationContext } from '@eas/common-web';
import { useStyles } from '@modules/login/login';

export function PasswordResetCompleted() {
  const classes = useStyles();

  const { navigate } = useContext(NavigationContext);

  const handleGoToLogin = () => {
    navigate('/');
  };

  return (
    <>
      <div className={classes.menubar} />
      <Container maxWidth="xs" className={classes.paper}>
        <Typography className={classes.title} variant="h5">
          Obnova hesla
        </Typography>
        <Typography variant="body1" align="center">
          Heslo bolo zmenené, pokračujte prihlásením do aplikácie.
        </Typography>
        <Button
          onClick={handleGoToLogin}
          variant="contained"
          disableElevation
          color="primary"
          className={classes.button}
        >
          <Typography
            component="span"
            variant="body1"
            className={classes.buttonLabel}
          >
            Prejsť na prihlásenie
          </Typography>
        </Button>
      </Container>
    </>
  );
}
