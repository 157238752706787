import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Role } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './role-columns';
import { Fields } from './role-fields';
import { useValidationSchema } from './role-schema';

export function Roles() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const permissions = useEvidencePermission([Permission.BO_USER_ROLE_MANAGE]);

  const evidence = useAuthoredEvidence<Role>({
    version: 2,
    identifier: 'ROLE',
    apiProps: {
      url: EvidenceApiUrl.ROLE,
    },
    tableProps: {
      columns,
      tableName: 'Role',
      showBulkActionButton: false,
      reportTag: ExportTag.ROLE,
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Rola',
        showBtn: permissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
