import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useAddPayment } from '@composite/payments/dialog-actions/add-payment-hook';
import { LicenceApplication } from '@models';
import { EvidenceApiUrl, LicenceApplicationState, Permission } from '@enums';

export function useAddPaymentDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } =
    useContext<DetailHandle<LicenceApplication>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(
      `${EvidenceApiUrl.LICENCE_APPLICATION}/${source?.data?.id}/add-payment`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({ id }),
      }
    )
  );

  const { FormFields, ...addPaymentProps } = useAddPayment(apiCall);

  const showBtn =
    hasPermission(Permission.BO_LICENCE_APPLICATION_MANAGE) &&
    !!source?.data?.state &&
    [
      LicenceApplicationState.NEW,
      LicenceApplicationState.INCORRECT_PAYMENT_AMOUNT,
    ].includes(source?.data?.state);

  return {
    showAddPaymentBtn: showBtn,
    FormFields: () => <FormFields disabledEmail />,
    ...addPaymentProps,
  };
}
