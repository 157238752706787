import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import {
  firstNameMapper,
  lastNameMapper,
} from '@components/form/misc/label-mappers';
import { User } from '@models';

export function useColumns(): TableColumn<User>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<User>();

  const { accountTypes } = useContext(StaticDataContext);
  const useTypes = () => useStaticListSource(accountTypes);

  return [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ účtu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'firstName',
      name: 'Meno',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: firstNameMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastName',
      name: 'Priezvisko',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: lastNameMapper,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'username',
      name: 'Prihlasovacie meno',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'email',
      name: 'E-mail',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
