import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';

export function Fields() {
  const { currencyTags } = useContext(StaticDataContext);
  const currencyTagSource = useStaticListSource(currencyTags);

  return (
    <>
      <FormPanel label="Obecné">
        <FormTextField name="symbol" label="Symbol" required />
        <FormSelect
          source={currencyTagSource}
          name="tag"
          label="Tag"
          valueIsId
        />
        <DictionaryEvidence.DictionaryFields />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
