import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TextFieldProps, useEventCallback } from '@eas/common-web';

const useStyles = makeStyles({
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fieldLabel: {
    fontSize: 14,
    fontWeight: 400,
  },
});

export function formTextFieldFactory({
  label,
  type,
}: {
  label?: string;
  type?: string;
}) {
  return function Field({
    onChange,
    value,
    name,
    className,
    error,
    helperText,
    form,
    disabled,
    size,
  }: Pick<
    TextFieldProps,
    'onChange' | 'value' | 'name' | 'error' | 'form' | 'disabled'
  > & {
    helperText?: string;
    size?: 'medium' | 'small';
  } & React.HTMLAttributes<HTMLInputElement>) {
    const classes = useStyles();

    const handleChange = useEventCallback(
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(e.target.value);
        }
      }
    );

    return (
      <div className={classes.fieldWrapper}>
        <Typography className={classes.fieldLabel}>{label}</Typography>
        <TextField
          variant="outlined"
          size={size}
          fullWidth
          name={name}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          type={type}
          className={className}
          error={error}
          helperText={helperText}
          inputProps={{
            form,
          }}
        />
      </div>
    );
  };
}
