import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { Stakeholder } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function useAuditCompanyColumns(): TableFieldColumn<Stakeholder>[] {
  const { stakeholderTypes } = useContext(StaticDataContext);
  const { hasPermission } = useContext(UserContext);
  const useTypes = () => useStaticListSource(stakeholderTypes);

  const columns: TableFieldColumn<Stakeholder>[] = [
    {
      datakey: 'subject.label',
      name: 'Subjekt',
      width: 200,
    },
    {
      datakey: 'types',
      name: 'Typ zainteresovanej strany',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useTypes,
        undefined,
        true,
        true
      ),
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 200,
    },
  ];

  return hasPermission(Permission.BO_SUBJECT_READ)
    ? [
        {
          datakey: 'subject.id',
          name: '',
          width: 70,
          CellComponent: function Cell({ value }) {
            return (
              <Redirect id={value} url={EvidenceBrowserUrl.NATURAL_PERSON} />
            );
          },
        },
        ...columns,
      ]
    : columns;
}

export function useSubjectColumns(): TableFieldColumn<Stakeholder>[] {
  const { stakeholderTypes } = useContext(StaticDataContext);
  const { hasPermission } = useContext(UserContext);
  const useTypes = () => useStaticListSource(stakeholderTypes);

  const columns: TableFieldColumn<Stakeholder>[] = [
    {
      datakey: 'auditCompany.label',
      name: 'Audítorská spoločnosť',
      width: 200,
    },
    {
      datakey: 'types',
      name: 'Typ zainteresovanej strany',
      width: 300,
      CellComponent: TableFieldCells.useSelectCellFactory(
        useTypes,
        undefined,
        true,
        true
      ),
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 200,
    },
  ];

  return hasPermission(Permission.BO_AUDITING_ENTITY_READ)
    ? [
        {
          datakey: 'auditCompany.id',
          name: '',
          width: 70,
          CellComponent: function Cell({ value }) {
            return (
              <Redirect id={value} url={EvidenceBrowserUrl.AUDIT_COMPANY} />
            );
          },
        },
        ...columns,
      ]
    : columns;
}
