import { useEvidencePermission } from '@utils/permission-hook';
import React, { useContext } from 'react';
import { green, grey, red } from '@material-ui/core/colors';
import { DetailContext, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Qualification } from '@models';
import {
  EvidenceApiUrl,
  ExportTag,
  Permission,
  QualificationState,
} from '@enums';
import { ActionBar } from './qualification-actionbar';
import { useColumns } from './qualification-columns';
import { Fields } from './qualification-fields';
import { useValidationSchema } from './qualification-schema';

export function Qualifications() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_QUALIFICATION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<Qualification>({
    version: 2,
    identifier: 'QUALIFICATION',
    apiProps: {
      url: EvidenceApiUrl.QUALIFICATION,
    },
    tableProps: {
      columns,
      tableName: 'Odbornosti',
      showBulkActionButton: false,
      reportTag: ExportTag.QUALIFICATION,
      createRowStyle: (q) =>
        q.state === QualificationState.APPROVED
          ? { color: green[500], backgroundColor: green[50] }
          : q.state === QualificationState.DECLINED
          ? { color: red[500], backgroundColor: red[50] }
          : q.state === QualificationState.REVOKED
          ? {
              color: grey[500],
              backgroundColor: grey[100],
            }
          : {},
    },
    detailProps: {
      toolbarProps: {
        title: 'Odbornosť',
        ActionBar,
        disableBtn: function useDisableBtn(btn) {
          const { source } = useContext(DetailContext);

          if (
            source?.data?.state &&
            source?.data?.state !== QualificationState.FOR_APPROVAL
          ) {
            return !['SHARE', 'REFRESH', 'NEW'].includes(btn);
          }

          return false;
        },
        showBtn: (btn) => {
          if (btn === 'REMOVE') {
            return false;
          }

          return permissions(btn);
        },
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
