import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import {
  useAuditingEntities,
  useAuditors,
  useCompanyAuditors,
} from '@modules/auditor/auditor-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { AddressFields } from '@components/form/address-panel/address-panel';
import { IdentifierField } from '@components/form/identifier-field/form-identifier-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditReport,
  AuditingEntity,
  AuditingEntityAutocomplete,
  FinancialStatementAuditReport,
} from '@models';
import { AuditingEntityType } from '@enums';
import { useFinancialStatementSchema } from '../audit-report-schema';

export function FinancialStatementFields() {
  const { mode } = useContext(DetailContext);
  const { owner, financialStatements } = useFormSelector(
    (data: AuditReport) => ({
      owner: data.owner,
      financialStatements: data.financialStatements,
    })
  );
  const schema = useFinancialStatementSchema();
  const { setFieldValue } = useContext(FormContext);

  return (
    <FormTableField
      disabled={!owner || mode !== DetailMode.NEW}
      name="financialStatements"
      labelOptions={{ hide: true }}
      layoutOptions={{ noSpacing: true }}
      notifyChange={(val) => {
        const statements = val as FinancialStatementAuditReport[];
        const isSVZpresent = (statements ?? []).some((s) => s.publicInterest);

        if (!isSVZpresent) {
          setFieldValue('overallAuditProfits', undefined);
        }
      }}
      columns={[
        {
          datakey: 'auditingAuditors',
          name: 'Vykonávatelia auditu',
          width: 300,
          CellComponent: TableFieldCells.useAutocompleteCellFactory<
            FinancialStatementAuditReport,
            AuditingEntityAutocomplete
          >({
            dataHook: useAuditingEntities,
            multiple: true,
            labelMapper: autocompleteLabelMapper,
          }),
        },
        {
          datakey: 'consolidated',
          name: 'Konsolidovaná účtovná závierka',
          width: 150,
          CellComponent: TableFieldCells.BooleanCell,
        },
        {
          datakey: 'individual',
          name: 'Individuálna účtovná závierka',
          width: 150,
          CellComponent: TableFieldCells.BooleanCell,
        },
        {
          datakey: 'tradingCompany',
          name: 'Obchodná spoločnosť',
          width: 150,
          CellComponent: TableFieldCells.BooleanCell,
        },
        {
          datakey: 'publicInterest',
          name: 'Subjekt verejného záujmu',
          width: 150,
          CellComponent: TableFieldCells.BooleanCell,
        },
        {
          datakey: 'auditCalendarYear',
          name: 'Kalendárny rok, v ktorom bol vykonaný audit',
          width: 150,
          CellComponent: TableFieldCells.NumberCell,
        },
        {
          datakey: 'auditedUnitInfo.businessName',
          name: 'Subjekt - Názov spoločnosti',
          width: 150,
          CellComponent: TableFieldCells.TextCell,
        },
        {
          datakey: 'auditedUnitInfo.identifier',
          name: 'Subjekt - IČO',
          width: 150,
          CellComponent: TableFieldCells.TextCell,
        },
      ]}
      initNewItem={() => ({
        id: uuidv4(),
        auditingAuditors:
          owner?.type === AuditingEntityType.AUDITOR ? [owner] : [],
      })}
      disabledAdd={
        owner?.type === AuditingEntityType.AUDITOR &&
        financialStatements?.length === 1
      }
      validationSchema={schema}
      FormFieldsComponent={(props) => (
        <FinancialStatementAuditReportFields {...props} owner={owner} />
      )}
    />
  );
}

function FinancialStatementAuditReportFields({
  owner,
}: {
  owner?: AuditingEntity;
}) {
  const { setFieldValue } = useContext(FormContext);
  const { mode } = useContext(DetailContext);
  const { publicInterestEntityCategories } = useContext(StaticDataContext);

  const { publicInterest, tradingCompany } = useFormSelector(
    (data: FinancialStatementAuditReport) => ({
      tradingCompany: data.tradingCompany,
      publicInterest: data.publicInterest,
    })
  );

  const allAuditors = useAuditors();
  const companyAuditors = useCompanyAuditors(owner?.id);
  const categorySource = useStaticListSource(publicInterestEntityCategories);

  return (
    <>
      <FormPanel label="Audit">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={
            owner?.type === AuditingEntityType.AUDIT_COMPANY
              ? companyAuditors
              : allAuditors
          }
          name="auditingAuditors"
          label="Vykonávatelia auditu"
          labelMapper={autocompleteLabelMapper}
          multiple
          required
          disabled={owner?.type === AuditingEntityType.AUDITOR}
        />
        <FormTextField
          type="number"
          name="auditCalendarYear"
          label="Kalendárny rok, v ktorom bol vykonaný audit"
        />
        <FormLocalDateField
          name="auditedUnitAccountingEndDate"
          label="Koniec účtovného obdobia účtovnej jednotky, za ktorého bol vykonaný audit"
          required
        />
        <FormCheckbox
          name="consolidated"
          label="Konsolidovaná účtovná závierka"
        />
        <FormCheckbox name="individual" label="Individuálna účtovná závierka" />
        <FormCheckbox
          name="tradingCompany"
          label="Obchodná spoločnosť"
          notifyChange={(value) => {
            if (!value && !publicInterest) {
              setFieldValue('auditStartYear', undefined);
            }
          }}
        />
        <FormCheckbox
          name="publicInterest"
          label="Subjekt verejného záujmu"
          notifyChange={(value) => {
            if (!value && !tradingCompany) {
              setFieldValue('auditStartYear', undefined);
            }

            if (!value) {
              setFieldValue('category', undefined);
              setFieldValue('subjectAuditProfits', undefined);
            }
          }}
        />
        {(tradingCompany || publicInterest) && (
          <FormTextField
            type="number"
            name="auditStartYear"
            label="Audit v SVZ/OS vykonávaný od roku"
            required={!!tradingCompany || !!publicInterest}
          />
        )}
        {publicInterest && (
          <FormSelect
            source={categorySource}
            name="category"
            label="Kategórie subjektu verejného záujmu"
            valueIsId
            disabled={!publicInterest}
            required={!!publicInterest}
          />
        )}
      </FormPanel>
      <FormPanel label="Účtovná jednotka">
        <IdentifierField
          name="auditedUnitInfo.identifier"
          notifyChange={(value) => setFieldValue('auditedUnitInfo', value)}
        />
        <FormTextField
          name="auditedUnitInfo.businessName"
          label="Názov spoločnosti"
          required
        />
        <AddressFields name="auditedUnitInfo.address" required />
      </FormPanel>
      {!!publicInterest && (
        <FormPanel label="Tržby za všetky auditované účtovné jednotky">
          <FormTextField
            type="number"
            name="subjectAuditProfits.statutoryAudit"
            label="Tržby za štatutárny audit"
            required={!!publicInterest}
          />
          <FormTextField
            type="number"
            name="subjectAuditProfits.nonAuditServicesB"
            label="Tržby za neaudítorské služby podľa čl. 14 b) Nariadenia č. 537/2014"
            required={!!publicInterest}
          />
          <FormTextField
            type="number"
            name="subjectAuditProfits.nonAuditServicesC"
            label="Tržby za neaudítorské služby podľa čl. 14 c) Nariadenia č. 537/2014"
            required={!!publicInterest}
          />
          <FormCheckbox
            name="subjectAuditProfits.auditCommittee"
            label="Výbor pre audit"
          />
          {mode === DetailMode.VIEW && (
            <FormTextField
              type="number"
              name="subjectAuditProfits.total"
              label="Tržby celkom"
              disabled
            />
          )}
        </FormPanel>
      )}
      <FormPanel label="Poznámka z Portálu">
        <FormTextArea
          name="portalNote"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
