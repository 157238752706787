import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { Payment } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function useColumns(): TableFieldColumn<Payment>[] {
  const { hasPermission } = useContext(UserContext);

  const { paymentStates } = useContext(StaticDataContext);
  const usePaymentStates = () => useStaticListSource(paymentStates);

  const columns: TableFieldColumn<Payment>[] = [
    ...(hasPermission(Permission.BO_PAYMENT_READ)
      ? [
          {
            datakey: 'id',
            name: '',
            width: 70,
            CellComponent: function Cell({ value }) {
              return <Redirect id={value} url={EvidenceBrowserUrl.PAYMENT} />;
            },
          } as TableFieldColumn<Payment>,
        ]
      : []),
    {
      datakey: 'charge.label',
      name: 'Poplatok',
      width: 200,
    },
    {
      datakey: 'state',
      name: 'Stav',
      CellComponent: TableFieldCells.useSelectCellFactory(usePaymentStates),
      width: 150,
    },
    {
      datakey: 'dueDate',
      name: 'Dátum splatnosti',
      CellComponent: TableFieldCells.DateCell,
      width: 150,
    },
    {
      datakey: 'receivedDate',
      name: 'Dátum prijatia platby',
      CellComponent: TableFieldCells.DateCell,
      width: 150,
    },
    {
      datakey: 'year',
      name: 'Platba na rok',
      width: 150,
    },
    {
      datakey: 'vs',
      name: 'Variabilný symbol',
      width: 150,
    },
    {
      datakey: 'amount.amount',
      name: 'Suma',
      width: 150,
    },
    {
      datakey: 'amount.currency.label',
      name: 'Mena',
      width: 150,
    },
    {
      datakey: 'receivedAmount.amount',
      name: 'Prijatá suma',
      width: 150,
    },
    {
      datakey: 'receivedAmount.currency.label',
      name: 'Mena',
      width: 150,
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 150,
    },
  ];

  return columns;
}
