import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  NavigationContext,
  UserContext,
  useFirstRender,
} from '@eas/common-web';
import { Login } from '@modules/login/login';
import { PasswordReset } from '@modules/password-reset/password-reset';
import { PasswordResetCompleted } from '@modules/password-reset/password-reset-completed';
import { PasswordResetConfirm } from '@modules/password-reset/password-reset-confirm';
import { EvidenceBrowserUrl } from './enums/evidence-url';

export function AppPublic() {
  const { navigate } = useContext(NavigationContext);
  const { user } = useContext(UserContext);

  useFirstRender(() => {
    if (user) {
      navigate(EvidenceBrowserUrl.HOME);
    }
  });

  return (
    <Switch>
      <Route
        path={EvidenceBrowserUrl.PASSWORD_RESET}
        exact
        component={PasswordReset}
      />
      <Route
        path={`${EvidenceBrowserUrl.PASSWORD_RESET}/heslo-obnoveno`}
        exact
        component={PasswordResetCompleted}
      />
      <Route
        path={`${EvidenceBrowserUrl.PASSWORD_RESET}/:secret`}
        exact={true}
        component={PasswordResetConfirm}
      />
      <Route path="/" component={Login} />
    </Switch>
  );
}
