/**
 * Právna forma audítorskej spoločnosti
 */
export enum CompanyLegalForm {
  /**
   * Verejná obchodná spoločnosť", "v.o.s.
   */
  PUBLIC_COMPANY = 'PUBLIC_COMPANY',

  /**
   * Komanditná spoločnosť", "k.s.
   */
  LIMITED_PARTNERSHIP_COMPANY = 'LIMITED_PARTNERSHIP_COMPANY',

  /**
   * Spoločnosť s ručením obmedzeným", "s.r.o.
   */
  LIMITED_LIABILITY_COMPANY = 'LIMITED_LIABILITY_COMPANY',

  /**
   * Akciová spoločnosť", "a.s.
   */
  JOINT_STOCK_COMPANY = 'JOINT_STOCK_COMPANY',
}

/**
 * Stav pozvánky
 */
export enum EmploymentInvitationState {
  /**
   * Poslaná audítorovi
   */
  SENT = 'SENT',

  /**
   * Zrušená spoločnosťou
   */
  WITHDRAWN = 'WITHDRAWN',

  /**
   * Potvrdená audítorom
   */
  ACCEPTED = 'ACCEPTED',

  /**
   * Zamietnutá audítorom
   */
  REJECTED = 'REJECTED',
}
