import * as Yup from 'yup';
import {
  DictEducationField,
  DictEducationForm,
  EducationalTraining,
} from '@models';

export function useValidationSchema() {
  return Yup.object<EducationalTraining>().shape({
    name: Yup.string().nullable().required(),
    form: Yup.mixed<DictEducationForm>().nullable().required(),
    educationField: Yup.mixed<DictEducationField>().nullable().required(),
    hours: Yup.number().min(1, 'Musí byť kladné číslo').nullable().required(),
    fromDate: Yup.string().nullable().required(),
    toDate: Yup.string().nullable().required(),
  });
}
