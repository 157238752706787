import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useDocuments } from '@modules/document/document-api';
import { useUsers } from '@modules/user/user-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  DocumentAutocomplete,
  GeneralSubmission,
  UserAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const {
    generalSubmissionAgendas,
    generalSubmissionTypes,
    generalSubmissionStates,
  } = useContext(StaticDataContext);

  const auditingEntities = useAuditingEntities();
  const users = useUsers();
  const documents = useDocuments();

  const generalSubmissionAgendaSource = useStaticListSource(
    generalSubmissionAgendas
  );

  const generalSubmissionTypeSource = useStaticListSource(
    generalSubmissionTypes
  );

  const generalSubmissionStateSource = useStaticListSource(
    generalSubmissionStates
  );

  const { reply, attachment, owner } = useFormSelector(
    (data: GeneralSubmission) => ({
      reply: data.reply,
      attachment: data.attachment,
      owner: data.owner,
    })
  );

  return (
    <>
      <FormPanel label="Všeobecný podnet">
        <FormTextField label="Vec podnetu" name="matter" required />
        <FormTextField label="Poznámka z Portálu" name="portalNote" />
        <FormAutocomplete<AuditingEntityAutocomplete>
          name="owner"
          label="Vlastník podnetu"
          source={auditingEntities}
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          required
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        <FormSelect
          source={generalSubmissionAgendaSource}
          name="agenda"
          label="Agenda"
          valueIsId
          required
        />
        <FormSelect
          source={generalSubmissionTypeSource}
          name="type"
          label="Typ"
          valueIsId
          required
        />
        <FormSelect
          source={generalSubmissionStateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
        <FormAutocomplete<DocumentAutocomplete>
          name="attachment"
          label="Príloha"
          source={documents}
          labelMapper={autocompleteLabelMapper}
          after={
            attachment?.id &&
            hasPermission(Permission.BO_DOCUMENT_READ) && (
              <Redirect id={attachment.id} url={EvidenceBrowserUrl.DOCUMENT} />
            )
          }
        />
        <FormAutocomplete<UserAutocomplete>
          name="solvers"
          label="Riešitelia"
          source={users}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <FormPanel label="Text podnetu">
        <FormTextArea name="text" labelOptions={{ hide: true }} required />
      </FormPanel>
      {reply && (
        <FormPanel label="Odpoveď">
          <FormTextField label="Autor" name="reply.replyOwner.name" disabled />
          <FormLocalDateField
            name="reply.repliedDate"
            label="Dátum odpovedi"
            disabled
          />
          <FormTextArea label="Obsah" name="reply.replyText" disabled />
        </FormPanel>
      )}
    </>
  );
}
