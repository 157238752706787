import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { Insurance } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function useColumns(): TableFieldColumn<Insurance>[] {
  const { hasPermission } = useContext(UserContext);

  const columns: TableFieldColumn<Insurance>[] = [
    {
      datakey: 'insuranceCompany.label',
      name: 'Názov poisťovne',
      width: 150,
    },
    {
      datakey: 'insuranceCompany.address.label',
      name: 'Adresa poisťovne',
      width: 150,
    },
    {
      datakey: 'insuranceCompany.identifier',
      name: 'IČO poisťovne',
      width: 150,
    },
    {
      datakey: 'cost.label',
      name: 'Výška poistného',
      width: 150,
    },
    {
      datakey: 'number',
      name: 'Číslo poistnej zmluvy',
      width: 150,
    },
    {
      datakey: 'validFrom',
      name: 'Zmluva platná od',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'validTo',
      name: 'Zmluva platná do',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
  ];

  return hasPermission(Permission.BO_INSURANCE_READ)
    ? [
        {
          datakey: 'id',
          name: '',
          width: 70,
          CellComponent: function Cell({ value }) {
            return <Redirect id={value} url={EvidenceBrowserUrl.INSURANCE} />;
          },
        },
        ...columns,
      ]
    : columns;
}
