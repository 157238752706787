import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditCompanies } from '@modules/audit-company/audit-company-api';
import { useAuditors } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { PaymentField } from '@composite/payments/payment-field';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import {
  AddressFields,
  FormAddressPanel,
} from '@components/form/address-panel/address-panel';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { IdentifierField } from '@components/form/identifier-field/form-identifier-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditCompanyAutocomplete,
  AuditorAutocomplete,
  CompanyRelatedSubjectData,
  DictCountryAutocomplete,
  LicenceApplication,
  UserAutocomplete,
} from '@models';
import {
  EvidenceBrowserUrl,
  LicenceApplicationType,
  Permission,
  SubjectType,
} from '@enums';
import { useLicenceApplicationTypes } from './licence-application-api';
import {
  useCompanyEmployeeSchema,
  useForeignLicenceSchema,
  useRelatedSubjectSchema,
} from './licence-application-schema';

export function Fields() {
  const { licenceApplicationStates } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const auditors = useAuditors();
  const backofficeUsers = useBackofficeUsers();
  const stateSource = useStaticListSource(licenceApplicationStates);
  const types = useLicenceApplicationTypes();

  const { type, applicant } = useFormSelector((data: LicenceApplication) => ({
    type: data.type,
    applicant: data.applicant,
  }));

  return (
    <>
      <FormPanel label="Obecné">
        <FormSelect source={types} name="type" label="Typ" valueIsId required />
        <FormAutocomplete<AuditorAutocomplete>
          source={auditors}
          name="applicant"
          label="Žiadateľ"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          after={
            applicant?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect url={EvidenceBrowserUrl.AUDITOR} id={applicant.id} />
            )
          }
        />
        <FormSelect
          source={stateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
        <FormTextField name="email" label="Kontaktný email" required />
        <FormTextField
          name="phone"
          label="Kontaktné telefonné čislo"
          required
        />
        <FormLocalDateField
          name="processUntil"
          label="Najneskorší termín rozhodnutia"
          disabled
        />
        <FormLocalDateField
          name="decisionDate"
          label="Dátum rozhodnutia"
          disabled
        />
        <FormTextArea name="decisionText" label="Text rozhodnutia" disabled />
        <FormCheckbox name="expired" label="Expirovaná žiadosť" disabled />
      </FormPanel>
      {type === LicenceApplicationType.AUDIT_COMPANY && <CompanyDataFields />}
      {type === LicenceApplicationType.AUDITOR && <AuditorFields />}
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <PaymentField />
      <FormPanel label="Poznámka z Portálu">
        <FormTextArea
          name="portalNote"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          disabled
        />
      </FormPanel>
    </>
  );
}

function CompanyDataFields() {
  const { legalForms } = useContext(StaticDataContext);
  const { setFieldValue } = useContext(FormContext);

  const legalFormSource = useStaticListSource(legalForms);
  const countries = useCountries();

  return (
    <>
      <FormPanel label="Údaje spoločnosti">
        <IdentifierField
          name="companyData.identifier"
          notifyChange={(value) => {
            setFieldValue('companyData', value);
          }}
        />
        <FormTextField
          name="companyData.businessName"
          label="Názov spoločnosti"
          required
        />
        <FormTextField name="companyData.website" label="Internetová adresa" />
        <FormTextField
          name="companyData.phone"
          label="Telefónne číslo"
          required
        />
        <FormTextField name="companyData.email" label="E-mail" required />
        <FormAutocomplete<DictCountryAutocomplete>
          source={countries}
          name="companyData.homeCountry"
          label="Domovská krajina"
          required
          labelMapper={autocompleteLabelMapper}
        />
        <FormTextField
          name="companyData.contactPerson"
          label="Kontaktná osoba"
        />
        <FormSelect
          source={legalFormSource}
          name="companyData.legalForm"
          label="Právna forma"
          valueIsId
          required
        />
        <FormTextArea
          name="companyData.membership"
          label="Členstvo AS v sieti a zoznam subjektov začlenených do siete"
        />
      </FormPanel>
      <FormAddressPanel
        name="companyData.address"
        label="Adresa spoločnosti"
        required
      />
      <FormAddressPanel
        name="companyData.mailAddress"
        label="Korešpondenčná adresa spoločnosti"
        required
      />
      <ForeignLicencesField name="companyData.foreignLicences" />
      <RelatedSubjectField name="companyData.relatedSubjects" />
      <CompanyEmployeeField />
      <OfficeField />
    </>
  );
}

function AuditorFields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const auditCompanies = useAuditCompanies();
  const { setFieldValue } = useContext(FormContext);

  const { selfEmployedPerson, employer } = useFormSelector(
    (data: LicenceApplication) => ({
      selfEmployedPerson: data.selfEmployedPerson,
      employer: data.employer,
    })
  );

  return (
    <>
      {(mode === DetailMode.NEW || selfEmployedPerson) && (
        <FormCheckbox
          name="selfEmployedPerson"
          label="Samostatne zárobkovo činná osoba (SZČO)"
          notifyChange={() => setFieldValue('employer', undefined)}
          disabled={!!employer || mode !== DetailMode.NEW}
        />
      )}
      {(mode === DetailMode.NEW || !selfEmployedPerson) && (
        <FormAutocomplete<AuditCompanyAutocomplete>
          source={auditCompanies}
          name="employer"
          label="Zamestnávateľ"
          labelMapper={autocompleteLabelMapper}
          notifyChange={() => setFieldValue('selfEmployedPerson', undefined)}
          disabled={selfEmployedPerson || mode !== DetailMode.NEW}
          after={
            employer?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.AUDIT_COMPANY}
                id={employer.id}
              />
            )
          }
        />
      )}
    </>
  );
}

export function ForeignLicencesField({ name }: { name: string }) {
  const { mode } = useContext(DetailContext);
  const schema = useForeignLicenceSchema();

  const FormFields = () => {
    const countries = useCountries();

    return (
      <>
        <FormTextField name="number" label="Číslo" required />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" required />
        <FormAutocomplete<DictCountryAutocomplete>
          source={countries}
          name="origin.country"
          label="Krajina pôvodu"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          required
        />
        <FormTextArea
          name="note"
          label="Poznámka"
          layoutOptions={{ noSpacing: true }}
        />
      </>
    );
  };

  return (
    <FormPanel label="Licencie zo zahraničia">
      <FormTableField
        name={name}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabledAdd={mode !== DetailMode.NEW}
        disabledEdit={mode !== DetailMode.NEW}
        disabledRemove={mode !== DetailMode.NEW}
        showRadioCond={() => mode === DetailMode.NEW}
        columns={[
          {
            datakey: 'number',
            name: 'Číslo',
            width: 150,
          },
          {
            datakey: 'issuedDate',
            name: 'Dátum vydania',
            width: 150,
            CellComponent: TableFieldCells.DateCell,
          },
          {
            datakey: 'origin.country.label',
            name: 'Krajina pôvodu',
            width: 150,
          },
          {
            datakey: 'origin.institution',
            name: 'Vydávajúci orgán',
            width: 150,
          },
          {
            datakey: 'note',
            name: 'Poznámka',
            width: 150,
          },
        ]}
        validationSchema={schema}
        FormFieldsComponent={FormFields}
      />
    </FormPanel>
  );
}

function OfficeField() {
  const { mode } = useContext(DetailContext);
  const schema = useAddressValidationSchema();

  return (
    <FormPanel label="Kancelárie">
      <FormTableField
        name="companyData.offices"
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabledAdd={mode !== DetailMode.NEW}
        disabledEdit={mode !== DetailMode.NEW}
        disabledRemove={mode !== DetailMode.NEW}
        showRadioCond={() => mode === DetailMode.NEW}
        columns={[
          {
            datakey: 'street',
            name: 'Ulice',
            width: 150,
          },
          {
            datakey: 'houseNumber',
            name: 'Číslo budovy',
            width: 150,
          },
          {
            datakey: 'zip',
            name: 'PSČ',
            width: 150,
          },
          {
            datakey: 'municipality',
            name: 'Obec',
            width: 150,
          },
          {
            datakey: 'country.label',
            name: 'Krajina',
            width: 150,
          },
          {
            datakey: 'poBox',
            name: 'P.O. Box',
            width: 150,
          },
        ]}
        FormFieldsComponent={() => <AddressFields required />}
        validationSchema={schema}
      />
    </FormPanel>
  );
}

export function RelatedSubjectField({ name }: { name: string }) {
  const { mode } = useContext(DetailContext);
  const { subjectTypes, stakeholderTypes } = useContext(StaticDataContext);
  const useTypes = () => useStaticListSource(subjectTypes);

  const validationSchema = useRelatedSubjectSchema();

  const FormFields = () => {
    const { setFieldValue } = useContext(FormContext);

    const { type } = useFormSelector((data: CompanyRelatedSubjectData) => ({
      type: data.type,
    }));
    const typeSource = useStaticListSource(subjectTypes);
    const stakeholderTypeSource = useStaticListSource(stakeholderTypes);

    return (
      <>
        <FormPanel label="Subjekt">
          <FormSelect
            source={typeSource}
            name="type"
            label="Typ subjektu"
            valueIsId
            required
            disabled={mode !== DetailMode.NEW}
          />
          <FormSelect
            source={stakeholderTypeSource}
            name="relationTypes"
            label="Typ zainteresovanej strany"
            multiple
            valueIsId
            required
          />
          {type === SubjectType.NATURAL && (
            <>
              <FormTextField name="titleBefore" label="Titul pred" />
              <FormTextField name="firstName" label="Meno" required />
              <FormTextField name="lastName" label="Priezvisko" required />
              <FormTextField name="titleAfter" label="Titul za" />
            </>
          )}
          {type === SubjectType.LEGAL && (
            <>
              <IdentifierField
                notifyChange={({ businessName, address }) => {
                  setFieldValue('businessName', businessName);
                  setFieldValue('address', address);
                }}
              />
              <FormTextField
                name="businessName"
                label="Obchodné meno"
                required
              />
            </>
          )}
        </FormPanel>
        <FormAddressPanel required />
      </>
    );
  };

  return (
    <FormPanel label="Subjekty prepojené s AS">
      <FormTableField
        name={name}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabledAdd={mode !== DetailMode.NEW}
        disabledEdit={mode !== DetailMode.NEW}
        disabledRemove={mode !== DetailMode.NEW}
        showRadioCond={() => mode === DetailMode.NEW}
        columns={[
          {
            datakey: 'type',
            name: 'Typ subjektu',
            width: 150,
            CellComponent: TableFieldCells.useSelectCellFactory(useTypes),
          },
          {
            datakey: 'titleBefore',
            name: 'Titul pred',
            width: 150,
          },
          {
            datakey: 'firstName',
            name: 'Meno',
            width: 150,
          },
          {
            datakey: 'lastName',
            name: 'Priezvisko',
            width: 150,
          },
          {
            datakey: 'titleAfter',
            name: 'Titul za',
            width: 150,
          },
          {
            datakey: 'businessName',
            name: 'Obchodné meno',
            width: 150,
          },
          {
            datakey: 'identifier',
            name: 'IČO',
            width: 150,
          },
        ]}
        validationSchema={validationSchema}
        FormFieldsComponent={FormFields}
      />
    </FormPanel>
  );
}

function CompanyEmployeeField() {
  const { mode } = useContext(DetailContext);
  const validationSchema = useCompanyEmployeeSchema();

  const FormFields = () => {
    const auditors = useAuditors();

    return (
      <>
        <FormAutocomplete<AuditorAutocomplete>
          source={auditors}
          name="employee"
          label="Zamestnanec"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextArea name="position" label="Popis pozície" required />
      </>
    );
  };

  return (
    <FormPanel label="Zoznam zamestaných auditorov">
      <FormTableField
        name="companyData.companyEmployees"
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabledAdd={mode !== DetailMode.NEW}
        disabledEdit={mode !== DetailMode.NEW}
        disabledRemove={mode !== DetailMode.NEW}
        showRadioCond={() => mode === DetailMode.NEW}
        columns={[
          {
            datakey: 'employee.label',
            name: 'Zamestnanec',
            width: 400,
          },
          {
            datakey: 'position',
            name: 'Popis pozície',
            width: 450,
          },
        ]}
        validationSchema={validationSchema}
        FormFieldsComponent={FormFields}
      />
    </FormPanel>
  );
}
