import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
  textButton: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableActions: {
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    width: '100%',
    boxSizing: 'border-box',
    verticalAlign: 'top',
    display: 'flex',
    padding: '0 16px',
    '& svg': {
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: '2pt',
    },
  },
  divider: {
    background: theme.palette.primary.light,
    width: 1,
    minWidth: 1,
  },
  buttonGroup: {
    display: 'flex',
    marginBottom: 8,
  },
  buttonLabel: {
    textTransform: 'uppercase',
  },
}));
