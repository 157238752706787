import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { DisciplinaryRecord, Document } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';


export function useDisciplinaryRecordColumns(): TableFieldColumn<DisciplinaryRecord>[] {
  const { hasPermission } = useContext(UserContext);
  return [
    {
      datakey: 'sanction',
      name: 'Sankce',
      width: 250,
    },
    {
      datakey: 'measure',
      name: 'Opatrenie',
      width: 250,
    },
    {
      datakey: 'date',
      name: 'Dátum',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'documents',
      name: 'Dokumenty',
      width: 300,
      CellComponent: ({ value }: { value: Document[] }) => {
        if (!hasPermission(Permission.BO_DOCUMENT_READ)) {
          return <></>;
        }
        return (
          <>
            {value.map((doc) => (
              <>
                <span style={{ marginRight: 4 }}>
                  {doc.label || doc.name || ''}
                </span>
                <Redirect id={doc.id} url={EvidenceBrowserUrl.DOCUMENT} />
              </>
            ))}
          </>
        );
      },
    },
  ];
}
