import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useAddPayment } from '@composite/payments/dialog-actions/add-payment-hook';
import { ExamApplication } from '@models';
import { EvidenceApiUrl, ExamApplicationState, Permission } from '@enums';

export function useAddPaymentDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<ExamApplication>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(
      `${EvidenceApiUrl.EXAM_APPLICATION}/${source?.data?.id}/add-payment`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({ id }),
      }
    )
  );

  const { FormFields, ...addPaymentProps } = useAddPayment(apiCall);

  const showBtn =
    hasPermission(Permission.BO_EXAM_APPLICATION_MANAGE) &&
    source?.data?.state &&
    [
      ExamApplicationState.APPROVED,
      ExamApplicationState.INCORRECT_PAYMENT_AMOUNT,
    ].includes(source.data.state);

  return {
    showAddPaymentBtn: showBtn,
    FormFields: () => <FormFields disabledEmail />,
    ...addPaymentProps,
  };
}
