/**
 * Typ uživateľského účtu
 */
export enum AccountType {
  /**
   * Backoffice účet
   */
  BACKOFFICE = 'BACKOFFICE',

  /**
   * Portálový účet
   */
  PORTAL = 'PORTAL',

}
