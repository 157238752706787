import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { DictEducationField } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function DictEducationFields() {
  const permissions = useEvidencePermission([Permission.BO_EDUCATION_MANAGE]);

  const evidence = DictionaryEvidence.useDictionaryEvidence<DictEducationField>(
    {
      identifier: 'DICT_EDUCATION_FIELD',
      apiProps: {
        url: EvidenceApiUrl.DICT_EDUCATION_FIELD,
      },
      tableProps: {
        tableName: 'Oblasti vzdelávania',
        showBulkActionButton: false,
        showReportButton: false,
      },
      detailProps: {
        toolbarProps: {
          title: 'Oblasť vzdelávania',
          showBtn: permissions,
        },
      },
    }
  );

  return <Evidence {...evidence} />;
}
