/**
 * Typ odbornosti podľa SKAU
 */
export enum SkauType {
  /**
   * Daňový poradca
   */
  TAX_ADVISOR = 'TAX_ADVISOR',

  /**
   * Asistent daňového poradcu
   */
  TAX_ADVISOR_ASSISTENT = 'TAX_ADVISOR_ASSISTENT',

  /**
   * Súdný znalec
   */
  EXPERT_WITNESS = 'EXPERT_WITNESS',

  /**
   * Exekútor
   */
  EXECUTOR = 'EXECUTOR',

  /**
   * Správa konkurznej podstaty
   */
  BANCRUPCY_ESSENCE_ADMINISTRATION = 'BANCRUPCY_ESSENCE_ADMINISTRATION',

  /**
   * Správa konkurzu a reštrukturalizácie
   */
  BANCRUPCY_ADMINISTRATION_AND_RESTRUCTURALISATION = 'BANCRUPCY_ADMINISTRATION_AND_RESTRUCTURALISATION',

  /**
   * Český audítor
   */
  CZECH_AUDITOR = 'CZECH_AUDITOR',

  /**
   * Certifikovaný účtovník
   */
  CERTIFICATED_ACCOUNTANT = 'CERTIFICATED_ACCOUNTANT',

  /**
   * Špecialista PHARE programy
   */
  PHARE_PROGRAM_SPECIALIST = 'PHARE_PROGRAM_SPECIALIST',

  /**
   * Certifikovaný účtovník - expert
   */
  CERTIFIED_ACCOUNTANT_EXPERT = 'CERTIFIED_ACCOUNTANT_EXPERT',

  /**
   * Skúšky IAS/IFRS
   */
  IAS_IFRS_EXAMS = 'IAS_IFRS_EXAMS',

  /**
   * ACCA
   */
  ACCA = 'ACCA',

  /**
   * Certifikovaný interný audítor
   */
  CERTIFICATED_INTERNAL_AUDITOR = 'CERTIFICATED_INTERNAL_AUDITOR',

  /**
   * Iné - Ostatné, inde neuvedené
   */
  OTHER_NOT_SPECIFIED = 'OTHER_NOT_SPECIFIED',

  /**
   * Iné - Verejné obstarávanie
   */
  OTHER_PROCUREMENT = 'OTHER_PROCUREMENT',

  /**
   * Iné - Účtovník expert, Certifikovaný účtovník
   */
  OTHER_ACCOUNTANT_EXPERT_CERTIFICATED_ACCOUNTANT = 'OTHER_ACCOUNTANT_EXPERT_CERTIFICATED_ACCOUNTANT',

  /**
   * Iné - IFRS expert
   */
  OTHER_IFRS_EXPERT = 'OTHER_IFRS_EXPERT',

  /**
   * Iné - Advokát
   */
  OTHER_ADVOCATE = 'OTHER_ADVOCATE',

  /**
   * Iné - Asistent daňového poradcu
   */
  OTHER_TAX_ADVISOR_ASSISTENT = 'OTHER_TAX_ADVISOR_ASSISTENT',

  /**
   * Iné - účtovník
   */
  OTHER_ACCOUNTANT = 'OTHER_ACCOUNTANT',

  /**
   * Iné - LEKTOR účtovníctvo
   */
  OTHER_ACCOUNTING_LECTURER = 'OTHER_ACCOUNTING_LECTURER',

  /**
   * FCCA
   */
  FCCA = 'FCCA',

  /**
   * Iné
   */
  OTHER = 'OTHER',

  /**
   * FRM - Financial Risk Manager
   */
  FRM = 'FRM',

  /**
   * DipIIFR
   */
  DIPIIFR = 'DIPIIFR',

  /**
   * Vládny audítor
   */
  GOVERNMENT_AUDITOR = 'GOVERNMENT_AUDITOR',
}

/**
 * Stav odbornosti
 */
export enum QualificationState {
  /**
   * Na schválenie
   */
  FOR_APPROVAL = 'FOR_APPROVAL',

  /**
   * Schválená
   */
  APPROVED = 'APPROVED',

  /**
   * Zamietnutá
   */
  DECLINED = 'DECLINED',

  /**
   * Odobratá
   */
  REVOKED = 'REVOKED',
}
