/**
 * Typ skúšky
 */
export enum ExamType {
  /**
   * Audítorská skúška
   */
  AUDIT = 'AUDIT',

  /**
   * Skúška spôsobilosti
   */
  CAPABILITY = 'CAPABILITY',

  /**
   * Preskúšanie
   */
  RETEST = 'RETEST',
}
