import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import {
  AuditingEntityAutocomplete,
  DictCountryAutocomplete,
  Qualification,
} from '@models';

export function useColumns(): TableColumn<Qualification>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<Qualification>();

  const { skauTypes, qualificationStates } = useContext(StaticDataContext);

  const useSkauTypes = () => useStaticListSource(skauTypes);
  const useStates = () => useStaticListSource(qualificationStates);

  return [
    {
      datakey: 'number',
      name: 'Číslo',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'skauType',
      sortkey: 'skauType.name',
      filterkey: 'skauType.id',
      name: 'Typ odbornosti podľa SKAU',
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useSkauTypes),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useSkauTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Subjekt odbornosti',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Platnosť do',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'verdictDate',
      name: 'Dátum verdiktu',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'revokedDate',
      name: 'Dátum odobrania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'origin.country',
      sortkey: 'origin.country.name',
      filterkey: 'origin.country.id',
      name: 'Krajina pôvodu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictCountryAutocomplete>(
          useCountries,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'origin.institution',
      name: 'Vydávajúci orgán',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
