import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useAddPayment } from '@composite/payments/dialog-actions/add-payment-hook';
import { AuditNotice } from '@models';
import { AuditNoticeState, EvidenceApiUrl, Permission } from '@enums';

export function useAddPaymentDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<AuditNotice>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(
      `${EvidenceApiUrl.AUDIT_NOTICE}/${source?.data?.id}/add-payment`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({ id }),
      }
    )
  );

  const { FormFields, ...addPaymentProps } = useAddPayment(apiCall);

  const showBtn =
    hasPermission(Permission.BO_AUDIT_NOTICE_MANAGE) &&
    !!source?.data?.state &&
    [AuditNoticeState.NEW, AuditNoticeState.INCORRECT_PAYMENT_AMOUNT].includes(
      source?.data?.state
    );

  return {
    showAddPaymentBtn: showBtn,
    FormFields: () => <FormFields />,
    ...addPaymentProps,
  };
}
