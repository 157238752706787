import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  listItemsFactory,
  useScrollableSource,
} from '@eas/common-web';
import {
  DISCIPLINARY_RECORD_SOURCE,
  EMPLOYMENT_SOURCE,
  INSURANCE_SOURCE,
} from '@modules/audit-company/audit-company-hooked-sources';
import { Auditor } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

function useDisciplinaryRecordSource(): HookedSource<Auditor> {
  const source = useScrollableSource({
    listItems: listItemsFactory({
      postProcess: async (data) => {
        console.log({ data });
        return data;
      },
    }),
  });
  const { hasPermission } = useContext(UserContext);

  return {
    [DISCIPLINARY_RECORD_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_INSURANCE_READ),

      modifyBeforeLoad: ({ id }) => {
        console.log('mnodifing');
        source.setUrl(
          `${EvidenceApiUrl.AUDITING_ENTITY}/${id}/disciplinary/list`
        );
        source.setParams({
          size: -1,
          filters: [],
        });
      },
    },
  };
}

function useInsuranceSource(): HookedSource<Auditor> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [INSURANCE_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_INSURANCE_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.INSURANCE}/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'insuredSubjects.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useEmploymentSource(): HookedSource<Auditor> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [EMPLOYMENT_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_EMPLOYMENT_READ),
      modifyBeforeLoad: ({ subject }) => {
        source.setUrl(`${EvidenceApiUrl.AUDIT_COMPANY}/employments/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'subject.id',
              operation: ApiFilterOperation.EQ,
              value: subject?.id,
            },
          ],
        });
      },
    },
  };
}

export function useAuditorHookedSources(): HookedSource<Auditor> {
  const insurances = useInsuranceSource();
  const employments = useEmploymentSource();
  const disciplinaryRecords = useDisciplinaryRecordSource();

  return { ...insurances, ...employments, ...disciplinaryRecords };
}
