/**
 * Typ osobného kontaktu
 */
export enum PersonContactType {
  /**
   * Hlavný e-mail
   */
  MAIN_EMAIL = 'MAIN_EMAIL',

  /**
   * Vedľajší e-mail
   */
  OTHER_EMAIL = 'OTHER_EMAIL',

  /**
   * Fax
   */
  FAX = 'FAX',

  /**
   * Tel. číslo (mobilné)
   */
  PHONE_MOBILE = 'PHONE_MOBILE',

  /**
   * Tel. číslo (pevná linka)
   */
  PHONE_LANDLINE = 'PHONE_LANDLINE',

  /**
   * Iný kontakt
   */
  OTHER = 'OTHER',
}
