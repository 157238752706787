import React, { useContext } from 'react';
import {
  FormContext,
  FormPanel,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { ContactField } from '@composite/contacts/contact-field';
import { EmploymentField } from '@composite/employments/employment-field';
import { StakeholderField } from '@composite/stakeholders/stakeholder-field';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { IdentifierField } from '@components/form/identifier-field/form-identifier-field';
import { Permission } from '@enums';

export function Fields() {
  const { hasPermission } = useContext(UserContext);

  return (
    <>
      <GeneralFields />
      <AddressFields />
      {hasPermission(Permission.BO_STAKEHOLDER_READ) && (
        <StakeholderField
          label="Zoznam rolí"
          disabled={true}
          display="IN_SUBJECT"
        />
      )}
      {hasPermission(Permission.BO_EMPLOYMENT_READ) && (
        <EmploymentField display="IN_SUBJECT" disabled />
      )}
      <ContactField />
      <NoteField />
    </>
  );
}

export function GeneralFields() {
  const { getFieldValues, setFieldValues } = useContext(FormContext);

  return (
    <FormPanel label="Subjekt">
      <FormTextField name="businessName" label="Názov spoločnosti" required />
      <IdentifierField
        notifyChange={(values) => {
          const currentValues = getFieldValues();
          setFieldValues({ ...currentValues, ...values });
        }}
      />
    </FormPanel>
  );
}

export function AddressFields() {
  return (
    <FormAddressPanel name="address" label="Adresa spoločnosti" required />
  );
}

export function NoteField() {
  return (
    <FormPanel label="Poznámka">
      <FormTextField
        name="note"
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
      />
    </FormPanel>
  );
}
