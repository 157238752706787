import { get, noop, stubFalse } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AutocompleteContext,
  AutocompleteInput,
  AutocompletePopup,
  AutocompleteSource,
  DetailContext,
  DetailMode,
  FormContext,
  FormCustomField,
  FormFieldContext,
  InfiniteListHandle,
  TextField,
  useAutocompleteValue,
  useEventCallback,
  useFormSelector,
} from '@eas/common-web';
import { SearchByIcoResponseDto, Subject } from '@models';
import { useRPOSync } from './rpo-sync-hook';

const defaultSource = {
  loading: false,
  reset: noop,
  setSearchQuery: noop,
  items: [],
  hasNextPage: stubFalse,
  isDataValid: stubFalse,
  loadMore: async () => Promise.resolve({ items: [], count: 0 }),
  loadDetail: async () => Promise.resolve({} as SearchByIcoResponseDto),
  setUrl: noop,
  setParams: noop,
  setLoading: noop,
  count: 0,
  getParams: () => ({}),
};

const labelMapper = (item: SearchByIcoResponseDto) =>
  `${item?.businessName} (${item?.address?.street} ${item?.address?.houseNumber}, ${item?.address?.zip} ${item?.address?.municipality})`;

export function IdentifierField({
  notifyChange,
  name = 'identifier',
  required = true,
  disabled = false,
}: {
  notifyChange: (item: SearchByIcoResponseDto) => void;
  name?: string;
  required?: boolean;
  disabled?: boolean;
}) {
  const anchorElRef = useRef<HTMLDivElement>(null);
  const infiniteListRef = useRef<InfiniteListHandle<any>>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const formFieldContext = useContext(FormFieldContext);
  const { setFieldValue, editing } = useContext(FormContext);
  const { mode, source: detailSource } = useContext(DetailContext);
  const identifier = useFormSelector((data: Subject) => get(data, name) ?? '');

  const [popupOpen, setPopupOpen] = useState(false);
  const [textValue, setTextValue] = useState<string>(identifier);
  const [source, setSource] =
    useState<AutocompleteSource<SearchByIcoResponseDto>>(defaultSource);

  const { loadRPOValues } = useRPOSync({
    setSource,
    setPopupOpen,
    source: source,
  });

  useEffect(() => {
    setSource(defaultSource);
  }, [detailSource.data]);

  const handleTextValueChange = useEventCallback(async (value) => {
    value = value.replace(/[^0-9]/gi, '').substring(0, 8);
    await loadRPOValues(value, value !== identifier);
    setTextValue(value);
    setFieldValue(name, value);
  });

  const handlePopupOpen = useEventCallback((value) => {
    if (!value) {
      setPopupOpen(false);
    } else {
      loadRPOValues(textValue);
    }
  });

  const handleSelectItem = useEventCallback(
    async (value: SearchByIcoResponseDto) => {
      notifyChange(value);
      setPopupOpen(false);
    }
  );

  const commonProps = {
    setTextValue: handleTextValueChange,
    setPopupOpen: handlePopupOpen,
    handleSelectItem: handleSelectItem,
    handleClear: noop,
    infiniteListRef: infiniteListRef,
    anchorElRef: anchorElRef,
    invalidTextValue: false,
  };

  const autocompleteValue = useAutocompleteValue({
    value: identifier,
    labelMapper,
    freeSolo: true,
    multiple: false,
  });

  useEffect(() => {
    if (mode !== DetailMode.VIEW) {
      handleTextValueChange(autocompleteValue.getTextLabel());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autocompleteValue.text]);

  const disabledField = disabled || formFieldContext?.disabled || !editing;

  return (
    <FormCustomField name={name} label="IČO" required={required}>
      {(mode === DetailMode.VIEW || disabledField) && (
        <TextField
          type="number"
          value={identifier}
          onChange={noop}
          disabled={true}
        />
      )}
      {mode !== DetailMode.VIEW && !disabledField && (
        <AutocompleteContext.Provider
          value={{
            source: source,
            value: autocompleteValue.getLabel(),
            labelMapper,
            clearable: false,
            onChange: noop,
          }}
        >
          <AutocompleteInput<SearchByIcoResponseDto>
            {...commonProps}
            setSearchQuery={noop}
            inputRef={inputRef}
            popupOpen={popupOpen}
            textValue={textValue}
            multiline={false}
          />
          {anchorElRef && popupOpen && (
            <AutocompletePopup<SearchByIcoResponseDto>
              {...commonProps}
              autocompleteValue={autocompleteValue}
            />
          )}
        </AutocompleteContext.Provider>
      )}
    </FormCustomField>
  );
}
