import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ExamApplication, SubjectAutocomplete } from '@models';

export function useColumns(): TableColumn<ExamApplication>[] {
  const { examApplicationStates, examApplicationTypes } =
    useContext(StaticDataContext);

  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<ExamApplication>();

  const useStates = () => useStaticListSource(examApplicationStates);
  const useTypes = () => useStaticListSource(examApplicationTypes);

  return [
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Fyzická osoba vykonávajúca skúšku',
      width: 250,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useNaturalPersons,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.fullName',
      name: 'Celé meno',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.email',
      name: 'E-mail',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.phone',
      name: 'Telefón',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.birthPlace',
      name: 'Miesto narodenia',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.birthDate',
      name: 'Dátum narodenia',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'personalInfo.address.label',
      name: 'Adresa',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'year',
      name: 'Rok konania skúšky',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'retakeNumber',
      name: 'Číslo pokusu v poradí',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'retake',
      name: 'Náhradný termín',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ žiadosti o skúšku',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
