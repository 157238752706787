import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { RegistryRecord } from '@models';

export function useColumns(): TableColumn<RegistryRecord>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<RegistryRecord>();
  const { registryRecordSources, registryRecordStates, registryRecordTypes } =
    useContext(StaticDataContext);

  const useSources = () => useStaticListSource(registryRecordSources);
  const useStates = () => useStaticListSource(registryRecordStates);
  const useTypes = () => useStaticListSource(registryRecordTypes);

  return [
    {
      datakey: 'name',
      name: 'Názov záznamu',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'emailAddressee',
      name: 'E-mail adresáta',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'source',
      sortkey: 'source.name',
      filterkey: 'source.id',
      name: 'Pôvod záznamu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useSources),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useSources),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav záznamu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ záznamu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'registryDetails.number',
      name: 'Číslo evidencie z reg. systému',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'registryDetails.registrationDate',
      name: 'Dátum zápisu do reg. systému',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
