import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import { FormHandle, SnackbarContext, useEventCallback } from '@eas/common-web';
import { ErrorObject, PasswordChangeForm } from '@models';
import { Messages } from '@enums';
import { changePassword } from '../profile-api';
import { usePasswordValidation } from '../profile-utils';

export function usePasswordChangeDialog(closeDialog: () => void) {
  const ref = useRef<FormHandle<PasswordChangeForm>>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const validatePassword = usePasswordValidation();

  const handleConfirm = useEventCallback(async () => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      if (errors.length > 0) {
        setLoading(false);
        return false;
      }
    }

    ref.current?.submitForm();

    return false;
  });

  const handleSubmit = useEventCallback(async (values: PasswordChangeForm) => {
    try {
      setLoading(true);

      await changePassword({ rawPassword: values.newPassword }).raw();

      closeDialog();
      setLoading(false);
      showSnackbar(...Messages.User.PASSWORD_CHANGE.SUCCESS);
    } catch (err) {
      closeDialog();
      setLoading(false);

      const message = getErrorMessage(
        err as ErrorObject<string>,
        Messages.User.PASSWORD_CHANGE
      );

      if ((err as Error).name !== 'AbortError') {
        showSnackbar(...message);
      }

      return undefined;
    }
  });

  const validationSchema = Yup.object<PasswordChangeForm>().shape({
    newPassword: validatePassword,
    newPasswordAgain: Yup.string()
      .nullable()
      .required('Nové heslo znova musí byť vyplnené')
      .oneOf([Yup.ref('newPassword')], 'Zadané heslá sa musia zhodovať'),
  });

  return {
    ref,
    loading,
    validationSchema,
    handleConfirm,
    handleSubmit,
  };
}
