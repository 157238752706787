import * as Yup from 'yup';
import { Role } from '@models';
import { AccountType } from "@enums";

export function useValidationSchema() {
  return Yup.object<Role>().shape({
    name: Yup.string().nullable().required(),
    description: Yup.string().nullable().required(),
    allowedFor: Yup.mixed<AccountType>().nullable().required(),
  });
}
