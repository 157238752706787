/**
 * Typ subjektu
 */
export enum SubjectType {
  /**
   * Fyzická osoba
   */
  NATURAL = 'NATURAL',

  /**
   * Právnická osoba (Spoločnosť)
   */
  LEGAL = 'LEGAL',
}
