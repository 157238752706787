import React from 'react';
import {
  AlogEvent,
  FormEditor,
  FormPanel,
  auditLogFactory,
  useFormSelector,
} from '@eas/common-web';

export function Fields() {
  const { detail } = useFormSelector((data: AlogEvent) => ({
    detail: data.detail,
  }));

  const Fields = auditLogFactory.Fields;

  return (
    <>
      <Fields />
      {detail && (
        <FormPanel label="Detail">
          <FormEditor
            name="detail"
            labelOptions={{ hide: true }}
            language="json"
          />
        </FormPanel>
      )}
    </>
  );
}
