import * as Yup from 'yup';
import { AuditingEntity, FinancialStatementAudit, Subject } from '@models';
import { PublicInterestEntityCategory } from '@enums';

export function useValidationSchema() {
  return Yup.object<FinancialStatementAudit>().shape({
    auditingEntities: Yup.array<AuditingEntity>().nullable().min(1).required(),
    auditedUnit: Yup.mixed<Subject>().nullable().required(),
    auditedUnitAccountingEndDate: Yup.string().nullable().required(),
    auditingEntityAccountingEndDate: Yup.string().nullable().required(),
    auditStartYear: Yup.number()
      .nullable()
      .when(['publicInterest', 'tradingCompany'], {
        is: (publicInterest, tradingCompany) =>
          !!publicInterest || !!tradingCompany,
        then: Yup.number().nullable().required(),
      }),
    subjectAuditProfits: Yup.object()
      .nullable()
      .when(['publicInterest'], {
        is: (publicInterest) => !!publicInterest,
        then: Yup.object().shape({
          statutoryAudit: Yup.number().nullable().required(),
          nonAuditServicesB: Yup.number().nullable().required(),
          nonAuditServicesC: Yup.number().nullable().required(),
        }),
      }),
    category: Yup.mixed<PublicInterestEntityCategory>().when('publicInterest', {
      is: true,
      then: Yup.mixed<PublicInterestEntityCategory>().required(),
    }),
  }) as Yup.Schema<FinancialStatementAudit>;
}
