/**
 * Zdroj vytvorenia registraturného záznamu
 */
export enum RegistryRecordSource {
  /**
   * Zabudnuté heslo
   */
  FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',

  /**
   * Vytvorenie účtu
   */
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',

  /**
   * Zmena hesla
   */
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',

  /**
   * Platba
   */
  PAYMENT = 'PAYMENT',

  /**
   * Iná e-mailová notifikácia
   */
  OTHER_EMAIL_NOTIFICATION = 'OTHER_EMAIL_NOTIFICATION',

  /**
   * Vytvorenie podnětu
   */
  GENERAL_SUBMISSION_CREATED = 'GENERAL_SUBMISSION_CREATED',

  /**
   * Odpoveď na podnět
   */
  GENERAL_SUBMISSION_REPLY = 'GENERAL_SUBMISSION_REPLY',

  /**
   * Vytvorenie podnětu
   */
  ANONYMOUS_SUBMISSION_CREATED = 'ANONYMOUS_SUBMISSION_CREATED',

  /**
   * Odpoveď na podnět
   */
  ANONYMOUS_SUBMISSION_REPLY = 'ANONYMOUS_SUBMISSION_REPLY',

  /**
   * Iné
   */
  OTHER = 'OTHER',
}

/**
 * Typ záznamu
 */
export enum RegistryRecordType {
  /**
   * E-mail
   */
  EMAIL = 'EMAIL',

  /**
   * Akcia
   */
  ACTION = 'ACTION',

  /**
   * Iné
   */
  OTHER_TYPE = 'OTHER_TYPE',
}

/**
 * Stav registraturného záznamu
 */
export enum RegistryRecordState {
  /**
   * Nezaznamenaný
   */
  UNRECORDED = 'UNRECORDED',

  /**
   * Zaznamenaný
   */
  RECORDED = 'RECORDED',
}
