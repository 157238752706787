import React, { useContext } from 'react';
import {
  FormCheckbox,
  FormInstantField,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';

export function ContactDialog() {
  const { personalContactTypes } = useContext(StaticDataContext);
  const contactTypes = useStaticListSource(personalContactTypes);

  return (
    <>
      <FormTextField label="Hodnota" name="value" required />
      <FormSelect
        label="Typ kontaktu"
        name="type"
        source={contactTypes}
        valueIsId
        required
      />
      <FormCheckbox label="Zobraziť verejne" name="shownPublicly" />
      <FormInstantField label="Záznam vytvorený" name="created" disabled />
      <FormInstantField label="Záznam zmenený" name="updated" disabled />
    </>
  );
}
