import { stubFalse } from 'lodash';
import React from 'react';
import { FormPanel, FormTableField } from '@eas/common-web';
import { useColumns } from './payment-columns';

export function PaymentField() {
  const paymentColumns = useColumns();

  return (
    <FormPanel label="Platby">
      <FormTableField
        name="payments"
        showRadioCond={stubFalse}
        showDetailBtnCond={stubFalse}
        showToolbar={false}
        columns={paymentColumns}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
      />
    </FormPanel>
  );
}
