import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { INSUREE_SOURCE } from '@modules/insurance/insurance-hooked-sources';
import { RemoteTableToolbar } from '@components/toolbar/remote-table-toolbar';
import { EvidenceApiUrl } from '@enums';
import {
  addInsureeApiCall,
  deleteInsureeApiCall,
  editInsureeApiCall,
} from './insuree-api';
import { useColumns } from './insuree-columns';
import { InsureeDialog } from './insuree-dialog';
import { useValidationSchema } from './insuree-schema';

interface InsureeFieldProps {
  label?: string;
  disabled?: boolean;
}

export function InsureeField({
  label = 'Poistené subjekty',
  disabled,
}: InsureeFieldProps) {
  const detailCtx = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[INSUREE_SOURCE].source;

  const columns = useColumns();
  const validationSchema = useValidationSchema();

  const Toolbar = (props: TableFieldToolbarProps) => (
    <RemoteTableToolbar
      {...props}
      source={source}
      dialogTitle="Subjekt"
      validationSchema={validationSchema}
      Dialog={InsureeDialog}
      addButtonProps={{
        promptKey: 'ADD_SUBJECT',
        apiCall: addInsureeApiCall,
      }}
      editButtonProps={{
        promptKey: 'EDIT_SUBJECT',
        apiCall: editInsureeApiCall,
      }}
      deleteButtonProps={{
        promptKey: 'DELETE_SUBJECT',
        apiCall: deleteInsureeApiCall,
      }}
      url={`${EvidenceApiUrl.INSURANCE}/${detailCtx?.source?.data?.id}/insurees`}
      disabled={disabled}
    />
  );

  return (
    <FormPanel label={label} expandable={true} defaultExpanded={true}>
      <RemoteTableField
        source={source}
        showRadioCond={() => !disabled}
        columns={columns}
        showDetailBtnCond={stubFalse}
        disabled={disabled}
        ToolbarComponent={Toolbar}
      />
    </FormPanel>
  );
}
