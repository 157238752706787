import {
  DictionaryObject,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import {
  DictMonetaryCurrency,
  DictMonetaryCurrencyAutocomplete,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export function useMonetaryCurrencies() {
  return useAutocompleteSource<DictMonetaryCurrencyAutocomplete>({
    url: `${EvidenceApiUrl.DICT_MONETARY_CURRENCY}/autocomplete`,
  });
}

export function useMonetaryCurrencyTags() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DICT_MONETARY_CURRENCY}/tags/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useHomeCurrency() {
  return useFetch<DictMonetaryCurrency>(
    `${EvidenceApiUrl.DICT_MONETARY_CURRENCY}/home-currency`,
    {
      method: 'GET',
    }
  );
}
