/**
 * Oprávnenia
 */
export enum Permission {
  SYSTEM_ADMINISTRATION = 'SYSTEM_ADMINISTRATION', // Administrácia systému

  BO_DYNAMIC_EXPORT = 'BO_DYNAMIC_EXPORT', // "Úrad - Export evidencií"

  BO_UDVA_USER_READ = 'BO_UDVA_USER_READ', // Používatelia systému - čítanie
  BO_UDVA_USER_MANAGE = 'BO_UDVA_USER_MANAGE', // Používatelia systému - spravovanie

  BO_USER_ROLE_READ = 'BO_USER_ROLE_READ', // Role používateľov - čítanie
  BO_USER_ROLE_MANAGE = 'BO_USER_ROLE_MANAGE', // Role používateľov - spravovanie

  BO_REGISTRY_RECORD_READ = 'BO_REGISTRY_RECORD_READ', // Úrad - Registratúrne záznamy - čítanie
  BO_REGISTRY_RECORD_MANAGE = 'BO_REGISTRY_RECORD_MANAGE', // Úrad - Registratúrne záznamy - spravovanie

  BO_ANONYMOUS_SUBMISSION_READ = 'BO_ANONYMOUS_SUBMISSION_READ', // Úrad - Anonymné podnety - čítanie
  BO_ANONYMOUS_SUBMISSION_MANAGE = 'BO_ANONYMOUS_SUBMISSION_MANAGE', // Úrad - Anonymné podnety - spravovanie

  BO_CHARGE_READ = 'BO_CHARGE_READ', // Úrad - Poplatky - čítanie
  BO_CHARGE_MANAGE = 'BO_CHARGE_MANAGE', // Úrad - Poplatky - spravovanie

  BO_SUBJECT_READ = 'BO_SUBJECT_READ', // Úrad - Subjekty - čítanie
  BO_SUBJECT_MANAGE = 'BO_SUBJECT_MANAGE', // Úrad - Subjekty - spravovanie

  BO_LICENCE_READ = 'BO_LICENCE_READ', // Úrad - Licencie - čítanie
  BO_LICENCE_MANAGE = 'BO_LICENCE_MANAGE', // Úrad - Licencie - spravovanie

  BO_STAKEHOLDER_READ = 'BO_STAKEHOLDER_READ', // Úrad - Zainteresované strany - čítanie
  BO_STAKEHOLDER_MANAGE = 'BO_STAKEHOLDER_MANAGE', // Úrad - Zainteresované strany - spravovanie

  BO_EXAM_READ = 'BO_EXAM_READ', // Úrad - Skúšky - čítanie
  BO_EXAM_MANAGE = 'BO_EXAM_MANAGE', // Úrad - Skúšky - spravovanie

  BO_EXAM_APPLICATION_READ = 'BO_EXAM_APPLICATION_READ', // Úrad - Žiadosti o audítorskú skúšku - čítanie
  BO_EXAM_APPLICATION_MANAGE = 'BO_EXAM_APPLICATION_MANAGE', // Úrad - Žiadosti o audítorskú skúšku - spravovanie

  BO_PAYMENT_READ = 'BO_PAYMENT_READ', // Úrad - Platby - čítanie
  BO_PAYMENT_MANAGE = 'BO_PAYMENT_MANAGE', // Úrad - Platby - spravovanie

  BO_FIN_STATEMENT_AUDIT_READ = 'BO_FIN_STATEMENT_AUDIT_READ', // Úrad - Audity účtovných závierok - čítanie
  BO_FIN_STATEMENT_AUDIT_MANAGE = 'BO_FIN_STATEMENT_AUDIT_MANAGE', // Úrad - Audity účtovných závierok - spravovanie

  BO_AUDITING_ENTITY_READ = 'BO_AUDITING_ENTITY_READ', // Úrad - Audítori a audítorské spoločnosti- čítanie
  BO_AUDITING_ENTITY_MANAGE = 'BO_AUDITING_ENTITY_MANAGE', // Úrad - Audítori a audítorské spoločnosti- spravovanie

  BO_EMPLOYMENT_READ = 'BO_EMPLOYMENT_READ', // Úrad - Zamestnania - čítanie
  BO_EMPLOYMENT_MANAGE = 'BO_EMPLOYMENT_MANAGE', // Úrad - Zamestnania - spravovanie

  BO_EMPLOYMENT_INVITATION_READ = 'BO_EMPLOYMENT_INVITATION_READ', // Úrad - Pozvánky do spoločnosti - čítanie"
  BO_EMPLOYMENT_INVITATION_MANAGE = 'BO_EMPLOYMENT_INVITATION_MANAGE', // Úrad - Pozvánky do spoločnosti - spravovanie"

  BO_CERTIFICATION_READ = 'BO_CERTIFICATION_READ', // Úrad - Certifikáty - čítanie
  BO_CERTIFICATION_MANAGE = 'BO_CERTIFICATION_MANAGE', // Úrad - Certifikáty - spravovanie

  BO_COUNTRY_READ = 'BO_COUNTRY_READ', // Úrad - Krajiny - čítanie
  BO_COUNTRY_MANAGE = 'BO_COUNTRY_MANAGE', // Úrad - Krajiny - spravovanie

  BO_CURRENCY_READ = 'BO_CURRENCY_READ', // Úrad - Finančné meny - čítanie
  BO_CURRENCY_MANAGE = 'BO_CURRENCY_MANAGE', // Úrad - Finančné meny - spravovanie

  BO_DOCUMENT_READ = 'BO_DOCUMENT_READ', // Úrad - Dokumenty - čítanie
  BO_DOCUMENT_MANAGE = 'BO_DOCUMENT_MANAGE', // Úrad - Dokumenty - spravovanie

  BO_INSURANCE_READ = 'BO_INSURANCE_READ', // Úrad - Poistenia - čítanie
  BO_INSURANCE_MANAGE = 'BO_INSURANCE_MANAGE', // Úrad - Poistenia - spravovanie

  BO_INSURANCE_COMPANY_READ = 'BO_INSURANCE_COMPANY_READ', // Úrad - Poisťovne - čítanie
  BO_INSURANCE_COMPANY_MANAGE = 'BO_INSURANCE_COMPANY_MANAGE', // Úrad - Poisťovne - spravovanie

  BO_INSURANCE_BROKERAGE_READ = 'BO_INSURANCE_BROKERAGE_READ', // Úrad - Sprostredkovania poistenia - čítanie
  BO_INSURANCE_BROKERAGE_MANAGE = 'BO_INSURANCE_BROKERAGE_MANAGE', // Úrad - Sprostredkovania poistenia - spravovanie

  BO_EMPLOYMENT_POSITION_READ = 'BO_EMPLOYMENT_POSITION_READ', // Úrad - Popisy pozície - čítanie
  BO_EMPLOYMENT_POSITION_MANAGE = 'BO_EMPLOYMENT_POSITION_MANAGE', // Úrad - Popisy pozície - spravovanie

  BO_QUALIFICATION_READ = 'BO_QUALIFICATION_READ', // Úrad - Odbornosti - čítanie
  BO_QUALIFICATION_MANAGE = 'BO_QUALIFICATION_MANAGE', // Úrad - Odbornosti - spravovanie

  BO_GENERAL_SUBMISSION_READ = 'BO_GENERAL_SUBMISSION_READ', // Úrad - Všeobecné podnety - čítanie
  BO_GENERAL_SUBMISSION_MANAGE = 'BO_GENERAL_SUBMISSION_MANAGE', // Úrad - Všeobecné podnety - spravovanie

  BO_INSURANCE_SUBMISSION_READ = 'BO_INSURANCE_SUBMISSION_READ', // Úrad - Nahlásenia poistenií - čítanie
  BO_INSURANCE_SUBMISSION_MANAGE = 'BO_INSURANCE_SUBMISSION_MANAGE', // Úrad - Nahlásenie poistenií - spravovanie

  BO_EDUCATION_SUBMISSION_READ = 'BO_EDUCATION_SUBMISSION_READ', // Úrad - Oznámenie o sústavnom vzdelávaní - čítanie
  BO_EDUCATION_SUBMISSION_MANAGE = 'BO_EDUCATION_SUBMISSION_MANAGE', // Úrad - Oznámenie o sústavnom vzdelávaní - spravovanie

  BO_AUDIT_NOTICE_READ = 'BO_AUDIT_NOTICE_READ', // Úrad - Oznámenie o prerušení/zahájení auditorskej činnosti - čítanie
  BO_AUDIT_NOTICE_MANAGE = 'BO_AUDIT_NOTICE_MANAGE', // Úrad - Oznámenie o prerušení/zahájení auditorskej činnosti - spravovanie

  BO_AUDIT_REGISTRATION_READ = 'BO_AUDIT_REGISTRATION_READ', // Úrad - Registrácia vykonávania auditu - čítanie
  BO_AUDIT_REGISTRATION_MANAGE = 'BO_AUDIT_REGISTRATION_MANAGE', // Úrad - Registrácia vykonávania auditu - spravovanie

  BO_AUDIT_REPORT_READ = 'BO_AUDIT_REPORT_READ', // Úrad - Oznámenie o auditorskej činnosti - čítanie
  BO_AUDIT_REPORT_MANAGE = 'BO_AUDIT_REPORT_MANAGE', // Úrad - Oznámenie o auditorskej činnosti - spravovanie

  BO_LICENCE_APPLICATION_MANAGE = 'BO_LICENCE_APPLICATION_MANAGE', // Úrad - Žiadosti o licenciu - spravovanie
  BO_LICENCE_APPLICATION_READ = 'BO_LICENCE_APPLICATION_READ', // Úrad - Žiadosti o licenciu - čítanie

  BO_EDUCATION_MANAGE = 'BO_EDUCATION_MANAGE', // Úrad - Sústavné vzdelávanie - spravovanie
  BO_EDUCATION_READ = 'BO_EDUCATION_READ', // Úrad - Sústavné vzdelávanie - čítanie

  BO_CALENDAR_EVENT_READ = 'BO_CALENDAR_EVENT_READ', // Úrad - Udalosti v kalendári - čítanie
  BO_CALENDAR_EVENT_MANAGE = 'BO_CALENDAR_EVENT_MANAGE', // Úrad - Udalosti v kalendári  - spravovanie
}
