import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { AuditNotice } from '@models';
import { AuditNoticeState, EvidenceApiUrl, Permission } from '@enums';
import { ActionBar } from './audit-notice-actionbar';
import { useColumns } from './audit-notice-columns';
import { Fields } from './audit-notice-fields';
import { useValidationSchema } from './audit-notice-schema';

export function AuditNotices() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = useEvidencePermission([
    Permission.BO_AUDIT_NOTICE_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<AuditNotice>({
    version: 3,
    identifier: 'AUDIT_NOTICE',
    apiProps: {
      url: EvidenceApiUrl.AUDIT_NOTICE,
    },
    tableProps: {
      columns,
      tableName: 'Preušenie/zahájenie činnosti',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(AuditNoticeState.PROCESSED),
    },
    detailProps: {
      validationSchema,
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Činnosť',
        showBtn: permissions,
        ActionBar,
      },
      initNewItem: () => ({ id: uuidv4(), state: AuditNoticeState.NEW }),
    },
  });

  return <Evidence {...evidence} />;
}
