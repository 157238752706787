import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { PersonalContact, SubjectLegal } from '@models';

export function useValidationSchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<SubjectLegal>().shape({
    businessName: Yup.string().nullable().required(),
    address: validateAddress,
    identifier: validateIdentifier,
    contacts: Yup.array<PersonalContact>().test('', '', function (value) {
      const { createError, path } = this;
      const contacts = (value ?? []) as PersonalContact[];

      // if (!contacts.some((c) => c.type === PersonContactType.MAIN_EMAIL)) {
      //   return createError({
      //     path,
      //     message: 'Musí byť vyplnený hlavný e-mail',
      //   });
      // }

      return true;
    }),
  });
}

export const validateIdentifier = Yup.string()
  .test('', '', function (value) {
    const { path, createError } = this;

    const stringValue = value?.toString();

    const isValid =
      stringValue &&
      (11 -
        ((8 * Number(stringValue[0]) +
          7 * Number(stringValue[1]) +
          6 * Number(stringValue[2]) +
          5 * Number(stringValue[3]) +
          4 * Number(stringValue[4]) +
          3 * Number(stringValue[5]) +
          2 * Number(stringValue[6])) %
          11)) %
        10 ===
        Number(stringValue[7]);

    if (value && stringValue?.length !== 8) {
      return createError({
        path,
        message: 'IČO musí pozostávať z 8 číslic.',
      });
    }

    if (value && !isValid) {
      return createError({ path, message: 'Zadané IČO nie je validné.' });
    }

    return true;
  })
  .required('Musí byť vyplnené');
