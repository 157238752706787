import React, { useContext } from 'react';
import * as Yup from 'yup';
import { UserContext, abortableFetch, useEventCallback } from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { EvidenceApiUrl, Permission } from '@enums';
import { FormFields } from './create-payment-bulk-dialog';

export function useCreatePaymentBulkDialog() {
  const { hasPermission } = useContext(UserContext);

  const staticDataCtx = useContext(StaticDataContext);

  const apiCall = useEventCallback((params, selected, formData) =>
    abortableFetch(
      `${EvidenceApiUrl.PAYMENT}/bulk?sendEmail=${!!formData.sendEmail}`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(formData?.payments ?? []),
      }
    )
  );

  const showBtn = hasPermission(Permission.BO_PAYMENT_MANAGE);

  const formValidationSchema = Yup.object().shape({
    payments: Yup.array().nullable().required(),
    sendEmail: Yup.boolean().notRequired(),
  });

  return {
    apiCall,
    showCreateBulkBtn: showBtn,
    formValidationSchema,
    FormFields: () => (
      <StaticDataContext.Provider value={staticDataCtx}>
        <FormFields />
      </StaticDataContext.Provider>
    ),
  };
}
