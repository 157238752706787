import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Education } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './education-columns';
import { Fields } from './education-fields';
import { useValidationSchema } from './education-schema';

export function Educations() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission([Permission.BO_EDUCATION_MANAGE]);

  const evidence = useAuthoredEvidence<Education>({
    version: 2,
    identifier: 'EDUCATION',
    apiProps: {
      url: EvidenceApiUrl.EDUCATION,
    },
    tableProps: {
      columns,
      tableName: 'Vzdelávania',
      showBulkActionButton: false,
      showReportButton: false,
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Vzdelávanie',
        showBtn: permissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
