import { useContext } from 'react';
import {
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import { Insurance } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export const INSUREE_SOURCE = 'INSUREE_SOURCE';

function useInsureeSource(): HookedSource<Insurance> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [INSUREE_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_INSURANCE_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.INSURANCE}/${id}/insurees/list`);
        source.setParams({
          size: 15,
        });
      },
    },
  };
}

export function useInsuranceHookedSources(): HookedSource<Insurance> {
  const insurees = useInsureeSource();

  return insurees;
}
