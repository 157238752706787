import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  FormContext,
  FormTextField,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { ErrorObject, Exam } from '@models';
import { EvidenceApiUrl, ExamType, Messages, Permission } from '@enums';

export function useConstructUserDialog() {
  const { showSnackbar } = useContext(SnackbarContext);
  const { source, refreshAll } = useContext<DetailContext<Exam>>(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const [email, setEmail] = useState('');

  const dryApiCall = useEventCallback(() =>
    abortableFetch(
      `${EvidenceApiUrl.EXAM}/${source?.data?.id}/construct-user/dry`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
      }
    )
  );

  const apiCall = useEventCallback((id: string, formData) => {
    setEmail(formData?.email);

    return abortableFetch(`${EvidenceApiUrl.EXAM}/${id}/construct-user`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    });
  });

  const onError = useEventCallback(async (err) => {
    const message = getErrorMessage(
      err as ErrorObject<string>,
      Messages.User.CREATE
    );

    if ((err as Error).name !== 'AbortError') {
      showSnackbar(...message);
    }
  });

  const onSuccess = useEventCallback(async () => {
    refreshAll();

    showSnackbar(
      `Používateľ bol úspešne vytvorený. Na e-mail ${email} bolo zaslané heslo pre jednorazové prihlásenie.`,
      SnackbarVariant.SUCCESS
    );
  });

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .nullable()
      .email('Musí byť v správnom formáte')
      .required(),
  });

  const showBtn =
    hasPermission(Permission.BO_EXAM_MANAGE) &&
    source?.data?.successfullyCompleted &&
    !source?.data?.portalUser &&
    source?.data?.type !== ExamType.RETEST;

  const FormFields = () => {
    const { setFieldValues } = useContext(FormContext);

    useEffect(() => {
      const loadTestData = async () => {
        const data = await dryApiCall().json();
        setFieldValues(data);
      };

      loadTestData();
    }, []);

    return (
      <>
        <FormTextField name="email" label="E-mail" required />
        <FormTextField name="person.label" label="Fyzická osoba" disabled />
        <FormTextField name="role.label" label="Rola" disabled />
      </>
    );
  };

  return {
    showConstructUserBtn: showBtn,
    apiCall,
    FormFields,
    onError,
    formValidationSchema,
    onSuccess,
  };
}
