import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { FinancialStatementAudit } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './financial-statement-audit-columns';
import { Fields } from './financial-statement-audit-fields';
import { useValidationSchema } from './financial-statement-audit-schema';

export function FinancialStatementAudits() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_FIN_STATEMENT_AUDIT_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<FinancialStatementAudit>({
    version: 2,
    identifier: 'FINANCIAL_STATEMENT_AUDIT',
    apiProps: {
      url: EvidenceApiUrl.FINANCIAL_STATEMENT_AUDIT,
    },
    tableProps: {
      columns,
      tableName: 'Audity účtovných závierok',
      showBulkActionButton: false,
      reportTag: ExportTag.FIN_STATEMENT_AUDIT,
    },
    detailProps: {
      toolbarProps: {
        title: 'Audit účtovnej závierky',
        showBtn: permissions,
      },
      FieldsComponent: () => <Fields />,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
