import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Form, FormSubmitButton, formFieldFactory } from '@eas/common-web';
import { formTextFieldFactory } from '@components/form/simple-fields/simple-text-field';
import { PasswordResetConfirmData } from '@models';
import { useStyles as useLoginStyles } from '../login/login';
import { usePasswordResetConfirm } from './password-reset-confirm-hook';

const initialValues: PasswordResetConfirmData = {
  newPassword: '',
  newPasswordAgain: '',
};

const NewPasswordField = formFieldFactory(
  formTextFieldFactory({
    label: 'Nové heslo',
    type: 'password',
  }),
  ({ children }) => <>{children}</>
);

const NewPasswordAgainField = formFieldFactory(
  formTextFieldFactory({
    label: 'Nové heslo znovu',
    type: 'password',
  }),
  ({ children }) => <>{children}</>
);

export function PasswordResetConfirm() {
  const classes = useLoginStyles();

  const {
    validationSchema,
    ref,
    loading,
    handleSubmit,
    handleNavigateBack,
    handleNavigateToReset,
    getFieldError,
    secretIsValid,
  } = usePasswordResetConfirm();

  if (secretIsValid === undefined) {
    return null;
  }

  return (
    <>
      <div className={classes.menubar} />

      <Form<PasswordResetConfirmData>
        ref={ref}
        editing={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Container maxWidth="xs" className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Obnova hesla
          </Typography>

          {secretIsValid && (
            <>
              <NewPasswordField
                name="newPassword"
                error={getFieldError('newPassword') ? true : false}
                helperText={getFieldError('newPassword')}
              />
              <NewPasswordAgainField
                name="newPasswordAgain"
                error={getFieldError('newPasswordAgain') ? true : false}
                helperText={getFieldError('newPasswordAgain')}
              />
              <div className={classes.buttonGroup}>
                <Button
                  variant="contained"
                  color="default"
                  disableElevation
                  onClick={handleNavigateBack}
                  className={classes.button}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonLabel}
                  >
                    Späť na prihlásenie
                  </Typography>
                </Button>
                <FormSubmitButton
                  variant="contained"
                  disableElevation
                  disabled={loading}
                  color="primary"
                  type="submit"
                  className={classes.button}
                >
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.buttonLabel}
                  >
                    Pokračovať
                  </Typography>
                </FormSubmitButton>
              </div>
            </>
          )}
          {!secretIsValid && (
            <>
              <Typography variant="body1" align="center">
                Platnosť Vášho odkazu vypršala, alebo už bol využitý. Pre
                opätovné vytvorenie nového hesla kliknite na tlačidlo nižšie.
              </Typography>

              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleNavigateToReset}
                className={classes.button}
              >
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.buttonLabel}
                >
                  Vytvorenie nového hesla
                </Typography>
              </Button>
            </>
          )}
        </Container>
      </Form>
    </>
  );
}
