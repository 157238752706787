import {
  DictionaryObject,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { LicenceAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useLicences() {
  return useAutocompleteSource<LicenceAutocomplete>({
    url: `${EvidenceApiUrl.LICENCE}/autocomplete`,
  });
}

export function useLicenceStates() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.LICENCE}/states/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}


export function useLicenceTypes() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.LICENCE}/types/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}
