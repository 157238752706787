import React, { useContext } from 'react';
import { UserPortal } from 'src/models/user';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  eqFilterParams,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useRoles } from '@modules/role/role-api';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { RoleAutocomplete, SubjectAutocomplete, User } from '@models';
import { AccountType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { accountTypes } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const { role, type, person } = useFormSelector((data: User) => ({
    role: data.role,
    type: data.type,
    person: (data as UserPortal).person,
  }));

  const accountTypeSource = useStaticListSource(accountTypes);
  const roles = useRoles(
    eqFilterParams({ field: 'allowedFor.id', value: type })
  );
  const subjects = useNaturalPersons();

  return (
    <FormPanel label="Používateľ">
      <FormSelect
        source={accountTypeSource}
        name="type"
        label="Typ uživateľského účtu"
        valueIsId
        required
        disabled={mode !== DetailMode.NEW}
      />
      {type === AccountType.BACKOFFICE && (
        <>
          <FormTextField name="firstName" label="Meno" required />
          <FormTextField name="lastName" label="Priezvisko" required />
        </>
      )}
      {type === AccountType.PORTAL && (
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="person"
          label="Fyzická osoba"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          after={
            person?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.NATURAL_PERSON}
                id={person.id}
              />
            )
          }
        />
      )}
      <FormTextField
        name="username"
        label="Prihlasovacie meno"
        required
        disabled={mode !== DetailMode.NEW}
      />
      <FormTextField
        name="email"
        label="E-mail"
        required
        disabled={mode !== DetailMode.NEW}
      />
      <FormAutocomplete<RoleAutocomplete>
        source={roles}
        name="role"
        label="Rola"
        disabled={!type}
        labelMapper={autocompleteLabelMapper}
        required
        after={
          role?.id &&
          mode === DetailMode.VIEW &&
          hasPermission(Permission.BO_USER_ROLE_READ) && (
            <Redirect url={EvidenceBrowserUrl.ROLE} id={role.id} />
          )
        }
      />
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </FormPanel>
  );
}
