import React, { useContext } from 'react';
import {
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';

export function Fields() {
  const { anonymousSubmissionStates } = useContext(StaticDataContext);

  const anonymousSubmissionStateSource = useStaticListSource(
    anonymousSubmissionStates
  );

  return (
    <>
      <FormPanel label="Anonymný podnet">
        <FormTextField label="Vec podnetu" name="matter" disabled />
        <FormTextField label="Email" name="email" disabled />
        <FormLocalDateField
          name="processedDate"
          label="Dátum spracovania"
          disabled
        />
        <FormSelect
          source={anonymousSubmissionStateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
      </FormPanel>
      <FormPanel label="Text podnetu">
        <FormTextArea name="text" labelOptions={{ hide: true }} disabled />
      </FormPanel>
    </>
  );
}
