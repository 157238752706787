import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { EducationSubmission } from '@models';
import { EducationSubmissionState, EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './education-submission-columns';
import { Fields } from './education-submission-fields';
import { useValidationSchema } from './education-submission-schema';
import { ActionBar } from './education-submissions-actionbar';

export function EducationSubmissions() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = useEvidencePermission([
    Permission.BO_EDUCATION_SUBMISSION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<EducationSubmission>({
    version: 3,
    identifier: 'EDUCATION_SUBMISSION',
    apiProps: {
      url: EvidenceApiUrl.EDUCATION_SUBMISSION,
    },
    tableProps: {
      columns,
      tableName: 'Ohlásenie vzdelávania',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(
        EducationSubmissionState.PROCESSED
      ),
    },
    detailProps: {
      toolbarProps: {
        title: 'Ohlásenie',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
