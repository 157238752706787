import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { GeneralSubmission } from '@models';
import {
  EvidenceApiUrl,
  ExportTag,
  GeneralSubmissionState,
  Permission,
} from '@enums';
import { ActionBar } from './general-submission-actionbar';
import { useColumns } from './general-submission-columns';
import { Fields } from './general-submission-fields';
import { useValidationSchema } from './general-submission-schema';

export function GeneralSubmissions() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_GENERAL_SUBMISSION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<GeneralSubmission>({
    identifier: 'GENERAL_SUBMISSION',
    apiProps: {
      url: EvidenceApiUrl.GENERAL_SUBMISSION,
    },
    tableProps: {
      columns,
      tableName: 'Všeobecné podnety',
      showBulkActionButton: false,
      reportTag: ExportTag.GENERAL_SUBMISSION,
      createRowStyle: createSubmissionRowStyle(GeneralSubmissionState.REPLIED),
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Všeobecný podnet',
        showBtn: permissions,
        ActionBar,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
