import React from 'react';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormSubmitButton, formFieldFactory } from '@eas/common-web';
import { formTextFieldFactory } from '@components/form/simple-fields/simple-text-field';
import { LoginData } from '@models';
import { EvidenceBrowserUrl } from '@enums';
import { useLogin } from './login-hook';

export const useStyles = makeStyles((theme) => ({
  menubar: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: 33,
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '30px',
    padding: 0,
    width: 500,
  },
  title: {
    fontWeight: 700,
    fontSize: 36,
  },
  button: {
    width: '100%',
    height: 44,
  },
  buttonLabel: {
    textTransform: 'none',
    fontSize: 18,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  link: {
    marginRight: 0,
    marginLeft: 'auto',
    marginTop: '-20px',
    color: '#000',
    fontSize: 16,
    fontWeight: 400,
    textDecoration: 'underline',
  },
  text: {
    fontSize: 16,
  },
  buttonGroup: { display: 'flex', gap: '20px', width: '100%' },
}));

const LoginFields = {
  UsernameField: formFieldFactory(
    formTextFieldFactory({ label: 'Používateľské meno' }),
    ({ children }) => <>{children}</>
  ),
  PasswordField: formFieldFactory(
    formTextFieldFactory({ label: 'Heslo', type: 'password' }),
    ({ children }) => <>{children}</>
  ),
};

export function Login() {
  const classes = useStyles();

  const { ref, handleSubmit, validationSchema, getFieldError } = useLogin();

  return (
    <>
      <div className={classes.menubar} />
      <Form<LoginData>
        onSubmit={handleSubmit}
        editing={true}
        ref={ref}
        validationSchema={validationSchema}
        initialValues={{ username: '', password: '' }}
      >
        <Container maxWidth="xs" className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Prihlásiť sa
          </Typography>
          <LoginFields.UsernameField
            name="username"
            error={!!getFieldError('username')}
            helperText={getFieldError('username')}
          />
          <LoginFields.PasswordField
            name="password"
            error={!!getFieldError('password')}
            helperText={getFieldError('password')}
          />
          <Link
            href={EvidenceBrowserUrl.PASSWORD_RESET}
            className={classes.link}
            underline="always"
          >
            Zabudli ste heslo?
          </Link>
          <FormSubmitButton
            variant="contained"
            color="primary"
            type="submit"
            disableElevation
            className={classes.button}
          >
            <Typography
              component="span"
              variant="body1"
              className={classes.buttonLabel}
            >
              Prihlásiť sa
            </Typography>
          </FormSubmitButton>
        </Container>
      </Form>
    </>
  );
}
