import React, { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useInsuranceBrokerages } from '@modules/dict-insurance-brokerage/dict-insurance-brokerage-api';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import {
  AuditingEntityAutocomplete,
  DictInsuranceBrokerageAutocomplete,
  DictMonetaryCurrencyAutocomplete,
  InsuranceSubmission,
} from '@models';

export function useColumns(): TableColumn<InsuranceSubmission>[] {
  const { insuranceSubmissionStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(insuranceSubmissionStates);

  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<InsuranceSubmission>();

  return [
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Vlastník podnetu',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav spracovania',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'processedDate',
      name: 'Dátum spracovania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'broker',
      name: 'Sprostredkovanie poistenia',
      width: 250,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictInsuranceBrokerageAutocomplete>(
          useInsuranceBrokerages,
          autocompleteLabelMapper,
          true
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'number',
      name: 'Číslo poistnej zmluvy',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'contractConcludedDate',
      name: 'Dátum uzavretia zmluvy',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'cost.amount',
      name: 'Čiastka',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'cost.currency.name',
      name: 'Mena',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictMonetaryCurrencyAutocomplete>(
          useMonetaryCurrencies,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'validFrom',
      name: 'Zmluva platná od',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validTo',
      name: 'Zmluva platná do',
      width: 150,
      CellComponent: ({ value, ...props }) =>
        value ? (
          <TableCells.DateCell {...props} value={value} />
        ) : (
          <TableCells.TextCell {...props} value="Na dobu neurčitú" />
        ),
      FilterComponent: TableFilterCells.FilterDateCell,
      filterOperation: ApiFilterOperation.AND,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'insuranceCompany.name',
      name: 'Názov poisťovne',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'insuranceCompany.identifier',
      name: 'IČO poisťovne',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'insuranceCompany.address.label',
      name: 'Adresa poisťovne',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 4 })),
  ];
}
