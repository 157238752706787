import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useRecordDialog } from './dialog-actions/record-hook';

export function ActionBar() {
  const { showRecordBtn, ...recordProps } = useRecordDialog();

  return (
    <>
      {showRecordBtn && (
        <DetailActionButton
          promptKey="RECORD"
          buttonLabel="Zmenit stav na zaznamenaný"
          dialogText="Naozaj chcete zmenit stav na zaznamenaný?"
          dialogTitle="Zmena stavu"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          dialogDisableBackdrop
          {...recordProps}
        />
      )}
    </>
  );
}
