import React from 'react';
import * as Yup from 'yup';
import {
  FormTextArea,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  EducationCycleAutocomplete,
  EducationOverviewPerAuditorDto,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEditNoteDialog() {
  const createNoteApiCall = useEventCallback((formData) =>
    abortableFetch(`${EvidenceApiUrl.EDUCATION}/overview-notes`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const updateNoteApiCall = useEventCallback((id, formData) =>
    abortableFetch(`${EvidenceApiUrl.EDUCATION}/overview-notes/${id}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(formData),
    })
  );

  const FormFields = () => (
    <FormTextArea
      name="note"
      required
      labelOptions={{ hide: true }}
      layoutOptions={{ noSpacing: true }}
    />
  );

  const formValidationSchema = Yup.object<
    EducationOverviewPerAuditorDto & { cycle?: EducationCycleAutocomplete }
  >().shape({
    note: Yup.string().nullable().required(),
  });

  return {
    FormFields,
    formValidationSchema,
    createNoteApiCall,
    updateNoteApiCall,
  };
}
