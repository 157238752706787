export enum ExportTag {
  AUDITOR = 'AUDITOR',
  AUDIT_COMPANY = 'AUDIT_COMPANIES',
  INSURANCE = 'INSURANCE',
  SUBJECT = 'SUBJECT',
  GENERAL_SUBMISSION = 'GENERAL_SUBMISSION',
  ANONYMOUS_SUBMISSION = 'ANONYMOUS_SUBMISSION',
  FIN_STATEMENT_AUDIT = 'FINANCIAL_STATEMENT_AUDIT',
  EXAM = 'EXAM',
  EXAM_APPLICATION = 'EXAM_APPLICATION',
  DOCUMENT = 'DOCUMENT',
  LICENCE = 'LICENCE',
  USER = 'USER',
  ROLE = 'ROLE',
  CURRENCY = 'DICTIONARY_MONETARY_CURRENCY',
  COUNTRY = 'DICTIONARY_COUNTRY',
  INSURANCE_COMPANIES = 'DICTIONARY_INSURANCE_COMPANY',
  CERTIFICATION = 'CERTIFICATION',
  PAYMENT = 'PAYMENT',
  QUALIFICATION = 'QUALIFICATION',
  CHARGE = 'CHARGE',
  REGISTRY_RECORD = 'REGISTRY_RECORD',
}
