import { abortableFetch } from '@eas/common-web';
import { Insuree } from '@models';
import { EvidenceApiUrl } from '@enums';

export const addInsureeApiCall = (id: string, formData: Insuree) =>
  abortableFetch(`${EvidenceApiUrl.INSURANCE}/${id}/insurees`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const editInsureeApiCall = (id: string, formData: Insuree) =>
  abortableFetch(`${EvidenceApiUrl.INSURANCE}/${id}/insurees/${formData.id}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(formData),
  });

export const deleteInsureeApiCall = (id: string, insureeId: string) =>
  abortableFetch(`${EvidenceApiUrl.INSURANCE}/${id}/insurees/${insureeId}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'DELETE',
  });
