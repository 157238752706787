import { stubFalse } from 'lodash';
import React, { useContext, useEffect } from 'react';
import {
  DetailContext,
  DetailMode,
  DetailToolbarButton2,
  DetailToolbarButtonProps,
  FormContext,
  FormTableField,
  PromptContext,
  usePrompts,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';
import { useSearchPersonColumns } from './natural-person-columns';

const SIMILAR_USER_FOUND = 'SIMILAR_USER_FOUND';

export function SaveButton(props: DetailToolbarButtonProps) {
  const { formRef, mode, validate } = useContext(DetailContext);
  const { testPrompt } = useContext(PromptContext);

  const FormFields = () => {
    const { formRef } = useContext(DetailContext);
    const { setFieldValue } = useContext(FormContext);

    const columns = useSearchPersonColumns();

    useEffect(() => {
      const { persons } = formRef?.getFieldValues() || {};
      setFieldValue('persons', persons);
    }, []);

    return (
      <FormTableField
        name="persons"
        labelOptions={{ hide: true }}
        columns={columns}
        showToolbar={false}
        showDetailBtnCond={stubFalse}
        showRadioCond={stubFalse}
      />
    );
  };

  usePrompts(
    [
      {
        key: SIMILAR_USER_FOUND,
        dialogTitle: 'Podobné fyzické osoby',
        dialogText:
          'V aplikácii boli nájdené tieto fyzické osoby s rovnakým menom alebo dátumom narodenia. Skutočne chcete fyzickú osobu vytvoriť?',
        dialogWidth: 800,
        FormFields,
      },
    ],
    []
  );

  return (
    <>
      <DetailToolbarButton2
        {...props}
        onClick={async () => {
          const errors = await validate(true);
          if (errors.length > 0) {
            return;
          }

          if (mode === DetailMode.NEW) {
            const values = formRef?.getFieldValues();

            const response = await fetch(
              `${EvidenceApiUrl.SUBJECT}/search-person`,
              {
                method: 'POST',
                headers: new Headers({
                  'Content-Type': 'application/json',
                }),
                body: JSON.stringify({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  birthDate: values.birthDate,
                }),
              }
            );

            const data = await response.json();

            if (data?.persons?.length > 0) {
              formRef?.setFieldValue('persons', data.persons);

              testPrompt({
                key: SIMILAR_USER_FOUND,
                submitCallback: props.onClick,
              });
            } else {
              props.onClick();
            }
          } else {
            props.onClick();
          }
        }}
      />
    </>
  );
}
