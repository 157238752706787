import { isAfter } from 'date-fns';
import * as Yup from 'yup';
import {
  AuditingEntity,
  Auditor,
  DictEducationField,
  DictEducationForm,
  EducatedAuditor,
  EducationCycle,
  EducationSubmission,
} from '@models';

export function useValidationSchema() {
  const educatedAuditorSchema = useEducatedAuditorSchema();

  return Yup.object<EducationSubmission>().shape({
    reportedYear: Yup.number().nullable().required(),
    trainingName: Yup.string().nullable().required(),
    date: Yup.date().nullable().required(),
    form: Yup.mixed<DictEducationForm>().nullable().required(),
    field: Yup.mixed<DictEducationField>().nullable().required(),
    cycle: Yup.mixed<EducationCycle>().nullable().required(),
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    attachments: Yup.array<Document>().nullable().max(10),
    educatedAuditors: Yup.array()
      .of(educatedAuditorSchema)
      .nullable()
      .required(),
  }) as Yup.Schema<EducationSubmission>;
}

export function useEducatedAuditorSchema(
  requiredEmployedFrom?: boolean,
  requiredApprovedHours?: boolean
) {
  return Yup.object<EducatedAuditor>().shape({
    auditor: Yup.mixed<Auditor>().nullable().required(),
    proposedTrainedHours: Yup.number().nullable().required(),
    approvedTrainedHours: requiredApprovedHours
      ? Yup.number().nullable().required()
      : Yup.number().nullable().notRequired(),
    employedFrom: requiredEmployedFrom
      ? Yup.date()
          .nullable()
          .test('', '', function (date) {
            const { createError, path } = this;

            if (date && isAfter(date, new Date())) {
              return createError({ path, message: 'Nesmie byť v budúcnosti' });
            }

            return true;
          })
          .required()
      : Yup.date()
          .nullable()
          .test('', '', function (date) {
            const { createError, path } = this;

            if (date && isAfter(date, new Date())) {
              return createError({ path, message: 'Nesmie byť v budúcnosti' });
            }

            return true;
          })
          .notRequired(),
  }) as Yup.Schema<EducatedAuditor>;
}
