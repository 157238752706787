import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Exam } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { ActionBar } from './exam-actionbar';
import { useColumns } from './exam-columns';
import { Fields } from './exam-fields';
import { useValidationSchema } from './exam-schema';

export function Exams() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission([Permission.BO_EXAM_MANAGE]);

  const evidence = useAuthoredEvidence<Exam>({
    version: 2,
    identifier: 'EXAM',
    apiProps: {
      url: EvidenceApiUrl.EXAM,
    },
    tableProps: {
      columns,
      tableName: 'Skúšky',
      showBulkActionButton: false,
      reportTag: ExportTag.EXAM,
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Skúška',
        showBtn: permissions,
        ActionBar,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
