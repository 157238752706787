import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailActionButton,
  DetailContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { Licence } from '@models';
import { useAddLicenceDialog } from './dialog-actions/add-licence-hook';

export function ActionBar() {
  const { source } = useContext(DetailContext);

  const { showAddLicenceBtn, ...addLicenceProps } = useAddLicenceDialog();

  return (
    <>
      {showAddLicenceBtn && (
        <DetailActionButton
          promptKey="ADD_LICENCE"
          buttonLabel="Pridať zahraničnú licenciu"
          dialogText={null}
          dialogTitle="Pridanie zahraničnej licencie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={1000}
          dialogDisableBackdrop
          formInitialValues={{ id: uuidv4(), subject: source?.data } as Licence}
          {...addLicenceProps}
        />
      )}
    </>
  );
}
