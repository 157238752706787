/**
 * Status podnetu
 */
export enum GeneralSubmissionState {
  /**
   * Podané
   */
  SUBMITTED = 'SUBMITTED',
  /**
   * Zodpovedané
   */
  REPLIED = 'REPLIED',
}

/**
 * Typ podnetu
 */
export enum GeneralSubmissionType {
  /**
   * Oznámenie
   */
  ANNOUNCEMENT = 'ANNOUNCEMENT',
  /**
   * Žiadosť
   */
  REQUEST = 'REQUEST',
  /**
   * Potvrdenie
   */
  CONFIRMATION = 'CONFIRMATION',
  /**
   * Vysvetlenie, vyjadrenie
   */
  EXPLANATION_COMMENT = 'EXPLANATION_COMMENT',
  /**
   * Námietka
   */
  OBJECTION = 'OBJECTION',
  /**
   * Podnet
   */
  SUBMISSION = 'SUBMISSION',
  /**
   * Ostatné - všeobecné
   */
  OTHER = 'OTHER',
  /**
   * Námety a návrhy
   */
  IDEAS_AND_SUGGESTIONS = 'IDEAS_AND_SUGGESTIONS',
}

/**
 * Agenda podnetu
 */
export enum GeneralSubmissionAgenda {
  /**
   * Agenda členov a poistenia (štatutárni auditori a auditné spoločnosti)
   */
  MEMBERS_AND_INSURANCE = 'MEMBERS_AND_INSURANCE',
  /**
   * Agenda asistentov štatutárneho auditora a školitelia
   */
  STATUTORY_AUDITORS_ASSISTANTS_AND_TRAINERS = 'STATUTORY_AUDITORS_ASSISTANTS_AND_TRAINERS',
  /**
   * Agenda členských príspevkov a penálov
   */
  MEMBER_POSTS_AND_PENALTIES = 'MEMBER_POSTS_AND_PENALTIES',
  /**
   * Agenda vzdelávania
   */
  EDUCATION_AGENDA = 'EDUCATION_AGENDA',
  /**
   * Agenda previerok kvality auditu
   */
  AUDIT_QUALITY_BACKGROUND_CHECK = 'AUDIT_QUALITY_BACKGROUND_CHECK',
  /**
   * Agenda dozornej rady
   */
  BOARD_OF_SUPERVISORS = 'BOARD_OF_SUPERVISORS',
  /**
   * Agenda disciplinárnej komisie
   */
  DISCIPLINARY_COMMISSION = 'DISCIPLINARY_COMMISSION',
  /**
   * Agenda politických strán
   */
  POLITICAL_PARTY_AGENDA = 'POLITICAL_PARTY_AGENDA',
  /**
   * Ostatné - všeobecné
   */
  OTHER_GENERAL = 'OTHER_GENERAL',
  /**
   * Zákon o štatutárnom audite - námety na zmenu
   */
  STATUTARY_AUDIT_LAW = 'STATUTARY_AUDIT_LAW',
}
