import React from 'react';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { FinancialStatementFields } from './fields/financial-statements';
import { GeneralFields } from './fields/general';
import { OverallAuditProfitFields } from './fields/overall-audit-profits';

export function Fields() {
  const config: TabsConfig = [
    {
      label: 'Obecné',
      key: 'GENERAL',
      validationKeys: ['owner'],
      content: <GeneralFields />,
    },
    {
      label: 'Účtovné závierky',
      key: 'FINANCIAL_STATEMENTS',
      validationKeys: ['financialStatements'],
      content: <FinancialStatementFields />,
    },
    {
      label: 'Tržby',
      key: 'OVERALL_AUDIT_PROFITS',
      validationKeys: [
        'overallAuditProfits.statutoryAudit',
        'overallAuditProfits.otherAssuranceServices',
        'overallAuditProfits.taxServices',
        'overallAuditProfits.otherNonAuditServices',
      ],

      content: <OverallAuditProfitFields />,
    },
  ];

  return <Tabs config={config} />;
}
