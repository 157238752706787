import * as Yup from 'yup';
import { PersonalContact } from '@models';
import { PersonContactType } from '@enums';

export function useValidationSchema() {
  return Yup.object<PersonalContact>().shape({
    value: Yup.string().when('type', {
      is: (type) =>
        type === PersonContactType.MAIN_EMAIL ||
        type === PersonContactType.OTHER_EMAIL,
      then: Yup.string()
        .email('Musí byť v správnom formáte')
        .nullable()
        .required(),
      otherwise: Yup.string().nullable().required(),
    }),
    type: Yup.mixed<PersonContactType>().nullable().required(),
  });
}
