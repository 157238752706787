import React, { ReactNode, createContext, useMemo } from 'react';
import { DictionaryObject } from '@eas/common-web';
import { useAnonymousSubmissionStates } from '@modules/anonymous-submission/anonymous-submission-api';
import {
  useEmploymentInvitationStates,
  useLegalForms,
  useStakeholderTypes,
} from '@modules/audit-company/audit-company-api';
import {
  useAuditNoticeStates,
  useAuditNoticeTypes,
} from '@modules/audit-notice/audit-notice-api';
import {
  useAuditRegistrationCompanyTypes,
  useAuditRegistrationStates,
} from '@modules/audit-registration/audit-registration-api';
import { useAuditReportStates } from '@modules/audit-report/audit-report-api';
import { useAuditingEntityTypes } from '@modules/auditor/auditor-api';
import { useCalendarEventTypes } from '@modules/calendar-event/calendar-event-api';
import {
  usePaymentAdditionalSpecifications,
  usePaymentCategories,
  usePaymentEconomicClassifications,
  usePaymentMainSpecifications,
} from '@modules/charge/charge-api';
import { useCountryTags } from '@modules/dict-country/dict-country-api';
import {
  useHomeCurrency,
  useMonetaryCurrencyTags,
} from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { useEducationSubmissionStates } from '@modules/education-submission/education-submission-api';
import {
  useExamApplicationStates,
  useExamApplicationTypes,
  useRetakePartTypes,
} from '@modules/exam-application/exam-application-api';
import { useExamTypes } from '@modules/exam/exam-api';
import { usePublicInterestEntityCategories } from '@modules/financial-statement-audit/financial-statement-audit-api';
import {
  useGeneralSubmissionAgendas,
  useGeneralSubmissionStates,
  useGeneralSubmissionTypes,
} from '@modules/general-submission/general-submission-api';
import { useInsuranceSubmissionStates } from '@modules/insurance-submission/insurance-submission-api';
import { useLicenceApplicationStates } from '@modules/licence-application/licence-application-api';
import { useLicenceStates, useLicenceTypes } from '@modules/licence/licence-api';
import { useNotificationEvents } from '@modules/notification/notification-api';
import {
  useParsedPaymentResults,
  usePaymentStates,
} from '@modules/payment/payment-api';
import {
  useQualificationStates,
  useSkauTypes,
} from '@modules/qualification/qualification-api';
import {
  useRegistryRecordSources,
  useRegistryRecordStates,
  useRegistryRecordTypes,
} from '@modules/registry-record/registry-record-api';
import {
  usePermissionGroups,
  usePermissions,
  useRoleTags,
} from '@modules/role/role-api';
import { useSubjectTypes } from '@modules/subject/subject-api';
import { useAccountTypes } from '@modules/user/user-api';
import { usePersonalContactTypes } from '@composite/contacts/contact-api';
import { DictMonetaryCurrency, Permission } from '@models';

export interface StaticDataContextType {
  accountTypes: DictionaryObject[];
  anonymousSubmissionStates: DictionaryObject[];
  auditingEntityTypes: DictionaryObject[];
  auditNoticeStates: DictionaryObject[];
  auditNoticeTypes: DictionaryObject[];
  auditRegistrationStates: DictionaryObject[];
  auditRegistrationCompanyTypes: DictionaryObject[];
  auditReportStates: DictionaryObject[];
  calendarEventTypes: DictionaryObject[];
  countryTags: DictionaryObject[];
  currencyTags: DictionaryObject[];
  educationSubmissionStates: DictionaryObject[];
  employmentInvitationStates: DictionaryObject[];
  examTypes: DictionaryObject[];
  examApplicationTypes: DictionaryObject[];
  examApplicationStates: DictionaryObject[];
  homeCurrency?: DictMonetaryCurrency;
  insuranceSubmissionStates: DictionaryObject[];
  legalForms: DictionaryObject[];
  licenceStates: DictionaryObject[];
  licenceTypes: DictionaryObject[];
  licenceApplicationStates: DictionaryObject[];
  notificationEvents: DictionaryObject[];
  parsedPaymentResults: DictionaryObject[];
  paymentCategories: DictionaryObject[];
  paymentAdditionalSpecifications: DictionaryObject[];
  paymentEconomicClassifications: DictionaryObject[];
  paymentMainSpecifications: DictionaryObject[];
  paymentStates: DictionaryObject[];
  permissions: Permission[];
  permissionGroups: DictionaryObject[];
  personalContactTypes: DictionaryObject[];
  publicInterestEntityCategories: DictionaryObject[];
  generalSubmissionStates: DictionaryObject[];
  generalSubmissionTypes: DictionaryObject[];
  generalSubmissionAgendas: DictionaryObject[];
  qualificationStates: DictionaryObject[];
  registryRecordSources: DictionaryObject[];
  registryRecordStates: DictionaryObject[];
  registryRecordTypes: DictionaryObject[];
  retakePartTypes: DictionaryObject[];
  roleTags: DictionaryObject[];
  skauTypes: DictionaryObject[];
  stakeholderTypes: DictionaryObject[];
  subjectTypes: DictionaryObject[];
}

export const StaticDataContext = createContext<StaticDataContextType>({
  accountTypes: [],
  auditingEntityTypes: [],
  auditNoticeStates: [],
  auditNoticeTypes: [],
  auditRegistrationStates: [],
  auditRegistrationCompanyTypes: [],
  auditReportStates: [],
  calendarEventTypes: [],
  countryTags: [],
  currencyTags: [],
  educationSubmissionStates: [],
  employmentInvitationStates: [],
  examTypes: [],
  examApplicationTypes: [],
  examApplicationStates: [],
  homeCurrency: undefined,
  insuranceSubmissionStates: [],
  legalForms: [],
  licenceStates: [],
  licenceTypes: [],
  licenceApplicationStates: [],
  notificationEvents: [],
  parsedPaymentResults: [],
  paymentCategories: [],
  paymentAdditionalSpecifications: [],
  paymentEconomicClassifications: [],
  paymentMainSpecifications: [],
  paymentStates: [],
  permissions: [],
  permissionGroups: [],
  personalContactTypes: [],
  publicInterestEntityCategories: [],
  generalSubmissionStates: [],
  generalSubmissionTypes: [],
  generalSubmissionAgendas: [],
  anonymousSubmissionStates: [],
  qualificationStates: [],
  registryRecordSources: [],
  registryRecordStates: [],
  registryRecordTypes: [],
  retakePartTypes: [],
  roleTags: [],
  skauTypes: [],
  stakeholderTypes: [],
  subjectTypes: [],
});

export function StaticDataProvider({ children }: { children: ReactNode }) {
  const [accountTypes] = useAccountTypes();
  const [auditingEntityTypes] = useAuditingEntityTypes();
  const [auditNoticeStates] = useAuditNoticeStates();
  const [auditNoticeTypes] = useAuditNoticeTypes();
  const [auditRegistrationStates] = useAuditRegistrationStates();
  const [auditRegistrationCompanyTypes] = useAuditRegistrationCompanyTypes();
  const [auditReportStates] = useAuditReportStates();
  const [calendarEventTypes] = useCalendarEventTypes();
  const [countryTags] = useCountryTags();
  const [currencyTags] = useMonetaryCurrencyTags();
  const [educationSubmissionStates] = useEducationSubmissionStates();
  const [employmentInvitationStates] = useEmploymentInvitationStates();
  const [examTypes] = useExamTypes();
  const [examApplicationTypes] = useExamApplicationTypes();
  const [examApplicationStates] = useExamApplicationStates();
  const [homeCurrency] = useHomeCurrency();
  const [insuranceSubmissionStates] = useInsuranceSubmissionStates();
  const [legalForms] = useLegalForms();
  const [licenceStates] = useLicenceStates();
  const [licenceTypes] = useLicenceTypes();
  const [licenceApplicationStates] = useLicenceApplicationStates();
  const [notificationEvents] = useNotificationEvents();
  const [parsedPaymentResults] = useParsedPaymentResults();
  const [paymentCategories] = usePaymentCategories();
  const [paymentAdditionalSpecifications] =
    usePaymentAdditionalSpecifications();
  const [paymentEconomicClassifications] = usePaymentEconomicClassifications();
  const [paymentMainSpecifications] = usePaymentMainSpecifications();
  const [paymentStates] = usePaymentStates();
  const [permissions] = usePermissions();
  const [permissionGroups] = usePermissionGroups();
  const [personalContactTypes] = usePersonalContactTypes();
  const [publicInterestEntityCategories] = usePublicInterestEntityCategories();
  const [generalSubmissionStates] = useGeneralSubmissionStates();
  const [generalSubmissionTypes] = useGeneralSubmissionTypes();
  const [generalSubmissionAgendas] = useGeneralSubmissionAgendas();
  const [anonymousSubmissionStates] = useAnonymousSubmissionStates();
  const [registryRecordSources] = useRegistryRecordSources();
  const [registryRecordStates] = useRegistryRecordStates();
  const [registryRecordTypes] = useRegistryRecordTypes();
  const [retakePartTypes] = useRetakePartTypes();
  const [roleTags] = useRoleTags();
  const [qualificationStates] = useQualificationStates();
  const [skauTypes] = useSkauTypes();
  const [stakeholderTypes] = useStakeholderTypes();
  const [subjectTypes] = useSubjectTypes();

  const isLoaded =
    !!accountTypes?.length &&
    !!auditingEntityTypes?.length &&
    !!auditNoticeStates?.length &&
    !!auditNoticeTypes?.length &&
    !!auditRegistrationStates?.length &&
    !!auditRegistrationCompanyTypes?.length &&
    !!auditReportStates?.length &&
    !!calendarEventTypes?.length &&
    !!countryTags?.length &&
    !!currencyTags?.length &&
    !!educationSubmissionStates?.length &&
    !!employmentInvitationStates?.length &&
    !!examTypes?.length &&
    !!examApplicationTypes?.length &&
    !!examApplicationStates?.length &&
    !!homeCurrency &&
    !!insuranceSubmissionStates?.length &&
    !!legalForms?.length &&
    !!licenceStates?.length &&
    !!licenceTypes?.length &&
    !!licenceApplicationStates?.length &&
    !!notificationEvents?.length &&
    !!parsedPaymentResults?.length &&
    !!paymentCategories?.length &&
    !!paymentAdditionalSpecifications?.length &&
    !!paymentEconomicClassifications?.length &&
    !!paymentMainSpecifications?.length &&
    !!paymentStates?.length &&
    !!permissions?.length &&
    !!permissionGroups?.length &&
    !!personalContactTypes?.length &&
    !!publicInterestEntityCategories?.length &&
    !!generalSubmissionStates?.length &&
    !!generalSubmissionTypes?.length &&
    !!generalSubmissionAgendas?.length &&
    !!anonymousSubmissionStates?.length &&
    !!registryRecordSources?.length &&
    !!registryRecordStates?.length &&
    !!registryRecordTypes?.length &&
    !!retakePartTypes?.length &&
    !!roleTags?.length &&
    !!qualificationStates?.length &&
    !!skauTypes?.length &&
    !!stakeholderTypes?.length &&
    !!subjectTypes?.length;

  const context = useMemo(
    () => ({
      accountTypes: accountTypes ?? [],
      auditingEntityTypes: auditingEntityTypes ?? [],
      auditNoticeStates: auditNoticeStates ?? [],
      auditNoticeTypes: auditNoticeTypes ?? [],
      auditRegistrationStates: auditRegistrationStates ?? [],
      auditRegistrationCompanyTypes: auditRegistrationCompanyTypes ?? [],
      auditReportStates: auditReportStates ?? [],
      calendarEventTypes: calendarEventTypes ?? [],
      countryTags: countryTags ?? [],
      currencyTags: currencyTags ?? [],
      educationSubmissionStates: educationSubmissionStates ?? [],
      employmentInvitationStates: employmentInvitationStates ?? [],
      examTypes: examTypes ?? [],
      examApplicationTypes: examApplicationTypes ?? [],
      examApplicationStates: examApplicationStates ?? [],
      homeCurrency: homeCurrency,
      insuranceSubmissionStates: insuranceSubmissionStates ?? [],
      legalForms: legalForms ?? [],
      licenceStates: licenceStates ?? [],
      licenceTypes: licenceTypes ?? [],
      licenceApplicationStates: licenceApplicationStates ?? [],
      notificationEvents: notificationEvents ?? [],
      parsedPaymentResults: parsedPaymentResults ?? [],
      paymentCategories: paymentCategories ?? [],
      paymentAdditionalSpecifications: paymentAdditionalSpecifications ?? [],
      paymentEconomicClassifications: paymentEconomicClassifications ?? [],
      paymentMainSpecifications: paymentMainSpecifications ?? [],
      paymentStates: paymentStates ?? [],
      permissions: permissions ?? [],
      permissionGroups: permissionGroups ?? [],
      personalContactTypes: personalContactTypes ?? [],
      publicInterestEntityCategories: publicInterestEntityCategories ?? [],
      generalSubmissionStates: generalSubmissionStates ?? [],
      generalSubmissionTypes: generalSubmissionTypes ?? [],
      generalSubmissionAgendas: generalSubmissionAgendas ?? [],
      anonymousSubmissionStates: anonymousSubmissionStates ?? [],
      qualificationStates: qualificationStates ?? [],
      registryRecordSources: registryRecordSources ?? [],
      registryRecordStates: registryRecordStates ?? [],
      registryRecordTypes: registryRecordTypes ?? [],
      retakePartTypes: retakePartTypes ?? [],
      roleTags: roleTags ?? [],
      skauTypes: skauTypes ?? [],
      stakeholderTypes: stakeholderTypes ?? [],
      subjectTypes: subjectTypes ?? [],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoaded]
  );

  return (
    <StaticDataContext.Provider value={context}>
      {isLoaded && children}
    </StaticDataContext.Provider>
  );
}
