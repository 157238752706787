import * as Yup from 'yup';
import { AuditingEntity, DictCountry, Qualification } from '@models';
import { SkauType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Qualification>().shape({
    number: Yup.string().nullable().required(),
    skauType: Yup.mixed<SkauType>().nullable().required(),
    origin: Yup.object().shape({
      country: Yup.mixed<DictCountry>().nullable().required(),
      institution: Yup.string().nullable().required(),
    }),
    subject: Yup.mixed<AuditingEntity>().nullable().required(),
  });
}
