import React, { useContext, useRef } from 'react';
import {
  ActionButtonHandle,
  DetailActionButton,
  DetailContext,
  DictionaryAutocomplete,
  FieldFilter,
  FormAutocomplete,
  FormContext,
  FormDictionaryField,
  FormPanel,
  FormTextField,
  TableFieldCells,
  TableFieldColumn,
  abortableFetch,
  useEventCallback,
  useFormSelector,
  useScrollableSource,
} from '@eas/common-web';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { useEducationForms } from '@modules/dict-education-form/dict-education-form-api';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { EducatedAuditors } from '@modules/education-submission/education-submission-fields';
import { useEducationalTrainings } from '@modules/educational-training/educational-training-api';
import { Fields } from '@modules/educational-training/educational-training-fields';
import { useValidationSchema as useTrainingSchema } from '@modules/educational-training/educational-training-schema';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  DictEducationField,
  DictEducationForm,
  EducationCycleAutocomplete,
  EducationalTraining,
  EducationalTrainingAutocomplete,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export function ProcessDialog() {
  const { setFieldValue } = useContext(FormContext);
  const { source } = useContext(DetailContext);

  const createRef = useRef<ActionButtonHandle>(null);
  const trainingSchema = useTrainingSchema();

  const cycles = useEducationCycles();
  const trainings = useEducationalTrainings();
  const trainingSource = useScrollableSource({
    url: `${EvidenceApiUrl.EDUCATIONAL_TRAINING}/list`,
  });

  const addTraining = useEventCallback((_, formData) =>
    abortableFetch(EvidenceApiUrl.EDUCATIONAL_TRAINING, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const { submissionTraining } = useFormSelector(
    (data: { submissionTraining?: EducationalTraining }) => ({
      submissionTraining: data.submissionTraining,
    })
  );

  return (
    <>
      <FormPanel label="Ohlásenie">
        <FormTextField
          name="submissionTraining.name"
          label="Názov školenia"
          disabled
        />
        <FormTextField
          name="submissionTraining.form.label"
          label="Forma vzdelávania"
          disabled
        />
        <FormTextField
          name="submissionTraining.educationField.label"
          label="Zameranie vzdelávania"
          disabled
        />
      </FormPanel>
      <FormPanel label="Validácia školenia">
        <FormAutocomplete<EducationCycleAutocomplete>
          source={cycles}
          name="cycle"
          label="Vzdelávací cyklus"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <>
          <DetailActionButton
            ref={createRef}
            promptKey="ADD_TRAINING"
            apiCall={addTraining}
            buttonLabel="Pridať školenie"
            dialogTitle="Školenie"
            dialogText="Po vyplnení a potvrdení formulára bude vytvorené nové školenie"
            FormFields={Fields}
            modes={[]}
            refreshAfterConfirm={false}
            dialogWidth={1000}
            dialogDisableBackdrop
            formInitialValues={{ ...submissionTraining } as EducationalTraining}
            onResult={async (value) => {
              setFieldValue('training', value);
              setFieldValue('year', value?.fromDate);
            }}
            formValidationSchema={trainingSchema}
          />
          <FormDictionaryField<EducationalTrainingAutocomplete>
            name="training"
            required={true}
            label="Školenie"
            filters={[
              {
                datakey: 'name',
                filterkey: 'name',
                label: 'Názov školenia',
                FilterComponent: FieldFilter.FilterTextCell,
              },
              {
                datakey: 'form',
                filterkey: 'form.id',
                label: 'Forma vzdelávania',
                FilterComponent:
                  FieldFilter.FilterAutocompleteCell(useEducationForms),
              },
              {
                datakey: 'educationField',
                filterkey: 'educationField.id',
                label: 'Oblasť zamerania vzdelávania',
                FilterComponent:
                  FieldFilter.FilterAutocompleteCell(useEducationFields),
              },
              {
                datakey: 'fromDate',
                filterkey: 'fromDate',
                label: 'Dátum od',
                FilterComponent: FieldFilter.FilterDateCell,
              },
              {
                datakey: 'toDate',
                filterkey: 'toDate',
                label: 'Dátum do',
                FilterComponent: FieldFilter.FilterDateCell,
              },
            ]}
            columns={
              [
                {
                  datakey: 'name',
                  sortkey: 'name',
                  name: 'Názov školenia',
                  width: 300,
                },
                {
                  datakey: 'form',
                  sortkey: 'form.name',
                  name: 'Forma vzdelávania',
                  width: 200,
                  CellComponent: TableFieldCells.useAutocompleteCellFactory<
                    EducationalTraining,
                    DictEducationForm
                  >({
                    dataHook: useEducationForms,
                    labelMapper: autocompleteLabelMapper,
                    multiple: false,
                  }),
                },
                {
                  datakey: 'educationField',
                  sortkey: 'educationField.name',
                  name: 'Oblasť zamerania vzdelávania',
                  width: 200,
                  CellComponent: TableFieldCells.useAutocompleteCellFactory<
                    EducationalTraining,
                    DictEducationField
                  >({
                    dataHook: useEducationFields,
                    labelMapper: autocompleteLabelMapper,
                    multiple: false,
                  }),
                },
                {
                  datakey: 'fromDate',
                  sortkey: 'fromDate',
                  name: 'Dátum od',
                  width: 200,
                  CellComponent: TableFieldCells.DateCell,
                },
                {
                  datakey: 'toDate',
                  sortkey: 'toDate',
                  name: 'Dátum do',
                  width: 200,
                  CellComponent: TableFieldCells.DateCell,
                },
              ] as TableFieldColumn<DictionaryAutocomplete>[]
            }
            autoFocus={true}
            autocompleteSource={trainings}
            dialogSource={trainingSource}
            labelMapper={(value) =>
              autocompleteLabelMapper(value as EducationalTrainingAutocomplete)
            }
            maxRows={9}
            dialogWidth={1000}
            onCreate={() => createRef.current?.executeAction()}
          />
        </>
        <FormTextField type="number" name="year" label="Rok" required />
      </FormPanel>
      <EducatedAuditors
        disabledPersonalFields
        showAuditorAutocomplete
        showNote
        owner={source?.data?.owner}
        disabledProposedHours
      />
    </>
  );
}
