import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import { EvidenceContext, FormPanel, RemoteTableField } from '@eas/common-web';
import { INSURANCE_SOURCE } from '@modules/audit-company/audit-company-hooked-sources';
import { useColumns } from './insurance-columns';

export function InsuranceField() {
  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[INSURANCE_SOURCE].source;

  const columns = useColumns();

  return (
    <FormPanel label="Poistenia" expandable={true} defaultExpanded={true}>
      <RemoteTableField
        source={source}
        columns={columns}
        showDetailBtnCond={stubFalse}
        showToolbar={false}
        showRadioCond={stubFalse}
      />
    </FormPanel>
  );
}
