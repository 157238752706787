import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useCancelDialog } from './dialog-actions/cancel-hook';
import { useReceiveDialog } from './dialog-actions/receive-hook';
import { useSendDialog } from './dialog-actions/send-hook';

export function ActionBar() {
  const { showSendBtn, ...sendProps } = useSendDialog();
  const { showCancelBtn, ...cancelProps } = useCancelDialog();
  const { showReceiveBtn, ...receiveProps } = useReceiveDialog();

  return (
    <>
      {showSendBtn && (
        <DetailActionButton
          promptKey="SEND"
          buttonLabel="Odoslať na email"
          dialogText="Naozaj chcete odoslať platbu na email?"
          dialogTitle="Odoslanie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...sendProps}
        />
      )}
      {showCancelBtn && (
        <DetailActionButton
          promptKey="CANCEL"
          buttonLabel="Zrušiť"
          dialogText="Naozaj chcete zrušiť platbu?"
          dialogTitle="Zrušenie platby"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...cancelProps}
        />
      )}
      {showReceiveBtn && (
        <DetailActionButton
          promptKey="RECEIVE"
          buttonLabel="Prijať platbu"
          dialogText={null}
          dialogTitle="Prijatie platby"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          dialogWidth={700}
          {...receiveProps}
        />
      )}
    </>
  );
}
