import React, { useContext, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import {
  NavigationContext,
  SnackbarContext,
  UserContext,
} from '@eas/common-web';
import { EvidenceBrowserUrl, Messages, Permission } from '../../enums';
import { Me } from '../../models';

export function SecureRoute(props: RouteProps & { permissions: Permission[] }) {
  const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);
  const { navigate } = useContext(NavigationContext);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const forbidden = !props.permissions.some((perm) => hasPermission(perm));

    if (forbidden) {
      navigate(EvidenceBrowserUrl.HOME);
      const warning = Messages.Router.ACCESS.WARNING;
      showSnackbar(warning[0], warning[1], true);
    }
  });

  return <Route {...props} />;
}
