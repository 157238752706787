import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { AuditReport } from '@models';
import { AuditReportState, EvidenceApiUrl, Permission } from '@enums';
import { ActionBar } from './audit-report-actionbar';
import { useColumns } from './audit-report-columns';
import { Fields } from './audit-report-fields';
import { useValidationSchema } from './audit-report-schema';

export function AuditReports() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = useEvidencePermission([
    Permission.BO_AUDIT_REPORT_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<AuditReport>({
    version: 2,
    identifier: 'AUDIT_REPORT',
    apiProps: {
      url: EvidenceApiUrl.AUDIT_REPORT,
    },
    tableProps: {
      columns,
      tableName: 'Oznámenia o audítorskej činnosti',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(AuditReportState.PROCESSED),
    },
    detailProps: {
      validationSchema,
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Oznámenie',
        showBtn: permissions,
        ActionBar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
