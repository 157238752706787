import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { DictInsuranceCompany } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './dict-insurance-company-columns';
import { Fields } from './dict-insurance-company-fields';
import { useValidationSchema } from './dict-insurance-company-schema';

export function DictInsuranceCompanies() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const permissions = useEvidencePermission([
    Permission.BO_INSURANCE_COMPANY_MANAGE,
  ]);

  const evidence =
    DictionaryEvidence.useDictionaryEvidence<DictInsuranceCompany>({
      version: 2,
      identifier: 'DICT_INSURANCE_COMPANY',
      apiProps: {
        url: EvidenceApiUrl.DICT_INSURANCE_COMPANY,
      },
      tableProps: {
        columns,
        tableName: 'Poisťovne',
        showBulkActionButton: false,
        reportTag: ExportTag.INSURANCE_COMPANIES,
      },
      detailProps: {
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          title: 'Poisťovňa',
          showBtn: permissions,
        },
        validationSchema,
      },
    });

  return <Evidence {...evidence} />;
}
