import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import {
  Evidence,
  createItemFactory,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { LicenceApplication } from '@models';
import {
  EvidenceApiUrl,
  LicenceApplicationState,
  LicenceApplicationType,
  Permission,
} from '@enums';
import { ActionBar } from './licence-application-actionbar';
import { useColumns } from './licence-application-columns';
import { Fields } from './licence-application-fields';
import { useValidationSchema } from './licence-application-schema';

export function LicenceApplications() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_LICENCE_APPLICATION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<LicenceApplication>({
    version: 2,
    identifier: 'LICENCE_APPLICATION',
    apiProps: {
      url: EvidenceApiUrl.LICENCE_APPLICATION,
      getItem: getItemFactory({
        postProcess,
      }),
      updateItem: updateItemFactory({ postProcess }),
      createItem: createItemFactory({ postProcess }),
    },
    tableProps: {
      columns,
      tableName: 'Žiadosti o zápis na zoznam ŠA/AS',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(
        LicenceApplicationState.APPROVED,
        LicenceApplicationState.CLOSED
      ),
    },
    detailProps: {
      toolbarProps: {
        title: 'Žiadosť',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () =>
        ({ state: LicenceApplicationState.NEW } as LicenceApplication),
    },
  });

  return <Evidence {...evidence} />;
}

async function postProcess(item: LicenceApplication) {
  return {
    ...item,
    type: item?.forAuditCompany
      ? LicenceApplicationType.AUDIT_COMPANY
      : LicenceApplicationType.AUDITOR,
  } as LicenceApplication;
}
