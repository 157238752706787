import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  DictCountryAutocomplete,
  Qualification,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { skauTypes, qualificationStates } = useContext(StaticDataContext);
  const { hasPermission } = useContext(UserContext);

  const qualificationStateSource = useStaticListSource(qualificationStates);
  const skauTypeSource = useStaticListSource(skauTypes);
  const auditingEntities = useAuditingEntities();
  const countries = useCountries();

  const { subject } = useFormSelector((data: Qualification) => ({
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Odbornosť">
        <FormTextField type="number" name="number" label="Číslo" required />
        <FormSelect
          source={skauTypeSource}
          name="skauType"
          label="Typ odbornosti podľa SKAU"
          valueIsId
          required
        />
        <FormSelect
          source={qualificationStateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="subject"
          label="Subjekt odbornosti"
          labelMapper={autocompleteLabelMapper}
          required
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                url={
                  subject?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={subject.id}
              />
            )
          }
        />
        <FormLocalDateField name="validTo" label="Platnosť do" />
        {mode === DetailMode.VIEW && (
          <>
            <FormLocalDateField
              name="verdictDate"
              label="Dátum verdiktu"
              disabled
            />
            <FormLocalDateField
              name="revokedDate"
              label="Dátum odobrania"
              disabled
            />
          </>
        )}
      </FormPanel>
      <FormPanel label="Pôvod odbornosti">
        <FormAutocomplete<DictCountryAutocomplete>
          source={countries}
          name="origin.country"
          label="Krajina pôvodu"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          required
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
