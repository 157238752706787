import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useEducationSubmissionStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.EDUCATION_SUBMISSION}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
