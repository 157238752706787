import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useRegistryRecordSources() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.REGISTRY_RECORD}/sources/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useRegistryRecordStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.REGISTRY_RECORD}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useRegistryRecordTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.REGISTRY_RECORD}/types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
