import * as Yup from 'yup';
import { Exam, Subject } from '@models';
import { ExamType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Exam>().shape({
    subject: Yup.mixed<Subject>().nullable().required(),
    type: Yup.mixed<ExamType>().nullable().required(),
    auditExamPartResult: Yup.object().shape({
      percent: Yup.number()
        .min(0, 'Musí ležať v rozsahu 0 až 100')
        .max(100, 'Musí ležať v rozsahu 0 až 100')
        .notRequired(),
    }),
    accountExamPartResult: Yup.object().shape({
      percent: Yup.number()
        .min(0, 'Musí ležať v rozsahu 0 až 100')
        .max(100, 'Musí ležať v rozsahu 0 až 100')
        .notRequired(),
    }),
    economyExamPartResult: Yup.object().shape({
      percent: Yup.number()
        .min(0, 'Musí ležať v rozsahu 0 až 100')
        .max(100, 'Musí ležať v rozsahu 0 až 100')
        .notRequired(),
    }),
  });
}
