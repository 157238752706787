import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { Office } from '@models';

export function useValidationSchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<Office>().shape({
    address: validateAddress,
  });
}
