import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  ListSource,
  NotificationType,
  Select,
  SubscriptionType,
  TableFieldCellProps,
  useSubscriptionTypes,
} from '@eas/common-web';

const filterSubscriptionTypes = (
  subscriptionTypes: { id: SubscriptionType }[],
  allowedTypes: NotificationType[]
) => {
  if (!allowedTypes?.includes(NotificationType.APPLICATION)) {
    return subscriptionTypes.filter(
      (item) =>
        ![SubscriptionType.BOTH, SubscriptionType.APPLICATION].includes(item.id)
    );
  } else if (!allowedTypes.includes(NotificationType.EMAIL)) {
    return subscriptionTypes.filter(
      (item) =>
        ![SubscriptionType.BOTH, SubscriptionType.EMAIL].includes(item.id)
    );
  }

  return subscriptionTypes;
};

export function useNotificationColumns(
  editing: boolean,
  setNotificationPreferences: (notificationEvents: any) => void
) {
  const intl = useIntl();

  const SelectTypeCell = (dataHook: () => ListSource<any>) => {
    return useMemo(
      () =>
        function SelectCell({
          value,
          rowValue,
          index,
        }: TableFieldCellProps<any>) {
          const source = dataHook();
          source.items = filterSubscriptionTypes(
            source.items,
            rowValue.event?.allowedTypes ?? []
          );

          return (
            <Select
              disabled={!editing}
              source={source}
              value={value}
              clearable={false}
              onChange={(value) =>
                setNotificationPreferences((prev: any[]) => {
                  const found = prev.find(
                    (item) => item.event.id === rowValue.event.id
                  );
                  return [
                    ...prev.slice(0, index),
                    { ...found, type: value },
                    ...prev.slice(index + 1),
                  ];
                })
              }
              valueIsId={true}
            />
          );
        },
      [dataHook, editing]
    );
  };

  return [
    {
      name: intl.formatMessage({
        id: 'ES__NOTIFICATIONS__COLUMN__EVENT',
        defaultMessage: 'Název události',
      }),
      datakey: 'event.name',
      width: 300,
    },
    {
      name: intl.formatMessage({
        id: 'ES__NOTIFICATIONS__COLUMN__TYPE',
        defaultMessage: 'Způsob odběru',
      }),
      datakey: 'type',
      width: 300,
      CellComponent: SelectTypeCell(useSubscriptionTypes),
    },
  ];
}
