import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  FormTextArea,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AuditNotice } from '@models';
import {
  AuditNoticeState,
  AuditNoticeType,
  EvidenceApiUrl,
  Permission,
} from '@enums';

export function useProcessDialog() {
  const detailCtx = useContext<DetailHandle<AuditNotice>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.AUDIT_NOTICE}/${id}/process`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_AUDIT_NOTICE_MANAGE) &&
    detailCtx?.source?.data?.state === AuditNoticeState.PAID;

  const formValidationSchema = Yup.object().shape({
    reason: Yup.string().nullable().required(),
  });

  const buttonLabel =
    detailCtx?.source?.data?.type === AuditNoticeType.SUSPENSION
      ? 'Pozastaviť licenciu'
      : 'Aktivovať licenciu';

  const dialogTitle =
    detailCtx?.source?.data?.type === AuditNoticeType.SUSPENSION
      ? 'Pozastavenie licencie'
      : 'Aktivovanie licencie';

  const dialogText =
    detailCtx?.source?.data?.type === AuditNoticeType.SUSPENSION
      ? 'Skutočne chcete pozastaviť licenciu?'
      : 'Skutočne chcete aktivovať licenciu?';

  const ButtonComponent =
    detailCtx?.source?.data?.type === AuditNoticeType.SUSPENSION
      ? SecondaryDetailActionbarButton
      : PrimaryDetailActionbarButton;

  return {
    apiCall,
    showProcessBtn: showBtn,
    FormFields: () => <FormTextArea name="reason" label="Dôvod" required />,
    formValidationSchema,
    buttonLabel,
    dialogText,
    dialogTitle,
    ButtonComponent,
  };
}
