import * as Yup from 'yup';
import {
  AuditingEntity,
  Auditor,
  Education,
  EducationCycle,
  EducationalTraining,
} from '@models';

export function useValidationSchema() {
  return Yup.object<Education>().shape({
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    auditor: Yup.mixed<Auditor>().nullable().required(),
    cycle: Yup.mixed<EducationCycle>().nullable().required(),
    hours: Yup.number().min(1, 'Musí byť kladné číslo').nullable().required(),
    training: Yup.mixed<EducationalTraining>().nullable().required(),
    year: Yup.number()
      .nullable()
      .min(1990, 'Musí byť v rozsahu 1990 až 2100')
      .max(2100, 'Musí byť v rozsahu 1990 až 2100')
      .required(),
  }) as Yup.Schema<Education>;
}
