import { getErrorMessage } from '@utils/error-message';
import { useContext, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AbortableFetch,
  FormHandle,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useFormError } from '@modules/login/form-error-hook';
import { ErrorObject, PasswordResetData } from '@models';
import { EvidenceApiUrl, Messages } from '@enums';

export function usePasswordReset() {
  const ref = useRef<FormHandle<PasswordResetData>>(null);
  const fetch = useRef<AbortableFetch | null>(null);

  const { showSnackbar } = useContext(SnackbarContext);
  const { setErrors, getFieldError } = useFormError();
  const { push } = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const validationSchema = Yup.object<PasswordResetData>().shape({
    email: Yup.string()
      .email('Musí byť v správnom formáte')
      .required('Email musí byť vyplnený'),
  });

  const handleNavigateBack = useEventCallback(() => {
    push('/');
  });

  const callSubmit = (data: PasswordResetData) => {
    return abortableFetch(EvidenceApiUrl.PASSWORD_RESET, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(data),
    });
  };

  const submitPasswordReset = useEventCallback(
    async (data: PasswordResetData) => {
      try {
        setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callSubmit(data);
        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);

        const message = getErrorMessage(
          err as ErrorObject<string>,
          Messages.User.PASSWORD_RESET
        );

        if ((err as Error).name !== 'AbortError') {
          showSnackbar(...message);
        }

        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(async (data: PasswordResetData) => {
    if (ref.current != undefined) {
      const errors = await ref.current.validateForm();

      setErrors(errors);

      if (errors.length) {
        return;
      }

      await submitPasswordReset(data);

      push(`/`, true);
    }
  });

  return {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleSubmit,
    getFieldError,
  };
}
