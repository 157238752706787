import React from 'react';
import { InternalAddress } from '@composite/internal-address/internal-address';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';

export function AddressFields() {
  return (
    <>
      <FormAddressPanel name="subject.address" label="Trvalý pobyt" disabled />
      <FormAddressPanel
        name="businessAddress"
        label="Miesto podnikania ak audítor vykonáva audit vo svojom mene a na vlastný účet"
      />
      <InternalAddress label="Adresa komory audítorov" code="ADDRESS_CHAMBER" />
      <InternalAddress label="Adresa úradu" code="ADDRESS_OFFICE" />
    </>
  );
}
