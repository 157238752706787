import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  FormAnchorProvider,
  InactivityProvider,
  InternalSettingsProvider,
  LocaleName,
  LocaleProvider,
  NavigationProvider,
  PromptProvider,
  SnackbarProvider,
  UserProvider,
  ValidationProvider,
  WebsocketProvider,
} from '@eas/common-web';
import { ThemeProvider } from '@components/theme/theme-provider';
import { EvidenceApiUrl, Permission } from '@enums';
import { AppPublic } from './app-public';
import { AppSecure } from './app-secure';
import sk from './lang/sk.json';
import { Me } from './models/auth';

const FETCH_CACHE_VERSION = 3;

export function App() {
  const checkPermission = (user: Me, permission: string) =>
    !!user.role?.permissions?.includes(permission as Permission);

  useEffect(() => {
    // TODO: remove after system is stabilized - after approx. 1.6.2024
    const version = window.localStorage.getItem('fetch-cache-version');
    if (!version || parseInt(version) !== FETCH_CACHE_VERSION) {
      window.localStorage.clear();
      window.localStorage.setItem(
        'fetch-cache-version',
        FETCH_CACHE_VERSION.toString()
      );
    }
  }, []);

  return (
    <ThemeProvider>
      <LocaleProvider
        defaultLocale={LocaleName.sk}
        messages={{ ['SLOVAK']: sk }}
      >
        <ValidationProvider>
          <InternalSettingsProvider url={EvidenceApiUrl.INTERNAL_SETTING}>
            <SnackbarProvider timeout={3000}>
              <FormAnchorProvider>
                <UserProvider
                  meUrl="/api/udva/account"
                  logoutUrl="/api/udva/logout"
                  checkPermission={checkPermission}
                >
                  <InactivityProvider timeout={3600}>
                    <BrowserRouter>
                      <NavigationProvider>
                        <Switch>
                          <Route path="/udva">
                            <PromptProvider>
                              <WebsocketProvider
                                wsUrl="/api/udva/stomp"
                                destinations={[
                                  '/user/queue/session',
                                  '/user/queue/login',
                                  '/user/queue/notification',
                                ]}
                                debug={false}
                                requiredUser={false}
                              >
                                <AppSecure />
                              </WebsocketProvider>
                            </PromptProvider>
                          </Route>
                          <Route path="/">
                            <AppPublic />
                          </Route>
                        </Switch>
                      </NavigationProvider>
                    </BrowserRouter>
                  </InactivityProvider>
                </UserProvider>
              </FormAnchorProvider>
            </SnackbarProvider>
          </InternalSettingsProvider>
        </ValidationProvider>
      </LocaleProvider>
    </ThemeProvider>
  );
}
