import { stubFalse } from 'lodash';
import React, { useMemo } from 'react';
import { green, orange, red } from '@material-ui/core/colors';
import {
  DictionaryObject,
  FormTableField,
  InlineTableFieldCells,
  TableFieldCellProps,
  TableFieldCells,
  TableFieldColumn,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { usePayments } from '@modules/payment/payment-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ParsedPaymentResultDto, PaymentAutocomplete } from '@models';
import { ParsedPaymentResult, PaymentState } from '@enums';
import { PaymentRow } from './parse-xml-payment-row';

export function ParsedPaymentResultFields({
  parsedPaymentResults,
  showPairedPayments,
  showSelectedPayments,
}: {
  parsedPaymentResults: DictionaryObject[];
  showPairedPayments?: boolean;
  showSelectedPayments?: boolean;
}) {
  const useResults = () => useStaticListSource(parsedPaymentResults);
  const useNewPayments = () =>
    usePayments({
      params: eqFilterParams({
        field: 'state.id',
        value: PaymentState.NEW,
      }),
    });

  const NoteCell = InlineTableFieldCells.useInlineTextFieldFactory({
    collectionDatakey: 'payments',
  });

  const ReceivedAmountCell = ({
    value,
    ...props
  }: TableFieldCellProps<ParsedPaymentResultDto>) => (
    <TableFieldCells.TextCell
      {...props}
      value={`${value.amount} ${value.currency.name}`}
    />
  );

  const ReceivedDateCell = ({
    rowValue,
    ...props
  }: TableFieldCellProps<ParsedPaymentResultDto>) => {
    const Cell =
      InlineTableFieldCells.useInlineDateFieldFactory<ParsedPaymentResultDto>({
        collectionDatakey: 'payments',
        disabled: !rowValue?.parsedPayment?.editableReceivedDate,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const MemoizedCell = useMemo(() => Cell, []);

    return <MemoizedCell {...props} rowValue={rowValue} />;
  };

  const SelectedPaymentCell =
    InlineTableFieldCells.useInlineAutocompleteCellFactory<
      ParsedPaymentResultDto,
      PaymentAutocomplete
    >({
      collectionDatakey: 'payments',
      dataHook: useNewPayments,
      labelMapper: autocompleteLabelMapper,
    });

  const ResultCell = TableFieldCells.useSelectCellFactory(useResults);

  const columns: TableFieldColumn<ParsedPaymentResultDto>[] = useMemo(
    () =>
      [
        {
          datakey: 'result',
          name: 'Výsledok',
          width: 150,
          CellComponent: ResultCell,
        },
        {
          datakey: 'parsedPayment.paymentType',
          name: 'Typ prijatej platby',
          width: 150,
        },
        {
          datakey: 'parsedPayment.vs',
          name: 'Variabilný symbol',
          width: 150,
        },
        {
          datakey: 'parsedPayment.receivedDate',
          name: 'Dátum prijatia platby',
          width: 150,
          CellComponent: ReceivedDateCell,
        },
        {
          datakey: 'parsedPayment.receivedAmount',
          name: 'Suma',
          width: 150,
          CellComponent: ReceivedAmountCell,
        },
        {
          datakey: 'parsedPayment.subjectName',
          name: 'Subjekt vykonávajúci platbu',
          width: 300,
        },
        ...(showSelectedPayments
          ? [
              {
                datakey: 'selectedPayment',
                name: 'Platba na spárovanie',
                width: 500,
                CellComponent: SelectedPaymentCell,
              },
            ]
          : []),
        ...(showPairedPayments
          ? [
              {
                datakey: 'pairedPayment.extendedLabel',
                name: 'Spárovaná platba',
                width: 500,
              },
            ]
          : []),
        {
          datakey: 'parsedPayment.note',
          name: 'Poznámka',
          width: 300,
          CellComponent: NoteCell,
        },
      ] as TableFieldColumn<ParsedPaymentResultDto>[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      NoteCell,
      ReceivedAmountCell,
      ResultCell,
      SelectedPaymentCell,
      ReceivedDateCell,
    ]
  );

  return (
    <FormTableField<ParsedPaymentResultDto>
      name="payments"
      columns={columns}
      layoutOptions={{ noSpacing: true }}
      labelOptions={{ hide: true }}
      createRowStyle={(item) =>
        item?.result === ParsedPaymentResult.FULL_MATCH
          ? { color: green[500], backgroundColor: green[50] }
          : item?.result === ParsedPaymentResult.NO_MATCH
          ? { color: red[500], backgroundColor: red[50] }
          : { color: orange[500], backgroundColor: orange[50] }
      }
      RowComponent={PaymentRow}
      showToolbar={false}
      maxRows={15}
      showRadioCond={stubFalse}
      showDetailBtnCond={stubFalse}
    />
  );
}
