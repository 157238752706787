import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { ProcessDialog } from './dialog-actions/process/process-dialog';
import { useProcessDialog } from './dialog-actions/process/process-hook';

export function ActionBar() {
  const { showProcessBtn, ...processProps } = useProcessDialog();

  return (
    <>
      {showProcessBtn && (
        <DetailActionButton
          promptKey="PROCESS"
          buttonLabel="Spracovať"
          dialogText="Naozaj chcete spracovať oznámenie?"
          dialogTitle="Spracovanie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={1000}
          dialogDisableBackdrop
          FormFields={ProcessDialog}
          {...processProps}
        />
      )}
    </>
  );
}
