/**
 * Typ zainteresovanej strany
 */
export enum StakeholderType {
  /**
   * Vlastník
   */
  OWNER = 'OWNER',

  /**
   * Akcionár
   */
  SHAREHOLDER = 'SHAREHOLDER',

  /**
   * Spoločník
   */
  PARTNER = 'PARTNER',

  /**
   * Člen štatutárneho orgánu
   */
  STATUTORY_BODY = 'STATUTORY_BODY',

  /**
   * Člen riadiaceho orgánu
   */
  MANAGEMENT_BODY = 'MANAGEMENT_BODY',

  /**
   * Člen dozorného orgánu
   */
  SUPERVISORY_BODY = 'SUPERVISORY_BODY',
}
