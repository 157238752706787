import React from 'react';
import { AlogEvent, EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { EvidenceApiUrl } from '@enums';
import { useColumns } from './alog-columns';
import { Fields } from './alog-fields';

export function Alog() {
  const columns = useColumns();

  const evidence = useAuthoredEvidence<AlogEvent>({
    identifier: 'ALOG',
    apiProps: {
      url: EvidenceApiUrl.ALOG,
    },
    tableProps: {
      columns,
      tableName: 'Auditné logy',
      defaultSorts: [
        { field: 'created', datakey: 'created', order: 'DESC', type: 'FIELD' },
      ],
      showReportButton: false,
    },
    detailProps: {
      GeneralFieldsComponent: EmptyComponent,
      FieldsComponent: Fields,
      toolbarProps: {
        showBtn: (btn) => ['REFRESH', 'SHARE'].includes(btn),
        title: 'Auditný log',
      },
    },
  });

  return <Evidence {...evidence} />;
}
