import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const GENERATE_NEXT: MessageType<'ERROR' | 'OPERATION_NOT_ALLOWED'> = {
  ERROR: ['Chyba pri generovaní licencie.', SnackbarVariant.ERROR],
  OPERATION_NOT_ALLOWED: [
    'Vybraný užívateľ už má pridelenú licenciu.',
    SnackbarVariant.ERROR,
  ],
};

export default { GENERATE_NEXT };
