import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import { SubjectLegal } from '@models';

export function useColumns(): TableColumn<SubjectLegal>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<SubjectLegal>();

  return [
    {
      datakey: 'businessName',
      name: 'Názov spoločnosti',
      width: 400,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'identifier',
      name: 'IČO',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'address.label',
      name: 'Adresa spoločnosti',
      width: 500,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
