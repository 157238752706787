import React from 'react';
import {
  FormAutocomplete,
  FormDateField,
  FormTextField,
} from '@eas/common-web';
import { useDocuments } from '@modules/document/document-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DocumentAutocomplete } from '@models';

export function DisciplinaryRecordDialog() {
  const documents = useDocuments();

  return (
    <>
      <FormTextField name="sanction" label="Sankce" required />
      <FormTextField name="measure" label="Opatrenie" required />
      <FormDateField name="date" label="Dátum" />
      <FormAutocomplete<DocumentAutocomplete>
        source={documents}
        label="Zápisy o uložení disciplinárneho opatrenia"
        name="documents"
        multiline
        multiple
        labelMapper={autocompleteLabelMapper}
      />
    </>
  );
}
