import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  FormCheckbox,
  FormPanel,
  FormTableField,
  TableFieldCells,
  useStaticListSource,
} from '@eas/common-web';
import { FormFields as PaymentFields } from '@modules/charge/dialog-actions/create-payment/create-payment-dialog';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { Payment } from '@models';
import { useValidationSchema } from '../../payment-schema';

export function FormFields() {
  const { paymentStates, homeCurrency } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(paymentStates);

  const validationSchema = useValidationSchema();

  return (
    <>
      <FormCheckbox name="sendEmail" label="Odoslať email" />
      <FormPanel label="Platby">
        <FormTableField
          name="payments"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={[
            {
              datakey: 'subject.label',
              name: 'Subjekt vykonávajúci platbu',
              width: 200,
            },
            {
              datakey: 'state',
              name: 'Stav',
              width: 100,
              CellComponent: TableFieldCells.useSelectCellFactory(useStates),
            },
            {
              datakey: 'email',
              name: 'E-mail',
              width: 150,
            },
            {
              datakey: 'year',
              name: 'Platba na rok',
              width: 150,
            },
            {
              datakey: 'dueDate',
              name: 'Dátum splatnosti',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              datakey: 'amount.amount',
              name: 'Suma',
              width: 150,
            },
            {
              datakey: 'amount.currency.label',
              name: 'Mena',
              width: 150,
            },
          ]}
          FormFieldsComponent={() => <PaymentFields showCharge={true} />}
          initNewItem={() =>
            ({
              id: uuidv4(),
              amount: { currency: homeCurrency },
            } as Payment)
          }
          validationSchema={validationSchema}
        />
      </FormPanel>
    </>
  );
}
