import * as Yup from 'yup';
import { Auditor, CalendarEvent } from '@models';
import { CalendarEventType } from '@enums';

export function useValidationSchema() {
  return Yup.object<CalendarEvent>().shape({
    name: Yup.string().nullable().required(),
    date: Yup.string().nullable().required(),
    reminderDate: Yup.string().nullable().required(),
    type: Yup.mixed<CalendarEventType>().nullable().required(),
    auditors: Yup.array<Auditor>().when('type', {
      is: (type) => type === CalendarEventType.FOR_SPECIFIED_AUDITORS,
      then: Yup.array<Auditor>().min(1).nullable().required(),
    }),
  });
}
