import { TableCells, TableColumn, TableFilterCells } from '@eas/common-web';
import { useDocuments } from '@modules/document/document-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DocumentAutocomplete, PaymentParse } from '@models';

export function useColumns(): TableColumn<PaymentParse>[] {
  return [
    {
      datakey: 'inputFile',
      sortkey: 'inputFile.name',
      filterkey: 'inputFile.name',
      name: 'Vstupný súbor',
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DocumentAutocomplete>(
          useDocuments,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'created',
      name: 'Vytvorenie',
      width: 150,
      CellComponent: TableCells.DateTimeCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'createdBy',
      sortkey: 'createdBy.name',
      filterkey: 'createdBy.name',
      name: 'Autor',
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'confirmedPaymentsCount',
      name: 'Počet spárovaných platieb',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
  ];
}
