/**
 * Tag používateľskej role
 */
export enum RoleTag {
  /**
   * Predvolená rola: používatelia portálu
   */
  DEFAULT_PORTAL_USER_ROLE = 'DEFAULT_PORTAL_USER_ROLE',

  /**
   * Predvolená rola: používatelia backofficu
   */
  DEFAULT_BACKOFFICE_USER_ROLE = 'DEFAULT_BACKOFFICE_USER_ROLE',
}

/**
 * Skupina oprávnení
 */
export enum PermissionGroup {
  /**
   * Administrácia systému
   */
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',

  /**
   * Úrad
   */
  BACKOFFICE = 'BACKOFFICE',

  /**
   * Portál - Spoločnosť
   */
  PORTAL_COMPANY = 'PORTAL_COMPANY',

  /**
   * Portál - Audítor
   */
  PORTAL_AUDITOR = 'PORTAL_AUDITOR',

  /**
   * Portál - všeobecné
   */
  PORTAL_OTHER = 'PORTAL_OTHER',
}
