import {
  DictionaryObject,
  Params,
  abortableFetch,
  useAutocompleteSource,
  useFetch,
  useScrollableSource,
} from '@eas/common-web';
import { Payment, PaymentAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function usePaymentStates() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.PAYMENT}/states/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

export function usePaymentSource() {
  return useScrollableSource<Payment>({
    url: `${EvidenceApiUrl.PAYMENT}/list`,
  });
}

export function usePayments(props?: { params?: Params }) {
  return useAutocompleteSource<PaymentAutocomplete>({
    url: `${EvidenceApiUrl.PAYMENT}/autocomplete/extended`,
    ...props,
  });
}

export function create(api: string, { sendEmail, ...item }: Payment) {
  return abortableFetch(`${api}?sendEmail=${!!sendEmail}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(item),
  });
}

export function useParsedPaymentResults() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.PAYMENT}/results/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
