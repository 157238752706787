import React, { useContext } from 'react';
import {
  ApiFilterOperation,
  FormAutocomplete,
  FormLocalDateField,
  FormTextArea,
  FormTextField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useValidationSchema } from '@modules/certification/certification-schema';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DictCountryAutocomplete } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function useAddCertificationDialog() {
  const { hasPermission } = useContext(UserContext);

  const showBtn = hasPermission(Permission.BO_CERTIFICATION_MANAGE);

  const apiCall = useEventCallback((_, formData) =>
    abortableFetch(EvidenceApiUrl.CERTIFICATION, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const formValidationSchema = useValidationSchema();

  const FormFields = () => {
    const countries = useCountries({
      params: {
        filters: [
          {
            operation: ApiFilterOperation.NOT,
            filters: [
              { field: 'code', operation: ApiFilterOperation.EQ, value: 'SK' },
            ],
          },
        ],
      },
    });

    return (
      <>
        <FormTextField name="number" label="Číslo certifikátu" required />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" required />
        <FormAutocomplete<DictCountryAutocomplete>
          source={countries}
          name="origin.country"
          label="Krajina pôvodu"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          required
        />
        <FormTextArea name="note" label="Poznámka" />
      </>
    );
  };

  return {
    showAddCertificationBtn: showBtn,
    apiCall,
    formValidationSchema,
    FormFields,
  };
}
