import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { STAKEHOLDER_SOURCE } from '@modules/audit-company/audit-company-hooked-sources';
import { RemoteTableToolbar } from '@components/toolbar/remote-table-toolbar';
import { EvidenceApiUrl } from '@enums';
import {
  addStakeholderApiCall,
  deleteStakeholderApiCall,
  editStakeholderApiCall,
} from './stakeholder-api';
import {
  useAuditCompanyColumns,
  useSubjectColumns,
} from './stakeholder-columns';
import { StakeholderDialog } from './stakeholder-dialog';
import { useValidationSchema } from './stakeholder-schema';

export function StakeholderField({
  label,
  disabled = false,
  display,
}: {
  label: string;
  disabled?: boolean;
  display: 'IN_SUBJECT' | 'IN_AUDIT_COMPANY';
}) {
  const detailCtx = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[STAKEHOLDER_SOURCE].source;

  const validationSchema = useValidationSchema();
  const subjectColumns = useSubjectColumns();
  const auditCompanyColumns = useAuditCompanyColumns();

  const Toolbar = (props: TableFieldToolbarProps) => (
    <RemoteTableToolbar
      {...props}
      source={source}
      dialogTitle="Zainteresovaná strana"
      validationSchema={validationSchema}
      Dialog={StakeholderDialog}
      addButtonProps={{
        promptKey: 'ADD_STAKEHOLDER',
        apiCall: addStakeholderApiCall,
      }}
      editButtonProps={{
        promptKey: 'EDIT_STAKEHOLDER',
        apiCall: editStakeholderApiCall,
      }}
      deleteButtonProps={{
        promptKey: 'DELETE_STAKEHOLDER',
        apiCall: deleteStakeholderApiCall,
      }}
      url={`${EvidenceApiUrl.AUDIT_COMPANY}/${detailCtx?.source?.data?.id}/stakeholders`}
      disabled={disabled}
    />
  );

  return (
    <FormPanel label={label} expandable={true} defaultExpanded={true}>
      <RemoteTableField
        source={source}
        columns={
          display === 'IN_SUBJECT' ? subjectColumns : auditCompanyColumns
        }
        showDetailBtnCond={stubFalse}
        ToolbarComponent={Toolbar}
        showToolbar={!disabled}
        disabled={disabled}
      />
    </FormPanel>
  );
}
