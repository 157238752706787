import * as Yup from 'yup';
import {
  DictInsuranceCompany,
  DictMonetaryCurrency,
  Insurance,
  InsuranceCost,
} from '@models';

export function useValidationSchema() {
  return Yup.object<Insurance>().shape({
    validFrom: Yup.date().nullable().required(),
    validTo: Yup.date()
      .nullable()
      .when('indefinitePeriod', {
        is: (indefinitePeriod) => indefinitePeriod !== true,
        then: Yup.string()
          .nullable()
          .required(
            'Musí byť vyplnený dátum konca platnosti alebo vybraný koniec na dobu neurčitú'
          ),
      }),
    cost: Yup.object<InsuranceCost>().shape({
      amount: Yup.number()
        .nullable()
        .min(1, 'Musí byť kladné číslo')
        .required(),
      currency: Yup.mixed<DictMonetaryCurrency>().nullable().required(),
    }),
    brokerage: Yup.string().nullable().required(),
    insuranceCompany: Yup.mixed<DictInsuranceCompany>().nullable().required(),
  });
}
