import {
  CompanyEmployeeData,
  CompanyForeignLicence,
  CompanyRelatedSubjectData,
  LicenceApplicationCompanyData,
} from 'src/models/licence-application';
import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { Auditor, DictCountry, LicenceApplication } from '@models';
import { CompanyLegalForm, LicenceApplicationType, SubjectType } from '@enums';

export function useValidationSchema() {
  const addressValidation = useAddressValidationSchema();

  return Yup.object<LicenceApplication>().shape({
    applicant: Yup.mixed<Auditor>().nullable().required(),
    email: Yup.string().nullable().required(),
    type: Yup.mixed<LicenceApplicationType>().nullable().required(),
    phone: Yup.string().nullable().required(),
    companyData: Yup.object<LicenceApplicationCompanyData>().when('type', {
      is: LicenceApplicationType.AUDIT_COMPANY,
      then: Yup.object<LicenceApplicationCompanyData>().shape({
        businessName: Yup.string().nullable().required(),
        identifier: Yup.string().nullable().required(),
        legalForm: Yup.mixed<CompanyLegalForm>().nullable().required(),
        phone: Yup.string().nullable().required(),
        email: Yup.string().nullable().required(),
        homeCountry: Yup.mixed<DictCountry>().nullable().required(),
        address: addressValidation,
        mailAddress: addressValidation,
      }),
    }),
  });
}

export function useRelatedSubjectSchema() {
  const addressValidation = useAddressValidationSchema();

  return Yup.object<CompanyRelatedSubjectData>().shape({
    address: addressValidation,
    relationTypes: Yup.array().nullable().required(),
    type: Yup.mixed<SubjectType>().nullable().required(),
    identifier: Yup.string().when('type', {
      is: SubjectType.LEGAL,
      then: Yup.string().nullable().required(),
    }),
    businessName: Yup.string().when('type', {
      is: SubjectType.LEGAL,
      then: Yup.string().nullable().required(),
    }),
    firstName: Yup.string().when('type', {
      is: SubjectType.NATURAL,
      then: Yup.string().nullable().required(),
    }),
    lastName: Yup.string().when('type', {
      is: SubjectType.NATURAL,
      then: Yup.string().nullable().required(),
    }),
  });
}

export function useForeignLicenceSchema() {
  return Yup.object<CompanyForeignLicence>().shape({
    number: Yup.string().nullable().required(),
    issuedDate: Yup.string().nullable().required(),
    origin: Yup.object().shape({
      country: Yup.mixed<DictCountry>().nullable().required(),
      institution: Yup.string().nullable().required(),
    }),
  });
}

export function useCompanyEmployeeSchema() {
  return Yup.object<CompanyEmployeeData>().shape({
    position: Yup.string().nullable().required(),
    employee: Yup.mixed<Auditor>().nullable().required(),
  });
}
