import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useSubjects } from '@modules/subject/subject-api';
import {
  autocompleteLabelMapper,
  objectArrayLabelMapper,
} from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  FinancialStatementAudit,
  SubjectAutocomplete,
} from '@models';

export function useColumns(): TableColumn<FinancialStatementAudit>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<FinancialStatementAudit>();

  return [
    {
      datakey: 'auditedUnit.label',
      sortkey: 'auditedUnit.name',
      filterkey: 'auditedUnit.id',
      name: 'Účtovná jednotka',
      width: 250,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useSubjects,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'auditingEntities',
      sortkey: 'auditingEntities.flat',
      filterkey: 'auditingEntities.id',
      name: 'Vykonávatelia auditu',
      width: 400,
      CellComponent: TableCells.TextCell,
      valueMapper: objectArrayLabelMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'auditCalendarYear',
      name: 'Rok vykonania auditu',
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'consolidated',
      name: 'Konsolidovaná účtovná závierka',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'individual',
      name: 'Individuálna účtovná závierka',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'tradingCompany',
      name: 'Obchodná spoločnosť',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'publicInterest',
      name: 'Subjekt verejného záujmu',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
