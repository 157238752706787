import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { DictMonetaryCurrency } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './dict-monetary-currency-columns';
import { Fields } from './dict-monetary-currency-fields';
import { useValidationSchema } from './dict-monetary-currency-schema';

export function DictMonetaryCurrencies() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const permissions = useEvidencePermission([Permission.BO_CURRENCY_MANAGE]);

  const evidence =
    DictionaryEvidence.useDictionaryEvidence<DictMonetaryCurrency>({
      version: 3,
      identifier: 'DICT_MONETARY_CURRENCY',
      apiProps: {
        url: EvidenceApiUrl.DICT_MONETARY_CURRENCY,
      },
      tableProps: {
        columns,
        tableName: 'Finančné meny',
        showBulkActionButton: false,
        reportTag: ExportTag.CURRENCY,
      },
      detailProps: {
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          title: 'Finančná mena',
          showBtn: permissions,
        },
        validationSchema,
      },
    });

  return <Evidence {...evidence} />;
}
