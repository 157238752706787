import React from 'react';
import { RequiredHoursCriteria } from 'src/models/education-cycle';
import * as Yup from 'yup';
import {
  FormAutocomplete,
  FormPanel,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
} from '@eas/common-web';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DictEducationField, DictEducationFieldAutocomplete } from '@models';

export function Fields() {
  return (
    <>
      <FormPanel label="Obecné">
        <FormTextField
          type="number"
          name="fromYear"
          label="Začiatok cyklu (rok)"
          required
        />
        <FormTextField
          type="number"
          name="toYear"
          label="Koniec cyklu (rok)"
          required
        />
      </FormPanel>
      <FormPanel label="Kritéria cyklu ">
        <FormTableField
          name="criteria"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          columns={[
            {
              datakey: 'name',
              name: 'Názov kritéria',
              width: 150,
            },
            {
              datakey: 'educationField',
              name: 'Oblasť zamerania vzdelávania',
              width: 150,
              CellComponent: TableFieldCells.useAutocompleteCellFactory<
                RequiredHoursCriteria,
                DictEducationField
              >({
                dataHook: useEducationFields,
                labelMapper: autocompleteLabelMapper,
                multiple: false,
              }),
            },
            {
              datakey: 'fromYear',
              name: 'Od roku',
              width: 150,
            },
            {
              datakey: 'toYear',
              name: 'Do roku',
              width: 150,
            },
            {
              datakey: 'requiredHours',
              name: 'Požadovaný počet hodín',
              width: 150,
            },
          ]}
          FormFieldsComponent={CriteriaFields}
          validationSchema={
            Yup.object<RequiredHoursCriteria>().shape({
              name: Yup.string().nullable().required(),
              educationField: Yup.mixed<DictEducationField>()
                .nullable()
                .required(),
              fromYear: Yup.number().nullable().required(),
              toYear: Yup.number()
                .nullable()
                .min(1990, 'Musí byť v rozsahu 1990 až 2100')
                .max(2100, 'Musí byť v rozsahu 1990 až 2100')
                .required(),
              requiredHours: Yup.number().nullable().required(),
            }) as Yup.Schema<RequiredHoursCriteria>
          }
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}

function CriteriaFields() {
  const educationFields = useEducationFields();

  return (
    <>
      <FormTextField name="name" label="Názov kritéria" required />
      <FormAutocomplete<DictEducationFieldAutocomplete>
        source={educationFields}
        name="educationField"
        label="Oblasť zamerania vzdelávania"
        required
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField type="number" name="fromYear" label="Od roku" required />
      <FormTextField type="number" name="toYear" label="Do roku" required />
      <FormTextField
        type="number"
        name="requiredHours"
        label="Požadovaný počet hodín"
        required
      />
    </>
  );
}
