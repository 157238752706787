import * as Yup from 'yup';
import { Role, Subject, User } from '@models';
import { AccountType } from '@enums';

export function useValidationSchema() {
  return Yup.object<User>().shape({
    email: Yup.string()
      .email('Musí byť v správnom formáte')
      .nullable()
      .required(),
    username: Yup.string().nullable().required(),
    role: Yup.mixed<Role>().nullable().required(),
    type: Yup.mixed<AccountType>().nullable().required(),
    firstName: Yup.string().when('type', {
      is: AccountType.BACKOFFICE,
      then: Yup.string().nullable().required(),
    }),
    lastName: Yup.string().when('type', {
      is: AccountType.BACKOFFICE,
      then: Yup.string().nullable().required(),
    }),
    person: Yup.mixed<Subject>().when('type', {
      is: AccountType.PORTAL,
      then: Yup.mixed<Subject>().nullable().required(),
    }),
  });
}
