import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormTextArea,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ValidityFields } from '@components/form/validity/validity-fields';
import { AuditingEntityAutocomplete } from '@models';

export function InsureeDialog() {
  const { mode } = useContext(DetailContext);

  const auditingEntities = useAuditingEntities();

  return (
    <>
      <FormAutocomplete<AuditingEntityAutocomplete>
        source={auditingEntities}
        label="Subjekt"
        name="insuredSubject"
        labelMapper={autocompleteLabelMapper}
        required
        disabled={mode !== DetailMode.NEW}
      />
      <ValidityFields
        fromLabel="Poistený od"
        fromKey="insuredFrom"
        toLabel="Poistený do"
        toKey="insuredTo"
        indefinitePeriodLabel="Poistený na dobu neurčitú"
        requiredTo={false}
      />
      {mode === DetailMode.VIEW && (
        <FormCheckbox
          label="Poistený na dobu neurčitú"
          name="indefinitePeriod"
          disabled
        />
      )}
      <FormTextArea label="Poznámka" name="note" />
    </>
  );
}
