import { isAfter } from 'date-fns';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  FormLocalDateField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Exam } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function useSuccessDialog() {
  const { source } = useContext<DetailHandle<Exam>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.EXAM}/${id}/success`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    !source?.data?.successfullyCompleted &&
    hasPermission(Permission.BO_EXAM_MANAGE);

  const FormFields = () => (
    <FormLocalDateField
      name="passingDate"
      label="Dátum úspešného zloženia skúšky"
      required
      maxDatePicker={new Date().toISOString()}
    />
  );

  const formValidationSchema = Yup.object().shape({
    passingDate: Yup.date()
      .nullable()
      .test('', '', function (date) {
        const { path, createError } = this;

        if (date && isAfter(date, new Date())) {
          return createError({ path, message: 'Nesmie byť v budúcnosti' });
        }

        return true;
      })
      .required(),
  });

  return {
    apiCall,
    showSuccessBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
