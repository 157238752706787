import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: 'calc(100vh - 180px)',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      marginTop: 200,
      flex: '1 0 auto',
    },
    header: {
      fontWeight: 400,
      lineHeight: '2em',
    },
    underline: {
      display: 'inline-block',
      boxShadow: `0px 13px 0px -10px ${theme.palette.primary.main}`,
    },
  })
);

export function Dashboard() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.header} align="center">
              <span className={classes.underline}>
                Vitajte v informačnom systéme UDVA
              </span>
            </Typography>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
