import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function usePublicInterestEntityCategories() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.FINANCIAL_STATEMENT_AUDIT}/public-interest-entities/categories/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
