import React from 'react';
import {
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormTextArea,
  FormTextField,
} from '@eas/common-web';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { useEducationForms } from '@modules/dict-education-form/dict-education-form-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  DictEducationFieldAutocomplete,
  DictEducationFormAutocomplete,
} from '@models';

export function Fields() {
  const educationForms = useEducationForms();
  const educationFields = useEducationFields();

  return (
    <>
      <FormPanel label="Obecné">
        <FormTextField name="name" label="Názov školenia" required />
        <FormAutocomplete<DictEducationFormAutocomplete>
          source={educationForms}
          name="form"
          label="Forma vzdelávania"
          required
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete<DictEducationFieldAutocomplete>
          source={educationFields}
          name="educationField"
          label="Oblasť zamerania vzdelávania"
          required
          labelMapper={autocompleteLabelMapper}
        />
        <FormTextField
          type="number"
          name="hours"
          label="Počet hodín"
          required
        />
        <FormLocalDateField name="fromDate" label="Dátum od" required />
        <FormLocalDateField name="toDate" label="Dátum do" required />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
