import React, { useContext, useRef } from 'react';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailActionButton,
  DetailContext,
  DetailMode,
  DictionaryAutocomplete,
  FieldFilter,
  FormDictionaryField,
  abortableFetch,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import {
  AddressFields,
  GeneralFields,
  NoteField,
} from '@modules/legal-person/legal-person-fields';
import { useValidationSchema } from '@modules/legal-person/legal-person-schema';
import { useLegalPersons } from '@modules/subject/subject-api';
import { ContactField } from '@composite/contacts/contact-field';
import { SubjectAutocomplete, SubjectLegal } from '@models';
import { EvidenceApiUrl, SubjectType } from '@enums';
import { autocompleteLabelMapper } from '../misc/label-mappers';

interface Props {
  formInitialValues: SubjectLegal;
  onResult: (value: SubjectLegal) => Promise<void>;
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
}

export function LegalPerson({
  formInitialValues,
  onResult,
  name,
  label = 'Právnická osoba',
  disabled,
  required,
}: Props) {
  const detailCtx = useContext(DetailContext);
  const createRef = useRef<ActionButtonHandle>(null);
  const validationSchema = useValidationSchema();

  const subjects = useLegalPersons(EvidenceApiUrl.SUBJECT);
  const subjectSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT}/list`,
  });

  const addSubjectApiCall = useEventCallback((_, formData) =>
    abortableFetch(EvidenceApiUrl.SUBJECT, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  return (
    <>
      <DetailActionButton
        ref={createRef}
        promptKey="ADD_LEGAL_PERSON"
        apiCall={addSubjectApiCall}
        buttonLabel="Pridať subjekt"
        dialogTitle={label}
        dialogText="Po vyplnení a potvrdení formulára bude vytvorená nová právnická osoba"
        FormFields={() => (
          <DetailContext.Provider
            value={{ ...detailCtx, mode: DetailMode.NEW }}
          >
            <GeneralFields />
            <AddressFields />
            <NoteField />
            <ContactField />
          </DetailContext.Provider>
        )}
        modes={[]}
        refreshAfterConfirm={false}
        dialogWidth={1000}
        dialogDisableBackdrop
        formInitialValues={formInitialValues}
        onResult={onResult}
        formValidationSchema={validationSchema}
      />
      <FormDictionaryField<SubjectAutocomplete>
        name={name}
        required={required}
        disabled={disabled}
        label={label}
        dialogPreFilters={[
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: SubjectType.LEGAL,
          },
        ]}
        filters={[
          {
            datakey: 'businessName',
            filterkey: 'businessName',
            label: 'Názov spoločnosti',
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            datakey: 'identifier',
            filterkey: 'identifier',
            label: 'IČO',
            FilterComponent: FieldFilter.FilterTextCell,
          },
        ]}
        columns={[
          {
            datakey: 'businessName',
            name: 'Názov spoločnosti',
            width: 300,
          },
          {
            datakey: 'identifier',
            name: 'IČO',
            width: 300,
          },
          {
            datakey: 'address.label',
            name: 'Trvalý pobyt osoby',
            width: 500,
          },
        ]}
        autoFocus={true}
        autocompleteSource={subjects}
        dialogSource={subjectSource}
        labelMapper={subjectsLabelMapper}
        maxRows={9}
        dialogWidth={1000}
        onCreate={() => createRef.current?.executeAction()}
      />
    </>
  );
}

const subjectsLabelMapper = (
  value?: DictionaryAutocomplete | DictionaryAutocomplete[] | null
) =>
  Array.isArray(value)
    ? value.map((i) => autocompleteLabelMapper(i)).join(',')
    : autocompleteLabelMapper(value);
