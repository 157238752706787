import * as Yup from 'yup';
import { AuditCompany, DictCountry, Subject } from '@models';
import { CompanyLegalForm } from '@enums';

export function useValidationSchema() {
  return Yup.object<AuditCompany>().shape({
    subject: Yup.mixed<Subject>().nullable().required(),
    legalForm: Yup.mixed<CompanyLegalForm>().nullable().required(),
    recordDate: Yup.string().nullable().required(),
    homeCountry: Yup.mixed<DictCountry>().nullable().required(),
  });
}
