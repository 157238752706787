import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { AuditRegistration } from '@models';
import { AuditRegistrationState, EvidenceApiUrl, Permission } from '@enums';
import { ActionBar } from './audit-registration-actionbar';
import { useColumns } from './audit-registration-columns';
import { Fields } from './audit-registration-fields';
import { useValidationSchema } from './audit-registration-schema';

export function AuditRegistrations() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const permissions = useEvidencePermission([
    Permission.BO_AUDIT_REGISTRATION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<AuditRegistration>({
    version: 2,
    identifier: 'AUDIT_REGISTRATION',
    apiProps: {
      url: EvidenceApiUrl.AUDIT_REGISTRATION,
    },
    tableProps: {
      columns,
      tableName: 'Registrácie vykonávania auditu',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(AuditRegistrationState.PAID),
    },
    detailProps: {
      validationSchema,
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Registrácia',
        showBtn: permissions,
        ActionBar,
      },
    },
  });

  return <Evidence {...evidence} />;
}
