import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormPanel,
  FormTableField,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useCertifications } from '@modules/certification/certification-api';
import { useLicences } from '@modules/licence/licence-api';
import { Redirect } from '@composite/redirect/redirect';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  Auditor,
  Certification,
  CertificationAutocomplete,
  Licence,
  LicenceAutocomplete,
} from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function CertificateAndLicenceFields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const licences = useLicences();
  const certifications = useCertifications();
  const { slovakLicence, slovakCertification } = useFormSelector(
    (data: Auditor) => ({
      slovakLicence: data.slovakLicence,
      slovakCertification: data.slovakCertification,
      esgLicences: data.esgLicences,
    })
  );

  return (
    <>
      <FormPanel label="Licencie">
        <FormAutocomplete<LicenceAutocomplete>
          source={licences}
          name="slovakLicence"
          labelMapper={autocompleteLabelMapper}
          label="Slovenská licencia"
          after={
            slovakLicence?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_LICENCE_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.LICENCE}
                id={slovakLicence.id}
              />
            )
          }
          disabled
        />
        <FormTableField
          name="foreignLicences"
          label="Zahraničné licencie"
          showDetailBtnCond={stubFalse}
          showRadioCond={stubFalse}
          showToolbar={false}
          columns={[
            ...(hasPermission(Permission.BO_LICENCE_READ) &&
            mode === DetailMode.VIEW
              ? [
                  {
                    datakey: 'id',
                    name: '',
                    width: 70,
                    CellComponent: function Cell({ value }) {
                      return (
                        <Redirect id={value} url={EvidenceBrowserUrl.LICENCE} />
                      );
                    },
                  } as TableFieldColumn<Licence>,
                ]
              : []),
            {
              datakey: 'label',
              name: 'Licencia',
              width: 150,
            },
            {
              datakey: 'issuedDate',
              name: 'Dátum vydania',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              datakey: 'origin.country.label',
              name: 'Krajina pôvodu',
              width: 150,
            },
            {
              datakey: 'origin.institution',
              name: 'Vydávajúci orgán',
              width: 150,
            },
          ]}
        />
        <FormTableField
          name="esgLicences"
          label="ESG licencie"
          showDetailBtnCond={stubFalse}
          showRadioCond={stubFalse}
          showToolbar={false}
          columns={[
            ...(hasPermission(Permission.BO_LICENCE_READ) &&
            mode === DetailMode.VIEW
              ? [
                  {
                    datakey: 'id',
                    name: '',
                    width: 70,
                    CellComponent: function Cell({ value }) {
                      return (
                        <Redirect id={value} url={EvidenceBrowserUrl.LICENCE} />
                      );
                    },
                  } as TableFieldColumn<Licence>,
                ]
              : []),
            {
              datakey: 'label',
              name: 'Licencia',
              width: 150,
            },
            {
              datakey: 'issuedDate',
              name: 'Dátum vydania',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              datakey: 'origin.country.label',
              name: 'Krajina pôvodu',
              width: 150,
            },
            {
              datakey: 'origin.institution',
              name: 'Vydávajúci orgán',
              width: 150,
            },
          ]}
        />
      </FormPanel>
      <FormPanel label="Certifikáty">
        <FormAutocomplete<CertificationAutocomplete>
          source={certifications}
          name="slovakCertification"
          labelMapper={autocompleteLabelMapper}
          label="Slovenský certifikát"
          after={
            slovakCertification?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_CERTIFICATION_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.CERTIFICATION}
                id={slovakCertification.id}
              />
            )
          }
          disabled
        />
        <FormTableField
          name="foreignCertifications"
          label="Zahraničné certifikáty"
          showDetailBtnCond={stubFalse}
          showRadioCond={stubFalse}
          showToolbar={false}
          columns={[
            ...(hasPermission(Permission.BO_CERTIFICATION_READ) &&
            mode === DetailMode.VIEW
              ? [
                  {
                    datakey: 'id',
                    name: '',
                    width: 70,
                    CellComponent: function Cell({ value }) {
                      return (
                        <Redirect
                          id={value}
                          url={EvidenceBrowserUrl.CERTIFICATION}
                        />
                      );
                    },
                  } as TableFieldColumn<Certification>,
                ]
              : []),
            {
              datakey: 'label',
              name: 'Certifikát',
              width: 150,
            },
            {
              datakey: 'issuedDate',
              name: 'Dátum vydania',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
            {
              datakey: 'origin.country.label',
              name: 'Krajina pôvodu',
              width: 150,
            },
            {
              datakey: 'origin.institution',
              name: 'Vydávajúci orgán',
              width: 150,
            },
          ]}
        />
      </FormPanel>
    </>
  );
}
