import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { DictCountry } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './dict-country-columns';
import { Fields } from './dict-country-fields';
import { useValidationSchema } from './dict-country-schema';

export function DictCountries() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const permissions = useEvidencePermission([Permission.BO_COUNTRY_MANAGE]);

  const evidence = DictionaryEvidence.useDictionaryEvidence<DictCountry>({
    version: 3,
    identifier: 'DICT_COUNTRY',
    apiProps: {
      url: EvidenceApiUrl.DICT_COUNTRY,
    },
    tableProps: {
      columns,
      tableName: 'Krajiny',
      showBulkActionButton: false,
      reportTag: ExportTag.COUNTRY,
    },
    detailProps: {
      GeneralFieldsComponent: Fields,
      toolbarProps: {
        title: 'Krajina',
        showBtn: permissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
