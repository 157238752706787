import React, { useContext } from 'react';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {
  DetailActionButton,
  DetailContext,
  DetailMode,
  DetailToolbarButton2,
  DetailToolbarButtonType,
} from '@eas/common-web';
import { useGenerateNextDialog } from './dialog-actions/generate-next-hook';

export function Toolbar() {
  const { showGenerateNextBtn, ...generateNextProps } = useGenerateNextDialog();
  const { mode } = useContext(DetailContext);

  return (
    <>
      {showGenerateNextBtn && (
        <DetailActionButton
          promptKey="GENERATE_NEXT"
          dialogTitle="Náhľad generovaného certifikátu"
          buttonLabel="Generovať"
          dialogText="Prajete si generovať nasledujúci certifikát?"
          ButtonComponent={(props) => (
            <DetailToolbarButton2
              {...props}
              disabled={![DetailMode.NONE, DetailMode.VIEW].includes(mode)}
              startIcon={<AddBoxOutlinedIcon />}
              type={DetailToolbarButtonType.PRIMARY}
            />
          )}
          {...generateNextProps}
          modes={[
            DetailMode.NONE,
            DetailMode.VIEW,
            DetailMode.EDIT,
            DetailMode.NEW,
          ]}
          dialogDisableBackdrop
          dialogWidth={800}
        />
      )}
    </>
  );
}
