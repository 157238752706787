import React, { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import * as Yup from 'yup';
import {
  AbortableFetch,
  DetailContext,
  DetailHandle,
  DetailMode,
  FormPanel,
  FormTableField,
  FormTextArea,
  SnackbarContext,
  UserContext,
  abortableFetch,
  eqFilterParams,
  useEventCallback,
} from '@eas/common-web';
import { AddressFields } from '@modules/audit-company/fields/addresses';
import { GeneralFields } from '@modules/audit-company/fields/general';
import { useColumns } from '@composite/employments/employment-columns';
import { EmploymentDialog } from '@composite/employments/employment-dialog';
import { useValidationSchema } from '@composite/employments/employment-schema';
import { SimpleTabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import {
  AuditCompany,
  CompanyForeignLicence,
  DictCountry,
  Employment,
  LicenceApplication,
  Stakeholder,
  Subject,
} from '@models';
import {
  AuditingEntityType,
  CompanyLegalForm,
  EvidenceApiUrl,
  LicenceApplicationState,
  Messages,
  Permission,
} from '@enums';
import {
  ForeignLicencesField,
  RelatedSubjectField,
} from '../licence-application-fields';

export interface ConfirmApplicationDto {
  company: AuditCompany;
  foreignLicences?: CompanyForeignLicence[];
  stakeholders?: Stakeholder[];
  employments?: Employment[];
}

export function useConfirmDialog() {
  const detailCtx = useContext<DetailHandle<LicenceApplication>>(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { hasPermission } = useContext(UserContext);
  const fetch = useRef<AbortableFetch | null>(null);

  const getSubjectApiCall = useEventCallback((identifier: string) =>
    abortableFetch(`${EvidenceApiUrl.SUBJECT}/autocomplete`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(
        eqFilterParams({ field: 'identifier', value: identifier })
      ),
    })
  );

  const createSubjectApiCall = useEventCallback((formData: Subject) =>
    abortableFetch(EvidenceApiUrl.SUBJECT, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const createLicenceApplicationApiCall = useEventCallback(
    (id, formData: any) =>
      abortableFetch(`${EvidenceApiUrl.LICENCE_APPLICATION}/${id}/confirm`, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(formData),
      })
  );

  const showBtn =
    detailCtx?.source?.data?.state === LicenceApplicationState.PAID &&
    hasPermission(Permission.BO_LICENCE_APPLICATION_MANAGE);

  const handleSubmit = useEventCallback(async (values) => {
    const { company, stakeholders } = values || {};
    const completedStakeholders = [];

    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = getSubjectApiCall(company?.subject?.identifier);
      const response = await fetch.current.json();
      let subject = response?.items?.[0];

      if (!subject) {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = createSubjectApiCall(company?.subject);
        subject = await fetch.current.json();
      }

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      for (const stakeholder of stakeholders) {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        const { relationTypes, ...rest } = stakeholder || {};

        fetch.current = createSubjectApiCall(rest);
        const stakeholderSubject = await fetch.current.json();
        completedStakeholders.push({
          types: relationTypes,
          subject: stakeholderSubject,
        });
      }

      fetch.current = createLicenceApplicationApiCall(
        detailCtx?.source?.data?.id,
        {
          ...values,
          company: {
            ...company,
            subject,
            type: AuditingEntityType.AUDIT_COMPANY,
          },
          stakeholders: completedStakeholders,
        }
      );
      await fetch.current.json();

      unstable_batchedUpdates(() => {
        detailCtx?.source?.refresh();
        showSnackbar(...Messages.Common.SUCCESS);
      });
    } catch (err) {
      showSnackbar(...Messages.Common.ERROR.UNEXPECTED);
      console.log(err);

      throw err;
    }
  });

  const FormFields = () => {
    const employmentColumns = useColumns('IN_AUDIT_COMPANY');
    const validationSchema = useValidationSchema();

    const MemberFields = () => {
      return (
        <>
          <FormPanel label="Zamestnania">
            <FormTableField
              name="employments"
              labelOptions={{ hide: true }}
              layoutOptions={{ noSpacing: true }}
              FormFieldsComponent={EmploymentDialog}
              columns={employmentColumns}
              validationSchema={validationSchema}
            />
          </FormPanel>
          <RelatedSubjectField name="stakeholders" />
          <FormPanel label="Členstvo audítorskej spoločnosti v sieti a zoznam všetkých subjektov začlenených do siete a pridružených spoločností audítorských spoločností a ich sídlo alebo odkaz na miesto, kde sú tieto informácie prístupné verejnosti">
            <FormTextArea
              name="company.membership"
              labelOptions={{ hide: true }}
            />
          </FormPanel>
        </>
      );
    };

    const config: TabsConfig = [
      {
        label: 'Obecné',
        key: 'GENERAL',
        validationKeys: ['company.legalForm', 'company.homeCountry'],
        content: (
          <GeneralFields
            showAuthoredFields={false}
            prefix="company"
            showRecordDate={false}
            disabledSubject
          />
        ),
      },
      {
        label: 'Adresy',
        key: 'OFFICES',
        content: <AddressFields prefix="company" />,
      },
      {
        label: 'Licencie',
        key: 'LICENCES',
        content: <ForeignLicencesField name="foreignLicences" />,
      },
      {
        label: 'Členovia',
        key: 'MEMBERS',
        content: <MemberFields />,
      },
    ];

    return (
      <DetailContext.Provider value={{ ...detailCtx, mode: DetailMode.NEW }}>
        <SimpleTabs config={config} />
      </DetailContext.Provider>
    );
  };

  const formValidationSchema = Yup.object().shape({
    company: Yup.object<ConfirmApplicationDto>().shape({
      homeCountry: Yup.mixed<DictCountry>().nullable().required(),
      legalForm: Yup.mixed<CompanyLegalForm>().nullable().required(),
    }),
  });

  return {
    submitCallback: detailCtx?.source?.data?.forAuditor
      ? undefined
      : handleSubmit,
    apiCall: createLicenceApplicationApiCall,
    showConfirmBtn: showBtn,
    FormFields: detailCtx?.source?.data?.forAuditor ? undefined : FormFields,
    formValidationSchema: detailCtx?.source?.data?.forAuditor
      ? undefined
      : formValidationSchema,
  };
}
