import React, { useContext } from 'react';
import {
  FormAutocomplete,
  FormContext,
  FormLocalDateField,
  FormSelect,
  FormTextArea,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { useCharges } from '@modules/charge/charge-api';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { useSubjects } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  Charge,
  ChargeAutocomplete,
  DictMonetaryCurrencyAutocomplete,
  SubjectAutocomplete,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export interface CreatePaymentFieldsProps {
  showCharge?: boolean;
  showSubject?: boolean;
  disabledEmail?: boolean;
}

export function FormFields({
  showCharge = true,
  showSubject = true,
  disabledEmail = false,
}: CreatePaymentFieldsProps) {
  const { paymentStates } = useContext(StaticDataContext);
  const { setFieldValue } = useContext(FormContext);

  const subjects = useSubjects();
  const charges = useCharges(EvidenceApiUrl.CHARGE);
  const currencies = useMonetaryCurrencies();
  const stateSource = useStaticListSource(paymentStates);

  return (
    <>
      {showCharge && (
        <FormAutocomplete<ChargeAutocomplete>
          source={charges}
          name="charge"
          label="Poplatok"
          labelMapper={autocompleteLabelMapper}
          required
          notifyChange={(value) => {
            setFieldValue('amount', (value as Charge)?.amount);
          }}
        />
      )}
      {showSubject && (
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="subject"
          label="Subjekt vykonávajúci platbu"
          labelMapper={autocompleteLabelMapper}
          required
        />
      )}
      <FormSelect
        source={stateSource}
        name="state"
        label="Stav"
        valueIsId
        required
        disabled
      />
      <FormTextField
        name="email"
        label="Email"
        required
        disabled={disabledEmail}
      />
      <FormTextField name="year" label="Platba na rok" type="number" required />
      <FormTextField
        name="additionalInfo"
        label="Doplňujúce informácie k platbe"
      />
      <FormLocalDateField name="dueDate" label="Dátum splatnosti" required />
      <FormTextField name="amount.amount" label="Suma" required type="number" />
      <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
        source={currencies}
        name="amount.currency"
        label="Mena"
        labelMapper={autocompleteLabelMapper}
        required
      />
      <FormTextArea
        name="note"
        label="Poznámka"
        layoutOptions={{ noSpacing: true }}
      />
    </>
  );
}
