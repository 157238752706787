import { noop, stubFalse } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { TableField, TableFieldCells } from '@eas/common-web';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { useEducationOverview } from './education-overview-hook';
import { Toolbar } from './education-overview-toolbar';

const useStyles = makeStyles({
  page: {
    height: '100%',
    overflowY: 'auto',
  },
  container: {
    marginTop: 15,
    justifyContent: 'center',
    width: '100%',
  },
  titleWrapper: {
    textAlign: 'left',
    borderBottom: '1px solid #cdcdcd',
  },
  title: {
    display: 'inline-block',
    margin: '10px 0',
    fontSize: 25,
    fontWeight: 500,
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    gap: '20px',
  },
});

export function EducationOverview() {
  const classes = useStyles();
  const cycles = useEducationCycles();

  const { params, setParams, data, refresh } = useEducationOverview();

  useEffect(() => {
    const loadCycles = async () => {
      const result = await cycles.loadMore();
      setParams(result.items[0]);
    };

    loadCycles();
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Audítor',
        datakey: 'auditor.label',
        width: 200,
      },
      {
        name: 'Číslo licencie',
        datakey: 'auditor.slovakLicence.label',
        width: 100,
      },
      ...(data?.criteria?.map(({ name }) => ({
        name,
        datakey: name!,
        width: 200,
      })) ?? []),
      {
        name: 'Splnenie podmienok',
        datakey: 'fullfillingAllCriteria',
        width: 100,
        CellComponent: TableFieldCells.BooleanCell,
      },
      {
        name: 'Poznámka',
        datakey: 'note.note',
        width: 300,
        CellComponent: TableFieldCells.TextCell,
      },
    ],
    [data]
  );

  const MemoizedTable = useMemo(
    () =>
      function Table() {
        return (
          <TableField
            value={data?.overviewPerAuditors ?? []}
            ToolbarComponent={(props) => (
              <Toolbar
                {...props}
                items={data?.overviewPerAuditors ?? []}
                cycle={params}
                setCycle={setParams}
                refresh={refresh}
              />
            )}
            showDetailBtnCond={stubFalse}
            onChange={noop}
            columns={columns}
            maxRows={20}
          />
        );
      },
    [data, columns]
  );

  return (
    <div className={classes.page}>
      <Container maxWidth="lg">
        <Grid container spacing={5} className={classes.container}>
          <Grid item xs={12}>
            <div className={classes.titleWrapper}>
              <Typography variant="h1" className={classes.title}>
                Prehľad absolvovaných hodín sústavného vzdelávania štatutárnych
                audítorov
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <MemoizedTable />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
