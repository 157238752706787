import React, { useContext } from 'react';
import { DisciplinaryRecord } from 'src/models/disciplinary-record';
import * as Yup from 'yup';
import {
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  TableFieldToolbarProps,
  UserContext,
} from '@eas/common-web';
import { DISCIPLINARY_RECORD_SOURCE } from '@modules/audit-company/audit-company-hooked-sources';
import { RemoteTableToolbar } from '@components/toolbar/remote-table-toolbar';
import { Permission } from '@enums';
import {
  addDisciplinaryRecordApiCall,
  deleteDisciplinaryRecordApiCall,
  editDisciplinaryRecordApiCall,
} from './disciplinary-record-api';
import { useDisciplinaryRecordColumns } from './disciplinary-record-columns';
import { DisciplinaryRecordDialog } from './disciplinary-record-dialog';

interface Props {
  disabled?: boolean;
}
const validationSchema = Yup.object<DisciplinaryRecord>().shape({
  sanction: Yup.string().nullable().required(),
  measure: Yup.string().nullable().required(),
}) as Yup.Schema<DisciplinaryRecord>;

export function DisciplinaryRecords({ disabled }: Props) {
  const columns = useDisciplinaryRecordColumns();

  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[DISCIPLINARY_RECORD_SOURCE].source;

  const { hasPermission } = useContext(UserContext);

  const Toolbar = (props: TableFieldToolbarProps) => (
    <RemoteTableToolbar
      {...props}
      source={source}
      dialogTitle="Disciplinárne opatrenie"
      validationSchema={validationSchema}
      Dialog={DisciplinaryRecordDialog}
      addButtonProps={{
        promptKey: 'ADD_DISCIPLINARY_RECORD',
        apiCall: addDisciplinaryRecordApiCall,
      }}
      editButtonProps={{
        promptKey: 'EDIT_DISCIPLINARY_RECORD',
        apiCall: editDisciplinaryRecordApiCall,
      }}
      deleteButtonProps={{
        promptKey: 'DELETE_DISCIPLINARY_RECORD',
        apiCall: deleteDisciplinaryRecordApiCall,
      }}
      disabled={
        disabled || !hasPermission(Permission.BO_AUDITING_ENTITY_MANAGE)
      }
    />
  );

  return (
    <FormPanel
      label="Disciplinárne opatrenia"
      expandable={true}
      defaultExpanded={true}
    >
      <RemoteTableField
        source={source}
        columns={columns}
        ToolbarComponent={Toolbar}
        showToolbar={!disabled}
        disabled={disabled}
      />
    </FormPanel>
  );
}
