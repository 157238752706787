import { useAutocompleteSource } from '@eas/common-web';
import { DictInsuranceCompanyAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useInsuranceCompanies(props?: { apiUrl?: string }) {
  return useAutocompleteSource<DictInsuranceCompanyAutocomplete>({
    ...props,
    url: `${EvidenceApiUrl.DICT_INSURANCE_COMPANY}/autocomplete`,
  });
}
