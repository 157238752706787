import React, { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { AuditNotice, AuditingEntityAutocomplete } from '@models';

export function useColumns(): TableColumn<AuditNotice>[] {
  const { auditNoticeStates, auditNoticeTypes } = useContext(StaticDataContext);
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<AuditNotice>();

  const useStates = () => useStaticListSource(auditNoticeStates);
  const useTypes = () => useStaticListSource(auditNoticeTypes);

  return [
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Vlastník oznámenia',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dateFrom',
      name: 'Dátum od',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dateTo',
      name: 'Dátum do',
      width: 150,
      CellComponent: ({ value, ...props }) =>
        value ? (
          <TableCells.DateCell {...props} value={value} />
        ) : (
          <TableCells.TextCell {...props} value="Na dobu neurčitú" />
        ),
      FilterComponent: TableFilterCells.FilterDateCell,
      filterOperation: ApiFilterOperation.AND,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav podania',
      width: 150,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ podania',
      width: 250,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
