import { getErrorMessage } from '@utils/error-message';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { LicenceType } from 'src/enums/licence';
import * as Yup from 'yup';
import {
  AbortableFetch,
  ApiFilterOperation,
  EvidenceContext,
  FormAutocomplete,
  FormContext,
  FormFieldWrapper,
  FormLocalDateField,
  FormSelect,
  FormTextField,
  Select,
  SnackbarContext,
  UserContext,
  abortableFetch,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import {
  ErrorOptions,
  LabelOptions,
  LayoutOptions,
} from '@eas/common-web/dist/types/composite/form/fields/wrapper/form-field-wrapper-types';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntity,
  AuditingEntityAutocomplete,
  ErrorObject,
  GenerateLicenceDto,
} from '@models';
import {
  AuditingEntityType,
  EvidenceApiUrl,
  Messages,
  Permission,
} from '@enums';

export function useGenerateNextDialog() {
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { tableRef } = useContext(EvidenceContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const peekApiCall = useEventCallback(
    (type: AuditingEntityType, isEsg?: boolean) =>
      abortableFetch(
        `${EvidenceApiUrl.LICENCE}/peek-next?type=${type}&isEsg=${
          isEsg ?? false
        }`,
        {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'GET',
        }
      )
  );

  const generateApiCall = useEventCallback(({ subject, type, isEsg }) =>
    abortableFetch(`${EvidenceApiUrl.LICENCE}/generate-next`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({ subjectId: subject.id, type, isEsg }),
    })
  );

  const handleSubmit = useEventCallback(async (values) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = generateApiCall(values);
      await fetch.current.json();

      showSnackbar(...Messages.Common.SUCCESS);
      tableRef.current?.refresh();
    } catch (err) {
      const message = getErrorMessage(
        err as ErrorObject<string>,
        Messages.Licence.GENERATE_NEXT
      );

      if ((err as Error).name !== 'AbortError') {
        showSnackbar(...message);
      }

      throw err;
    }
  });

  const showBtn = hasPermission(Permission.BO_LICENCE_MANAGE);

  const FormFields = () => {
    const { licenceStates, auditingEntityTypes, licenceTypes } =
      useContext(StaticDataContext);
    const { setFieldValues, setFieldValue } = useContext(FormContext);

    const licenceStateSource = useStaticListSource(licenceStates);
    const licenceTypeSource = useStaticListSource(licenceTypes);

    const auditingEntityTypeSource = useStaticListSource(auditingEntityTypes);

    const { type, isEsg } = useFormSelector((data: GenerateLicenceDto) => ({
      type: data.type,
      isEsg: data.isEsg ?? false,
    }));

    useEffect(() => {
      const peekNext = async () => {
        const response = await peekApiCall(type, isEsg).json();
        setFieldValues({ ...response, type, isEsg });
      };
      peekNext();
    }, [type, isEsg]);

    const auditingEntities = useAuditingEntities({
      params: {
        filters: [
          { field: 'type.id', value: type, operation: ApiFilterOperation.EQ },
          {
            field: isEsg ? 'esgLicence' : 'slovakLicence',
            operation: ApiFilterOperation.IS_NULL,
          },
        ],
      },
    });

    return (
      <>
        <FormSelect
          source={auditingEntityTypeSource}
          name="type"
          label="Typ subjektu"
          required
          notifyChange={() => {
            setFieldValue('subject', undefined);
          }}
          valueIsId
        />
        <FormFieldWrapper
          label="Typ licencie"
          required
          disabled={false}
          errorOptions={[] as ErrorOptions}
          labelOptions={[] as LabelOptions}
          layoutOptions={[] as LayoutOptions}
        >
          <Select
            onChange={(item) => {
              setFieldValue('isEsg', _.get(item, 'id') === LicenceType.ESG);
            }}
            source={licenceTypeSource}
            value={
              isEsg
                ? licenceTypeSource.items.find(
                    (item) => item.id === LicenceType.ESG
                  ) ?? { id: LicenceType.ESG, name: 'ESG' }
                : licenceTypeSource.items.find(
                    (item) => item.id === LicenceType.STANDARD
                  ) ?? { id: LicenceType.STANDARD, name: 'Klasická' }
            }
          />
        </FormFieldWrapper>
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="subject"
          label="Subjekt"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField name="number" label="Číslo licencie" disabled />
        <FormSelect
          source={licenceStateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled
        />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" disabled />
        <FormTextField
          name="origin.country.name"
          label="Krajina pôvodu"
          disabled
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          disabled
        />
      </>
    );
  };

  const formValidationSchema = Yup.object<GenerateLicenceDto>().shape({
    subject: Yup.mixed<AuditingEntity>().nullable().required(),
    type: Yup.mixed<AuditingEntityType>().nullable().required(),
    isEsg: Yup.boolean().nullable(),
  });

  return {
    showGenerateNextBtn: showBtn,
    FormFields,
    submitCallback: handleSubmit,
    formValidationSchema,
  };
}
