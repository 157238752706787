/**
 * Kategórie subjektu verejného záujmu
 */
export enum PublicInterestEntityCategory {
  /**
   * Emitent cenných papierov
   */
  ECP = 'ECP',

  /**
   * Banka
   */
  B = 'B',

  /**
   * Pobočka zahraničnej banky
   */
  PZB = 'PZB',

  /**
   * Exportno-importná banka SR
   */
  EXIM = 'EXIM',

  /**
   * Poisťovňa
   */
  P = 'P',

  /**
   * Pobočka poisťovne z iného členského štátu
   */
  PZP = 'PZP',

  /**
   * Zaisťovňa
   */
  Z = 'Z',

  /**
   * Pobočka zaisťovne z iného členského štátu
   */
  PZZ = 'PZZ',

  /**
   * Zdravotná poisťovňa
   */
  ZP = 'ZP',

  /**
   * Správcovská spoločnosť
   */
  SS = 'SS',

  /**
   * Pobočka zahraničnej správcovskej spoločnosti
   */
  PZSS = 'PZSS',

  /**
   * Dôchodková správcovská spoločnosť
   */
  DSS = 'DSS',

  /**
   * Doplnková dôchodková spoločnosť
   */
  DDS = 'DDS',

  /**
   * Burza cenných papierov
   */
  BCP = 'BCP',

  /**
   * Železnice Slovenskej republiky
   */
  ZSR = 'ŽSR',

  NBS = 'NBS',
  CDCP = 'CDCP',
  OS = 'OS',
  KUZ = 'KÚZ',
  OCP = 'OCP',
  PZOCP = 'PZOCP',
}
