import React, { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  FormPanel,
  FormTextArea,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { GeneralSubmission } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function useReplyDialog() {
  const { source } = useContext<DetailHandle<GeneralSubmission>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.GENERAL_SUBMISSION}/${id}/reply`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_GENERAL_SUBMISSION_MANAGE) &&
    !source.data?.reply;

  const FormFields = () => (
    <FormPanel label="Text odpovede">
      <FormTextArea name="reply" labelOptions={{ hide: true }} required />
    </FormPanel>
  );

  return {
    apiCall,
    showReplyBtn: showBtn,
    FormFields,
  };
}
