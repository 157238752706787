import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { FormContext, defaultGetItem } from '@eas/common-web';

interface FormTableDetailFieldsProps {
  url: string;
  id: string;
}

export function FormTableDetailWrapper({
  url,
  id,
  children,
}: PropsWithChildren<FormTableDetailFieldsProps>) {
  const { setFieldValues } = useContext(FormContext);

  useEffect(() => {
    const loadDetail = async () => {
      const data = await defaultGetItem(url, id).json();
      setFieldValues(data);
    };

    loadDetail();
  }, [id]);

  return <>{children}</>;
}
