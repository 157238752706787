import { groupBy, noop } from 'lodash';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  DetailContext,
  DetailMode,
  FormCheckboxGroup,
  FormContext,
  FormCustomField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Permission, Role } from '@models';

const useStyles = makeStyles({
  autoAssignedPerms: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '5px',
  },
});

export function Fields() {
  const classes = useStyles();

  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const { permissions, roleTags, accountTypes, permissionGroups } =
    useContext(StaticDataContext);

  const tagSource = useStaticListSource(roleTags);
  const accountTypeSource = useStaticListSource(accountTypes);

  const { allowedFor, permissions: assignedPermissions } = useFormSelector(
    (data: Role) => ({
      allowedFor: data.allowedFor,
      permissions: data.permissions,
    })
  );

  const groupedPermissions = groupBy(permissions, 'group');

  return (
    <>
      <FormPanel label="Rola">
        <FormTextField name="name" label="Názov" required />
        <FormTextArea name="description" label="Popis" required />
        <FormSelect
          source={tagSource}
          name="tag"
          label="Tag používateľskej role"
          valueIsId
        />
        <FormSelect
          source={accountTypeSource}
          name="allowedFor"
          label="Určená pre typ účtu"
          valueIsId
          required
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
      {allowedFor && (
        <>
          {Object.entries(groupedPermissions).map(([key, value]) => {
            const groupAutoAssignablePerms = value?.filter((p) => p.autoAssign);
            const autoAssignedPerms = groupAutoAssignablePerms.filter((p) =>
              assignedPermissions?.includes(p.id)
            );
            const permsToAssign = value?.filter((p) => !p.autoAssign);
            const permsToAssignIds = permsToAssign.map((perm) => perm.id);
            const areAllSelected = permsToAssignIds.every((perm) =>
              assignedPermissions?.includes(perm)
            );

            const handleAllPermissionsCheck = () => {
              if (areAllSelected) {
                setFieldValue(
                  'permissions',
                  assignedPermissions?.filter(
                    (perm) => !permsToAssignIds.includes(perm)
                  )
                );
              } else {
                setFieldValue('permissions', [
                  ...(assignedPermissions ?? []),
                  ...permsToAssignIds,
                ]);
              }
            };

            const groupPermissionSource = {
              loading: false,
              reset: noop,
              loadDetail: (item: Permission) => Promise.resolve(item),
              items: permsToAssign,
            };

            return (
              (permsToAssign?.length > 0 || autoAssignedPerms?.length > 0) && (
                <React.Fragment key={key}>
                  <FormPanel
                    label={`Oprávnenia - ${
                      permissionGroups.find((g) => g.id === key)?.name
                    }`}
                    expandable={true}
                    defaultExpanded={false}
                    unmountOnExit={true}
                  >
                    {permsToAssign?.length > 0 && (
                      <>
                        <FormCustomField label="Označiť všetky oprávnenia">
                          <Checkbox
                            value={areAllSelected}
                            onChange={handleAllPermissionsCheck}
                            disabled={mode === DetailMode.VIEW}
                          />
                        </FormCustomField>
                        <FormCheckboxGroup<Permission>
                          name="permissions"
                          label="Oprávnenia k priradení"
                          source={groupPermissionSource}
                          labelMapper={autocompleteLabelMapper}
                        />
                      </>
                    )}
                    {autoAssignedPerms?.length > 0 && (
                      <FormCustomField label="Automaticky priradené oprávnenia">
                        <div className={classes.autoAssignedPerms}>
                          {autoAssignedPerms.map((p) => (
                            <TextField
                              key={p.id}
                              value={p.name}
                              disabled
                              onChange={noop}
                            />
                          ))}
                        </div>
                      </FormCustomField>
                    )}
                  </FormPanel>
                </React.Fragment>
              )
            );
          })}
        </>
      )}
    </>
  );
}
