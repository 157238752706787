import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useAuditingEntities, useAuditors } from '@modules/auditor/auditor-api';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  AuditorAutocomplete,
  Education,
} from '@models';

export function useColumns(): TableColumn<Education>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Education>();

  return [
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Audítor/Auditná spoločnosť poskytujúca vzdelávanie',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'auditor.label',
      sortkey: 'auditor.name',
      filterkey: 'auditor.id',
      name: 'Audítor',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditorAutocomplete>(
          useAuditors,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'cycle.label',
      sortkey: 'cycle.name',
      filterkey: 'cycle.id',
      name: 'Vzdelávací cyklus',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterAutocompleteCellFactory(
        useEducationCycles,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'year',
      name: 'Rok',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'hours',
      name: 'Uznaný počet hodín',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
