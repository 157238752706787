import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailActionButton,
  DetailContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { Certification, Licence } from '@models';
import { useAddCertificationDialog } from './dialog-actions/add-certification-hook';
import { useAddLicenceDialog } from './dialog-actions/add-licence-hook';

export function ActionBar() {
  const { source } = useContext(DetailContext);

  const { showAddLicenceBtn, ...addLicenceProps } = useAddLicenceDialog();
  const { showAddCertificationBtn, ...addCertificationProps } =
    useAddCertificationDialog();

  return (
    <>
      {showAddLicenceBtn && (
        <DetailActionButton
          promptKey="ADD_LICENCE"
          buttonLabel="Pridať zahraničnú licenciu"
          dialogText={null}
          dialogTitle="Pridanie zahraničnej licencie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={1000}
          dialogDisableBackdrop
          formInitialValues={{ id: uuidv4(), subject: source?.data } as Licence}
          {...addLicenceProps}
        />
      )}
      {showAddCertificationBtn && (
        <DetailActionButton
          promptKey="ADD_CERTIFICATION"
          buttonLabel="Pridať zahraničný certifikát"
          dialogText={null}
          dialogTitle="Pridanie zahraničného certifikátu"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={1000}
          dialogDisableBackdrop
          formInitialValues={
            { id: uuidv4(), subject: source?.data } as Certification
          }
          {...addCertificationProps}
        />
      )}
    </>
  );
}
