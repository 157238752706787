import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useCharges } from '@modules/charge/charge-api';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { useSubjects } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  Charge,
  ChargeAutocomplete,
  DictMonetaryCurrencyAutocomplete,
  Payment,
  SubjectAutocomplete,
} from '@models';
import {
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  Permission,
  SubjectType,
} from '@enums';

export function Fields() {
  const { paymentStates } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { setFieldValue } = useContext(FormContext);

  const stateSource = useStaticListSource(paymentStates);
  const charges = useCharges(EvidenceApiUrl.CHARGE);
  const subjects = useSubjects();
  const currencies = useMonetaryCurrencies();

  const { qr, charge, subject } = useFormSelector((data: Payment) => ({
    qr: data.qr,
    charge: data.charge,
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Platba">
        {mode === DetailMode.NEW && (
          <FormCheckbox name="sendEmail" label="Odoslať email" />
        )}
        <FormAutocomplete<ChargeAutocomplete>
          source={charges}
          name="charge"
          label="Poplatok"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          notifyChange={(value) => {
            setFieldValue('amount', (value as Charge)?.amount);
          }}
          required
          after={
            charge?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_CHARGE_READ) && (
              <Redirect url={EvidenceBrowserUrl.CHARGE} id={charge.id} />
            )
          }
        />
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="subject"
          label="Subjekt vykonávajúci platbu"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          required
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                url={
                  subject?.type === SubjectType.NATURAL
                    ? EvidenceBrowserUrl.NATURAL_PERSON
                    : EvidenceBrowserUrl.LEGAL_PERSON
                }
                id={subject.id}
              />
            )
          }
        />
        {mode !== DetailMode.EDIT && (
          <FormSelect
            source={stateSource}
            name="state"
            label="Stav"
            valueIsId
            disabled
          />
        )}
        <FormTextField
          name="email"
          label="Email"
          required
          disabled={mode !== DetailMode.NEW}
        />
        {mode === DetailMode.VIEW && (
          <FormTextField
            name="vs"
            label="Variabilný symbol"
            type="number"
            disabled
          />
        )}
        <FormTextField
          name="additionalInfo"
          label="Doplňujúce informácie"
          disabled={mode !== DetailMode.NEW}
        />
        <FormTextField
          name="year"
          label="Platba na rok"
          type="number"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormLocalDateField
          name="dueDate"
          label="Dátum splatnosti"
          required
          disabled={mode !== DetailMode.NEW}
          disablePast
          minDatePicker={new Date().toISOString()}
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox name="overdue" label="Po splatnosti" disabled />
        )}
        {mode !== DetailMode.NEW && (
          <FormLocalDateField
            name="receivedDate"
            label="Dátum prijatia platby"
            disabled
          />
        )}
        {mode === DetailMode.VIEW && (
          <>
            <FormTextField
              name="qr.label"
              label="QR kód platby"
              disabled
              after={
                qr?.id &&
                hasPermission(Permission.BO_DOCUMENT_READ) && (
                  <Redirect id={qr.id} url={EvidenceBrowserUrl.DOCUMENT} />
                )
              }
            />
            <FormCheckbox
              name="emailSent"
              label="Platba odoslaná na email"
              disabled
            />
          </>
        )}
      </FormPanel>
      <FormPanel label="Platba vo výške">
        <FormTextField
          type="number"
          name="amount.amount"
          label="Suma"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
          source={currencies}
          name="amount.currency"
          label="Mena"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
      <FormPanel label="Prijatá platba vo výške">
        <FormTextField
          type="number"
          name="receivedAmount.amount"
          label="Suma"
          disabled
        />
        <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
          source={currencies}
          name="receivedAmount.currency"
          label="Mena"
          labelMapper={autocompleteLabelMapper}
          disabled
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          disabled={mode !== DetailMode.EDIT}
        />
      </FormPanel>
    </>
  );
}
