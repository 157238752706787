import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { PaymentField } from '@composite/payments/payment-field';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ExamApplication, SubjectAutocomplete } from '@models';
import { EvidenceBrowserUrl, ExamApplicationType, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { examApplicationStates, examApplicationTypes } =
    useContext(StaticDataContext);
  const { retakePartTypes } = useContext(StaticDataContext);

  const stateSource = useStaticListSource(examApplicationStates);
  const typeSource = useStaticListSource(examApplicationTypes);
  const retakePartTypeSource = useStaticListSource(retakePartTypes);
  const subjects = useNaturalPersons();

  const { type, exam, subject } = useFormSelector((data: ExamApplication) => ({
    type: data.type,
    exam: data.exam,
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Žiadosť">
        <FormSelect
          source={typeSource}
          name="type"
          label="Typ žiadosti o skúšku"
          valueIsId
          required
          disabled
        />
        {exam && <FormTextField name="exam.label" label="Skúška" disabled />}
        <FormTextField
          name="year"
          label="Rok konania skúšky"
          type="number"
          required
        />
        <FormTextField
          name="retakeNumber"
          label="Číslo pokusu v poradí"
          type="number"
        />
        <FormSelect
          source={retakePartTypeSource}
          name="retakeParts"
          label="Časti opravnej skúšky"
          multiple
          valueIsId
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox name="retake" label="Náhradný termín" disabled />
        )}
        <FormSelect
          source={stateSource}
          name="state"
          label="Stav"
          valueIsId
          disabled={mode !== DetailMode.NEW}
          required
        />
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="subject"
          label="Fyzická osoba vykonávajúca skúšku"
          labelMapper={autocompleteLabelMapper}
          required={type === ExamApplicationType.RETEST}
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                id={subject.id}
                url={EvidenceBrowserUrl.NATURAL_PERSON}
              />
            )
          }
        />
        {type === ExamApplicationType.RETEST && (
          <FormTextField
            name="contactEmail"
            label="Kontaktný email pre odosielanie notifikácií"
            required
          />
        )}
        <FormCheckbox
          name="gdprConsent"
          label="Súhlas so spracovaním osobných dát"
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
      {type &&
        [ExamApplicationType.CAPABILITY, ExamApplicationType.AUDIT].includes(
          type
        ) && <PersonalInfoFields />}
      <PaymentField />
      <FormPanel label="Poznámka z Webu">
        <FormTextArea name="webNote" labelOptions={{ hide: true }} disabled />
      </FormPanel>
    </>
  );
}

function PersonalInfoFields() {
  return (
    <>
      <FormPanel label="Osobné informácie žiadateľa">
        <FormTextField
          name="personalInfo.titleBefore"
          label="Titul pred menom"
          required
        />
        <FormTextField name="personalInfo.firstName" label="Meno" required />
        <FormTextField
          name="personalInfo.lastName"
          label="Priezvisko"
          required
        />
        <FormTextField name="personalInfo.titleAfter" label="Titul za menom" />
        <FormTextField name="personalInfo.phone" label="Telefón" required />
        <FormTextField name="personalInfo.email" label="Email" required />
        <FormTextField
          name="personalInfo.birthPlace"
          label="Miesto narodenia"
          required
        />
        <FormLocalDateField
          name="personalInfo.birthDate"
          label="Dátum narodenia"
          required
        />
      </FormPanel>
      <FormAddressPanel
        label="Trvalý pobyt"
        name="personalInfo.residence"
        required
      />
      <FormAddressPanel
        label="Prechodný pobyt"
        name="personalInfo.temporaryResidence"
        required
      />
      <FormAddressPanel
        label="Kontaktná adresa"
        name="personalInfo.contactAddress"
        required
      />
    </>
  );
}
