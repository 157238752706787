import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormSelect,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditors } from '@modules/auditor/auditor-api';
import { useEmploymentPositions } from '@modules/dict-employment-position/dict-employment-position-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditorAutocomplete,
  DictEmploymentPositionAutocomplete,
} from '@models';

export function EmploymentInvitationDialog() {
  const { mode } = useContext(DetailContext);
  const { employmentInvitationStates } = useContext(StaticDataContext);

  const auditors = useAuditors();
  const stateSource = useStaticListSource(employmentInvitationStates);
  const positions = useEmploymentPositions();

  return (
    <>
      {mode !== DetailMode.NEW && (
        <FormSelect
          source={stateSource}
          label="Stav"
          name="state"
          valueIsId
          disabled
        />
      )}
      <FormAutocomplete<AuditorAutocomplete>
        source={auditors}
        label="Zamestnanec"
        name="auditor"
        labelMapper={autocompleteLabelMapper}
        required
        disabled={mode !== DetailMode.NEW}
      />
      <FormAutocomplete<DictEmploymentPositionAutocomplete>
        source={positions}
        label="Popis pozície"
        name="positions"
        labelMapper={autocompleteLabelMapper}
        multiline
        multiple
        required
      />
    </>
  );
}
