import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormContext,
  FormPanel,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import {
  useAuditingEntities,
  useAuditors,
  useCompanyAuditors,
} from '@modules/auditor/auditor-api';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { useEducationalTrainings } from '@modules/educational-training/educational-training-api';
import { Redirect } from '@composite/redirect/redirect';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntity,
  AuditingEntityAutocomplete,
  AuditorAutocomplete,
  Education,
  EducationCycleAutocomplete,
  EducationalTrainingAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);
  const { hasPermission } = useContext(UserContext);

  const { owner, auditor, cycle, training } = useFormSelector(
    (data: Education) => ({
      owner: data.owner,
      auditor: data.auditor,
      cycle: data.cycle,
      training: data.training,
    })
  );

  const auditors = useAuditors();
  const companyAuditors = useCompanyAuditors(owner?.id);
  const cycles = useEducationCycles();
  const trainings = useEducationalTrainings();
  const auditingEntities = useAuditingEntities();

  return (
    <>
      <FormPanel label="Obecné">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Audítor/Auditná spoločnosť poskytujúca vzdelávanie"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          notifyChange={(value) => {
            if (
              (value as AuditingEntity)?.type === AuditingEntityType.AUDITOR
            ) {
              setFieldValue('auditor', value);
            } else {
              setFieldValue('auditor', undefined);
            }
          }}
          required
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        <FormAutocomplete<AuditorAutocomplete>
          source={
            owner?.type === AuditingEntityType.AUDITOR
              ? auditors
              : companyAuditors
          }
          name="auditor"
          label="Audítor"
          labelMapper={autocompleteLabelMapper}
          disabled={
            mode !== DetailMode.NEW ||
            owner?.type === AuditingEntityType.AUDITOR
          }
          required
          after={
            auditor?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect url={EvidenceBrowserUrl.AUDITOR} id={auditor.id} />
            )
          }
        />
        <FormAutocomplete<EducationCycleAutocomplete>
          source={cycles}
          name="cycle"
          label="Vzdelávací cyklus"
          labelMapper={autocompleteLabelMapper}
          required
          after={
            cycle?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_EDUCATION_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.EDUCATION_CYCLE}
                id={cycle.id}
              />
            )
          }
        />
        <FormAutocomplete<EducationalTrainingAutocomplete>
          source={trainings}
          name="training"
          label="Školenie"
          labelMapper={autocompleteLabelMapper}
          required
          after={
            training?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_EDUCATION_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.EDUCATIONAL_TRAINING}
                id={training.id}
              />
            )
          }
        />
        <FormTextField type="number" name="year" label="Rok" required />
        <FormTextField
          type="number"
          name="hours"
          label="Uznaný počet hodín"
          required
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
