import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import {
  AuditingEntityAutocomplete,
  Certification,
  DictCountryAutocomplete,
} from '@models';

export function useColumns(): TableColumn<Certification>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<Certification>();

  return [
    {
      datakey: 'number',
      name: 'Číslo certifikátu',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Certifikovaný subjekt',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'issuedDate',
      name: 'Dátum vydania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'origin.country',
      filterkey: 'origin.country.id',
      name: 'Krajina pôvodu',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictCountryAutocomplete>(
          useCountries,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'origin.institution',
      name: 'Vydávajúci orgán',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
