import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { AnonymousSubmission } from '@models';
import {
  AnonymousSubmissionState,
  EvidenceApiUrl,
  ExportTag,
  Permission,
} from '@enums';
import { ActionBar } from './anonymous-submission-actionbar';
import { useColumns } from './anonymous-submission-columns';
import { Fields } from './anonymous-submission-fields';

export function AnonymousSubmissions() {
  const columns = useColumns();
  const permissions = useEvidencePermission([
    Permission.BO_ANONYMOUS_SUBMISSION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<AnonymousSubmission>({
    version: 2,
    identifier: 'ANONYMOUS_SUBMISSION',
    apiProps: {
      url: EvidenceApiUrl.ANONYMOUS_SUBMISSION,
    },
    tableProps: {
      columns,
      tableName: 'Anonymné podnety',
      showBulkActionButton: false,
      reportTag: ExportTag.ANONYMOUS_SUBMISSION,
      createRowStyle: createSubmissionRowStyle(
        AnonymousSubmissionState.PROCESSED
      ),
    },
    detailProps: {
      toolbarProps: {
        title: 'Anonymný podnet',
        showBtn: (btn) => {
          if (btn === 'NEW') {
            return false;
          }
          return permissions(btn);
        },
        ActionBar,
      },
      FieldsComponent: Fields,
    },
  });

  return <Evidence {...evidence} />;
}
