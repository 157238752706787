import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { CalendarEvent } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { ActionBar } from './calendar-event-actionbar';
import { useColumns } from './calendar-event-columns';
import { Fields } from './calendar-event-fields';
import { useValidationSchema } from './calendar-event-schema';

export function CalendarEvents() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_CALENDAR_EVENT_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<CalendarEvent>({
    version: 2,
    identifier: 'CALENDAR_EVENT',
    apiProps: {
      url: EvidenceApiUrl.CALENDAR_EVENT,
    },
    tableProps: {
      columns,
      tableName: 'Udalosti v kalendári',
      showBulkActionButton: false,
      showReportButton: false,
    },
    detailProps: {
      toolbarProps: {
        title: 'Udalost',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
