import React from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
} from '@eas/common-web';
import { useInsuranceBrokerages } from '@modules/dict-insurance-brokerage/dict-insurance-brokerage-api';
import { useInsuranceCompanies } from '@modules/dict-insurance-company/dict-insurance-company-api';
import { useSubjects } from '@modules/subject/subject-api';
import {
  autocompleteLabelMapper,
  insuredSubjectMapper,
} from '@components/form/misc/label-mappers';
import { FilterTextNumberCell } from '@components/table/filter-text-number-cell';
import {
  DictInsuranceBrokerageAutocomplete,
  DictInsuranceCompanyAutocomplete,
  Insurance,
  SubjectAutocomplete,
} from '@models';

export function useColumns(): TableColumn<Insurance>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Insurance>();

  const columns: TableColumn<Insurance>[] = [
    {
      datakey: 'insuranceCompany.label',
      filterkey: 'insuranceCompany.id',
      sortkey: 'insuranceCompany.name',
      name: 'Názov poisťovne',
      width: 250,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictInsuranceCompanyAutocomplete>(
          useInsuranceCompanies,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'insuranceCompany.address.label',
      name: 'Adresa poisťovne',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'insuranceCompany.identifier',
      name: 'IČO poisťovne',
      width: 150,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 3,
    },
    {
      datakey: 'cost.label',
      filterkey: 'cost.amount',
      sortkey: 'cost.label',
      name: 'Výška poistného',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'number',
      name: 'Číslo poistnej zmluvy',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'validFrom',
      name: 'Zmluva platná od',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'validTo',
      name: 'Zmluva platná do',
      width: 150,
      CellComponent: ({ value, ...props }) =>
        value ? (
          <TableCells.DateCell {...props} value={value} />
        ) : (
          <TableCells.TextCell {...props} value="Na dobu neurčitú" />
        ),
      FilterComponent: TableFilterCells.FilterDateCell,
      filterOperation: ApiFilterOperation.AND,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'insurees',
      sortkey: 'insuredSubjects.flat',
      filterkey: 'insuredSubjects.id',
      name: 'Poistený subjekt',
      width: 150,
      valueMapper: insuredSubjectMapper,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useSubjects,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'brokerage',
      name: 'Sprostredkovanie poistenia',
      width: 200,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictInsuranceBrokerageAutocomplete>(
          useInsuranceBrokerages,
          autocompleteLabelMapper,
          true
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 4 })),
  ];

  return columns;
}
