import React from 'react';
import QueueIcon from '@material-ui/icons/Queue';
import { TableActionButton } from '@eas/common-web';
import { useCreatePaymentBulkDialog } from './dialog-actions/create-payment-bulk/create-payment-bulk-hook';

export function Toolbar() {
  const { showCreateBulkBtn, ...bulkCreateProps } =
    useCreatePaymentBulkDialog();

  return (
    <>
      {showCreateBulkBtn && (
        <TableActionButton
          promptKey="CREATE_PAYMENTS_BULK"
          buttonLabel={<QueueIcon />}
          dialogTitle="Hromadné generovanie platieb"
          dialogText={null}
          dialogWidth={1000}
          formInitialValues={{
            sendEmail: true,
            payments: [],
          }}
          buttonTooltip="Generovať platby hromadne"
          {...bulkCreateProps}
        />
      )}
    </>
  );
}
