import { abortableFetch } from '@eas/common-web';
import { Stakeholder } from '@models';
import { EvidenceApiUrl } from '@enums';

export const addStakeholderApiCall = (id: string, formData: Stakeholder) =>
  abortableFetch(`${EvidenceApiUrl.AUDIT_COMPANY}/${id}/stakeholders`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const editStakeholderApiCall = (id: string, formData: Stakeholder) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/stakeholders/${formData.id}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(formData),
    }
  );

export const deleteStakeholderApiCall = (id: string, insureeId: string) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/stakeholders/${insureeId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  );
