import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { Payment } from '@models';
import { EvidenceApiUrl, PaymentState, Permission } from '@enums';

export function useCancelDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<Payment>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT}/${id}/cancel`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const showBtn =
    hasPermission(Permission.BO_PAYMENT_MANAGE) &&
    source?.data?.state === PaymentState.NEW;

  return {
    apiCall,
    showCancelBtn: showBtn,
  };
}
