import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { Charge } from '@models';

export function useColumns(): TableColumn<Charge>[] {
  const {
    paymentEconomicClassifications,
    paymentCategories,
    paymentMainSpecifications,
    paymentAdditionalSpecifications,
  } = useContext(StaticDataContext);
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Charge>();

  const useClassifications = () =>
    useStaticListSource(paymentEconomicClassifications);
  const useCategories = () => useStaticListSource(paymentCategories);
  const useMainSpecifications = () =>
    useStaticListSource(paymentMainSpecifications);
  const useAdditionalSpecifications = () =>
    useStaticListSource(paymentAdditionalSpecifications);

  return [
    {
      datakey: 'name',
      name: 'Názov',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'classification',
      sortkey: 'classification.name',
      filterkey: 'classification.id',
      name: 'Ekonomická klasifikácia',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useClassifications),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useClassifications),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'category',
      sortkey: 'category.name',
      filterkey: 'category.id',
      name: 'Kategória podľa §68 ods. 1 zákona',
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useCategories),
      FilterComponent:
        TableFilterCells.useFilterSelectCellFactory(useCategories),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'mainSpecification',
      sortkey: 'mainSpecification.name',
      filterkey: 'mainSpecification.id',
      name: 'Hlavná špecifikácia',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useMainSpecifications),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useMainSpecifications
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'additionalSpecification',
      sortkey: 'additionalSpecification.name',
      filterkey: 'additionalSpecification.id',
      name: 'Pomocná špecifikácia',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useAdditionalSpecifications),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useAdditionalSpecifications
      ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
