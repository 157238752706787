import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { PaymentField } from '@composite/payments/payment-field';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { IdentifierField } from '@components/form/identifier-field/form-identifier-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditRegistration,
  AuditingEntityAutocomplete,
  UserAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';
import { useAuditRegistrationCompanySchema } from './audit-registration-schema';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { auditRegistrationStates, auditRegistrationCompanyTypes } =
    useContext(StaticDataContext);

  const backofficeUsers = useBackofficeUsers();
  const auditingEntities = useAuditingEntities();
  const stateSource = useStaticListSource(auditRegistrationStates);
  const useCompanyTypes = () =>
    useStaticListSource(auditRegistrationCompanyTypes);

  const companySchema = useAuditRegistrationCompanySchema();

  const { owner } = useFormSelector((data: AuditRegistration) => ({
    owner: data.owner,
  }));

  return (
    <>
      <FormPanel label="Registrácia">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Vlastník oznámenia"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        <FormTextField
          type="number"
          name="year"
          label="Registrácia na rok"
          required
        />
        <FormSelect
          source={stateSource}
          name="state"
          label="Stav podania"
          valueIsId
          required
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
      <FormPanel label="Predpokladané subjekty auditu">
        <FormTableField
          name="companies"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          FormFieldsComponent={CompanyDialog}
          validationSchema={companySchema}
          columns={[
            {
              datakey: 'name',
              name: 'Názov subjektu',
              width: 150,
            },
            {
              datakey: 'identifier',
              name: 'IČO subjektu',
              width: 150,
            },
            {
              datakey: 'type',
              name: 'Typ subjektu',
              width: 150,
              CellComponent:
                TableFieldCells.useSelectCellFactory(useCompanyTypes),
            },
            {
              datakey: 'individual',
              name: 'Individuálna účtovná závierka',
              width: 150,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              datakey: 'consolidated',
              name: 'Konsolidovaná účtovná závierka',
              width: 150,
              CellComponent: TableFieldCells.BooleanCell,
            },
            {
              datakey: 'certifiedDate',
              name: 'Overovaná účtovná závierka k',
              width: 150,
              CellComponent: TableFieldCells.DateCell,
            },
          ]}
        />
      </FormPanel>
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <PaymentField />
      <FormPanel label="Poznámka z portálu">
        <FormTextArea
          name="portalNote"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
    </>
  );
}

function CompanyDialog() {
  const { auditRegistrationCompanyTypes } = useContext(StaticDataContext);
  const typeSource = useStaticListSource(auditRegistrationCompanyTypes);
  const { getFieldValues, setFieldValues } = useContext(FormContext);

  return (
    <>
      <FormPanel label="Subjekt">
        <FormTextField name="name" label="Názov subjektu" required />
        <IdentifierField
          notifyChange={(values) => {
            const currentValues = getFieldValues();
            setFieldValues({ ...currentValues, ...values });
          }}
        />
        <FormSelect
          source={typeSource}
          name="type"
          label="Typ subjektu"
          valueIsId
          required
        />
        <FormCheckbox name="individual" label="Individuálna účtovná závierka" />
        <FormCheckbox
          name="consolidated"
          label="Konsolidovaná účtovná závierka"
        />
        <FormLocalDateField
          name="certifiedDate"
          label="Overovaná účtovná závierka k"
          required
        />
      </FormPanel>
      <FormAddressPanel label="Sídlo subjektu" required />
    </>
  );
}
