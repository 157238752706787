import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useContext } from 'react';
import {
  AutocompleteSource,
  DetailContext,
  DetailMode,
  useEventCallback,
} from '@eas/common-web';
import { SearchByIcoResponseDto } from '@models';
import { EvidenceApiUrl } from '@enums';

interface RPOSyncProps {
  source: AutocompleteSource<SearchByIcoResponseDto>;
  setSource: Dispatch<
    SetStateAction<AutocompleteSource<SearchByIcoResponseDto>>
  >;
  setPopupOpen: Dispatch<SetStateAction<boolean>>;
}

export function useRPOSync({ source, setSource, setPopupOpen }: RPOSyncProps) {
  const { mode } = useContext(DetailContext);

  const loadRPOValues = useEventCallback(
    debounce(async (value: string, valueChanged?: boolean) => {
      if (
        value?.length === 8 &&
        mode !== DetailMode.VIEW &&
        (valueChanged || source?.items?.length === 0)
      ) {
        const response = await fetch(
          `${EvidenceApiUrl.INTEGRATION}/rpo/${value}`,
          { method: 'POST' }
        );

        const data = await response.json();

        if (response.ok) {
          setSource((prev) => ({ ...prev, items: [data] }));
        } else {
          setSource((prev) => ({ ...prev, items: [] }));
        }

        setPopupOpen(response.ok);
      } else if (value?.length === 8 && mode !== DetailMode.VIEW) {
        setPopupOpen(true);
      } else {
        setPopupOpen(false);
      }
    })
  );

  return { loadRPOValues };
}
