import React, { useContext } from 'react';
import {
  AuthoredEvidence,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditReport,
  AuditingEntityAutocomplete,
  UserAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function GeneralFields() {
  const { setFieldValue } = useContext(FormContext);
  const { auditReportStates } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const backofficeUsers = useBackofficeUsers();
  const auditingEntities = useAuditingEntities();
  const stateSource = useStaticListSource(auditReportStates);

  const { owner } = useFormSelector((data: AuditReport) => ({
    owner: data.owner,
  }));

  return (
    <>
      <FormPanel label="Oznámenie">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Vlastník oznámenia"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          notifyChange={() => setFieldValue('financialStatements', undefined)}
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        {mode === DetailMode.VIEW && (
          <FormSelect
            source={stateSource}
            name="state"
            label="Stav"
            valueIsId
            disabled
          />
        )}
        <FormLocalDateField
          name="auditingEntityAccountingEndDate"
          label="Koniec účtovného obdobia vykonávateľa auditu"
          disabled
        />
        {mode === DetailMode.VIEW && (
          <FormLocalDateField
            name="processedDate"
            label="Dátum spracovania"
            disabled
          />
        )}
      </FormPanel>
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <FormPanel label="Obecné">
        <AuthoredEvidence.AuthoredFields />
      </FormPanel>
    </>
  );
}
