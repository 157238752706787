import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { AuditReport, AuditingEntityAutocomplete } from '@models';

export function useColumns(): TableColumn<AuditReport>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<AuditReport>();

  const { auditReportStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(auditReportStates);

  return [
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Vlastník oznámenia',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'overallAuditProfits.total',
      name: 'Celkové tržby',
      width: 250,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'auditingEntityAccountingEndDate',
      name: 'Koniec účtovného obdobia',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'processedDate',
      name: 'Dátum spracovania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
