import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import {
  AuditingEntity,
  DictMonetaryCurrency,
  InsuranceCost,
  InsuranceSubmission,
} from '@models';

export function useValidationSchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<InsuranceSubmission>().shape({
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    brokerage: Yup.string().nullable().required(),
    number: Yup.string().nullable().required(),
    contractConcludedDate: Yup.string().nullable().required(),
    validFrom: Yup.string().nullable().required(),
    validTo: Yup.string()
      .nullable()
      .when('indefinitePeriod', {
        is: (indefinitePeriod) => indefinitePeriod !== true,
        then: Yup.string()
          .nullable()
          .required(
            'Musí byť vyplnený dátum konca platnosti alebo vybraný koniec na dobu neurčitú'
          ),
      }),
    cost: Yup.object<InsuranceCost>().shape({
      amount: Yup.number()
        .nullable()
        .min(1, 'Musí byť kladné číslo')
        .required(),
      currency: Yup.mixed<DictMonetaryCurrency>().nullable().required(),
    }),
    insuranceCompany: Yup.object<InsuranceCost>().shape({
      name: Yup.string().nullable().required(),
      identifier: Yup.string().nullable().required(),
      address: validateAddress,
    }),
  });
}
