import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, EmptyComponent, Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Auditor } from '@models';
import {
  AuditingEntityType,
  EvidenceApiUrl,
  ExportTag,
  Permission,
} from '@enums';
import { ActionBar } from './auditor-actionbar';
import { useColumns } from './auditor-columns';
import { Fields } from './auditor-fields';
import { useAuditorHookedSources } from './auditor-hooked-sources';
import { useValidationSchema } from './auditor-schema';

export function Auditors() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_AUDITING_ENTITY_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<Auditor>({
    version: 4,
    identifier: 'AUDITOR',
    apiProps: {
      url: EvidenceApiUrl.AUDITING_ENTITY,
      hookedSources: useAuditorHookedSources,
    },
    tableProps: {
      columns,
      tableName: 'Audítori',
      showBulkActionButton: false,
      reportTag: ExportTag.AUDITOR,
      defaultPreFilters: [
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: AuditingEntityType.AUDITOR,
        },
      ],
    },
    detailProps: {
      toolbarProps: {
        title: 'Audítor',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), type: AuditingEntityType.AUDITOR }),
    },
  });

  return <Evidence {...evidence} />;
}
