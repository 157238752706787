import { makeStyles } from '@material-ui/core/styles';

export const useStyles = (
  backgroundColor: string,
  boxShadowBlurRadius: number
) =>
  makeStyles((theme) => ({
    tabsWrapper: {
      position: 'sticky',
      top: -10,
      zIndex: 97,
      marginTop: -10,
      marginBottom: 20,
      width: '100%',
      backgroundColor: '#f1f3f4',
    },
    tabs: {
      marginTop: 10,
      backgroundColor: backgroundColor,
      boxShadow: `0px 0px ${boxShadowBlurRadius}px #ddd`,
      display: 'flex',

      '& .Mui-selected': {
        fontWeight: 600,
        background: `${theme.palette.primary.main}40`,
      },

      '& .Mui-disabled': {
        opacity: 0.2,
      },
    },
    tab: {
      fontSize: '12px',

      '&:hover': {
        background: `${theme.palette.primary.main}40`,
      },
    },
    errorCount: {
      marginLeft: 8,
      background: theme.palette.secondary.main,
      color: 'white',
      borderRadius: '50%',
      width: 21,
      height: 21,
      fontWeight: 500,
    },
    indicator: {
      display: 'none',
    },
    tabsRoot: {
      minHeight: 33,
      height: 33,
    },
    tabRoot: {
      minHeight: 33,
      height: 33,
      fontSize: 12,
      minWidth: 64,

      '&:not(:last-of-type)': {
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
    tabLabel: {
      display: 'flex',
      flexDirection: 'row',
    },
    scrollButtons: {
      width: 30,
    },
  }));
