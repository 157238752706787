/**
 * Stav oznámenie o prerušení/zahájení auditorskej činnosti
 */
export enum AuditNoticeState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Čakajúca na platbu
   */
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',

  /**
   * Nepresná platba
   */
  INCORRECT_PAYMENT_AMOUNT = 'INCORRECT_PAYMENT_AMOUNT',

  /**
   * Zaplatená
   */
  PAID = 'PAID',

  /**
   * Spracované oznámenie
   */
  PROCESSED = 'PROCESSED',
}

/**
 * Typ oznámenie o prerušení/zahájení auditorskej činnosti
 */
export enum AuditNoticeType {
  /**
   * Oznámenie o prerušení činnosti
   */
  SUSPENSION = 'SUSPENSION',

  /**
   * Oznámenie o zahájení činnosti
   */
  INITIATION = 'INITIATION',
}
