import { abortableFetch } from '@eas/common-web';
import { DisciplinaryRecord } from '@models';
import { EvidenceApiUrl } from '@enums';

export const addDisciplinaryRecordApiCall = (
  id: string,
  formData: DisciplinaryRecord
) =>
  abortableFetch(`${EvidenceApiUrl.AUDITING_ENTITY}/${id}/disciplinary/add`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const editDisciplinaryRecordApiCall = (
  id: string,
  formData: DisciplinaryRecord
) => {
  console.log({ formData });
  return abortableFetch(
    `${EvidenceApiUrl.AUDITING_ENTITY}/${id}/disciplinary/${formData.id}/update`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(formData),
    }
  );
};
export const deleteDisciplinaryRecordApiCall = (
  id: string,
  disciplinaryId: string
) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDITING_ENTITY}/${id}/disciplinary/${disciplinaryId}/delete`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  );
