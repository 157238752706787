import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Certification } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { useColumns } from './certification-columns';
import { Fields } from './certification-fields';
import { useValidationSchema } from './certification-schema';
import { Toolbar } from './certification-toolbar';

export function Certifications() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([
    Permission.BO_CERTIFICATION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<Certification>({
    version: 3,
    identifier: 'CERTIFICATION',
    apiProps: {
      url: EvidenceApiUrl.CERTIFICATION,
    },
    tableProps: {
      columns,
      tableName: 'Certifikáty',
      showBulkActionButton: false,
      reportTag: ExportTag.CERTIFICATION,
    },
    detailProps: {
      toolbarProps: {
        title: 'Certifikát',
        showBtn: (btn) => {
          if (['REMOVE', 'NEW'].includes(btn)) {
            return false;
          }

          return permissions(btn);
        },
        Before: Toolbar,
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
