import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { useEducationForms } from '@modules/dict-education-form/dict-education-form-api';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  DictEducationFieldAutocomplete,
  DictEducationFormAutocomplete,
  EducationCycleAutocomplete,
  EducationSubmission,
} from '@models';

export function useColumns(): TableColumn<EducationSubmission>[] {
  const { educationSubmissionStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(educationSubmissionStates);

  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<EducationSubmission>();

  return [
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Vlastník podnetu',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav spracovania',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'trainingName',
      name: 'Názov školenia',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'form.label',
      sortkey: 'form.name',
      filterkey: 'form.id',
      name: 'Forma vzdelania',
      width: 250,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictEducationFormAutocomplete>(
          useEducationForms,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'field.label',
      sortkey: 'field.name',
      filterkey: 'field.id',
      name: 'Oblasť vzdelania',
      width: 250,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<DictEducationFieldAutocomplete>(
          useEducationFields,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'cycle.label',
      sortkey: 'cycle.name',
      filterkey: 'cycle.id',
      name: 'Vzdelávací cyklus',
      width: 250,
      CellComponent: TableCells.TextCell,
      filterOperation: ApiFilterOperation.EQ,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<EducationCycleAutocomplete>(
          useEducationCycles,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'date',
      name: 'Dátum vzdelávania',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
