import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import {
  AuditExamApplication,
  ExamApplication,
  Payment,
  RetestExamApplication,
} from '@models';
import { ExamApplicationType, PaymentState } from '@enums';
import { useAddPaymentDialog } from './dialog-actions/add-payment-hook';
import { useApproveDialog } from './dialog-actions/approve-hook';
import { useCloseDialog } from './dialog-actions/close-hook';
import { useConfirmPaymentDialog } from './dialog-actions/confirm-payment-hook';
import { useRegisterDialog } from './dialog-actions/register-hook';

export function ActionBar() {
  const { source } = useContext<DetailHandle<ExamApplication>>(DetailContext);

  const { showCloseBtn, ...closeProps } = useCloseDialog();
  const { showApproveBtn, ...approveProps } = useApproveDialog();
  const { showRegisterBtn, ...registerProps } = useRegisterDialog();
  const { showAddPaymentBtn, ...addPaymentProps } = useAddPaymentDialog();
  const { showConfirmPaymentBtn, ...confirmPaymentProps } =
    useConfirmPaymentDialog();

  return (
    <>
      {showConfirmPaymentBtn && (
        <DetailActionButton
          promptKey="CONFIRM_PAYMENT"
          buttonLabel="Potvrdiť platbu"
          dialogTitle="Potvrdenie platby"
          dialogText="Skutočne chcete potvrdiť platbu pre vybranú žiadosť?"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...confirmPaymentProps}
        />
      )}
      {showApproveBtn && (
        <DetailActionButton
          promptKey="APPROVE"
          buttonLabel="Schváliť"
          dialogTitle="Schválenie žiadosti"
          dialogText={
            source?.data?.type !== ExamApplicationType.RETEST
              ? 'Záznam fyzickej osoby už môže existovať, pokúste sa ho nájsť. Ak fyzická osoba neexistuje, môžete ju vytvoriť kliknutím na tlačidlo Pridať.'
              : 'Skutočne chcete schváliť žiadosť o skúšku?'
          }
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={
            source?.data?.type !== ExamApplicationType.RETEST ? 900 : 500
          }
          dialogDisableBackdrop
          {...approveProps}
        />
      )}
      {showRegisterBtn && (
        <DetailActionButton
          promptKey="REGISTER"
          buttonLabel="Prihlásiť na skúšku"
          dialogTitle="Prihlásenie na skúšku"
          dialogText="Skutočne chcete prihlásiť používateľa na skúšku?"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...registerProps}
        />
      )}
      {showAddPaymentBtn && (
        <DetailActionButton
          promptKey="ADD_PAYMENT"
          buttonLabel="Generovať platbu"
          dialogTitle="Generovanie platby"
          dialogText={null}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          formInitialValues={
            {
              state: PaymentState.NEW,
              subject: source.data?.subject,
              year: new Date().getFullYear(),
              email:
                source.data?.type === ExamApplicationType.RETEST
                  ? (source?.data as RetestExamApplication).contactEmail
                  : (source.data as AuditExamApplication).personalInfo?.email,
            } as Payment
          }
          dialogDisableBackdrop
          {...addPaymentProps}
        />
      )}
      {showCloseBtn && (
        <DetailActionButton
          promptKey="CLOSE"
          buttonLabel="Uzavrieť"
          dialogTitle="Uzavretie žiadosti"
          dialogText="Skutočne chcete uzavreť žiadosť?"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...closeProps}
        />
      )}
    </>
  );
}
