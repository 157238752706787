import { useContext, useEffect, useRef, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  AbortableFetch,
  FormHandle,
  NavigationContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useFormError } from '@modules/login/form-error-hook';
import { usePasswordValidation } from '@modules/profile/profile-utils';
import { PasswordResetConfirmData } from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl } from '@enums';

interface RouteParams {
  secret: string;
}

export function usePasswordResetConfirm() {
  const { navigate } = useContext(NavigationContext);
  const { setErrors, getFieldError } = useFormError();
  const { secret } = useParams<RouteParams>();
  const validatePassword = usePasswordValidation();

  const [loading, setLoading] = useState<boolean>(false);
  const [secretIsValid, setSecretIsValid] = useState<boolean | undefined>(
    undefined
  );

  const ref = useRef<FormHandle<PasswordResetConfirmData>>(null);
  const fetch = useRef<AbortableFetch | null>(null);

  const validationSchema = Yup.object<PasswordResetConfirmData>().shape({
    newPassword: validatePassword,
    newPasswordAgain: Yup.string()
      .nullable()
      .required('Musí byť vyplnené')
      .oneOf(
        [Yup.ref('newPassword')],
        'Zopakované heslo se neshoduje s heslem'
      ),
  });

  const handleNavigateBack = useEventCallback(() => {
    navigate('/');
  });

  const handleNavigateToReset = useEventCallback(() => {
    navigate('/obnova-hesla');
  });

  const callSubmit = (data: PasswordResetConfirmData) => {
    return abortableFetch(`${EvidenceApiUrl.PASSWORD_RESET}/confirm`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        newRawPassword: data.newPassword,
        secret,
      }),
    });
  };

  const submitPasswordResetConfirm = useEventCallback(
    async (data: PasswordResetConfirmData) => {
      try {
        setLoading(true);

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = callSubmit(data);

        await fetch.current.raw();

        unstable_batchedUpdates(() => {
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);

        return undefined;
      }
    }
  );

  const handleSubmit = useEventCallback(
    async (data: PasswordResetConfirmData) => {
      if (ref.current != undefined) {
        const errors = await ref.current.validateForm();

        setErrors(errors);

        if (errors.length) {
          return;
        }

        await submitPasswordResetConfirm(data);

        navigate(`${EvidenceBrowserUrl.PASSWORD_RESET}/heslo-obnoveno`);
      }
    }
  );

  const sendSecretIsValid = () => {
    return abortableFetch(`${EvidenceApiUrl.PASSWORD_RESET}/validate-secret`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({ secret }),
    });
  };

  const getSecretIsValid = async () => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = sendSecretIsValid();

      await fetch.current.raw();

      unstable_batchedUpdates(() => {
        setSecretIsValid(true);
      });
    } catch (err) {
      setSecretIsValid(false);
      return undefined;
    }
  };

  useEffect(() => {
    getSecretIsValid?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ref,
    validationSchema,
    loading,
    handleNavigateBack,
    handleNavigateToReset,
    handleSubmit,
    getFieldError,
    secretIsValid,
  };
}
