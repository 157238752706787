import * as Yup from 'yup';
import { FileRef } from '@eas/common-web';
import { Document } from '@models';

export function useValidationSchema() {
  return Yup.object<Document>().shape({
    name: Yup.string().nullable().required(),
    file: Yup.mixed<FileRef>().nullable().required(),
  });
}
