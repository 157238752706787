import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { CalendarEvent } from '@models';

export function useColumns(): TableColumn<CalendarEvent>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<CalendarEvent>();

  const { calendarEventTypes } = useContext(StaticDataContext);
  const useTypes = () => useStaticListSource(calendarEventTypes);

  return [
    {
      datakey: 'name',
      name: 'Názov udalosti',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ udalosti',
      width: 250,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'date',
      name: 'Dátum udalosti',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'reminderDate',
      name: 'Dátum pripomienky',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
