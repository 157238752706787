import React from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Form, FormSubmitButton, formFieldFactory } from '@eas/common-web';
import { formTextFieldFactory } from '@components/form/simple-fields/simple-text-field';
import { PasswordResetData } from '@models';
import { useStyles as useLoginStyles } from '../login/login';
import { usePasswordReset } from './password-reset-hook';

export function PasswordReset() {
  const classes = useLoginStyles();

  const {
    validationSchema,
    ref,
    loading,
    handleSubmit,
    getFieldError,
    handleNavigateBack,
  } = usePasswordReset();

  const PasswordResetFields = {
    EmailField: formFieldFactory(
      formTextFieldFactory({ label: 'E-mail' }),
      ({ children }) => <>{children}</>
    ),
  };

  return (
    <>
      <div className={classes.menubar} />
      <Form<PasswordResetData>
        onSubmit={handleSubmit}
        editing={true}
        ref={ref}
        validationSchema={validationSchema}
        initialValues={{ email: '' }}
      >
        <Container maxWidth="xs" className={classes.paper}>
          <Typography className={classes.title} variant="h5">
            Obnova hesla
          </Typography>
          <Typography className={classes.text} align="center">
            Zadajte prihlasovací e-mail.
          </Typography>
          <PasswordResetFields.EmailField
            name="email"
            error={!!getFieldError('email')}
            helperText={getFieldError('email')}
          />
          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="default"
              disableElevation
              onClick={handleNavigateBack}
              className={classes.button}
            >
              <Typography
                component="span"
                variant="body1"
                className={classes.buttonLabel}
              >
                Späť na prihlásenie
              </Typography>
            </Button>
            <FormSubmitButton
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className={classes.button}
              disabled={loading}
            >
              <Typography
                component="span"
                variant="body1"
                className={classes.buttonLabel}
              >
                Odoslať
              </Typography>
            </FormSubmitButton>
          </div>
        </Container>
      </Form>
    </>
  );
}
