import React, { useContext } from 'react';
import {
  DictionaryEvidence,
  FormSelect,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';

export function Fields() {
  const { countryTags } = useContext(StaticDataContext);
  const countryTagSource = useStaticListSource(countryTags);

  return (
    <>
      <FormTextField name="alpha2" label="Kód krajiny Alpha-2" required />
      <FormTextField name="alpha3" label="Kód krajiny Alpha-3" required />
      <FormSelect source={countryTagSource} name="tag" label="Tag" valueIsId />
      <DictionaryEvidence.DictionaryFields />
    </>
  );
}
