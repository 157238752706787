import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { Insuree } from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function useColumns(): TableFieldColumn<Insuree>[] {
  const { hasPermission } = useContext(UserContext);

  const columns: TableFieldColumn<Insuree>[] = [
    {
      datakey: 'insuredSubject.label',
      name: 'Subjekt',
      width: 250,
    },
    {
      datakey: 'insuredFrom',
      name: 'Poistený od',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'insuredTo',
      name: 'Poistený do',
      width: 150,
      CellComponent: TableFieldCells.DateCell,
    },
    {
      datakey: 'indefinitePeriod',
      name: 'Poistený na dobu neurčitú',
      width: 150,
      CellComponent: TableFieldCells.BooleanCell,
    },
    {
      datakey: 'note',
      name: 'Poznámka',
      width: 250,
    },
  ];

  return hasPermission(Permission.BO_AUDITING_ENTITY_READ)
    ? [
        {
          datakey: 'insuredSubject.type',
          name: '',
          width: 70,
          CellComponent: function Cell({ value, rowValue }) {
            return (
              <Redirect
                id={rowValue?.insuredSubject!.id}
                url={
                  value === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
              />
            );
          },
        },
        ...columns,
      ]
    : columns;
}
