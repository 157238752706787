import React from 'react';
import { DictionaryEvidence, FormPanel, FormTextArea } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel label="Obecné">
        <DictionaryEvidence.DictionaryFields />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
