import {
  DictionaryObject,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { UserAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useAccountTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.USER}/account-types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useUsers() {
  return useAutocompleteSource<UserAutocomplete>({
    url: `${EvidenceApiUrl.USER}/autocomplete`,
  });
}

export function useBackofficeUsers() {
  return useAutocompleteSource<UserAutocomplete>({
    url: `${EvidenceApiUrl.USER}/autocomplete/backoffice`,
  });
}
