import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { Charge } from '@models';
import { EvidenceApiUrl, ExportTag, Permission } from '@enums';
import { ActionBar } from './charge-actionbar';
import { useColumns } from './charge-columns';
import { Fields } from './charge-fields';
import { useValidationSchema } from './charge-schema';

export function Charges() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_CHARGE_MANAGE]);

  const evidence = useAuthoredEvidence<Charge>({
    version: 2,
    identifier: 'CHARGE',
    apiProps: {
      url: EvidenceApiUrl.CHARGE,
    },
    tableProps: {
      columns,
      tableName: 'Poplatky',
      showBulkActionButton: false,
      reportTag: ExportTag.CHARGE,
    },
    detailProps: {
      toolbarProps: {
        title: 'Poplatok',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
