import * as Yup from 'yup';
import { Auditor, Subject } from '@models';

export function useValidationSchema() {
  return Yup.object<Auditor>().shape({
    subject: Yup.mixed<Subject>().nullable().required(),
    examPassedDate: Yup.string().nullable().required(),
    recordDate: Yup.string().nullable().required(),
  });
}
