/**
 * Tag krajiny
 */
export enum CountryTag {
  /**
   * Domovská krajina
   */
  HOME_COUNTRY = 'HOME_COUNTRY',

  /**
   * Cudzí členský štát Európskej Únie
   */
  EUROPEAN_UNION_COUNTRY = 'EUROPEAN_UNION_COUNTRY',
}
