import React from 'react';
import { InternalAddress } from '@composite/internal-address/internal-address';
import { OfficeField } from '@composite/offices/office-field';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';

interface Props {
  prefix?: string;
}

export function AddressFields({ prefix }: Props) {
  const path = prefix ? `${prefix}.` : '';

  return (
    <>
      <FormAddressPanel
        name={`${path}subject.address`}
        label="Sídlo"
        disabled
      />
      <InternalAddress label="Adresa komory audítorov" code="ADDRESS_CHAMBER" />
      <InternalAddress label="Adresa úradu" code="ADDRESS_OFFICE" />
      <OfficeField name={`${path}offices`} />
    </>
  );
}
