import { isAfter } from 'date-fns';
import { groupBy, stubFalse } from 'lodash';
import React, { useContext, useRef, useState } from 'react';
import * as Yup from 'yup';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {
  ActionButton,
  ActionButtonHandle,
  DetailToolbarButton2,
  DetailToolbarButtonType,
  EvidenceContext,
  FilesContext,
  FormFileField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { ParsedPaymentResultDto, ParsedPaymentsDto } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { ParsedPaymentResultFields } from './parse-xml-result-fields';

export function useParseXmlDialog() {
  const filesCtx = useContext(FilesContext);
  const { hasPermission } = useContext(UserContext);
  const { parsedPaymentResults } = useContext(StaticDataContext);

  const [result, setResult] = useState<ParsedPaymentsDto>();
  const resultRef = useRef<ActionButtonHandle>(null);

  const parseXmlApiCall = useEventCallback((formData) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT_PARSE}/${formData?.file?.id}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const updatePaymentsApiCall = useEventCallback((formData) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT_PARSE}/confirm`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify({
        parsingResponse: result,
        payments: formData?.payments
          .filter((p: ParsedPaymentResultDto) => !!p.selectedPayment?.id)
          .map(
            ({
              selectedPayment,
              rowIdentifier,
              parsedPayment,
            }: ParsedPaymentResultDto) => ({
              id: selectedPayment?.id,
              rowIdentifier: rowIdentifier,
              dto: { ...parsedPayment },
            })
          ),
      }),
    })
  );

  const showBtn = hasPermission(Permission.BO_PAYMENT_MANAGE);

  const FormFields = () => (
    <FilesContext.Provider value={filesCtx}>
      <FormFileField name="file" label="Súbor (xml)" accept={['xml']} />
    </FilesContext.Provider>
  );

  const validationSchema = Yup.object().shape({
    payments: Yup.array().test('', '', function (value) {
      const { createError, path } = this;
      const payments = value as ParsedPaymentResultDto[];

      const groupedPayments = groupBy(
        payments.filter((p) => !!p.selectedPayment),
        'selectedPayment.id'
      );

      if (Object.values(groupedPayments).some((p) => p?.length > 1)) {
        return createError({
          path,
          message:
            'K viacerým platbám bola vybraná rovnaká platba na spárovanie',
        });
      }

      if (
        payments?.some(
          (p) => p.selectedPayment?.id && !p.parsedPayment?.receivedDate
        )
      ) {
        return createError({
          path,
          message:
            'Dátum prijatia musí byť vyplnený pri platbách, ku ktorým bola vybraná platba na spárovanie',
        });
      }

      if (
        payments?.some(
          (p) =>
            p.selectedPayment?.id &&
            !!p.parsedPayment?.receivedDate &&
            isAfter(new Date(p.parsedPayment?.receivedDate), new Date())
        )
      ) {
        return createError({
          path,
          message: 'Dátum prijatia nesmie byť v budúcnosti',
        });
      }

      return true;
    }),
  });

  const ParseXmlButton = () => {
    const { tableSource } = useContext(EvidenceContext);

    return (
      <>
        <ActionButton
          promptKey="PARSE_XML"
          buttonLabel="Nový"
          dialogTitle="Nahranie platieb zo súboru"
          dialogText={null}
          buttonTooltip="Nahrať platby z xml súboru"
          FormFields={FormFields}
          apiCall={parseXmlApiCall}
          onResult={async (result: any) => {
            setResult(result);

            requestAnimationFrame(() => {
              resultRef.current?.executeAction();
            });
          }}
          ButtonComponent={(props) => (
            <DetailToolbarButton2
              {...props}
              type={DetailToolbarButtonType.PRIMARY}
              startIcon={<AddBoxOutlinedIcon />}
            />
          )}
          dialogWidth={800}
        />
        <ActionButton
          ref={resultRef}
          promptKey="PROCESS_PAYMENTS"
          buttonLabel={null}
          dialogTitle="Platby"
          dialogText="V tabuľke sú zobrazené platby načítané z nahraného xml súboru. Pre spárovanie platieb vyberte požadovanú platbu v stĺpci Platba na spárovanie a kliknite na tlačidlo Potvrdiť."
          FormFields={() => (
            <ParsedPaymentResultFields
              parsedPaymentResults={parsedPaymentResults}
              showSelectedPayments
            />
          )}
          formInitialValues={{
            payments: result?.payments?.map((p: ParsedPaymentResultDto) => ({
              ...p,
              parsedPayment: {
                ...p.parsedPayment,
                editableReceivedDate: !p.parsedPayment?.receivedDate,
              },
              selectedPayment: p?.matchedPayments?.find(
                (payment) => payment.vs === p.parsedPayment?.vs
              )
                ? {
                    ...p?.matchedPayments?.find(
                      (payment) => payment.vs === p.parsedPayment?.vs
                    ),
                    label: p?.matchedPayments?.find(
                      (payment) => payment.vs === p.parsedPayment?.vs
                    )?.extendedLabel,
                  }
                : undefined,
            })),
          }}
          apiCall={updatePaymentsApiCall}
          onShouldShow={stubFalse}
          dialogWidth={1200}
          formValidationSchema={validationSchema}
          onSuccess={async () => {
            tableSource?.reset();
            tableSource?.loadMore();
          }}
        />
      </>
    );
  };

  return {
    showParseXmlBtn: showBtn,
    ParseXmlButton,
  };
}
