import React from 'react';
import { FormPanel, FormTextArea } from '@eas/common-web';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';

export function OfficeDialog() {
  return (
    <>
      <FormAddressPanel name="address" label="Adresa" required />
      <FormPanel label="Popis">
        <FormTextArea name="position" labelOptions={{ hide: true }} />
      </FormPanel>
    </>
  );
}
