import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import { ExamApplication, PersonalInfo, Subject } from '@models';
import { ExamApplicationState, ExamApplicationType } from '@enums';

export function useValidationSchema() {
  const addressValidation = useAddressValidationSchema();

  return Yup.object<ExamApplication>().shape({
    type: Yup.mixed<ExamApplicationType>().nullable().required(),
    state: Yup.mixed<ExamApplicationState>().nullable().required(),
    year: Yup.number()
      .nullable()
      .min(1990, 'Musí ležať v rozsahu 1990 až 2100')
      .max(2100, 'Musí ležať v rozsahu 1990 až 2100')
      .required(),
    subject: Yup.mixed<Subject>().when('type', {
      is: ExamApplicationType.RETEST,
      then: Yup.mixed<Subject>().nullable().required(),
    }),
    retakeNumber: Yup.number()
      .nullable()
      .min(0, 'Musí ležať v rozsahu 0 až 3')
      .max(3, 'Musí ležať v rozsahu 0 až 3'),
    contactEmail: Yup.mixed<Subject>().when('type', {
      is: ExamApplicationType.RETEST,
      then: Yup.string()
        .nullable()
        .email('Musí byť v správnom formáte')
        .required(),
    }),
    personalInfo: Yup.mixed<PersonalInfo>().when('type', {
      is: (type) =>
        type &&
        [ExamApplicationType.CAPABILITY, ExamApplicationType.AUDIT].includes(
          type
        ),
      then: Yup.object<PersonalInfo>().shape({
        firstName: Yup.string().nullable().required(),
        lastName: Yup.string().nullable().required(),
        phone: Yup.string().nullable().required(),
        email: Yup.string().nullable().required(),
        residence: addressValidation,
      }),
    }),
  }) as Yup.Schema<ExamApplication>;
}
