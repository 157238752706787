import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AuthoredEvidence,
  AuthoredObject,
  EvidenceProps,
  TableSearchbar,
  UserContext,
} from '@eas/common-web';
import { Permission } from '@enums';

const useStyles = makeStyles({
  searchbar: {
    paddingTop: 10,
    width: '100%',
  },
});

export function useAuthoredEvidence<OBJECT extends AuthoredObject>(
  options: EvidenceProps<OBJECT>
): EvidenceProps<OBJECT> {
  const classes = useStyles();

  const { hasPermission } = useContext(UserContext);

  return AuthoredEvidence.useAuthoredEvidence({
    ...options,
    tableProps: {
      ...options.tableProps,
      showReportButton:
        options.tableProps?.showReportButton !== false &&
        hasPermission(Permission.BO_DYNAMIC_EXPORT),
      reportTag: hasPermission(Permission.BO_DYNAMIC_EXPORT)
        ? options.tableProps?.reportTag
        : undefined,
      SearchbarComponent: () => (
        <div className={classes.searchbar}>
          <TableSearchbar />
        </div>
      ),
    },
  });
}
