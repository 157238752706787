import * as Yup from 'yup';
import { EducationCycle } from '@models';

export function useValidationSchema() {
  return Yup.object<EducationCycle>().shape({
    fromYear: Yup.number()
      .nullable()
      .min(1990, 'Musí byť v rozsahu 1990 až 2100')
      .max(2100, 'Musí byť v rozsahu 1990 až 2100')
      .required(),
    toYear: Yup.number()
      .nullable()
      .min(1990, 'Musí byť v rozsahu 1990 až 2100')
      .max(2100, 'Musí byť v rozsahu 1990 až 2100')
      .required(),
  });
}
