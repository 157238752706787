import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const ACCESS: MessageType<'WARNING'> = {
  WARNING: [
    'Nemáte oprávnění na přístup k tomuto modulu.',
    SnackbarVariant.WARNING,
  ],
};

export default { ACCESS };
