import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { useApproveDialog } from './dialog-actions/approve-hook';
import { useDeclineDialog } from './dialog-actions/decline-hook';
import { useRevokeDialog } from './dialog-actions/revoke-hook';

export function ActionBar() {
  const { showDeclineBtn, ...declineProps } = useDeclineDialog();
  const { showRevokeBtn, ...revokeProps } = useRevokeDialog();
  const { showApproveBtn, ...approveProps } = useApproveDialog();

  return (
    <>
      {showRevokeBtn && (
        <DetailActionButton
          promptKey="REVOKE"
          buttonLabel="Odobrať"
          dialogText="Naozaj chcete odobrať odbornosť?"
          dialogTitle="Odobratie"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...revokeProps}
        />
      )}
      {showDeclineBtn && (
        <DetailActionButton
          promptKey="DECLINE"
          buttonLabel="Zamietnuť"
          dialogText="Naozaj chcete zamietnuť odbornosť?"
          dialogTitle="Zamietnutie"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogDisableBackdrop
          {...declineProps}
        />
      )}
      {showApproveBtn && (
        <DetailActionButton
          promptKey="APPROVE"
          buttonLabel="Schváliť"
          dialogText="Naozaj chcete schváliť odbornosť?"
          dialogTitle="Schválenie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...approveProps}
        />
      )}
    </>
  );
}
