import { isBefore } from 'date-fns';
import * as Yup from 'yup';
import { Charge, DictMonetaryCurrency, Payment, Subject } from '@models';

export function useValidationSchema() {
  return Yup.object<Payment>().shape({
    charge: Yup.mixed<Charge>().nullable().required(),
    subject: Yup.mixed<Subject>().nullable().required(),
    email: Yup.string()
      .email('Musí byť validná emailová adresa')
      .nullable()
      .required(),
    amount: Yup.object().shape({
      amount: Yup.number()
        .nullable()
        .min(1, 'Musí byť kladné číslo')
        .required(),
      currency: Yup.mixed<DictMonetaryCurrency>().nullable().required(),
    }),
    dueDate: Yup.date()
      .nullable()
      .test('', '', function (date) {
        const { createError, path } = this;
        const minDate = new Date();
        minDate.setHours(0, 0, 0, 0);

        if (date && isBefore(date, minDate)) {
          return createError({ path, message: 'Nesmie byť v minulosti' });
        }

        return true;
      })
      .required(),
    year: Yup.number().nullable().required(),
  });
}
