/**
 * Typ poisteného subjektu
 */
export enum AuditingEntityType {
  /**
   * Audítor
   */
  AUDITOR = 'AUDITOR',

  /**
   * Audítorska spoločnosť
   */
  AUDIT_COMPANY = 'AUDIT_COMPANY',
}
