/* eslint-disable react/jsx-key */
import { ReactNode } from 'react';
import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const ERROR: MessageType<string> = {
  OPERATION_ACCESS_DENIED: [
    'Prihlásený užívateľ nemá dostatočné oprávnenia na vykonanie akcie.',
    SnackbarVariant.ERROR,
  ],
  UNEXPECTED: [
    'Došlo k neočakávanej chybe, kontaktujte administrátora.',
    SnackbarVariant.ERROR,
  ],
};

const SUCCESS: [ReactNode, SnackbarVariant] = [
  'Akcia bola úspešne vykonaná.',
  SnackbarVariant.SUCCESS,
];

export default { ERROR, SUCCESS };
