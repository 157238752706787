import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useAuditNoticeStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_NOTICE}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useAuditNoticeTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_NOTICE}/types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
