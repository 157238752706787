import { useContext } from 'react';
import {
  ApiFilterOperation,
  HookedSource,
  UserContext,
  useScrollableSource,
} from '@eas/common-web';
import {
  EMPLOYMENT_SOURCE,
  STAKEHOLDER_SOURCE,
} from '@modules/audit-company/audit-company-hooked-sources';
import { Subject } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

function useStakeholderSource(): HookedSource<Subject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [STAKEHOLDER_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_STAKEHOLDER_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.AUDIT_COMPANY}/stakeholders/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'subject.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

function useEmploymentSource(): HookedSource<Subject> {
  const source = useScrollableSource();
  const { hasPermission } = useContext(UserContext);

  return {
    [EMPLOYMENT_SOURCE]: {
      source,
      shouldLoad: () => hasPermission(Permission.BO_EMPLOYMENT_READ),
      modifyBeforeLoad: ({ id }) => {
        source.setUrl(`${EvidenceApiUrl.AUDIT_COMPANY}/employments/list`);
        source.setParams({
          size: 15,
          filters: [
            {
              field: 'subject.id',
              operation: ApiFilterOperation.EQ,
              value: id,
            },
          ],
        });
      },
    },
  };
}

export function useSubjectHookedSources(): HookedSource<Subject> {
  const stakeholders = useStakeholderSource();
  const employments = useEmploymentSource();

  return { ...stakeholders, ...employments };
}
