import * as Yup from 'yup';
import { validateIdentifier } from '@modules/legal-person/legal-person-schema';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import {
  AuditReport,
  AuditingEntity,
  Auditor,
  FinancialStatementAuditReport,
} from '@models';
import { PublicInterestEntityCategory } from '@enums';

export function useValidationSchema() {
  const validateFinancialStatement = useFinancialStatementSchema();

  return Yup.object<AuditReport>().shape({
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    overallAuditProfits: Yup.object()
      .nullable()
      .when(['financialStatements'], {
        is: (financialStatements: FinancialStatementAuditReport[]) =>
          (financialStatements ?? []).some((s) => s.publicInterest),
        then: () =>
          Yup.object().shape({
            statutoryAudit: Yup.number().nullable().required(),
            otherAssuranceServices: Yup.number().nullable().required(),
            taxServices: Yup.number().nullable().required(),
            otherNonAuditServices: Yup.number().nullable().required(),
          }),
}),

    financialStatements: Yup.array()
      .of(validateFinancialStatement)
      .min(1)
      .required(),
  });
}

export function useFinancialStatementSchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<FinancialStatementAuditReport>().shape({
    auditingAuditors: Yup.array<Auditor>().nullable().min(1).required(),
    auditedUnitAccountingEndDate: Yup.date().nullable().required(),
    auditedUnitInfo: Yup.object().shape({
      address: validateAddress,
      businessName: Yup.string().nullable().required(),
      identifier: validateIdentifier,
    }),
    category: Yup.object()
      .nullable()
      .when('publicInterest', {
        is: true,
        then: () =>
          Yup.mixed<PublicInterestEntityCategory>().nullable().required(),
        }),
    auditStartYear: Yup.number()
      .when(['publicInterest', 'tradingCompany'], {
        is: (publicInterest, tradingCompany) =>
          !!publicInterest || !!tradingCompany,
        then: Yup.number().nullable().required(),
        otherwise: undefined,
      })
      .nullable(),
    subjectAuditProfits: Yup.object()
      .nullable()
      .when(['publicInterest'], {
        is: (publicInterest) => !!publicInterest,
        then: Yup.object().shape({
          statutoryAudit: Yup.number().nullable().required(),
          nonAuditServicesB: Yup.number().nullable().required(),
          nonAuditServicesC: Yup.number().nullable().required(),
        }),
      }),
  }) as Yup.Schema<FinancialStatementAuditReport>;
}
