import React from 'react';
import { DictionaryEvidence, FormCheckbox, FormPanel } from '@eas/common-web';

export function Fields() {
  return (
    <>
      <FormPanel label="Obecné">
        <DictionaryEvidence.DictionaryFields />
        <FormCheckbox
          label="Prednastavené povolenia v zamestnaní"
          name="flagsEnabled"
        />
      </FormPanel>
    </>
  );
}
