export function useColumns(prefix: string) {
  return [
    { datakey: `${prefix}.street`, name: 'Ulica', width: 150 },
    { datakey: `${prefix}.houseNumber`, name: 'Číslo budovy', width: 150 },
    { datakey: `${prefix}.zip`, name: 'PSČ', width: 150 },
    { datakey: `${prefix}.municipality`, name: 'Obec', width: 150 },
    { datakey: `${prefix}.country.name`, name: 'Krajina', width: 150 },
    { datakey: `${prefix}.poBox`, name: 'P.O. Box', width: 150 },
  ];
}
