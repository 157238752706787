import { useEvidencePermission } from '@utils/permission-hook';
import { createSubmissionRowStyle } from '@utils/row-style';
import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { InsuranceSubmission } from '@models';
import { EvidenceApiUrl, InsuranceSubmissionState, Permission } from '@enums';
import { ActionBar } from './insurance-submission-actionbar';
import { useColumns } from './insurance-submission-columns';
import { Fields } from './insurance-submission-fields';
import { useValidationSchema } from './insurance-submission-schema';

export function InsuranceSubmissions() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const { homeCurrency } = useContext(StaticDataContext);

  const permissions = useEvidencePermission([
    Permission.BO_INSURANCE_SUBMISSION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<InsuranceSubmission>({
    version: 2,
    identifier: 'INSURANCE_SUBMISSION',
    apiProps: {
      url: EvidenceApiUrl.INSURANCE_SUBMISSION,
    },
    tableProps: {
      columns,
      tableName: 'Nahlásenie poistenia',
      showBulkActionButton: false,
      showReportButton: false,
      createRowStyle: createSubmissionRowStyle(
        InsuranceSubmissionState.PROCESSED
      ),
    },
    detailProps: {
      toolbarProps: {
        title: 'Nahlásenie poistenia',
        showBtn: permissions,
        ActionBar,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), cost: { currency: homeCurrency } }),
    },
  });

  return <Evidence {...evidence} />;
}
