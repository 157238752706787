import { useAutocompleteSource } from '@eas/common-web';
import { EducationalTrainingAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEducationalTrainings(apiUrl?: EvidenceApiUrl) {
  return useAutocompleteSource<EducationalTrainingAutocomplete>({
    url: `${EvidenceApiUrl.EDUCATIONAL_TRAINING}/autocomplete`,
    apiUrl,
  });
}
