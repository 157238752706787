import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Exam, SubjectAutocomplete } from '@models';

export function useColumns(): TableColumn<Exam>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Exam>();

  const { examTypes } = useContext(StaticDataContext);
  const useTypes = () => useStaticListSource(examTypes);

  return [
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ skúšky',
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Fyzická osoba vykonávajúca skúšku',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useNaturalPersons,
          autocompleteLabelMapper
        ),
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'passingDate',
      name: 'Dátum úspešného zloženia skúšky',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'partialRating',
      name: 'Čiastočné hodnotenie',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'totalRating',
      name: 'Celkové hodnotenie',
      width: 175,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'partialResult',
      name: 'Čiastočný výsledok',
      width: 175,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'totalResult',
      name: 'Celkový výsledok',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'successfullyCompleted',
      name: 'Skúška úspešne ukončená',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
