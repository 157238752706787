import React, { ComponentType, useContext, useEffect } from 'react';
import { Schema } from 'yup';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import RemoveIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {
  DetailActionButton,
  DetailActionButtonProps,
  DetailContext,
  DetailMode,
  DomainObject,
  ScrollableSource,
  TableFieldToolbarButton,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { FormTableDetailWrapper } from '@components/form/table-field/form-table-detail-wrapper';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';

type ButtonProps<T> = Pick<DetailActionButtonProps<T>, 'apiCall' | 'promptKey'>;

interface RemoteTableToolbarProps<T> {
  dialogTitle: string;
  validationSchema?: Schema<T>;
  source: ScrollableSource<T>;
  Dialog?: ComponentType;
  addButtonProps?: ButtonProps<T>;
  editButtonProps?: ButtonProps<T>;
  deleteButtonProps?: ButtonProps<T>;
  url?: string;
  disabled?: boolean;
}

export function RemoteTableToolbar<T extends DomainObject>({
  selectedIndex,
  setSelectedIndex,
  dialogTitle,
  validationSchema,
  Dialog,
  source: tableSource,
  addButtonProps,
  editButtonProps,
  deleteButtonProps,
  url,
  disabled = false,
}: TableFieldToolbarProps & RemoteTableToolbarProps<T>) {
  const classes = useStyles();

  const detailCtx = useContext(DetailContext);
  const detailSource = detailCtx.source;
  const items = tableSource.items;

  useEffect(() => {
    setSelectedIndex(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailSource]);

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {addButtonProps && (
          <DetailActionButton
            {...addButtonProps}
            buttonLabel="Pridať nový záznam"
            dialogTitle={dialogTitle}
            dialogText={null}
            buttonDisabled={disabled}
            ButtonComponent={({ onClick, label, disabled }) => (
              <TableFieldToolbarButton
                IconComponent={ControlPointIcon}
                disabled={disabled ?? false}
                show={true}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">Pridať</Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={() => (
              <DetailContext.Provider
                value={{
                  ...detailCtx,
                  mode: DetailMode.NEW,
                }}
              >
                {Dialog && <Dialog />}
              </DetailContext.Provider>
            )}
            formValidationSchema={validationSchema}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            dialogWidth={600}
            dialogDisableBackdrop
          />
        )}
        {editButtonProps && (
          <DetailActionButton
            {...editButtonProps}
            buttonLabel="Upraviť záznam"
            dialogTitle={dialogTitle}
            dialogText={null}
            buttonDisabled={selectedIndex === undefined || disabled}
            ButtonComponent={({ onClick, label, disabled }) => (
              <TableFieldToolbarButton
                IconComponent={EditIcon}
                disabled={disabled ?? false}
                show={true}
                title={label}
                onClick={onClick}
                color="default"
              >
                <Typography variant="body2">Upraviť</Typography>
              </TableFieldToolbarButton>
            )}
            formInitialValues={
              selectedIndex !== undefined ? items?.[selectedIndex] : {}
            }
            FormFields={() => (
              <DetailContext.Provider
                value={{
                  ...detailCtx,
                  mode: DetailMode.EDIT,
                }}
              >
                {url && selectedIndex !== undefined ? (
                  <FormTableDetailWrapper
                    url={url}
                    id={items[selectedIndex].id}
                  >
                    {Dialog && <Dialog />}
                  </FormTableDetailWrapper>
                ) : (
                  Dialog && <Dialog />
                )}
              </DetailContext.Provider>
            )}
            formValidationSchema={validationSchema}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            dialogWidth={600}
            dialogDisableBackdrop
          />
        )}
        {deleteButtonProps && (
          <DetailActionButton
            {...deleteButtonProps}
            apiCall={
              deleteButtonProps.apiCall
                ? (id) =>
                    deleteButtonProps.apiCall!(id, items[selectedIndex!].id)
                : undefined
            }
            buttonLabel="Odstrániť záznam"
            dialogTitle={dialogTitle}
            dialogText="Skutočne chcete odstrániť vybraný záznam?"
            buttonDisabled={selectedIndex === undefined || disabled}
            ButtonComponent={({ onClick, label, disabled }) => (
              <TableFieldToolbarButton
                IconComponent={RemoveIcon}
                show={true}
                disabled={disabled ?? false}
                title={label}
                onClick={onClick}
                color="secondary"
              >
                <Typography variant="body2">Zmazať</Typography>
              </TableFieldToolbarButton>
            )}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            dialogDisableBackdrop
          />
        )}
      </ButtonGroup>
    </div>
  );
}
