import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { DictEmploymentPosition } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { Fields } from './dict-employment-position-fields';

export function DictEmploymentPositions() {
  const permissions = useEvidencePermission([
    Permission.BO_EMPLOYMENT_POSITION_MANAGE,
  ]);

  const evidence =
    DictionaryEvidence.useDictionaryEvidence<DictEmploymentPosition>({
      identifier: 'DICT_EMPLOYMENT_POSITION',
      apiProps: {
        url: EvidenceApiUrl.DICT_EMPLOYMENT_POSITION,
      },
      tableProps: {
        tableName: 'Popisy pozície',
        showBulkActionButton: false,
        showReportButton: false,
      },
      detailProps: {
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          title: 'Popis pozície',
          showBtn: permissions,
        },
      },
    });

  return <Evidence {...evidence} />;
}
