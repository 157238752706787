import React, { useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  FormContext,
  FormTableField,
  TableFieldCells,
  eqFilterParams,
  useFormSelector,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { Fields } from '@modules/financial-statement-audit/financial-statement-audit-fields';
import { useValidationSchema } from '@modules/financial-statement-audit/financial-statement-audit-schema';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { LegalPerson } from '@components/form/subject-field/legal-person-field';
import {
  AuditReport,
  AuditingEntityAutocomplete,
  FinancialStatementAudit,
  FinancialStatementAuditReport,
  SubjectLegal,
} from '@models';
import { AuditingEntityType, EvidenceApiUrl, SubjectType } from '@enums';

export function ProcessDialog() {
  const { source, ...detailCtx } =
    useContext<DetailHandle<AuditReport>>(DetailContext);
  const { setFieldValue, getFieldValues } = useContext(FormContext);

  const financialStatementAuditSchema = useValidationSchema();

  const isOwnerAuditor =
    source?.data?.owner?.type === AuditingEntityType.AUDITOR;

  useEffect(() => {
    const { financialStatements } = getFieldValues();

    const fetchUnitInfo = async () => {
      const loadedFinancialStatements = await Promise.all(
        financialStatements.map(async (f: FinancialStatementAuditReport) => {
          const response = await fetch(`${EvidenceApiUrl.SUBJECT}/list`, {
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            method: 'POST',
            body: JSON.stringify(
              eqFilterParams({
                field: 'identifier',
                value: f?.auditedUnitInfo?.identifier,
              })
            ),
          });

          const auditedUnits = await response.json();

          return { ...f, auditedUnit: auditedUnits?.items?.[0] };
        })
      );

      setFieldValue('financialStatements', loadedFinancialStatements);
    };

    if (financialStatements?.length > 0) {
      fetchUnitInfo();
    }
  }, []);

  const Subject = () => {
    const { setFieldValue } = useContext(FormContext);

    const { auditedUnitInfo } = useFormSelector(
      (data: FinancialStatementAuditReport) => ({
        auditedUnitInfo: data.auditedUnitInfo,
      })
    );

    return (
      <LegalPerson
        label="Subjekt"
        formInitialValues={
          { ...auditedUnitInfo, type: SubjectType.LEGAL } as SubjectLegal
        }
        name="auditedUnit"
        onResult={async (value) => setFieldValue('auditedUnit', value)}
        required
      />
    );
  };

  return (
    <DetailContext.Provider
      value={{ ...detailCtx, source, mode: DetailMode.NEW }}
    >
      <FormTableField
        name="financialStatements"
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        columns={[
          {
            datakey: 'auditingEntities',
            name: 'Vykonávatelia auditu',
            width: 300,
            CellComponent: TableFieldCells.useAutocompleteCellFactory<
              FinancialStatementAudit,
              AuditingEntityAutocomplete
            >({
              dataHook: useAuditingEntities,
              multiple: true,
              labelMapper: autocompleteLabelMapper,
            }),
          },
          {
            datakey: 'consolidated',
            name: 'Konsolidovaná účtovná závierka',
            width: 150,
            CellComponent: TableFieldCells.BooleanCell,
          },
          {
            datakey: 'individual',
            name: 'Individuálna účtovná závierka',
            width: 150,
            CellComponent: TableFieldCells.BooleanCell,
          },
          {
            datakey: 'tradingCompany',
            name: 'Obchodná spoločnosť',
            width: 150,
            CellComponent: TableFieldCells.BooleanCell,
          },
          {
            datakey: 'publicInterest',
            name: 'Subjekt verejného záujmu',
            width: 150,
            CellComponent: TableFieldCells.BooleanCell,
          },
          {
            datakey: 'auditCalendarYear',
            name: 'Kalendárny rok, v ktorom bol vykonaný audit',
            width: 150,
            CellComponent: TableFieldCells.NumberCell,
          },
          {
            datakey: 'auditedUnit.businessName',
            name: 'Účtovná jednotka - Názov spoločnosti',
            width: 150,
            CellComponent: TableFieldCells.TextCell,
          },
          {
            datakey: 'auditedUnit.identifier',
            name: 'Účtovná jednotka - IČO',
            width: 150,
            CellComponent: TableFieldCells.TextCell,
          },
        ]}
        validationSchema={financialStatementAuditSchema}
        FormFieldsComponent={() => {
          return (
            <Fields
              companyId={!isOwnerAuditor ? source?.data?.owner?.id : undefined}
              disabledAuditingEntities={isOwnerAuditor}
              Subject={Subject}
            />
          );
        }}
        initNewItem={() =>
          isOwnerAuditor
            ? ({
                id: uuidv4(),
                auditingEntities: [source?.data?.owner],
              } as FinancialStatementAudit)
            : {
                id: uuidv4(),
              }
        }
      />
    </DetailContext.Provider>
  );
}
