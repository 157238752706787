import { ReactNode } from 'react';
import { MessageType } from 'src/enums/messages/message-type';
import {
  SnackbarVariant,
  getErrorMessage as getMessage,
} from '@eas/common-web';
import { ErrorObject } from '@models';
import { Messages } from '@enums';

export function getErrorMessage(
  error: ErrorObject<string>,
  messages?: MessageType<string>
): [ReactNode, SnackbarVariant] {
  if (messages && error.code && error.code in messages) {
    return messages[error.code];
  }

  if (error?.code && error.code in Messages.Common.ERROR) {
    return Messages.Common.ERROR[error.code];
  }

  if (error?.message || error?.details || error?.code) {
    const errorDetail = getMessage(error);
    const message = `Chyba volání akce: ${errorDetail}`;

    return [message, SnackbarVariant.ERROR];
  }

  return Messages.Common.ERROR.UNEXPECTED;
}
