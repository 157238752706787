import { abortableFetch } from '@eas/common-web';
import { Employment } from '@models';
import { EvidenceApiUrl } from '@enums';

export const addEmploymentApiCall = (id: string, formData: Employment) =>
  abortableFetch(`${EvidenceApiUrl.AUDIT_COMPANY}/${id}/employments`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const editEmploymentApiCall = (id: string, formData: Employment) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/employments/${formData.id}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(formData),
    }
  );

export const deleteEmploymentApiCall = (id: string, insureeId: string) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/employments/${insureeId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  );
