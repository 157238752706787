import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormFileField,
  FormPanel,
  FormTextField,
} from '@eas/common-web';

export function Fields() {
  const { mode } = useContext(DetailContext);

  return (
    <FormPanel label="Dokument">
      <FormTextField name="name" label="Názov dokumentu" required />
      <FormFileField
        name="file"
        label="Súbor"
        disabled={mode !== DetailMode.NEW}
        required
      />
    </FormPanel>
  );
}
