import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { EducationalTraining } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { useColumns } from './educational-training-columns';
import { Fields } from './educational-training-fields';
import { useValidationSchema } from './educational-training-schema';

export function EducationalTrainings() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();

  const permissions = useEvidencePermission([Permission.BO_EDUCATION_MANAGE]);

  const evidence = useAuthoredEvidence<EducationalTraining>({
    version: 2,
    identifier: 'EDUCATIONAL_TRAINING',
    apiProps: {
      url: EvidenceApiUrl.EDUCATIONAL_TRAINING,
    },
    tableProps: {
      columns,
      tableName: 'Školenia',
      showBulkActionButton: false,
      showReportButton: false,
    },
    detailProps: {
      FieldsComponent: Fields,
      toolbarProps: {
        title: 'Školenie',
        showBtn: permissions,
      },
      validationSchema,
    },
  });

  return <Evidence {...evidence} />;
}
