import { abortableFetch } from '@eas/common-web';
import { EmploymentInvitation } from '@models';
import { EvidenceApiUrl } from '@enums';

export const addEmploymentInvitationApiCall = (
  id: string,
  formData: EmploymentInvitation
) =>
  abortableFetch(`${EvidenceApiUrl.AUDIT_COMPANY}/${id}/invitation`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(formData),
  });

export const editEmploymentInvitationApiCall = (
  id: string,
  formData: EmploymentInvitation
) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/invitations/${formData.id}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
      body: JSON.stringify(formData),
    }
  );

export const deleteEmploymentInvitationApiCall = (
  id: string,
  invitationId: string
) =>
  abortableFetch(
    `${EvidenceApiUrl.AUDIT_COMPANY}/${id}/invitation/${invitationId}`,
    {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  );
