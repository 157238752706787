/**
 * Stav registrácie vykonávania auditu
 */
export enum AuditRegistrationState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Čakajúca na platbu
   */
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',

  /**
   * Nepresná platba
   */
  INCORRECT_PAYMENT_AMOUNT = 'INCORRECT_PAYMENT_AMOUNT',

  /**
   * Zaplatená
   */
  PAID = 'PAID',
}

/**
 * Typ subjektu v registrácií vykonávania auditu
 */
export enum AuditRegistrationCompanyType {
  /**
   * Subjekt verejného záujmu
   */
  PUBLIC_INTEREST_ENTITY = 'PUBLIC_INTEREST_ENTITY',

  /**
   * Obchodná spoločnosť
   */
  TRADING_COMPANY = 'TRADING_COMPANY',
}
