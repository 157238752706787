import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useSendRenewalRequestDialog } from './dialog-actions/send-renewal-request-hook';

export function ActionBar() {
  const { showSendRenewalRequestBtn, ...sendRenewalRequestProps } =
    useSendRenewalRequestDialog();

  return (
    <>
      {showSendRenewalRequestBtn && (
        <DetailActionButton
          promptKey="SEND_RENEWAL_REQUEST"
          buttonLabel="Vyžiadať aktualizáciu poistenia"
          dialogTitle="Vyžiadanie aktualizácie poistenia"
          dialogText={null}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          dialogDisableBackdrop
          {...sendRenewalRequestProps}
        />
      )}
    </>
  );
}
