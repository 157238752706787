import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useEducatedAuditorSchema } from '@modules/education-submission/education-submission-schema';
import {
  Auditor,
  EducatedAuditor,
  Education,
  EducationCycle,
  EducationSubmission,
  EducationalTraining,
} from '@models';
import { EducationSubmissionState, EvidenceApiUrl, Permission } from '@enums';

export function useProcessDialog() {
  const detailCtx =
    useContext<DetailHandle<EducationSubmission>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const educatedAuditorSchema = useEducatedAuditorSchema(false, true);

  const apiCall = useEventCallback(
    (id: string, { training, cycle, year, educatedAuditors }) =>
      abortableFetch(`${EvidenceApiUrl.EDUCATION_SUBMISSION}/${id}/process`, {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          training,
          educations: educatedAuditors.map(
            (a: EducatedAuditor & { auditor: Auditor; note?: string }) => ({
              auditor: a.auditor,
              cycle,
              note: a.note,
              year,
              hours: a.approvedTrainedHours,
              owner: detailCtx?.source?.data?.owner,
            })
          ),
        }),
      })
  );

  const showBtn =
    detailCtx?.source?.data?.state === EducationSubmissionState.NEW &&
    hasPermission(Permission.BO_EDUCATION_SUBMISSION_MANAGE);

  const formValidationSchema = Yup.object<Education>().shape({
    cycle: Yup.mixed<EducationCycle>().nullable().required(),
    training: Yup.mixed<EducationalTraining>().nullable().required(),
    year: Yup.number()
      .nullable()
      .min(1990, 'Musí byť v rozsahu 1990 až 2100')
      .max(2100, 'Musí byť v rozsahu 1990 až 2100')
      .required(),
    educatedAuditors: Yup.array()
      .nullable()
      .test('', '', async function (value) {
        const { createError, path } = this;
        const auditors = value as EducatedAuditor[];

        try {
          if (auditors?.length === 0) {
            return createError({
              path,
              message: 'Musí existovať aspoň jeden audítor',
            });
          }

          for (const audit of auditors) {
            await educatedAuditorSchema.validate(audit);
          }
        } catch (err) {
          return createError({
            path,
            message: 'Doplňte chýbajúce povinné pole všetkých záznamov',
          });
        }

        return true;
      }),
  }) as Yup.Schema<Education>;

  const { educatedAuditors, reportedYear, trainingName, form, field, cycle } =
    detailCtx?.source?.data || {};

  return {
    apiCall,
    showProcessBtn: showBtn,
    formValidationSchema,
    formInitialValues: {
      id: uuidv4(),
      cycle,
      year: reportedYear,
      educatedAuditors: (educatedAuditors ?? []).map((a) => ({
        ...a,
        approvedTrainedHours: a.proposedTrainedHours,
      })),
      submissionTraining: {
        name: trainingName,
        form,
        educationField: field,
      },
    },
  };
}
