import * as Yup from 'yup';
import { RegistryRecord } from '@models';
import { RegistryRecordSource } from '@enums';

export function useValidationSchema() {
  return Yup.object<RegistryRecord>().shape({
    name: Yup.string().nullable().required(),
    source: Yup.mixed<RegistryRecordSource>().nullable().required(),
    emailAddressee: Yup.string()
      .email('Musí byť validná emailová adresa')
      .nullable()
      .notRequired(),
  }) as Yup.Schema<RegistryRecord>;
}
