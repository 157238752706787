import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { LicenceApplication } from '@models';
import { EvidenceApiUrl, LicenceApplicationState, Permission } from '@enums';

export function useConfirmPaymentDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } =
    useContext<DetailHandle<LicenceApplication>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(
      `${EvidenceApiUrl.LICENCE_APPLICATION}/${id}/confirm-payment`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'PUT',
      }
    )
  );

  const showBtn =
    hasPermission(Permission.BO_LICENCE_APPLICATION_MANAGE) &&
    source?.data?.state === LicenceApplicationState.INCORRECT_PAYMENT_AMOUNT;

  return { showConfirmPaymentBtn: showBtn, apiCall };
}
