import { useContext } from 'react';
import {
  ApiFilterOperation,
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { DictCountry } from '@models';

export function useColumns(): TableColumn<DictCountry>[] {
  const { countryTags } = useContext(StaticDataContext);
  const { dictionaryColumns } =
    DictionaryEvidence.useDictionaryColumns<DictCountry>();

  const useTags = () => useStaticListSource(countryTags);

  return [
    {
      datakey: 'alpha2',
      name: 'Kód krajiny Alpha-2',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'alpha3',
      name: 'Kód krajiny Alpha-3',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'tag',
      filterkey: 'tag.id',
      sortkey: 'tag.name',
      name: 'Tag',
      width: 150,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.useSelectCellFactory(useTags),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTags),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...dictionaryColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
