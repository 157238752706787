import React, { useContext, useRef } from 'react';
import * as Yup from 'yup';
import {
  AbortableFetch,
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FormContext,
  FormLocalDateField,
  FormSelect,
  FormTextArea,
  FormTextField,
  SnackbarContext,
  UserContext,
  abortableFetch,
  useEventCallback,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { AuditingEntity, Insurance, Insuree } from '@models';
import {
  AuditingEntityType,
  CalendarEventType,
  EvidenceApiUrl,
  Messages,
  Permission,
} from '@enums';

export function useSendRenewalRequestDialog() {
  const detailCtx = useContext<DetailHandle<Insurance>>(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const sendRenewalApiCall = useEventCallback((formData) =>
    abortableFetch(
      `${EvidenceApiUrl.INSURANCE}/${detailCtx?.source?.data?.id}/send-renewal-request`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify(formData),
      }
    )
  );

  const createCalendarEventApiCall = useEventCallback((formData) =>
    abortableFetch(EvidenceApiUrl.CALENDAR_EVENT, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const loadCompanyAuditorsApiCall = useEventCallback((companyId) =>
    abortableFetch(
      `${EvidenceApiUrl.AUDIT_COMPANY}/${companyId}/employments/auditors/autocomplete`,
      {
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        method: 'POST',
        body: JSON.stringify({
          size: -1,
          filters: [
            {
              field: 'companyCalendarAccess',
              operation: ApiFilterOperation.EQ,
              value: true,
            },
          ],
        }),
      }
    )
  );

  const showBtn = hasPermission(Permission.BO_INSURANCE_MANAGE);

  const handleSubmit = useEventCallback(
    async ({ date, reminderDate, ...values }) => {
      try {
        if (fetch.current !== null) {
          fetch.current.abort();
        }

        fetch.current = sendRenewalApiCall(values);
        await fetch.current.raw();

        if (fetch.current !== null) {
          fetch.current.abort();
        }

        if (values.insuredSubject.type === AuditingEntityType.AUDITOR) {
          fetch.current = createCalendarEventApiCall({
            type: CalendarEventType.FOR_SPECIFIED_AUDITORS,
            auditors: [values.insuredSubject],
            note: values.note,
            name: 'Aktualizácia poistenia',
            date,
            reminderDate,
          });
        } else {
          fetch.current = loadCompanyAuditorsApiCall(values.insuredSubject.id);
          const auditors = await fetch.current.json();

          if (fetch.current !== null) {
            fetch.current.abort();
          }

          fetch.current = createCalendarEventApiCall({
            type: CalendarEventType.FOR_SPECIFIED_AUDITORS,
            auditors: auditors?.items,
            note: values.note,
            name: 'Aktualizácia poistenia',
            date,
            reminderDate,
          });
        }

        await fetch.current.json();

        showSnackbar(...Messages.Common.SUCCESS);
      } catch (err) {
        showSnackbar(...Messages.Common.ERROR.UNEXPECTED);

        throw err;
      }
    }
  );

  const formValidationSchema = Yup.object().shape({
    email: Yup.string().nullable().required(),
    insuredSubject: Yup.mixed<AuditingEntity>().nullable().required(),
    note: Yup.string().nullable().required(),
    date: Yup.string().nullable().required(),
    reminderDate: Yup.string().nullable().required(),
  });

  const FormFields = () => {
    const { setFieldValue } = useContext(FormContext);

    const { insuredSubject } = useFormSelector((data: Insuree) => ({
      insuredSubject: data.insuredSubject,
    }));

    const subjects = useStaticListSource(
      detailCtx?.source?.data?.insurees?.map((i) => i.insuredSubject!) ?? []
    );

    return (
      <>
        <FormSelect<AuditingEntity>
          source={subjects}
          name="insuredSubject"
          label="Poistený vykonávateľ auditu"
          labelMapper={autocompleteLabelMapper}
          notifyChange={(value) => {
            const email = (value as AuditingEntity)?.subject?.mainEmail;

            if (email) {
              setFieldValue('email', email);
            }
          }}
          required
        />
        <FormTextField
          name="email"
          label="E-mail"
          required
          disabled={!insuredSubject}
        />
        <FormTextArea
          name="note"
          label="Text oznamenia"
          required
          layoutOptions={{ noSpacing: true }}
        />
        <FormLocalDateField name="date" label="Dátum udalosti" required />
        <FormLocalDateField
          name="reminderDate"
          label="Dátum pripomienky"
          required
        />
      </>
    );
  };

  return {
    showSendRenewalRequestBtn: showBtn,
    FormFields,
    formValidationSchema,
    submitCallback: handleSubmit,
  };
}
