import React, { forwardRef } from 'react';
import { Dialog, DialogHandle, Form, FormTextField } from '@eas/common-web';
import { PasswordChangeForm } from '@models';
import { usePasswordChangeDialog } from './password-change-hook';

export const PasswordChangeDialog = forwardRef<
  DialogHandle,
  Record<string, unknown>
>(function PasswordChangeDialog(_, dialogRef) {
  const closeDialog = () => {
    if (typeof dialogRef !== 'function') {
      dialogRef?.current?.close();
    }
  };

  const { ref, loading, validationSchema, handleConfirm, handleSubmit } =
    usePasswordChangeDialog(closeDialog);

  return (
    <Dialog
      ref={dialogRef}
      loading={loading}
      title="Změna hesla"
      onConfirm={handleConfirm}
    >
      {() => (
        <div style={{ width: 500 }}>
          <Form<PasswordChangeForm>
            editing={!loading}
            initialValues={{
              oldPassword: '',
              newPassword: '',
              newPasswordAgain: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            ref={ref}
          >
            <FormTextField
              name="newPassword"
              label="Nové heslo"
              type="password"
            />
            <FormTextField
              name="newPasswordAgain"
              label="Nové heslo znova"
              type="password"
            />
          </Form>
        </div>
      )}
    </Dialog>
  );
});
