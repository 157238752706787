import { green, red } from '@material-ui/core/colors';

export function createSubmissionRowStyle(
  processedState: string,
  rejectedState?: string
) {
  return function createRowStyle(submission: { state?: string }) {
    return submission.state === processedState
      ? { color: green[500], backgroundColor: green[50] }
      : submission.state === rejectedState
      ? { color: red[500], backgroundColor: red[50] }
      : {};
  };
}
