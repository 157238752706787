import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useUsers } from '@modules/user/user-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  GeneralSubmission,
  UserAutocomplete,
} from '@models';

export function useColumns(): TableColumn<GeneralSubmission>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<GeneralSubmission>();

  const {
    generalSubmissionAgendas,
    generalSubmissionTypes,
    generalSubmissionStates,
  } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(generalSubmissionStates);
  const useTypes = () => useStaticListSource(generalSubmissionTypes);
  const useAgendas = () => useStaticListSource(generalSubmissionAgendas);

  return [
    {
      datakey: 'matter',
      name: 'Vec podnetu',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'portalNote',
      name: 'Poznámka z Portálu',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'owner.label',
      sortkey: 'owner.name',
      filterkey: 'owner.id',
      name: 'Vlastník',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<AuditingEntityAutocomplete>(
          useAuditingEntities,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'solvers',
      filterkey: 'solvers.flat',
      name: 'Riešitelia',
      width: 300,
      CellComponent: TableCells.TextCell,
      valueMapper: TableCells.dictionaryArrayColumnMapper,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<UserAutocomplete>(
          useUsers,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: false,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 200,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'agenda',
      sortkey: 'agenda.name',
      filterkey: 'agenda.id',
      name: 'Agenda',
      width: 200,
      valueMapper: TableCells.useSelectCellFactory(useAgendas),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'type',
      sortkey: 'type.name',
      filterkey: 'type.id',
      name: 'Typ',
      width: 200,
      valueMapper: TableCells.useSelectCellFactory(useTypes),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
