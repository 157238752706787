import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useSendRemindersDialog } from './dialog-actions/send-reminders-hook';

export function ActionBar() {
  const { source } = useContext(DetailContext);

  const { showSendRemindersBtn, ...sendRemindersProps } =
    useSendRemindersDialog();

  return (
    <>
      {showSendRemindersBtn && (
        <DetailActionButton
          promptKey="SEND_REMINDERS"
          buttonLabel="Poslať upomienky"
          dialogText={null}
          dialogTitle="Poslanie upomienok"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={700}
          dialogDisableBackdrop
          formInitialValues={{ event: source?.data, subjects: [] }}
          {...sendRemindersProps}
        />
      )}
    </>
  );
}
