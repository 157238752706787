import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
  SecondaryDetailActionbarButton,
} from '@eas/common-web';
import { LicenceApplication, Payment } from '@models';
import { PaymentState, SubjectType } from '@enums';
import { useAddPaymentDialog } from './dialog-actions/add-payment-hook';
import { useConfirmDialog } from './dialog-actions/confirm-company-hook';
import { useConfirmPaymentDialog } from './dialog-actions/confirm-payment-hook';
import { useRejectDialog } from './dialog-actions/reject-hook';

export function ActionBar() {
  const { source } =
    useContext<DetailHandle<LicenceApplication>>(DetailContext);

  const { showRejectBtn, ...rejectProps } = useRejectDialog();
  const { showConfirmBtn, ...confirmProps } = useConfirmDialog();
  const { showAddPaymentBtn, ...addPaymentProps } = useAddPaymentDialog();
  const { showConfirmPaymentBtn, ...confirmPaymentProps } =
    useConfirmPaymentDialog();

  const parseCompanyLicenceData = () => {
    const { companyData } = source?.data || {};
    const {
      identifier,
      address,
      businessName,
      relatedSubjects,
      companyEmployees,
      foreignLicences,
      version,
      id,
      email,
      phone,
      ...restData
    } = companyData || {};

    return {
      company: {
        ...restData,
        subject: {
          identifier,
          businessName,
          type: SubjectType.LEGAL,
          address: address,
          label: `${businessName} (${identifier})`,
        },
      },
      stakeholders: relatedSubjects,
      employments: companyEmployees?.map(({ employee, ...rest }) => ({
        ...rest,
        auditor: employee,
      })),
      foreignLicences,
    };
  };

  return (
    <>
      {showRejectBtn && (
        <DetailActionButton
          promptKey="REJECT"
          buttonLabel="Zamietnuť"
          dialogText="Naozaj si prajete zamietnuť žiadosť?"
          dialogTitle="Zamietnutie žiadosti"
          ButtonComponent={SecondaryDetailActionbarButton}
          dialogWidth={700}
          dialogDisableBackdrop
          {...rejectProps}
        />
      )}
      {showConfirmBtn && (
        <DetailActionButton
          promptKey="CONFIRM"
          buttonLabel="Schváliť"
          dialogText="Naozaj si prajete schváliť žiadosť?"
          dialogTitle="Schválenie žiadosti"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          dialogWidth={source?.data?.forAuditor ? 500 : 1000}
          formInitialValues={
            source.data?.forAuditor
              ? undefined
              : (parseCompanyLicenceData() as any)
          }
          {...confirmProps}
        />
      )}
      {showAddPaymentBtn && (
        <DetailActionButton
          promptKey="ADD_PAYMENT"
          buttonLabel="Generovať platbu"
          dialogTitle="Generovanie platby"
          dialogText={null}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          formInitialValues={
            {
              state: PaymentState.NEW,
              subject: source?.data?.applicant?.subject,
              year: new Date().getFullYear(),
              email: source?.data?.email,
            } as Payment
          }
          dialogDisableBackdrop
          {...addPaymentProps}
        />
      )}
      {showConfirmPaymentBtn && (
        <DetailActionButton
          promptKey="CONFIRM_PAYMENT"
          buttonLabel="Potvrdiť platbu"
          dialogTitle="Potvrdenie platby"
          dialogText="Skutočne chcete potvrdiť platbu pre vybranú žiadosť?"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...confirmPaymentProps}
        />
      )}
    </>
  );
}
