import * as Yup from 'yup';
import {
  Auditor,
  DictEmploymentPositionAutocomplete,
  EmploymentInvitation,
} from '@models';

export function useValidationSchema() {
  return Yup.object<EmploymentInvitation>().shape({
    auditor: Yup.mixed<Auditor>().nullable().required(),
    positions: Yup.array<DictEmploymentPositionAutocomplete>()
      .nullable()
      .required(),
  }) as Yup.Schema<EmploymentInvitation>;
}
