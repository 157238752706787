import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useAnonymousSubmissionStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ANONYMOUS_SUBMISSION}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
