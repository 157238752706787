import { DictionaryObject, useFetch } from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export function useAuditRegistrationStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_REGISTRATION}/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useAuditRegistrationCompanyTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_REGISTRATION}/company-types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
