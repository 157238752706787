import {
  AlogEvent,
  TableCells,
  TableColumn,
  auditLogFactory,
} from '@eas/common-web';

export function useColumns(): TableColumn<AlogEvent>[] {
  const columns = auditLogFactory
    .useColumns()
    .filter((column) => column.datakey !== 'message');

  return [
    {
      datakey: 'message',
      name: 'Zpráva',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: true,
    },
    ...columns,
  ];
}
