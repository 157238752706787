import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { Role } from '@models';

export function useColumns(): TableColumn<Role>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Role>();

  return [
    {
      datakey: 'name',
      name: 'Názov',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'position',
      name: 'Popis',
      width: 400,
      CellComponent: TableCells.TextCell,
      sortable: false,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
