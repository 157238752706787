import React, { useContext } from 'react';
import { UserContext } from '@eas/common-web';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { Permission } from '@enums';
import { AddressFields } from './fields/addresses';
import { DisciplinaryRecordFields } from './fields/disciplinary-records';
import { GeneralFields } from './fields/general';
import { InsuranceFields } from './fields/insurances';
import { LicenceFields } from './fields/licences';
import { MemberFields } from './fields/members';

export function Fields() {
  const { hasPermission } = useContext(UserContext);

  const config: TabsConfig = [
    {
      label: 'Obecné',
      key: 'GENERAL',
      validationKeys: ['subject', 'recordDate', 'legalForm', 'homeCountry'],
      content: <GeneralFields />,
    },
    {
      label: 'Licencie',
      key: 'LICENCES',
      content: <LicenceFields />,
    },
    {
      label: 'Adresy',
      key: 'ADDRESSES',
      content: <AddressFields />,
    },
    {
      label: 'Členovia',
      key: 'MEMBERS',
      content: <MemberFields />,
    },
    {
      label: 'Disciplinárné opatrenia',
      key: 'DISCIPLINARY_RECORDS',
      content: <DisciplinaryRecordFields />,
    },
  ];

  if (hasPermission(Permission.BO_INSURANCE_READ)) {
    config.push({
      label: 'Poistenia',
      key: 'INSURANCES',
      content: <InsuranceFields />,
    });
  }

  return <Tabs config={config} />;
}
