import React, { useContext, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { DialogHandle, UserContext, useEventCallback } from '@eas/common-web';
import { formTextFieldFactory } from '@components/form/simple-fields/simple-text-field';
import { Notifications } from './notifications/notifications';
import { PasswordChangeDialog } from './password-change/password-change-dialog';

const useStyles = makeStyles({
  paper: {
    marginTop: 20,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
  },
  title: {
    fontWeight: 700,
    fontSize: 36,
  },
  boxTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  card: {
    marginTop: 20,
    width: '70%',
    margin: '0 0 24px',
    padding: `10px 16px`,
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  buttonLabel: {
    fontSize: 11,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    marginTop: 5,
    height: 30,
    marginBottom: 8,
  },
  boxHeadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
});

export function Profile() {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const pwdChangeDialog = useRef<DialogHandle>(null);

  const ProfileFields = {
    UsernameField: formTextFieldFactory({
      label: 'Meno',
    }),
    FakePasswordField: formTextFieldFactory({
      label: 'Heslo',
      type: 'password',
    }),
  };

  const handlePwdChange = useEventCallback(() => {
    pwdChangeDialog.current?.open();
  });

  return (
    <div className={classes.paper}>
      <Container style={{ display: 'flex', gap: '40px' }}>
        <Card className={classes.card}>
          <div className={classes.boxHeadingWrapper}>
            <Typography className={classes.boxTitle}>
              Prihlasovacie údaje
            </Typography>
          </div>
          <ProfileFields.UsernameField
            value={user.name}
            size="small"
            disabled
          />
          <ProfileFields.FakePasswordField
            value="************"
            size="small"
            disabled
          />
          <div className={classes.buttonWrapper}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handlePwdChange}
              className={classes.buttonLabel}
              disableElevation
            >
              Zmeniť heslo
            </Button>
          </div>
        </Card>
        <Notifications />
      </Container>
      <PasswordChangeDialog ref={pwdChangeDialog} />
    </div>
  );
}
