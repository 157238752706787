import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormPanel,
  FormSelect,
  FormTextArea,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { PaymentField } from '@composite/payments/payment-field';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ValidityFields } from '@components/form/validity/validity-fields';
import {
  AuditNotice,
  AuditingEntityAutocomplete,
  UserAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { auditNoticeStates, auditNoticeTypes } = useContext(StaticDataContext);
  const { hasPermission } = useContext(UserContext);

  const backofficeUsers = useBackofficeUsers();
  const auditingEntities = useAuditingEntities();
  const stateSource = useStaticListSource(auditNoticeStates);
  const typeSource = useStaticListSource(auditNoticeTypes);

  const { owner } = useFormSelector((data: AuditNotice) => ({
    owner: data.owner,
  }));

  return (
    <>
      <FormPanel label="Činnosť">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Vlastník oznámenia"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        <FormSelect
          source={stateSource}
          name="state"
          label="Stav podania"
          valueIsId
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormSelect
          source={typeSource}
          name="type"
          label="Typ podania"
          valueIsId
          required
          disabled={mode !== DetailMode.NEW}
        />
        <ValidityFields
          fromKey="dateFrom"
          fromLabel="Dátum od"
          toKey="dateTo"
          toLabel="Dátum do"
          indefinitePeriodLabel="Na dobu neurčitú"
          disabledFrom={mode !== DetailMode.NEW}
          disabledTo={mode !== DetailMode.NEW}
        />
      </FormPanel>
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <PaymentField />
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          disabled={mode !== DetailMode.NEW}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
