import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, Evidence } from '@eas/common-web';
import { DictEducationForm } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';

export function DictEducationForms() {
  const permissions = useEvidencePermission([Permission.BO_EDUCATION_MANAGE]);

  const evidence = DictionaryEvidence.useDictionaryEvidence<DictEducationForm>({
    identifier: 'DICT_EDUCATION_FORM',
    apiProps: {
      url: EvidenceApiUrl.DICT_EDUCATION_FORM,
    },
    tableProps: {
      tableName: 'Formy vzdelávania',
      showBulkActionButton: false,
      showReportButton: false,
    },
    detailProps: {
      toolbarProps: {
        title: 'Forma vzdelávania',
        showBtn: permissions,
      },
    },
  });

  return <Evidence {...evidence} />;
}
