import {
  DictionaryObject,
  eqFilterParams,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { AuditCompanyAutocomplete } from '@models';
import { AuditingEntityType, EvidenceApiUrl } from '@enums';

export function useAuditCompanies() {
  return useAutocompleteSource<AuditCompanyAutocomplete>({
    url: `${EvidenceApiUrl.AUDITING_ENTITY}/autocomplete`,
    params: eqFilterParams({
      field: 'type.id',
      value: AuditingEntityType.AUDIT_COMPANY,
    }),
  });
}

export function useLegalForms() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_COMPANY}/legal-forms/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useStakeholderTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_COMPANY}/stakeholders/types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useEmploymentInvitationStates() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDIT_COMPANY}/invitations/states/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
