import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormInlineTableField,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  InlineTableFieldCells,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities } from '@modules/auditor/auditor-api';
import { useInsuranceBrokerages } from '@modules/dict-insurance-brokerage/dict-insurance-brokerage-api';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { useDocuments } from '@modules/document/document-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ValidityFields } from '@components/form/validity/validity-fields';
import {
  AuditingEntityAutocomplete,
  DictInsuranceBrokerageAutocomplete,
  DictMonetaryCurrencyAutocomplete,
  DocumentAutocomplete,
  InsuranceSubmission,
  UserAutocomplete,
} from '@models';
import { AuditingEntityType, EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { insuranceSubmissionStates } = useContext(StaticDataContext);

  const backofficeUsers = useBackofficeUsers();
  const auditingEntities = useAuditingEntities();
  const currencies = useMonetaryCurrencies();
  const stateSource = useStaticListSource(insuranceSubmissionStates);
  const brokerages = useInsuranceBrokerages();
  const documents = useDocuments();

  const { attachment, owner } = useFormSelector(
    (data: InsuranceSubmission) => ({
      attachment: data.attachment,
      owner: data.owner,
    })
  );

  return (
    <>
      <FormPanel label="Nahlásenie poistenia">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Vlastník podnetu"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        {mode !== DetailMode.NEW && (
          <FormSelect
            source={stateSource}
            name="state"
            label="Stav spracovania"
            valueIsId
            disabled
          />
        )}
        <FormLocalDateField
          name="processedDate"
          label="Dátum spracovania"
          disabled
        />
        <FormAutocomplete<DictInsuranceBrokerageAutocomplete>
          source={brokerages}
          name="brokerage"
          label="Sprostredkovanie poistenia"
          disabled={mode !== DetailMode.NEW}
          required
          freeSolo
          labelMapper={autocompleteLabelMapper}
        />
        <FormTextField
          name="number"
          label="Číslo poistnej zmluvy"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormLocalDateField
          name="contractConcludedDate"
          label="Dátum uzavretia zmluvy"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <ValidityFields
          fromLabel="Zmluva platná od"
          toLabel="Zmluva platná do"
          indefinitePeriodLabel="Poistený na dobu neurčitú"
          disabledFrom={mode !== DetailMode.NEW}
          disabledTo={mode !== DetailMode.NEW}
        />
        <FormAutocomplete<DocumentAutocomplete>
          source={documents}
          name="attachment"
          label="Príloha"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          after={
            attachment?.id &&
            hasPermission(Permission.BO_DOCUMENT_READ) && (
              <Redirect id={attachment.id} url={EvidenceBrowserUrl.DOCUMENT} />
            )
          }
        />
      </FormPanel>
      <FormPanel label="Výška poistného">
        <FormTextField
          name="cost.amount"
          label="Čiastka"
          type="number"
          disabled={mode !== DetailMode.NEW}
          required
        />
        <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
          source={currencies}
          name="cost.currency"
          label="Mena"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          required
        />
      </FormPanel>
      <FormPanel label="Údaje o poisťovni">
        <FormTextField
          name="insuranceCompany.name"
          label="Názov poisťovne"
          disabled={mode !== DetailMode.NEW}
          required
        />
        <FormTextField
          name="insuranceCompany.identifier"
          label="IČO poisťovne"
          disabled={mode !== DetailMode.NEW}
          required
        />
      </FormPanel>
      <FormAddressPanel
        name="insuranceCompany.address"
        label="Adresa poisťovne"
        disabled={mode !== DetailMode.NEW}
        required
      />
      <InsureesField />
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          labelMapper={autocompleteLabelMapper}
          multiline
          multiple
        />
      </FormPanel>
      <FormPanel label="Poznámka z Portálu">
        <FormTextArea
          name="portalNote"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          disabled={mode !== DetailMode.NEW}
        />
      </FormPanel>
    </>
  );
}

export function InsureesField() {
  const { mode } = useContext(DetailContext);

  return (
    <FormPanel label="Poisteneci">
      <FormInlineTableField
        columns={[
          {
            datakey: 'insuredFrom',
            name: 'Poistený od',
            width: 150,
            CellComponent: InlineTableFieldCells.useInlineDateFieldFactory({
              collectionDatakey: 'insurees',
            }),
          },
          {
            datakey: 'insuredTo',
            name: 'Poistený do',
            width: 150,
            CellComponent: InlineTableFieldCells.useInlineDateFieldFactory({
              collectionDatakey: 'insurees',
            }),
          },
          {
            datakey: 'insuredSubject',
            name: 'Poistený vykonávateľ auditu',
            width: 500,
            CellComponent:
              InlineTableFieldCells.useInlineAutocompleteCellFactory<
                InsuranceSubmission,
                AuditingEntityAutocomplete
              >({
                collectionDatakey: 'insurees',
                dataHook: useAuditingEntities,
                labelMapper: autocompleteLabelMapper,
              }),
          },
        ]}
        name="insurees"
        showRadioCond={() => mode === DetailMode.NEW}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabled={mode !== DetailMode.NEW}
      />
    </FormPanel>
  );
}
