import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailActionButton,
  DetailContext,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { Payment } from '@models';
import { PaymentState } from '@enums';
import { useCreatePaymentDialog } from './dialog-actions/create-payment/create-payment-hook';

export function ActionBar() {
  const { source } = useContext(DetailContext);
  const { showCreatePaymentBtn, ...createPaymentProps } =
    useCreatePaymentDialog();

  return (
    <>
      {showCreatePaymentBtn && (
        <DetailActionButton
          promptKey="CREATE_PAYMENT"
          buttonLabel="Generovať platbu"
          dialogText={null}
          dialogTitle="Platba"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          formInitialValues={
            {
              id: uuidv4(),
              charge: { id: source.data?.id },
              sendEmail: true,
              state: PaymentState.NEW,
              amount: source?.data?.amount,
              year: new Date().getFullYear(),
            } as Payment
          }
          dialogDisableBackdrop
          {...createPaymentProps}
        />
      )}
    </>
  );
}
