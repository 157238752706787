import React, { useContext, useEffect } from 'react';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  FormContext,
  FormHandle,
  FormPanel,
  FormTextArea,
  UserContext,
  abortableFetch,
  eqFilterParams,
  useEventCallback,
} from '@eas/common-web';
import {
  CostFields,
  InsuranceCompanyFields,
  InsuranceFields,
} from '@modules/insurance/insurance-fields';
import { useValidationSchema } from '@modules/insurance/insurance-schema';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { InsuranceSubmission } from '@models';
import { EvidenceApiUrl, InsuranceSubmissionState, Permission } from '@enums';
import { InsureesField } from '../insurance-submission-fields';

export function useProcessDialog() {
  const detailCtx =
    useContext<DetailHandle<InsuranceSubmission>>(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, { insurees, ...insurance }) =>
    abortableFetch(`${EvidenceApiUrl.INSURANCE_SUBMISSION}/${id}/process`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({ insurees, insurance }),
    })
  );

  const showBtn =
    detailCtx?.source?.data?.state !== InsuranceSubmissionState.PROCESSED &&
    hasPermission(Permission.BO_INSURANCE_SUBMISSION_MANAGE);

  const FormFields = () => {
    const { getFieldValues, setFieldValue } =
      useContext<FormHandle<InsuranceSubmission>>(FormContext);

    useEffect(() => {
      const { insuranceCompany } = getFieldValues();

      const loadInsuranceCompany = async () => {
        const reponse = await fetch(
          `${EvidenceApiUrl.DICT_INSURANCE_COMPANY}/list`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(
              eqFilterParams({
                field: 'identifier',
                value: insuranceCompany?.identifier,
              })
            ),
          }
        );

        const companies = await reponse.json();
        if (companies?.items?.length) {
          setFieldValue('insuranceCompany', companies.items[0]);
        } else {
          setFieldValue('insuranceCompany', undefined);
        }
      };

      loadInsuranceCompany();
    }, []);

    return (
      <DetailContext.Provider value={{ ...detailCtx, mode: DetailMode.NEW }}>
        <InsuranceFields />
        <CostFields />
        <InsuranceCompanyFields />
        <FormAddressPanel
          name="insuranceCompany.address"
          label="Adresa poisťovne"
          disabled
        />
        <InsureesField />
        <FormPanel label="Poznámka">
          <FormTextArea
            name="note"
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
          />
        </FormPanel>
      </DetailContext.Provider>
    );
  };

  const formValidationSchema = useValidationSchema();

  return {
    apiCall,
    showProcessBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
