import { SnackbarVariant } from '@eas/common-web';
import { MessageType } from './message-type';

const LOGIN: MessageType<'502' | '503' | 'ERROR'> = {
  502: [
    'ERROR 502: Proxy server obdržel od hlavního serveru neplatnou odpověď.',
    SnackbarVariant.ERROR,
  ],
  503: [
    'ERROR 503: Server dočasně nemůže zpracovat požadavek. Buď je přetížený nebo se provádí údržba.',
    SnackbarVariant.ERROR,
  ],
  ERROR: ['Chyba při přihlašování uživatele.', SnackbarVariant.ERROR],
};

const PASSWORD_RESET: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'USER_DETAILS_NOT_FOUND'
  | 'USERNAME_NOT_FOUND'
  | 'USER_WITHOUT_EMAIL'
> = {
  SUCCESS: [
    'E-mail s odkazem pro nastavení nového hesla byl úspěšně odeslán.',
    SnackbarVariant.SUCCESS,
  ],
  ERROR: ['Chyba při vytváření hesla.', SnackbarVariant.ERROR],
  USERNAME_NOT_FOUND: [
    'Chyba při vytváření hesla: Uživatelské jméno neexistuje. Zadejte prosím platné uživatelské jméno.',
    SnackbarVariant.ERROR,
  ],
  USER_DETAILS_NOT_FOUND: [
    'Chyba při vytváření hesla: Kombinace jména, příjmení a e-mailu neexistuje. Zadejte prosím platné údaje.',
    SnackbarVariant.ERROR,
  ],
  USER_WITHOUT_EMAIL: [
    'U tohoto uživatelského účtu není evidován žádný e-mail. Obnova hesla není možná. Proveďte novou registraci uživatele.',
    SnackbarVariant.ERROR,
  ],
};

const PASSWORD_CHANGE: MessageType<
  | 'SUCCESS'
  | 'ERROR'
  | 'PASSWORD_SAME_AS_PREVIOUS'
  | 'PASSWORD_DOES_NOT_MEET_REQUIREMENTS'
> = {
  SUCCESS: ['Heslo bolo úspešne zmenené.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba pri zmene hesla.', SnackbarVariant.ERROR],
  PASSWORD_SAME_AS_PREVIOUS: [
    'Heslo je rovnaké ako predchádzajúce.',
    SnackbarVariant.ERROR,
  ],
  PASSWORD_DOES_NOT_MEET_REQUIREMENTS: [
    'Heslo nespĺňa požiadavky.',
    SnackbarVariant.ERROR,
  ],
};

const CREATE: MessageType<'SUCCESS' | 'ERROR' | 'USER_EMAIL_ALREADY_TAKEN'> = {
  SUCCESS: ['Používateľ bol úspešne vytvorený.', SnackbarVariant.SUCCESS],
  ERROR: ['Chyba pri vytváraní používateľa.', SnackbarVariant.ERROR],
  USER_EMAIL_ALREADY_TAKEN: [
    'Užívateľ so zadaným e-mailom už bol vytvorený.',
    SnackbarVariant.ERROR,
  ],
};

export default { LOGIN, PASSWORD_RESET, PASSWORD_CHANGE, CREATE };
