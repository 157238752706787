/**
 * Stav žiadosti o skúšku
 */
export enum ExamApplicationState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Schválená úradníkom
   */
  APPROVED = 'APPROVED',

  /**
   * Čakajúca na platbu
   */
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',

  /**
   * Nepresná platba
   */
  INCORRECT_PAYMENT_AMOUNT = 'INCORRECT_PAYMENT_AMOUNT',

  /**
   * Pripravený na prihlásenie na skúšku
   */
  READY_FOR_EXAM_REGISTRATION = 'READY_FOR_EXAM_REGISTRATION',

  /**
   * Prihlásený na skúšku
   */
  REGISTERED_FOR_EXAM = 'REGISTERED_FOR_EXAM',

  /**
   * Uzavretá
   */
  CLOSED = 'CLOSED',
}

/**
 * Typ Žiadosť o skúšku
 */
export enum ExamApplicationType {
  /**
   * Žiadosť o audítorskú skúšku
   */
  AUDIT = 'AUDIT',

  /**
   * Žiadosť o skúšku spôsobilosti
   */
  CAPABILITY = 'CAPABILITY',

  /**
   * Žiadosť o preskúšanie
   */
  RETEST = 'RETEST',
}

/**
 * Typ časti opakovania
 */
export enum RetakePartType {
  /**
   * 1. čiastková skúška
   */
  FIRST_PARTIAL = 'FIRST_PARTIAL',

  /**
   * 2. čiastková skúška
   */
  SECOND_PARTIAL = 'SECOND_PARTIAL',

  /**
   * 3. čiastková skúška
   */
  THIRD_PARTIAL = 'THIRD_PARTIAL',
}
