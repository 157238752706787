import { noop, stubFalse, stubTrue } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  DetailContext,
  DetailHandle,
  EmptyComponent,
  FormContext,
  TableField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useSearchPersonColumns } from '@modules/natural-person/natural-person-columns';
import { NaturalPerson } from '@components/form/subject-field/natural-person-field';
import { AuditExamApplication, Subject, SubjectNatural } from '@models';
import {
  EvidenceApiUrl,
  ExamApplicationState,
  ExamApplicationType,
  Permission,
  PersonContactType,
  SubjectType,
} from '@enums';

const useStyles = makeStyles({
  wrapper: {
    borderBottom: '1px solid #cdcdcd',
    width: '100%',
    marginTop: '-15px',
    marginBottom: 10,
  },
  title: {
    marginTop: '-15px',
    marginBottom: 10,
  },
});

export function useApproveDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } =
    useContext<DetailHandle<AuditExamApplication>>(DetailContext);

  const classes = useStyles();

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.EXAM_APPLICATION}/${id}/approve`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_EXAM_APPLICATION_MANAGE) &&
    source?.data?.state === ExamApplicationState.NEW;

  const formValidationSchema = Yup.object().shape({
    subject: Yup.mixed<SubjectNatural>().nullable().required(),
  });

  const FormFields = () => {
    const { setFieldValue } = useContext(FormContext);
    const [persons, setPersons] = useState([]);

    const columns = useSearchPersonColumns();

    const { residence, ...personalInfo } =
      (source?.data as AuditExamApplication)?.personalInfo || {};

    useEffect(() => {
      const findSubject = async () => {
        const response = await fetch(
          `${EvidenceApiUrl.SUBJECT}/search-person`,
          {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              firstName: source?.data?.personalInfo?.firstName,
              lastName: source?.data?.personalInfo?.lastName,
              birthDate: source?.data?.personalInfo?.birthDate,
            }),
          }
        );

        const data = await response.json();
        setPersons(data.persons);
      };

      findSubject();
    }, []);

    return (
      <>
        {persons?.length > 0 && (
          <>
            <div className={classes.wrapper}>
              <Typography>
                V systéme boli nájdené tieto fyzické osoby s rovnakým menom
                alebo dátumom narodenia, ako má žiadateľ:
              </Typography>
              <TableField
                showToolbar={false}
                showRadioCond={stubTrue}
                showDetailBtnCond={stubFalse}
                onSelect={(row) => {
                  if (row) {
                    setFieldValue('subject', {
                      ...row,
                      label: row?.fullName,
                      type: SubjectType.NATURAL,
                    });
                  } else {
                    setFieldValue('subject', undefined);
                  }
                }}
                value={persons}
                columns={columns}
                onChange={noop}
              />
            </div>
          </>
        )}
        {persons?.length === 0 && (
          <Typography className={classes.title}>
            V systéme sa nepodarilo nájsť fyzickou osobu s uvedeným menom a
            dátumom narodenia, ako má žiadateľ.
          </Typography>
        )}
        <NaturalPerson
          name="subject"
          formInitialValues={
            {
              ...personalInfo,
              type: SubjectType.NATURAL,
              address: residence,
              contacts: [
                {
                  value: source?.data?.personalInfo?.email,
                  type: PersonContactType.MAIN_EMAIL,
                },
              ],
            } as Subject
          }
          onResult={async (value) => setFieldValue('subject', value)}
        />
      </>
    );
  };

  return {
    showApproveBtn: showBtn,
    apiCall,
    FormFields:
      source?.data?.type !== ExamApplicationType.RETEST
        ? FormFields
        : EmptyComponent,
    formValidationSchema,
    formInitialValues:
      source?.data?.type === ExamApplicationType.RETEST
        ? { subject: source?.data.subject }
        : undefined,
  };
}
