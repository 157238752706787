import { useContext } from 'react';
import { DetailToolbarBtnName, UserContext } from '@eas/common-web';
import { Me } from '@models';
import { Permission } from '@enums';

export function useEvidencePermission(permissions: Permission[]) {
  return function usePermissionIntern(button: DetailToolbarBtnName) {
    const { hasPermission } = useContext<UserContext<Me, string>>(UserContext);

    if (button === 'SHARE' || button === 'REFRESH') {
      return true;
    }
    return permissions.some((perm) => hasPermission(perm));
  };
}
