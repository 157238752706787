/**
 * Stav podnetu
 */
export enum EducationSubmissionState {
  /**
   * Nový
   */
  NEW = 'NEW',

  /**
   * Spracovaný
   */
  PROCESSED = 'PROCESSED',
}
