import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  FormTextArea,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, LicenceState, Permission } from '@enums';

export function useSuspendDialog() {
  const { source } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.LICENCE}/${id}/suspend`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    source?.data?.state === LicenceState.ACTIVE &&
    hasPermission(Permission.BO_LICENCE_MANAGE);

  const FormFields = () => <FormTextArea name="reason" label="Dôvod" />;

  const formValidationSchema = Yup.object().shape({
    reason: Yup.string().nullable(),
  });

  return {
    apiCall,
    showSuspendBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
