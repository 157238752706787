import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  EvidenceContext,
  FormPanel,
  RemoteTableField,
  TableFieldToolbarProps,
} from '@eas/common-web';
import { EMPLOYMENT_INVITATION_SOURCE } from '@modules/audit-company/audit-company-hooked-sources';
import { RemoteTableToolbar } from '@components/toolbar/remote-table-toolbar';
import { EvidenceApiUrl } from '@enums';
import {
  addEmploymentInvitationApiCall,
  deleteEmploymentInvitationApiCall,
} from './employment-invitation-api';
import { useColumns } from './employment-invitation-columns';
import { EmploymentInvitationDialog } from './employment-invitation-dialog';
import { useValidationSchema } from './employment-invitation-schema';

export function EmploymentInvitationField({ disabled }: { disabled: boolean }) {
  const detailCtx = useContext(DetailContext);
  const { hookedSources } = useContext(EvidenceContext);
  const source = hookedSources[EMPLOYMENT_INVITATION_SOURCE].source;

  const validationSchema = useValidationSchema();
  const columns = useColumns();

  const Toolbar = (props: TableFieldToolbarProps) => (
    <RemoteTableToolbar
      {...props}
      source={source}
      dialogTitle="Zamestnanie"
      validationSchema={validationSchema}
      Dialog={EmploymentInvitationDialog}
      addButtonProps={{
        promptKey: 'ADD_EMPLOYMENT_INVITATION',
        apiCall: addEmploymentInvitationApiCall,
      }}
      deleteButtonProps={{
        promptKey: 'DELETE_EMPLOYMENT_INVITATION',
        apiCall: deleteEmploymentInvitationApiCall,
      }}
      url={`${EvidenceApiUrl.AUDIT_COMPANY}/${detailCtx?.source?.data?.id}/invitations`}
    />
  );

  return (
    <FormPanel
      label="Pozvánky do spoločnosti"
      expandable={true}
      defaultExpanded={true}
    >
      <RemoteTableField
        source={source}
        columns={columns}
        showDetailBtnCond={stubFalse}
        ToolbarComponent={Toolbar}
        showToolbar={!disabled}
        disabled={disabled}
      />
    </FormPanel>
  );
}
