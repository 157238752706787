import { format } from 'date-fns';
import { stubFalse } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormContext,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextArea,
  FormTextField,
  TableFieldCells,
  TableFieldColumn,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useAuditingEntities, useAuditors } from '@modules/auditor/auditor-api';
import { useEducationFields } from '@modules/dict-education-field/dict-education-field-api';
import { useEducationForms } from '@modules/dict-education-form/dict-education-form-api';
import { useDocuments } from '@modules/document/document-api';
import { useEducationCycles } from '@modules/education-cycle/education-cycle-api';
import { useBackofficeUsers } from '@modules/user/user-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntity,
  AuditingEntityAutocomplete,
  AuditorAutocomplete,
  DictEducationFieldAutocomplete,
  DictEducationFormAutocomplete,
  Document,
  DocumentAutocomplete,
  EducatedAuditor,
  EducationCycleAutocomplete,
  EducationSubmission,
  UserAutocomplete,
} from '@models';
import {
  AuditingEntityType,
  EducationSubmissionState,
  EvidenceApiUrl,
  EvidenceBrowserUrl,
  Permission,
} from '@enums';
import { useEducatedAuditorSchema } from './education-submission-schema';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { educationSubmissionStates } = useContext(StaticDataContext);
  const { setFieldValue } = useContext(FormContext);
  const { hasPermission } = useContext(UserContext);

  const auditingEntities = useAuditingEntities({
    apiUrl: EvidenceApiUrl.AUDITING_ENTITY,
  });
  const backofficeUsers = useBackofficeUsers();
  const cycles = useEducationCycles();
  const forms = useEducationForms();
  const fields = useEducationFields();
  const documents = useDocuments();
  const stateSource = useStaticListSource(educationSubmissionStates);

  const { owner, cycle } = useFormSelector((data: EducationSubmission) => ({
    owner: data.owner,
    cycle: data.cycle,
  }));

  return (
    <>
      <FormPanel label="Obecné">
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditingEntities}
          name="owner"
          label="Vlastník podnetu"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW}
          notifyChange={() => {
            setFieldValue('educatedAuditors', undefined);
          }}
          after={
            owner?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect
                url={
                  owner?.type === AuditingEntityType.AUDITOR
                    ? EvidenceBrowserUrl.AUDITOR
                    : EvidenceBrowserUrl.AUDIT_COMPANY
                }
                id={owner.id}
              />
            )
          }
        />
        {mode !== DetailMode.NEW && (
          <FormSelect
            source={stateSource}
            name="state"
            label="Stav spracovania"
            valueIsId
            disabled
          />
        )}
        <FormTextField
          type="number"
          name="reportedYear"
          label="Rok nahlásenia"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormAutocomplete<EducationCycleAutocomplete>
          source={cycles}
          name="cycle"
          label="Vzdelávací cyklus"
          required
          disabled={mode !== DetailMode.NEW}
          labelMapper={autocompleteLabelMapper}
          after={
            cycle?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_EDUCATION_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.EDUCATION_CYCLE}
                id={cycle.id}
              />
            )
          }
        />
        <FormTextField
          name="trainingName"
          label="Názov školenia"
          required
          disabled={mode !== DetailMode.NEW}
        />
        <FormAutocomplete<DictEducationFormAutocomplete>
          source={forms}
          name="form"
          label="Forma vzdelávania"
          required
          disabled={mode !== DetailMode.NEW}
          labelMapper={autocompleteLabelMapper}
        />
        <FormAutocomplete<DictEducationFieldAutocomplete>
          source={fields}
          name="field"
          label="Zameranie vzdelávania"
          required
          disabled={mode !== DetailMode.NEW}
          labelMapper={autocompleteLabelMapper}
        />
        <FormLocalDateField name="date" label="Dátum vzdelávania" required />
      </FormPanel>
      <EducatedAuditors disabled={mode !== DetailMode.NEW} owner={owner} />
      <FormPanel label="Prílohy">
        {mode === DetailMode.NEW && (
          <FormAutocomplete<DocumentAutocomplete>
            source={documents}
            name="attachments"
            labelOptions={{ hide: true }}
            multiline
            multiple
            labelMapper={autocompleteLabelMapper}
            disabled={mode !== DetailMode.NEW}
          />
        )}
        {mode !== DetailMode.NEW && (
          <FormTableField
            name="attachments"
            labelOptions={{ hide: true }}
            layoutOptions={{ noSpacing: true }}
            showDetailBtnCond={stubFalse}
            showRadioCond={stubFalse}
            showToolbar={false}
            columns={[
              ...(hasPermission(Permission.BO_DOCUMENT_READ)
                ? [
                    {
                      datakey: 'id',
                      name: '',
                      width: 70,
                      CellComponent: function Cell({ value }) {
                        return (
                          <Redirect
                            id={value}
                            url={EvidenceBrowserUrl.DOCUMENT}
                          />
                        );
                      },
                    } as TableFieldColumn<Document>,
                  ]
                : []),
              {
                datakey: 'name',
                name: 'Názov',
                width: 500,
              },
            ]}
          />
        )}
      </FormPanel>
      <FormPanel label="Riešitelia">
        <FormAutocomplete<UserAutocomplete>
          source={backofficeUsers}
          name="solvers"
          labelOptions={{ hide: true }}
          multiline
          multiple
          labelMapper={autocompleteLabelMapper}
        />
      </FormPanel>
      <FormPanel label="Poznámka z Portálu">
        <FormTextArea
          name="portalNote"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          disabled
        />
      </FormPanel>
    </>
  );
}

export function EducatedAuditors({
  disabled,
  disabledPersonalFields,
  disabledProposedHours = false,
  showNote,
  owner,
}: {
  disabled?: boolean;
  disabledPersonalFields?: boolean;
  disabledProposedHours?: boolean;
  showAuditorAutocomplete?: boolean;
  showNote?: boolean;
  owner?: AuditingEntity;
}) {
  const { source } = useContext(DetailContext);

  const { educatedAuditors } = useFormSelector((data: EducationSubmission) => ({
    educatedAuditors: data.educatedAuditors ?? [],
  }));

  const educatedAuditorSchema = useEducatedAuditorSchema(
    owner?.type === AuditingEntityType.AUDIT_COMPANY,
    disabledProposedHours
  );

  const educatedAuditorColumns = useMemo(
    () => [
      { datakey: 'auditor.label', name: 'Audítor', width: 200 },
      {
        datakey: 'auditor.slovakLicence.number',
        name: 'Číslo licencie',
        width: 150,
      },
      {
        datakey: 'proposedTrainedHours',
        name: 'Počet navrhnutých hodín školenia',
        width: 150,
      },
      ...(disabledProposedHours ||
      source?.data?.state === EducationSubmissionState.PROCESSED
        ? [
            {
              datakey: 'approvedTrainedHours',
              name: 'Počet schválených hodín školenia',
              width: 150,
            },
          ]
        : []),
      {
        datakey: 'employedFrom',
        name: 'Dátum od kedy je audítor zamestnaný',
        width: 150,
        CellComponent: TableFieldCells.DateCell,
      },
      {
        datakey: 'employedTo',
        name: 'Dátum do kedy je audítor zamestnaný',
        width: 150,
        CellComponent: TableFieldCells.DateCell,
      },
    ],
    [source?.data, disabledProposedHours]
  );

  const FormFields = () => {
    const auditors = useAuditors();

    return (
      <>
        <FormAutocomplete<AuditorAutocomplete>
          source={auditors}
          name="auditor"
          label="Audítor"
          labelMapper={autocompleteLabelMapper}
          required
          disabled={
            disabledPersonalFields || owner?.type === AuditingEntityType.AUDITOR
          }
        />
        <FormTextField
          name="auditor.subject.firstName"
          label="Meno"
          required
          disabled
        />
        <FormTextField
          name="auditor.subject.lastName"
          label="Priezvisko"
          required
          disabled
        />
        <FormTextField
          name="auditor.slovakLicence.number"
          label="Číslo licencie"
          required
          disabled
        />
        <FormTextField
          type="number"
          name="proposedTrainedHours"
          label="Počet navrhnutých hodín školenia"
          required
          disabled={disabledProposedHours}
        />
        {(disabledProposedHours ||
          source?.data?.state === EducationSubmissionState.PROCESSED) && (
          <FormTextField
            type="number"
            name="approvedTrainedHours"
            label="Počet schválených hodín školenia"
            required
          />
        )}
        {owner?.type === AuditingEntityType.AUDIT_COMPANY && (
          <>
            <FormLocalDateField
              name="employedFrom"
              label="Dátum od kedy je audítor zamestnaný"
              required
              maxDatePicker={format(new Date(), 'yyyy-MM-dd')}
            />
            <FormLocalDateField
              name="employedTo"
              label="Dátum do kedy bol audítor zamestnaný"
              maxDatePicker={format(new Date(), 'yyyy-MM-dd')}
            />
          </>
        )}
        {showNote && <FormTextArea name="note" label="Poznámka" />}
      </>
    );
  };

  return (
    <FormPanel label="Vzdelávaní audítori">
      <FormTableField<EducatedAuditor>
        name="educatedAuditors"
        columns={educatedAuditorColumns}
        disabled={disabled || !owner}
        validationSchema={educatedAuditorSchema}
        FormFieldsComponent={FormFields}
        required
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        disabledAdd={
          owner?.type === AuditingEntityType.AUDITOR &&
          educatedAuditors?.length > 0
            ? true
            : false
        }
        initNewItem={() =>
          owner?.type === AuditingEntityType.AUDITOR
            ? { id: uuidv4(), auditor: owner }
            : {}
        }
      />
    </FormPanel>
  );
}
