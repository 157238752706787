import React from 'react';
import { FormPanel, FormTableField, TableFieldCells } from '@eas/common-web';
import { useColumns } from '@components/form/address-panel/address-columns';
import { OfficeDialog } from './office-dialog';
import { useValidationSchema } from './office-schema';

interface OfficeFieldProps {
  label?: string;
  name?: string;
}

export function OfficeField({
  label = 'Adresa každej kancelárie Audit. spol. v členskom štáte',
  name = 'offices',
}: OfficeFieldProps) {
  const validationSchema = useValidationSchema();

  const addressColumns = useColumns('address');

  return (
    <FormPanel label={label} expandable={true} defaultExpanded={true}>
      <FormTableField
        name={name}
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
        columns={[
          ...addressColumns,
          {
            datakey: 'position',
            name: 'Popis',
            width: 250,
          },
          {
            datakey: 'created',
            name: 'Vytvorený',
            width: 150,
            CellComponent: TableFieldCells.DateTimeCell,
          },
          {
            datakey: 'updated',
            name: 'Zmenený',
            width: 150,
            CellComponent: TableFieldCells.DateTimeCell,
          },
        ]}
        FormFieldsComponent={OfficeDialog}
        validationSchema={validationSchema}
      />
    </FormPanel>
  );
}
