import React, { useContext } from 'react';
import { UserContext } from '@eas/common-web';
import { Tabs } from '@composite/tabs/tabs';
import { TabsConfig } from '@composite/tabs/tabs-types';
import { Permission } from '@enums';
import { AddressFields } from './fields/addresses';
import { CertificateAndLicenceFields } from './fields/certificates-and-licences';
import { DisciplinaryRecordFields } from './fields/disciplinary-records';
import { EmploymentFields } from './fields/employments';
import { GeneralFields } from './fields/general';
import { InsuranceFields } from './fields/insurances';

export function Fields() {
  const { hasPermission } = useContext(UserContext);

  const config: TabsConfig = [
    {
      label: 'Obecné',
      key: 'GENERAL',
      validationKeys: ['subject', 'examPassedDate', 'recordDate'],
      content: <GeneralFields />,
    },
    {
      label: 'Certifikáty a licencie',
      key: 'CERTIFICATES_AND_LICENCES',
      content: <CertificateAndLicenceFields />,
    },
    {
      label: 'Adresy',
      key: 'ADDRESSES',
      content: <AddressFields />,
    },
    {
      label: 'Zamestnania',
      key: 'EMPLOYMENTS',
      content: <EmploymentFields />,
    },
    {
      label: 'Disciplinárné opatrenia',
      key: 'DISCIPLINARY_RECORDS',
      content: <DisciplinaryRecordFields />,
    },
  ];

  if (hasPermission(Permission.BO_INSURANCE_READ)) {
    config.push({
      label: 'Poistenia',
      key: 'INSURANCES',
      content: <InsuranceFields />,
    });
  }

  return <Tabs config={config} />;
}
