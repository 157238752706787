import { noop } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormCustomField,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  TextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { RegistryRecord } from '@models';
import { EvidenceBrowserUrl, Permission, RegistryRecordSource } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);
  const { registryRecordSources, registryRecordStates, registryRecordTypes } =
    useContext(StaticDataContext);

  const sourceSource = useStaticListSource(registryRecordSources);
  const stateSource = useStaticListSource(registryRecordStates);
  const typeSource = useStaticListSource(registryRecordTypes);

  const { reference, source } = useFormSelector((data: RegistryRecord) => ({
    reference: data.reference,
    source: data.source,
  }));

  const referenceUrlAndPermission = getReferenceUrlAndPermission(source);

  return (
    <>
      <FormPanel label="Registratúrny záznam">
        <FormTextField name="name" label="Názov záznamu" required />
        <FormTextArea name="content" label="Obsah záznamu" />
        <FormTextField name="emailAddressee" label="E-mail adresáta" />
        {mode !== DetailMode.VIEW && (
          <FormSelect
            source={sourceSource}
            name="source"
            label="Pôvod záznamu"
            valueIsId
            required
          />
        )}
        {reference && mode === DetailMode.VIEW && (
          <FormCustomField
            name="reference"
            label="Pôvod záznamu"
            after={
              reference?.id &&
              referenceUrlAndPermission &&
              mode === DetailMode.VIEW &&
              hasPermission(referenceUrlAndPermission.permission) && (
                <Redirect
                  url={referenceUrlAndPermission.url}
                  id={reference.id}
                />
              )
            }
          >
            <TextField
              value={`${
                registryRecordSources.find((s) => s.id === source)?.name
              } - ${reference.name}`}
              onChange={noop}
              disabled
            />
          </FormCustomField>
        )}
        {mode === DetailMode.VIEW && (
          <>
            <FormSelect
              source={stateSource}
              name="state"
              label="Stav záznamu"
              valueIsId
              disabled
            />
            <FormSelect
              source={typeSource}
              name="type"
              label="Typ záznamu"
              valueIsId
              disabled
            />
          </>
        )}
      </FormPanel>
      <FormPanel label="Dáta z registratúry">
        <FormTextField
          name="registryDetails.number"
          label="Číslo evidencie z registraturného systému"
        />
        <FormLocalDateField
          name="registryDetails.registrationDate"
          label="Dátum zápisu do registraturného systému"
        />
      </FormPanel>
    </>
  );
}

const getReferenceUrlAndPermission = (source?: RegistryRecordSource) => {
  switch (source) {
    case RegistryRecordSource.PAYMENT:
      return {
        url: EvidenceBrowserUrl.PAYMENT,
        permission: Permission.BO_PAYMENT_READ,
      };
    case RegistryRecordSource.GENERAL_SUBMISSION_CREATED:
    case RegistryRecordSource.GENERAL_SUBMISSION_REPLY:
      return {
        url: EvidenceBrowserUrl.GENERAL_SUBMISSION,
        permission: Permission.BO_GENERAL_SUBMISSION_READ,
      };
    case RegistryRecordSource.ANONYMOUS_SUBMISSION_CREATED:
    case RegistryRecordSource.ANONYMOUS_SUBMISSION_REPLY:
      return {
        url: EvidenceBrowserUrl.ANONYMOUS_SUBMISSION,
        permission: Permission.BO_ANONYMOUS_SUBMISSION_READ,
      };
    case RegistryRecordSource.FORGOTTEN_PASSWORD:
    case RegistryRecordSource.ACCOUNT_CREATED:
    case RegistryRecordSource.PASSWORD_CHANGED:
      return {
        url: EvidenceBrowserUrl.USER,
        permission: Permission.BO_USER_ROLE_READ,
      };
    default:
      return undefined;
  }
};
