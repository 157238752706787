import React, { useContext, useRef } from 'react';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailActionButton,
  DetailContext,
  DetailMode,
  DictionaryAutocomplete,
  FieldFilter,
  FormDictionaryField,
  abortableFetch,
  useEventCallback,
  useScrollableSource,
} from '@eas/common-web';
import {
  AddressFields,
  GeneralFields,
  NoteField,
} from '@modules/natural-person/natural-person-fields';
import { useValidationSchema } from '@modules/natural-person/natural-person-schema';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { ContactField } from '@composite/contacts/contact-field';
import { SubjectAutocomplete, SubjectNatural } from '@models';
import { EvidenceApiUrl, SubjectType } from '@enums';
import { autocompleteLabelMapper } from '../misc/label-mappers';

interface Props {
  formInitialValues: SubjectNatural;
  onResult: (value: SubjectNatural) => Promise<void>;
  name: string;
}

export function NaturalPerson({ formInitialValues, onResult, name }: Props) {
  const detailCtx = useContext(DetailContext);
  const createRef = useRef<ActionButtonHandle>(null);
  const validationSchema = useValidationSchema();

  const subjects = useNaturalPersons();
  const subjectSource = useScrollableSource({
    url: `${EvidenceApiUrl.SUBJECT}/list`,
  });

  const addSubjectApiCall = useEventCallback((_, formData) =>
    abortableFetch(EvidenceApiUrl.SUBJECT, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  return (
    <>
      <DetailActionButton
        ref={createRef}
        promptKey="ADD_NATURAL_PERSON"
        apiCall={addSubjectApiCall}
        buttonLabel="Pridať subjekt"
        dialogTitle="Fyzická osoba"
        dialogText="Po vyplnení a potvrdení formulára bude vytvorená nová fyzická osoba"
        FormFields={() => (
          <DetailContext.Provider
            value={{ ...detailCtx, mode: DetailMode.NEW }}
          >
            <GeneralFields />
            <AddressFields />
            <NoteField />
            <ContactField />
          </DetailContext.Provider>
        )}
        modes={[]}
        refreshAfterConfirm={false}
        dialogWidth={1000}
        dialogDisableBackdrop
        formInitialValues={formInitialValues}
        onResult={onResult}
        formValidationSchema={validationSchema}
      />
      <FormDictionaryField<SubjectAutocomplete>
        name={name}
        required={true}
        dialogPreFilters={[
          {
            field: 'type.id',
            operation: ApiFilterOperation.EQ,
            value: SubjectType.NATURAL,
          },
        ]}
        label="Fyzická osoba"
        filters={[
          {
            datakey: 'firstName',
            filterkey: 'firstName',
            label: 'Meno',
            FilterComponent: FieldFilter.FilterTextCell,
          },
          {
            datakey: 'lastName',
            filterkey: 'lastName',
            label: 'Priezvisko',
            FilterComponent: FieldFilter.FilterTextCell,
          },
        ]}
        columns={[
          {
            datakey: 'fullName',
            name: 'Meno a priezvisko',
            width: 300,
          },
          {
            datakey: 'address.label',
            name: 'Trvalý pobyt osoby',
            width: 500,
          },
        ]}
        autoFocus={true}
        autocompleteSource={subjects}
        dialogSource={subjectSource}
        labelMapper={subjectsLabelMapper}
        maxRows={9}
        dialogWidth={1000}
        onCreate={() => createRef.current?.executeAction()}
      />
    </>
  );
}

const subjectsLabelMapper = (
  value?: DictionaryAutocomplete | DictionaryAutocomplete[] | null
) =>
  Array.isArray(value)
    ? value.map((i) => autocompleteLabelMapper(i)).join(',')
    : autocompleteLabelMapper(value);
