/**
 * Stav licencie
 */
export enum LicenceState {
  /**
   * Aktívna
   */
  ACTIVE = 'ACTIVE',

  /**
   * Pozastavená
   */
  SUSPENDED = 'SUSPENDED',

  /**
   * Odobratá
   */
  STOPPED = 'STOPPED',

  /**
   * Zaniknutá
   */
  EXPIRED = 'EXPIRED',
}

/**
 * Typ licencie
 */
export enum LicenceType {
  /**
   * Klasicka
   */
  STANDARD = 'STANDARD',

  /**
   * Esg
   */
  ESG = 'ESG',
}
