import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Evidence } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { ExamApplication } from '@models';
import {
  EvidenceApiUrl,
  ExamApplicationType,
  ExportTag,
  Permission,
} from '@enums';
import { ActionBar } from './exam-application-actionbar';
import { useColumns } from './exam-application-columns';
import { Fields } from './exam-application-fields';
import { useValidationSchema } from './exam-application-schema';

export function ExamApplications() {
  const validationSchema = useValidationSchema();
  const columns = useColumns();
  const permissions = useEvidencePermission([
    Permission.BO_EXAM_APPLICATION_MANAGE,
  ]);

  const evidence = useAuthoredEvidence<ExamApplication>({
    version: 2,
    identifier: 'EXAM_APPLICATION',
    apiProps: {
      url: EvidenceApiUrl.EXAM_APPLICATION,
    },
    tableProps: {
      columns,
      tableName: 'Žiadosti o skúšku',
      showBulkActionButton: false,
      reportTag: ExportTag.EXAM_APPLICATION,
    },
    detailProps: {
      toolbarProps: {
        title: 'Žiadosť o skúšku',
        ActionBar,
        showBtn: permissions,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), type: ExamApplicationType.RETEST }),
    },
  });

  return <Evidence {...evidence} />;
}
