import * as Yup from 'yup';
import { useAddressValidationSchema } from '@components/form/address-panel/address-schema';
import {
  AuditRegistration,
  AuditRegistrationCompany,
  AuditingEntity,
} from '@models';
import { AuditRegistrationCompanyType, AuditRegistrationState } from '@enums';

export function useValidationSchema() {
  return Yup.object<AuditRegistration>().shape({
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    state: Yup.mixed<AuditRegistrationState>().nullable().required(),
    year: Yup.number().nullable().required(),
  });
}

export function useAuditRegistrationCompanySchema() {
  const validateAddress = useAddressValidationSchema();

  return Yup.object<AuditRegistrationCompany>().shape({
    name: Yup.string().nullable().required(),
    identifier: Yup.string().nullable().required(),
    type: Yup.mixed<AuditRegistrationCompanyType>().nullable().required(),
    certifiedDate: Yup.string().nullable().required(),
    address: validateAddress,
  });
}
