/**
 * Stav podnetu
 */
export enum AnonymousSubmissionState {
  /**
   * Nový
   */
  NEW = 'NEW',
  /**
   * Spracovaný
   */
  PROCESSED = 'PROCESSED',
}
