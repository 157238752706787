import { noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Card from '@material-ui/core/Card/Card';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  DictionaryObject,
  EmptyComponent,
  NotificationContext,
  TableField,
  fetchNotificationPreferences,
} from '@eas/common-web';
import { useNotificationColumns } from './notifications-columns';
import { NotificationsFooter } from './notifications-footer';

const useStyles = makeStyles({
  card: {
    marginTop: 20,
    width: '100%',
    margin: '0 0 24px',
    padding: '10px 0',
  },
  grid: {
    overflow: 'auto',
    backgroundColor: '#fff',
    marginBottom: 20,
  },
  content: {
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 15,
  },
});

export function Notifications() {
  const classes = useStyles();
  const { notificationPreferenceUrl, notificationEventUrl } =
    useContext(NotificationContext);

  const [editing, setEditing] = useState(false);

  const [notificationPreferences, setNotificationPreferences] = useState<
    any[] | undefined
  >(undefined);

  const notificationColumns = useNotificationColumns(
    editing,
    setNotificationPreferences
  );

  useEffect(() => {
    const loadNotifications = async () => {
      const notificationPreferences = await fetchNotificationPreferences(
        notificationPreferenceUrl
      ).json();

      const response = await fetch(`${notificationEventUrl}/list`, {
        method: 'GET',
      });

      const notificationEvents = await response.json();

      const preferences = Object.entries(notificationPreferences).map(
        ([key, value]) => ({
          event: notificationEvents?.find(
            (event: DictionaryObject) => event.id === key
          ),
          type: value,
          defaultType: value,
        })
      );

      setNotificationPreferences(preferences);
    };

    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetDefaultValues = () => {
    setNotificationPreferences((prev) =>
      prev?.map((preference) => ({
        ...preference,
        type: preference.defaultType,
      }))
    );
  };

  return (
    <>
      <Card className={classes.card}>
        <Typography className={classes.title}>
          Nastavenie notifikácií
        </Typography>
        <TableField
          value={notificationPreferences}
          ToolbarComponent={EmptyComponent}
          classes={classes}
          columns={notificationColumns}
          showRadioCond={() => false}
          showDetailBtnCond={() => false}
          onChange={noop}
          maxRows={15}
        />
        <NotificationsFooter
          editing={editing}
          setEditing={setEditing}
          resetDefaultValues={resetDefaultValues}
          notificationPreferences={notificationPreferences}
        />
      </Card>
    </>
  );
}
