import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormTextField,
  useFormSelector,
} from '@eas/common-web';
import { AuditReport } from '@models';

export function OverallAuditProfitFields() {
  const { mode } = useContext(DetailContext);

  const { financialStatements } = useFormSelector((data: AuditReport) => ({
    financialStatements: data.financialStatements ?? [],
  }));

  const isSVZpresent = (financialStatements ?? []).some(
    (s) => s.publicInterest
  );

  return (
    <>
      <FormTextField
        name="overallAuditProfits.statutoryAudit"
        label="Tržby za uisťovacie služby - štatutárny audit"
        required={isSVZpresent}
        disabled={mode !== DetailMode.NEW || !isSVZpresent}
      />
      <FormTextField
        name="overallAuditProfits.otherAssuranceServices"
        label="Tržby za uisťovacie služby - ostatné"
        required={isSVZpresent}
        disabled={mode !== DetailMode.NEW || !isSVZpresent}
      />
      <FormTextField
        name="overallAuditProfits.taxServices"
        label="Tržby za neaudítorské služby - daňové služby"
        required={isSVZpresent}
        disabled={mode !== DetailMode.NEW || !isSVZpresent}
      />
      <FormTextField
        name="overallAuditProfits.otherNonAuditServices"
        label="Tržby za neaudítorské služby - ostatné"
        required={isSVZpresent}
        disabled={mode !== DetailMode.NEW || !isSVZpresent}
      />
    </>
  );
}
