import { abortableFetch } from '@eas/common-web';

/**
 * Function to log user into secure app.
 *
 * @param username
 * @param password
 */
export function loginUser(username: string, password: string) {
  const formData = new FormData();

  formData.append('username', username);
  formData.append('password', password);

  return abortableFetch('/api/udva/auth/backoffice', {
    method: 'POST',
    body: formData,
  });
}
