import {
  DictionaryObject,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { Permission, RoleAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useRoles(params?: Params) {
  return useAutocompleteSource<RoleAutocomplete>({
    url: `${EvidenceApiUrl.ROLE}/autocomplete`,
    params,
  });
}

export function usePermissions() {
  return useFetch<Permission[]>(`${EvidenceApiUrl.ROLE}/permissions/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}

export function usePermissionGroups() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.ROLE}/permissions/groups/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function useRoleTags() {
  return useFetch<DictionaryObject[]>(`${EvidenceApiUrl.ROLE}/tags/list`, {
    method: 'GET',
    cacheResponse: true,
  });
}
