import { getErrorMessage } from '@utils/error-message';
import React, { useContext, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import {
  AbortableFetch,
  EvidenceContext,
  FormAutocomplete,
  FormContext,
  FormLocalDateField,
  FormTextField,
  SnackbarContext,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useAuditors } from '@modules/auditor/auditor-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntity,
  AuditingEntityAutocomplete,
  ErrorObject,
} from '@models';
import { EvidenceApiUrl, Messages, Permission } from '@enums';

export function useGenerateNextDialog() {
  const { hasPermission } = useContext(UserContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { tableRef } = useContext(EvidenceContext);

  const fetch = useRef<AbortableFetch | null>(null);

  const peekApiCall = useEventCallback(() =>
    abortableFetch(`${EvidenceApiUrl.CERTIFICATION}/peek-next`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'GET',
    })
  );

  const generateApiCall = useEventCallback((formData) => {
    return abortableFetch(`${EvidenceApiUrl.CERTIFICATION}/generate-next`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify({ subjectId: formData.subject.id }),
    });
  });

  const handleSubmit = useEventCallback(async (values) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = generateApiCall(values);
      await fetch.current.json();

      showSnackbar(...Messages.Common.SUCCESS);
      tableRef.current?.refresh();
    } catch (err) {
      const message = getErrorMessage(
        err as ErrorObject<string>,
        Messages.Certification.GENERATE_NEXT
      );

      if ((err as Error).name !== 'AbortError') {
        showSnackbar(...message);
      }

      throw err;
    }
  });

  const showBtn = hasPermission(Permission.BO_CERTIFICATION_MANAGE);

  const FormFields = () => {
    const { setFieldValues } = useContext(FormContext);

    useEffect(() => {
      const peekNext = async () => {
        const response = await peekApiCall().json();
        setFieldValues(response);
      };

      peekNext();
    }, []);

    const auditors = useAuditors();

    return (
      <>
        <FormAutocomplete<AuditingEntityAutocomplete>
          source={auditors}
          name="subject"
          label="Subjekt"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField name="number" label="Číslo certifikátu" disabled />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" disabled />
        <FormTextField
          name="origin.country.name"
          label="Krajina pôvodu"
          disabled
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          disabled
        />
      </>
    );
  };

  const formValidationSchema = Yup.object().shape({
    subject: Yup.mixed<AuditingEntity>().nullable().required(),
  });

  return {
    showGenerateNextBtn: showBtn,
    FormFields,
    submitCallback: handleSubmit,
    formValidationSchema,
  };
}
