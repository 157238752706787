import { DictionaryAutocomplete, useStaticListSource } from '@eas/common-web';
import { ExportTag } from '@enums';

export function useExportTags() {
  return useStaticListSource<DictionaryAutocomplete>([
    { id: ExportTag.AUDITOR, name: 'Audítori' },
    { id: ExportTag.AUDIT_COMPANY, name: 'Audítorské spoločnost' },
    { id: ExportTag.INSURANCE, name: 'Poistenia' },
    { id: ExportTag.SUBJECT, name: 'Subjekty' },
    { id: ExportTag.FIN_STATEMENT_AUDIT, name: 'Audity účtovných závierok' },
    { id: ExportTag.GENERAL_SUBMISSION, name: 'Všeobecné podnety' },
    { id: ExportTag.ANONYMOUS_SUBMISSION, name: 'Anonymné podnety' },
    { id: ExportTag.EXAM_APPLICATION, name: 'Žiadosti o skúšku' },
    { id: ExportTag.DOCUMENT, name: 'Dokumenty' },
    { id: ExportTag.USER, name: 'Používatelia' },
    { id: ExportTag.ROLE, name: 'Role' },
    { id: ExportTag.CURRENCY, name: 'Finančné meny' },
    { id: ExportTag.COUNTRY, name: 'Krajiny' },
    { id: ExportTag.INSURANCE_COMPANIES, name: 'Poisťovne' },
    { id: ExportTag.CERTIFICATION, name: 'Certifikáty' },
    { id: ExportTag.LICENCE, name: 'Licencie' },
    { id: ExportTag.QUALIFICATION, name: 'Odbornosti' },
  ]);
}
