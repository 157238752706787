import {
  ApiFilterOperation,
  DictionaryObject,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { AuditingEntityAutocomplete, AuditorAutocomplete } from '@models';
import { AuditingEntityType, EvidenceApiUrl } from '@enums';

export function useAuditors(props?: {
  params?: Params;
  apiUrl?: EvidenceApiUrl;
}) {
  return useAutocompleteSource<AuditorAutocomplete>({
    url: `${EvidenceApiUrl.AUDITING_ENTITY}/autocomplete`,
    apiUrl: props?.apiUrl,
    params: {
      ...props?.params,
      filters: [
        ...(props?.params?.filters ?? []),
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: AuditingEntityType.AUDITOR,
        },
      ],
    },
  });
}

export function useCompanyAuditors(companyId?: string, params?: Params) {
  return useAutocompleteSource<AuditorAutocomplete>({
    url: `${EvidenceApiUrl.AUDIT_COMPANY}/${companyId}/employments/auditors/autocomplete`,
    params,
  });
}

export function useAuditingEntities(props?: {
  params?: Params;
  apiUrl?: EvidenceApiUrl;
}) {
  return useAutocompleteSource<AuditingEntityAutocomplete>({
    url: `${EvidenceApiUrl.AUDITING_ENTITY}/autocomplete`,
    ...props,
  });
}

export function useAuditingEntityTypes() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.AUDITING_ENTITY}/types/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
