import { isAfter } from 'date-fns';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DetailContext,
  DetailHandle,
  FormAutocomplete,
  FormLocalDateField,
  FormTextArea,
  FormTextField,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  CurrencyAmount,
  DictMonetaryCurrency,
  DictMonetaryCurrencyAutocomplete,
  Payment,
} from '@models';
import { EvidenceApiUrl, PaymentState, Permission } from '@enums';

export function useReceiveDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<Payment>>(DetailContext);

  const apiCall = useEventCallback((id: string, formData) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT}/${id}/receive`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn =
    hasPermission(Permission.BO_PAYMENT_MANAGE) &&
    source?.data?.state === PaymentState.NEW;

  const FormFields = () => {
    const currencies = useMonetaryCurrencies();

    return (
      <>
        <FormLocalDateField
          name="receivedDate"
          label="Dátum prijatia platby"
          maxDatePicker={new Date().toISOString()}
          required
        />
        <FormTextField
          type="number"
          name="receivedAmount.amount"
          label="Suma"
          required
        />
        <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
          source={currencies}
          name="receivedAmount.currency"
          label="Mena"
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextArea
          name="note"
          label="Poznámka"
          layoutOptions={{ noSpacing: true }}
        />
      </>
    );
  };

  const formValidationSchema = Yup.object().shape({
    receivedDate: Yup.date()
      .nullable()
      .test('', '', function (date) {
        const { createError, path } = this;

        if (date && isAfter(date, new Date())) {
          return createError({ path, message: 'Nesmie byť v budúcnosti' });
        }

        return true;
      })
      .required(),
    receivedAmount: Yup.object().shape({
      amount: Yup.number()
        .nullable()
        .min(1, 'Musí byť kladné číslo')
        .required(),
      currency: Yup.mixed<DictMonetaryCurrency>().nullable().required(),
    }),
  });

  const formInitialValues = {
    receivedAmount: source.data?.amount as CurrencyAmount,
  } as any;

  return {
    apiCall,
    showReceiveBtn: showBtn,
    FormFields,
    formValidationSchema,
    formInitialValues,
  };
}
