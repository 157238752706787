import React, { useContext } from 'react';
import {
  FormAutocomplete,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  useStaticListSource,
} from '@eas/common-web';
import { useMonetaryCurrencies } from '@modules/dict-monetary-currency/dict-monetary-currency-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { DictMonetaryCurrencyAutocomplete } from '@models';

export function Fields() {
  const {
    paymentEconomicClassifications,
    paymentMainSpecifications,
    paymentCategories,
    paymentAdditionalSpecifications,
  } = useContext(StaticDataContext);

  const currencies = useMonetaryCurrencies();
  const categorySource = useStaticListSource(paymentCategories);
  const classificationSource = useStaticListSource(
    paymentEconomicClassifications
  );
  const additionalSpecificationSource = useStaticListSource(
    paymentAdditionalSpecifications
  );
  const mainSpecificationSource = useStaticListSource(
    paymentMainSpecifications
  );

  return (
    <>
      <FormPanel label="Poplatok">
        <FormTextField name="name" label="Názov" required />
        <FormSelect
          source={classificationSource}
          name="classification"
          label="Ekonomická klasifikácia"
          valueIsId
          required
        />
        <FormSelect
          source={categorySource}
          name="category"
          label="Kategória podľa §68 ods. 1 zákona"
          valueIsId
          required
        />
        <FormSelect
          source={mainSpecificationSource}
          name="mainSpecification"
          label="Hlavná špecifikácia"
          valueIsId
          required
        />
        <FormSelect
          source={additionalSpecificationSource}
          name="additionalSpecification"
          label="Pomocná špecifikácia"
          valueIsId
          required
        />
      </FormPanel>
      <FormPanel label="Stanovená suma">
        <FormTextField name="amount.amount" label="Suma" type="number" />
        <FormAutocomplete<DictMonetaryCurrencyAutocomplete>
          source={currencies}
          name="amount.currency"
          label="Mena"
          labelMapper={autocompleteLabelMapper}
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
