import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useConstructUserDialog } from './dialog-actions/construct-user-hook';
import { useSuccessDialog } from './dialog-actions/success-hook';

export function ActionBar() {
  const { showSuccessBtn, ...successProps } = useSuccessDialog();
  const { showConstructUserBtn, ...constructUserProps } =
    useConstructUserDialog();

  return (
    <>
      {showSuccessBtn && (
        <DetailActionButton
          promptKey="SUCCESS"
          buttonLabel="Úspešne ukončiť"
          dialogText="Naozaj si prajete označiť skúšku za úspešne ukončenú?"
          dialogTitle="Úspešné ukončenie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={700}
          dialogDisableBackdrop
          {...successProps}
        />
      )}
      {showConstructUserBtn && (
        <DetailActionButton
          promptKey="CONSTRUCT_USER_DRY"
          buttonLabel="Vytvoriť používateľa"
          dialogTitle="Vytvorenie používateľa"
          dialogText="Skutočne chcete vytvoriť používateľa?"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          dialogWidth={800}
          {...constructUserProps}
        />
      )}
    </>
  );
}
