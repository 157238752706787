import React, { useContext } from 'react';
import {
  FormLocalDateField,
  FormPanel,
  FormTextField,
  UserContext,
} from '@eas/common-web';
import { ContactField } from '@composite/contacts/contact-field';
import { EmploymentField } from '@composite/employments/employment-field';
import { StakeholderField } from '@composite/stakeholders/stakeholder-field';
import { FormAddressPanel } from '@components/form/address-panel/address-panel';
import { Permission } from '@enums';

export function Fields() {
  const { hasPermission } = useContext(UserContext);

  return (
    <>
      <GeneralFields />
      <AddressFields />
      {hasPermission(Permission.BO_STAKEHOLDER_READ) && (
        <StakeholderField
          label="Zoznam rolí"
          disabled={true}
          display="IN_SUBJECT"
        />
      )}
      {hasPermission(Permission.BO_EMPLOYMENT_READ) && (
        <EmploymentField display="IN_SUBJECT" disabled />
      )}
      <ContactField />
      <NoteField />
    </>
  );
}

export function GeneralFields() {
  return (
    <FormPanel label="Obecné">
      <FormTextField name="titleBefore" label="Titul pred menom" />
      <FormTextField name="firstName" label="Meno" required />
      <FormTextField name="lastName" label="Priezvisko" required />
      <FormTextField name="titleAfter" label="Titul za menom" />
      <FormTextField name="birthPlace" label="Miesto narodenia" />
      <FormLocalDateField name="birthDate" label="Dátum narodenia" />
    </FormPanel>
  );
}

export function AddressFields() {
  return (
    <>
      <FormAddressPanel name="address" label="Trvalý pobyt" required />
      <FormAddressPanel name="temporaryResidence" label="Prechodný pobyt" />
      <FormAddressPanel name="contactAddress" label="Kontaktná adresa" />
    </>
  );
}

export function NoteField() {
  return (
    <FormPanel label="Poznámka k fyz. osobe">
      <FormTextField
        name="note"
        labelOptions={{ hide: true }}
        layoutOptions={{ noSpacing: true }}
      />
    </FormPanel>
  );
}
