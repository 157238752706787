import * as Yup from 'yup';
import { Charge } from '@models';
import {
  EconomicClassification,
  PaymentAdditionalSpecification,
  PaymentCategory,
  PaymentMainSpecification,
} from '@enums';

export function useValidationSchema() {
  return Yup.object<Charge>().shape({
    name: Yup.string().nullable().required(),
    classification: Yup.mixed<EconomicClassification>().nullable().required(),
    category: Yup.mixed<PaymentCategory>().nullable().required(),
    mainSpecification: Yup.mixed<PaymentMainSpecification>()
      .nullable()
      .required(),
    additionalSpecification: Yup.mixed<PaymentAdditionalSpecification>()
      .nullable()
      .required(),
  });
}
