import React, { useContext } from 'react';
import {
  FormCheckbox,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { useValidationSchema } from '@modules/payment/payment-schema';
import { EvidenceApiUrl, Permission } from '@enums';
import { FormFields } from './create-payment-dialog';

export function useCreatePaymentDialog() {
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string, { sendEmail, ...formData }) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT}?sendEmail=${!!sendEmail}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn = hasPermission(Permission.BO_PAYMENT_MANAGE);
  const formValidationSchema = useValidationSchema();

  return {
    apiCall,
    showCreatePaymentBtn: showBtn,
    FormFields: () => (
      <>
        <FormCheckbox name="sendEmail" label="Odoslať email" />
        <FormFields showCharge={false} />
      </>
    ),
    formValidationSchema,
  };
}
