import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { DictionaryEvidence, EmptyComponent, Evidence } from '@eas/common-web';
import { DictInsuranceBrokerage } from '@models';
import { EvidenceApiUrl, Permission } from '@enums';
import { Fields } from './dict-insurange-brokerage-fields';

export function DictInsuranceBrokerages() {
  const permissions = useEvidencePermission([
    Permission.BO_INSURANCE_BROKERAGE_MANAGE,
  ]);

  const evidence =
    DictionaryEvidence.useDictionaryEvidence<DictInsuranceBrokerage>({
      identifier: 'DICT_INSURANCE_BROKERAGE',
      apiProps: {
        url: EvidenceApiUrl.DICT_INSURANCE_BROKERAGE,
      },
      tableProps: {
        tableName: 'Sprostredkovanie poistenia',
        showBulkActionButton: false,
        showReportButton: false,
      },
      detailProps: {
        FieldsComponent: Fields,
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          title: 'Sprostredkovanie poistenia',
          showBtn: permissions,
        },
      },
    });

  return <Evidence {...evidence} />;
}
