import { useContext } from 'react';
import {
  DetailContext,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { EvidenceApiUrl, Permission, QualificationState } from '@enums';

export function useDeclineDialog() {
  const { source } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.QUALIFICATION}/${id}/decline`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    })
  );

  const showBtn =
    source?.data?.state === QualificationState.FOR_APPROVAL &&
    hasPermission(Permission.BO_QUALIFICATION_MANAGE);

  return {
    apiCall,
    showDeclineBtn: showBtn,
  };
}
