import { abortableFetch } from '@eas/common-web';
import { PasswordChangeRequest } from '@models';
import { EvidenceApiUrl } from '@enums';

export function changePassword(dto: PasswordChangeRequest) {
  return abortableFetch(`${EvidenceApiUrl.MY_ACCOUNT}/change-password`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    method: 'POST',
    body: JSON.stringify(dto),
  });
}
