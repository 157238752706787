import React, { useContext } from 'react';
import {
  AuthoredEvidence,
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { useLegalPersons } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditCompany,
  DictCountryAutocomplete,
  SubjectAutocomplete,
} from '@models';
import { EvidenceApiUrl, EvidenceBrowserUrl, Permission } from '@enums';

interface Props {
  showAuthoredFields?: boolean;
  prefix?: string;
  showRecordDate?: boolean;
  disabledSubject?: boolean;
}

export function GeneralFields({
  showAuthoredFields = true,
  showRecordDate = true,
  prefix,
  disabledSubject = false,
}: Props) {
  const { legalForms } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const legalFormSource = useStaticListSource(legalForms);
  const countries = useCountries();

  const legalSubjects = useLegalPersons(EvidenceApiUrl.SUBJECT);

  const path = prefix ? `${prefix}.` : '';

  const { subject } = useFormSelector((data: AuditCompany) => ({
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Audítorská spoločnosť">
        <FormAutocomplete<SubjectAutocomplete>
          label="Obchodné meno"
          name={`${path}subject`}
          source={legalSubjects}
          labelMapper={autocompleteLabelMapper}
          required
          disabled={mode !== DetailMode.NEW || disabledSubject}
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect id={subject.id} url={EvidenceBrowserUrl.LEGAL_PERSON} />
            )
          }
        />
        <FormTextField name={`${path}contactPerson`} label="Kontaktná osoba" />
        <FormTextField name={`${path}website`} label="Internetová adresa" />
        <FormSelect
          source={legalFormSource}
          name={`${path}legalForm`}
          label="Právná forma"
          valueIsId
          required
        />
        {showRecordDate && (
          <FormLocalDateField
            name={`${path}recordDate`}
            label="Dátum zápisu do zoznamu audítorských spoločností"
            required
          />
        )}
        <FormAutocomplete<DictCountryAutocomplete>
          name={`${path}homeCountry`}
          label="Domovská krajina"
          source={countries}
          labelMapper={autocompleteLabelMapper}
          required
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox
            name={`${path}foreignHomeCountry`}
            label="AS zapísaná podľa § 5 ods. 2"
            disabled
          />
        )}
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea name={`${path}note`} labelOptions={{ hide: true }} />
      </FormPanel>
      {showAuthoredFields && (
        <FormPanel label="Obecné">
          <AuthoredEvidence.AuthoredFields />
        </FormPanel>
      )}
    </>
  );
}
