import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { Insurance, InsuranceSubmission } from '@models';
import { useProcessDialog } from './dialog-actions/process-hook';

export function ActionBar() {
  const { source } =
    useContext<DetailHandle<InsuranceSubmission>>(DetailContext);
  const { showProcessBtn, ...processProps } = useProcessDialog();

  const { portalNote, ...insurance } = source.data || {};

  return (
    <>
      {showProcessBtn && (
        <DetailActionButton
          promptKey="PROCESS"
          buttonLabel="Spracovať"
          dialogText={null}
          dialogTitle="Poistenie"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={1000}
          dialogDisableBackdrop
          formInitialValues={
            {
              ...insurance,
              note: portalNote,
            } as Insurance
          }
          {...processProps}
        />
      )}
    </>
  );
}
