/**
 * Stav vybavenia žiadosti
 */
export enum LicenceApplicationState {
  /**
   * Nová
   */
  NEW = 'NEW',

  /**
   * Schválená úradníkom
   */
  APPROVED = 'APPROVED',

  /**
   * Čakajúca na platbu
   */
  WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',

  /**
   * Zaplatená
   */
  PAID = 'PAID',

  /**
   * Nepresná platba
   */
  INCORRECT_PAYMENT_AMOUNT = 'INCORRECT_PAYMENT_AMOUNT',

  /**
   * Platnosť vypršala
   */
  EXPIRED = 'EXPIRED',

  /**
   * Uzavretá
   */
  CLOSED = 'CLOSED',
}

/**
 * Typ žiadosti
 */
export enum LicenceApplicationType {
  /**
   * Žiadosť pre zápis Audítora
   */
  AUDITOR = 'AUDITOR',

  /**
   * Žiadosť pre zápis Audítorskej spoločnosti
   */
  AUDIT_COMPANY = 'AUDIT_COMPANY',
}
