import React, { useContext } from 'react';
import {
  FormCustomField,
  FormPanel,
  InternalSettingsContext,
  TextField,
} from '@eas/common-web';

interface InternalAddressProps {
  label: string;
  defaultExpanded?: boolean;
  code: string;
}

export function InternalAddress({
  label,
  defaultExpanded = true,
  code,
}: InternalAddressProps) {
  const { settingGroups } = useContext(InternalSettingsContext);

  const settings = settingGroups.find((group) => group.code === code)?.settings;

  return (
    <FormPanel
      label={label}
      expandable={true}
      defaultExpanded={defaultExpanded}
    >
      {settings?.map((setting) => (
        <FormCustomField key={setting.code} label={setting.name}>
          <TextField value={setting.value} disabled />
        </FormCustomField>
      ))}
    </FormPanel>
  );
}
