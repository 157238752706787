/**
 * Ekonomická klasifikácia platby
 */
export enum EconomicClassification {
  /**
   * 222003
   */
  CLASSIFICATION_222003 = 'CLASSIFICATION_222003',

  /**
   * 223001
   */
  CLASSIFICATION_223001 = 'CLASSIFICATION_223001',

  /**
   * 292027
   */
  CLASSIFICATION_292027 = 'CLASSIFICATION_292027',
}
