import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormLocalDateField,
  FormPanel,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
} from '@eas/common-web';
import { useAuditors } from '@modules/auditor/auditor-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { Redirect } from '@composite/redirect/redirect';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import {
  AuditingEntityAutocomplete,
  Certification,
  DictCountryAutocomplete,
} from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function Fields() {
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const auditors = useAuditors();
  const countries = useCountries();

  const { subject } = useFormSelector((data: Certification) => ({
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Certifikát">
        <FormTextField
          type="number"
          name="number"
          label="Číslo certifikátu"
          required
        />
        <FormAutocomplete<AuditingEntityAutocomplete>
          name="subject"
          label="Certifikovaný subjekt"
          source={auditors}
          labelMapper={autocompleteLabelMapper}
          required
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_AUDITING_ENTITY_READ) && (
              <Redirect url={EvidenceBrowserUrl.AUDITOR} id={subject.id} />
            )
          }
        />
        <FormLocalDateField name="issuedDate" label="Dátum vydania" required />
      </FormPanel>
      <FormPanel label="Pôvod certifikátu">
        <FormAutocomplete<DictCountryAutocomplete>
          name="origin.country"
          label="Krajina pôvodu"
          source={countries}
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="origin.institution"
          label="Vydávajúci orgán"
          required
        />
      </FormPanel>
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}
