import { get } from 'lodash';
import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormCheckbox,
  FormContext,
  FormLocalDateField,
  useFormSelector,
} from '@eas/common-web';

interface Props {
  fromKey?: string;
  toKey?: string;
  fromLabel: string;
  toLabel: string;
  indefinitePeriodLabel: string;
  disabledFrom?: boolean;
  disabledTo?: boolean;
  requiredTo?: boolean;
}

export function ValidityFields({
  fromLabel,
  toLabel,
  indefinitePeriodLabel,
  fromKey = 'validFrom',
  toKey = 'validTo',
  disabledFrom = false,
  disabledTo = false,
  requiredTo = true,
}: Props) {
  const { mode } = useContext(DetailContext);
  const { setFieldValue } = useContext(FormContext);

  const { indefinitePeriod } = useFormSelector(
    (data: { indefinitePeriod?: boolean }) => ({
      indefinitePeriod: get(data, 'indefinitePeriod'),
    })
  );

  return (
    <>
      <FormLocalDateField
        name={fromKey}
        label={fromLabel}
        required={requiredTo}
        disabled={disabledFrom}
      />
      {(mode !== DetailMode.VIEW ||
        (mode === DetailMode.VIEW && !indefinitePeriod)) && (
        <FormLocalDateField
          name={toKey}
          label={toLabel}
          disabled={!!indefinitePeriod || disabledTo}
        />
      )}
      {(mode !== DetailMode.VIEW ||
        (mode === DetailMode.VIEW && !!indefinitePeriod)) && (
        <FormCheckbox
          name="indefinitePeriod"
          label={indefinitePeriodLabel}
          notifyChange={() => setFieldValue(toKey, null)}
          disabled={disabledTo}
        />
      )}
    </>
  );
}
