import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormCheckbox,
  FormLocalDateField,
  FormPanel,
  FormSelect,
  FormTextArea,
  FormTextField,
  UserContext,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { Redirect } from '@composite/redirect/redirect';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Exam, SubjectAutocomplete } from '@models';
import { EvidenceBrowserUrl, ExamType, Permission } from '@enums';

export function Fields() {
  const { examTypes } = useContext(StaticDataContext);
  const { mode } = useContext(DetailContext);
  const { hasPermission } = useContext(UserContext);

  const subjects = useNaturalPersons();
  const typeSource = useStaticListSource(examTypes);

  const { type, subject } = useFormSelector((data: Exam) => ({
    type: data.type,
    subject: data.subject,
  }));

  return (
    <>
      <FormPanel label="Skúška">
        <FormSelect
          source={typeSource}
          name="type"
          label="Typ skúšky"
          valueIsId
          disabled={mode !== DetailMode.NEW}
          required
        />
        <FormAutocomplete<SubjectAutocomplete>
          source={subjects}
          name="subject"
          label="Fyzická osoba vykonávajúca skúšku"
          labelMapper={autocompleteLabelMapper}
          disabled={mode !== DetailMode.NEW}
          required
          after={
            subject?.id &&
            mode === DetailMode.VIEW &&
            hasPermission(Permission.BO_SUBJECT_READ) && (
              <Redirect
                url={EvidenceBrowserUrl.NATURAL_PERSON}
                id={subject.id}
              />
            )
          }
        />
        <FormLocalDateField
          name="passingDate"
          label="Dátum úspešného zloženia skúšky"
          disabled
        />
        <FormTextField name="partialRating" label="Čiastočné hodnotenie" />
        <FormTextField name="totalRating" label="Celkové hodnotenie" />
        <FormTextField
          name="partialResult"
          label="Čiastočný výsledok"
          type="number"
        />
        <FormTextField
          name="totalResult"
          label="Celkový výsledok"
          type="number"
        />
        {mode === DetailMode.VIEW && (
          <FormCheckbox
            name="successfullyCompleted"
            label="Skúška úspešne ukončená"
            disabled
          />
        )}
      </FormPanel>
      {type === ExamType.AUDIT && <AuditExamFields />}
      {type === ExamType.CAPABILITY && <CapabilityExamFields />}
      {type === ExamType.RETEST && <RetestExamFields />}
      <FormPanel label="Poznámka">
        <FormTextArea
          name="note"
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
        />
      </FormPanel>
    </>
  );
}

function AuditExamFields() {
  return (
    <>
      <FormPanel label="Účtovnícka časť skúšky">
        <ExamPartResultFields prefix="accountExamPartResult" />
      </FormPanel>
      <FormPanel label="Auditorská časť skúšky">
        <ExamPartResultFields prefix="auditExamPartResult" />
      </FormPanel>
      <FormPanel label="Ekonomická časť skúšky">
        <ExamPartResultFields prefix="economyExamPartResult" />
      </FormPanel>
    </>
  );
}

function CapabilityExamFields() {
  return (
    <>
      <FormPanel label="Účtovnícka časť skúšky">
        <ExamPartResultFields prefix="accountExamPartResult" />
      </FormPanel>
      <FormPanel label="Právnická časť skúšky">
        <ExamPartResultFields prefix="lawExamPartResult" />
      </FormPanel>
    </>
  );
}

function RetestExamFields() {
  return (
    <>
      <FormPanel label="Účtovnícka časť skúšky">
        <ExamPartResultFields prefix="accountExamPartResult" />
      </FormPanel>
      <FormPanel label="Auditorská časť skúšky">
        <ExamPartResultFields prefix="auditExamPartResult" />
      </FormPanel>
      <FormPanel label="Ekonomická časť skúšky">
        <ExamPartResultFields prefix="economyExamPartResult" />
      </FormPanel>
    </>
  );
}

function ExamPartResultFields({ prefix }: { prefix: string }) {
  const { mode } = useContext(DetailContext);

  return (
    <>
      <FormLocalDateField name={`${prefix}.eventDate`} label="Dátum konania" />
      <FormLocalDateField
        name={`${prefix}.passingDate`}
        label="Dátum zloženia"
      />
      <FormTextField
        name={`${prefix}.percent`}
        label="Percentá"
        type="number"
      />
      <FormTextField name={`${prefix}.result`} label="Výsledok" type="number" />
      {mode === DetailMode.VIEW && (
        <FormTextField name={`${prefix}.score`} label="Skóre" disabled />
      )}
    </>
  );
}
