import React from 'react';
import {
  DetailActionButton,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { useProcessDialog } from './dialog-actions/process-hook';

export function ActionBar() {
  const { showProcessBtn, ...processProps } = useProcessDialog();

  return (
    <>
      {showProcessBtn && (
        <DetailActionButton
          promptKey="PROCESS"
          buttonLabel="Spracovať podnet"
          dialogText="Naozaj chcete zmeniť stav na spracovaný?"
          dialogTitle="Spracovanie podnetu"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          dialogDisableBackdrop
          {...processProps}
        />
      )}
    </>
  );
}
