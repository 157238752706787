import * as Yup from 'yup';
import { AuditNotice, AuditingEntity } from '@models';
import { AuditNoticeState, AuditNoticeType } from '@enums';

export function useValidationSchema() {
  return Yup.object<AuditNotice>().shape({
    owner: Yup.mixed<AuditingEntity>().nullable().required(),
    state: Yup.mixed<AuditNoticeState>().nullable().required(),
    type: Yup.mixed<AuditNoticeType>().nullable().required(),
    dateFrom: Yup.string().nullable().required(),
    dateTo: Yup.string()
      .nullable()
      .when('indefinitePeriod', {
        is: (indefinitePeriod) => indefinitePeriod !== true,
        then: Yup.string()
          .nullable()
          .required(
            'Musí byť vyplnený dátum konca platnosti alebo vybraný koniec na dobu neurčitú'
          ),
      }),
  });
}
