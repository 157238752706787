import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { AnonymousSubmission } from '@models';

export function useColumns(): TableColumn<AnonymousSubmission>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<AnonymousSubmission>();

  const { anonymousSubmissionStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(anonymousSubmissionStates);

  return [
    {
      datakey: 'matter',
      name: 'Vec podnetu',
      width: 250,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'processedDate',
      name: 'Dátum spracovania',
      width: 200,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'email',
      name: 'E-mail',
      width: 200,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
