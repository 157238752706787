import React, { useContext } from 'react';
import {
  TableFieldCells,
  TableFieldColumn,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import {
  DictEmploymentPositionAutocomplete,
  EmploymentInvitation,
} from '@models';

export function useColumns(): TableFieldColumn<EmploymentInvitation>[] {
  const { employmentInvitationStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(employmentInvitationStates);

  return [
    {
      datakey: 'state',
      sortkey: 'state.name',
      name: 'Stav',
      width: 250,
      CellComponent: TableFieldCells.useSelectCellFactory(useStates),
    },
    {
      datakey: 'auditor.label',
      name: 'Zamestnanec',
      width: 250,
    },
    {
      datakey: 'positions',
      name: 'Popis pozície',
      width: 400,
      CellComponent: ({ value, ...props }) => (
        <TableFieldCells.TextCell
          {...props}
          value={value
            .map((p: DictEmploymentPositionAutocomplete) => p?.label)
            .join(', ')}
        />
      ),
    },
  ];
}
