export enum EvidenceBrowserUrl {
  HOME = '/udva',
  ALOG = '/udva/auditny-log',
  AUDITOR = '/udva/auditor',
  AUDIT_COMPANY = '/udva/auditorska-spolocnost',
  AUDIT_NOTICE = '/udva/auditorska-cinnost',
  AUDIT_REGISTRATION = '/udva/registracia-vykonavania-auditu',
  AUDIT_REPORT = '/udva/oznamenie-o-auditorskej-cinnosti',
  CALENDAR_EVENT = '/udva/udalost-v-kalendari',
  CERTIFICATION = '/udva/certifikat',
  CHARGE = '/udva/poplatok',
  DICT_COUNTRY = '/udva/krajina',
  DICT_EDUCATION_FIELD = '/udva/oblast-vzdelavania',
  DICT_EDUCATION_FORM = '/udva/forma-vzdelavania',
  DICT_EMPLOYMENT_POSITION = '/udva/popis-pozicie',
  DICT_INSURANCE_BROKERAGE = '/udva/sprostredkovanie-poistenia',
  DICT_INSURANCE_COMPANY = '/udva/poistovna',
  DICT_MONETARY_CURRENCY = '/udva/financna-mena',
  DOCUMENT = '/udva/dokument',
  EDUCATION = '/udva/vzdelavanie',
  EDUCATION_CYCLE = '/udva/vzdelavaci-cyklus',
  EDUCATION_OVERVIEW = '/udva/prehlad-vzdelavania',
  EDUCATION_SUBMISSION = '/udva/ohlasenie-vzdelavania',
  EDUCATIONAL_TRAINING = '/udva/skolenie',
  EXAM_APPLICATION = '/udva/ziadost-o-skusku',
  EXAM = '/udva/skuska',
  FINANCIAL_STATEMENT_AUDIT = '/udva/audit-uctovnej-zavierky',
  GENERAL_SUBMISSION = '/udva/vseobecny-podnet',
  ANONYMOUS_SUBMISSION = '/udva/anonymny-podnet',
  INSURANCE = '/udva/poistenie',
  INSURANCE_SUBMISSION = '/udva/nahlasenie-poistenia',
  INTERNAL_SETTING = '/udva/nastavenia-aplikacie',
  LICENCE = '/udva/licencia',
  LICENCE_APPLICATION = '/udva/ziadost-o-zapips-na-zoznam-sa-as',
  NOTIFICATION = '/udva/notifikacia',
  NOTIFICATION_TEMPLATE = '/udva/sablona-notifikacie',
  PASSWORD_RESET = '/obnova-hesla',
  PAYMENT = '/udva/platba',
  PAYMENT_PARSE = '/udva/parovanie-platby',
  PROFILE = '/udva/profil',
  QUALIFICATION = '/udva/odbornost',
  ROLE = '/udva/rola',
  REGISTRY_RECORD = '/udva/registraturny-zaznam',
  NATURAL_PERSON = '/udva/fyzicka-osoba',
  LEGAL_PERSON = '/udva/pravnicka-osoba',
  USER = '/udva/pouzivatel',
}

export enum EvidenceApiUrl {
  ALOG = '/api/udva/alog',
  AUDITOR = '/api/udva/backoffice/auditing-entities/auditors',
  AUDIT_COMPANY = '/api/udva/backoffice/auditing-entities/audit-companies',
  AUDIT_NOTICE = '/api/udva/backoffice/audit-notices',
  AUDIT_REGISTRATION = '/api/udva/backoffice/audit-registrations',
  AUDIT_REPORT = '/api/udva/backoffice/audit-reports',
  AUDITING_ENTITY = '/api/udva/backoffice/auditing-entities',
  CALENDAR_EVENT = '/api/udva/backoffice/calendar-events',
  CERTIFICATION = '/api/udva/backoffice/certifications',
  CHARGE = '/api/udva/backoffice/charges',
  DICT_COUNTRY = '/api/udva/backoffice/countries',
  DICT_EDUCATION_FIELD = '/api/udva/backoffice/educations/fields',
  DICT_EDUCATION_FORM = '/api/udva/backoffice/educations/forms',
  DICT_EMPLOYMENT_POSITION = '/api/udva/backoffice/audit-companies/employments/positions',
  DICT_INSURANCE_BROKERAGE = '/api/udva/backoffice/insurance-brokerages',
  DICT_INSURANCE_COMPANY = '/api/udva/backoffice/insurance-companies',
  DICT_MONETARY_CURRENCY = '/api/udva/backoffice/monetary-currencies',
  DISCIPLINARY_RECORD = '/api/udva/backoffice/auditing-entities/disciplinary/add',
  DOCUMENT = '/api/udva/backoffice/documents',
  EDUCATION = '/api/udva/backoffice/educations',
  EDUCATION_CYCLE = '/api/udva/backoffice/educations/cycles',
  EDUCATION_SUBMISSION = '/api/udva/backoffice/education-submissions',
  EDUCATIONAL_TRAINING = '/api/udva/backoffice/educations/trainings',
  EXAM_APPLICATION = '/api/udva/backoffice/exams/applications',
  EXAM = '/api/udva/backoffice/exams',
  FINANCIAL_STATEMENT_AUDIT = '/api/udva/backoffice/financial-statement-audits',
  GENERAL_SUBMISSION = '/api/udva/backoffice/general-submissions',
  ANONYMOUS_SUBMISSION = '/api/udva/backoffice/anonymous-submissions',
  INSURANCE = '/api/udva/backoffice/insurances',
  INSURANCE_SUBMISSION = '/api/udva/backoffice/insurance-submissions',
  INTEGRATION = '/api/udva/integrations',
  INTERNAL_SETTING = '/api/udva/settings',
  LICENCE = '/api/udva/backoffice/licences',
  LICENCE_APPLICATION = '/api/udva/backoffice/licence-applications',
  MY_ACCOUNT = '/api/udva/account',
  NOTIFICATION = '/api/udva/notification',
  NOTIFICATION_TEMPLATE = '/api/udva/notification/template',
  NOTIFICATION_EVENT = '/api/udva/notification/event',
  NOTIFICATION_PREFERENCE = '/api/udva/notification/preference',
  PASSWORD_RESET = '/api/udva/passwords/resets',
  PAYMENT = '/api/udva/backoffice/payments',
  PAYMENT_PARSE = '/api/udva/backoffice/payments/parse',
  PERSONAL_CONTACT = '/api/udva/backoffice/personal-contacts',
  QUALIFICATION = '/api/udva/backoffice/qualifications',
  REGISTRY_RECORD = '/api/udva/backoffice/registry-records',
  ROLE = '/api/udva/backoffice/roles',
  SUBJECT = '/api/udva/backoffice/subjects',
  USER = '/api/udva/backoffice/users',
}
