import { useContext } from 'react';
import {
  ApiFilterOperation,
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { useCharges } from '@modules/charge/charge-api';
import { useSubjects } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { ChargeAutocomplete, Payment, SubjectAutocomplete } from '@models';

export function useColumns(): TableColumn<Payment>[] {
  const { authoredColumns } = AuthoredEvidence.useAuthoredColumns<Payment>();

  const { paymentStates } = useContext(StaticDataContext);
  const useStates = () => useStaticListSource(paymentStates);

  return [
    {
      datakey: 'charge.label',
      sortkey: 'charge.name',
      filterkey: 'charge.id',
      name: 'Poplatok',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<ChargeAutocomplete>(
          useCharges,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'subject.label',
      sortkey: 'subject.name',
      filterkey: 'subject.id',
      name: 'Subjekt vykonávajúci platbu',
      width: 300,
      CellComponent: TableCells.TextCell,
      FilterComponent:
        TableFilterCells.useFilterAutocompleteCellFactory<SubjectAutocomplete>(
          useSubjects,
          autocompleteLabelMapper
        ),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'emailSent',
      name: 'Platba odoslaná na email',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'vs',
      name: 'Variabilný symbol',
      width: 150,
      CellComponent: TableCells.NumberCell,
      FilterComponent: TableFilterCells.FilterNumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'year',
      name: 'Platba na rok',
      width: 150,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'state',
      sortkey: 'state.name',
      filterkey: 'state.id',
      name: 'Stav',
      width: 150,
      valueMapper: TableCells.useSelectCellFactory(useStates),
      CellComponent: TableCells.TextCell,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useStates),
      filterOperation: ApiFilterOperation.EQ,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'dueDate',
      name: 'Dátum splatnosti',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'receivedDate',
      name: 'Dátum prijatia platby',
      width: 150,
      CellComponent: TableCells.DateCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    {
      datakey: 'overdue',
      name: 'Po splatnosti',
      width: 150,
      CellComponent: TableCells.BooleanCell,
      sortable: true,
      filterable: true,
      filterGroup: 2,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 3 })),
  ];
}
