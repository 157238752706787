/**
 * Stav oznámenia o auditorskej činnosti
 */
export enum AuditReportState {
  /**
   * Nové
   */
  NEW = 'NEW',

  /**
   * Spracované
   */
  PROCESSED = 'PROCESSED',
}
