import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  ApiFilterOperation,
  DetailContext,
  DetailHandle,
  FormAutocomplete,
  UserContext,
  abortableFetch,
  eqFilterParams,
  useEventCallback,
} from '@eas/common-web';
import { useNaturalPersons } from '@modules/subject/subject-api';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { Auditor, CalendarEvent, SubjectAutocomplete } from '@models';
import { CalendarEventType, EvidenceApiUrl, Permission } from '@enums';

export function useSendRemindersDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<CalendarEvent>>(DetailContext);

  const apiCall = useEventCallback((_, formData) =>
    abortableFetch(`${EvidenceApiUrl.CALENDAR_EVENT}/send-reminders`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const showBtn = hasPermission(Permission.BO_CALENDAR_EVENT_MANAGE);

  const getSubjectFilter = () => {
    switch (source?.data?.type) {
      case CalendarEventType.FOR_AUDITORS_WITHOUT_EMPLOYMENT:
        return {
          filters: [
            { field: 'employments', operation: ApiFilterOperation.IS_NULL },
          ],
        };

      case CalendarEventType.FOR_AUDIT_COMPANIES:
        return eqFilterParams({
          field: 'employments.companyCalendarAccess',
          value: true,
        });

      case CalendarEventType.FOR_SPECIFIED_AUDITORS:
        return {
          filters: [
            {
              field: 'id',
              operation: ApiFilterOperation.IDS,
              ids: source?.data?.auditors?.map((a) => a.subject!.id),
            },
          ],
        };

      default:
        return undefined;
    }
  };

  const FormFields = () => {
    const subjects = useNaturalPersons({ params: getSubjectFilter() });

    return (
      <FormAutocomplete<SubjectAutocomplete>
        source={subjects}
        name="subjects"
        label="Vybraní audítori"
        required
        labelMapper={autocompleteLabelMapper}
        multiline
        multiple
      />
    );
  };

  const formValidationSchema = Yup.object().shape({
    subjects: Yup.array<Auditor>().nullable().required(),
    event: Yup.mixed<CalendarEvent>().nullable().required(),
  });

  return {
    apiCall,
    showSendRemindersBtn: showBtn,
    FormFields,
    formValidationSchema,
  };
}
