import React, { useContext } from 'react';
import {
  DetailContext,
  DetailMode,
  FormAutocomplete,
  FormSelect,
  FormTextArea,
  useStaticListSource,
} from '@eas/common-web';
import { useSubjects } from '@modules/subject/subject-api';
import { StaticDataContext } from '@components/evidence/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { SubjectAutocomplete } from '@models';

export function StakeholderDialog() {
  const { mode } = useContext(DetailContext);
  const { stakeholderTypes } = useContext(StaticDataContext);

  const subjects = useSubjects();
  const stakeholderTypeSource = useStaticListSource(stakeholderTypes);

  return (
    <>
      <FormAutocomplete<SubjectAutocomplete>
        source={subjects}
        label="Subjekt"
        name="subject"
        labelMapper={autocompleteLabelMapper}
        required
        disabled={mode !== DetailMode.NEW}
      />
      <FormSelect
        label="Typ zainteresovanej strany"
        name="types"
        source={stakeholderTypeSource}
        valueIsId
        multiple
        required
      />
      <FormTextArea label="Poznámka" name="note" />
    </>
  );
}
