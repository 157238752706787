import React, { useContext } from 'react';
import {
  AuthoredEvidence,
  TableCells,
  TableColumn,
  TableFieldCellProps,
  TableFieldCells,
  UserContext,
} from '@eas/common-web';
import { Redirect } from '@composite/redirect/redirect';
import { SubjectNatural } from '@models';
import { EvidenceBrowserUrl, Permission } from '@enums';

export function useColumns(): TableColumn<SubjectNatural>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<SubjectNatural>();

  return [
    {
      datakey: 'fullName',
      name: 'Meno a priezvisko',
      width: 300,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'address.label',
      name: 'Trvalý pobyt osoby',
      width: 500,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'firstName',
      name: 'Meno',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'lastName',
      name: 'Priezvisko',
      width: 150,
      CellComponent: TableCells.TextCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}

export function useSearchPersonColumns() {
  const { hasPermission } = useContext(UserContext);

  return [
    ...(hasPermission(Permission.BO_SUBJECT_READ)
      ? [
          {
            datakey: 'id',
            name: '',
            width: 70,
            CellComponent: function Cell({
              value,
            }: TableFieldCellProps<SubjectNatural>) {
              return (
                <Redirect id={value} url={EvidenceBrowserUrl.NATURAL_PERSON} />
              );
            },
          },
        ]
      : []),
    {
      datakey: 'fullName',
      name: 'Meno a priezvisko',
      width: 400,
    },
    {
      datakey: 'birthDate',
      name: 'Dátum narodenia',
      width: 250,
      CellComponent: TableFieldCells.DateCell,
    },
  ];
}
