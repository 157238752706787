import { AuthoredEvidence, TableCells, TableColumn } from '@eas/common-web';
import { EducationCycle } from '@models';

export function useColumns(): TableColumn<EducationCycle>[] {
  const { authoredColumns } =
    AuthoredEvidence.useAuthoredColumns<EducationCycle>();

  return [
    {
      datakey: 'fromYear',
      name: 'Začiatok cyklu (rok)',
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    {
      datakey: 'toYear',
      name: 'Koniec cyklu (rok)',
      width: 200,
      CellComponent: TableCells.NumberCell,
      sortable: true,
      filterable: true,
      filterGroup: 1,
    },
    ...authoredColumns.map((c) => ({ ...c, filterGroup: 2 })),
  ];
}
