import { useEvidencePermission } from '@utils/permission-hook';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ApiFilterOperation, Evidence } from '@eas/common-web';
import { useSubjectHookedSources } from '@modules/subject/subject-hooked-sources';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { SubjectLegal } from '@models';
import { EvidenceApiUrl, ExportTag, Permission, SubjectType } from '@enums';
import { useColumns } from './legal-person-columns';
import { Fields } from './legal-person-fields';
import { useValidationSchema } from './legal-person-schema';

export function LegalPersons() {
  const columns = useColumns();
  const validationSchema = useValidationSchema();
  const permissions = useEvidencePermission([Permission.BO_SUBJECT_MANAGE]);

  const evidence = useAuthoredEvidence<SubjectLegal>({
    version: 2,
    identifier: 'LEGAL_PERSON',
    apiProps: {
      url: EvidenceApiUrl.SUBJECT,
      hookedSources: useSubjectHookedSources,
    },
    tableProps: {
      columns,
      tableName: 'Právnické osoby',
      showBulkActionButton: false,
      reportTag: ExportTag.SUBJECT,
      defaultPreFilters: [
        {
          field: 'type.id',
          operation: ApiFilterOperation.EQ,
          value: SubjectType.LEGAL,
        },
      ],
    },
    detailProps: {
      toolbarProps: {
        title: 'Právnická osoba',
        showBtn: permissions,
      },
      FieldsComponent: Fields,
      validationSchema,
      initNewItem: () => ({ id: uuidv4(), type: SubjectType.LEGAL }),
    },
  });

  return <Evidence {...evidence} />;
}
