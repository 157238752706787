import { useEffect, useState } from 'react';
import { abortableFetch, useEventCallback } from '@eas/common-web';
import {
  EducationCycleAutocomplete,
  EducationOverviewResponseDto,
} from '@models';
import { EvidenceApiUrl } from '@enums';

export function useEducationOverview(props?: {
  cycle?: EducationCycleAutocomplete;
}) {
  const [params, setParams] = useState<EducationCycleAutocomplete | undefined>(
    props?.cycle
  );
  const [data, setData] = useState<EducationOverviewResponseDto>();

  const loadData = async (params?: EducationCycleAutocomplete) => {
    if (params) {
      const data: EducationOverviewResponseDto = await abortableFetch(
        `${EvidenceApiUrl.EDUCATION}/overview`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ cycle: params }),
        }
      ).json();

      setData({ ...data, overviewPerAuditors: parseData(data) });
    }
  };

  useEffect(() => {
    loadData(params);
  }, []);

  const handleSetParams = useEventCallback(
    (newParams: EducationCycleAutocomplete) => {
      setParams(newParams);
      loadData(newParams);
    }
  );

  return {
    data,
    params,
    setParams: handleSetParams,
    refresh: () => loadData(params),
  };
}

const parseData = (data: EducationOverviewResponseDto) => {
  return data.overviewPerAuditors?.map(({ hoursPerCriteria, ...rest }) => {
    const result: any = rest;

    for (const h of hoursPerCriteria!) {
      result[h.criteria!.name!] = `${h.hours} - ${
        h.fulfilled ? 'spĺňa' : 'nespĺňa'
      }`;
    }

    return result;
  });
};
