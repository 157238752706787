import {
  DictionaryObject,
  Params,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { DictCountryAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useCountries(props?: {
  params?: Params;
  apiUrl?: EvidenceApiUrl;
}) {
  return useAutocompleteSource<DictCountryAutocomplete>({
    url: `${EvidenceApiUrl.DICT_COUNTRY}/autocomplete`,
    ...props,
  });
}

export function useCountryTags() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.DICT_COUNTRY}/tags/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
