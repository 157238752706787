import React, { useContext, useRef } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  AbortableFetch,
  DetailContext,
  FormCheckbox,
  SnackbarContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import {
  CreatePaymentFieldsProps,
  FormFields,
} from '@modules/charge/dialog-actions/create-payment/create-payment-dialog';
import { useValidationSchema } from '@modules/payment/payment-schema';
import { EvidenceApiUrl, Messages } from '@enums';

export function useAddPayment(
  addPaymentApiCall: (id: string) => AbortableFetch
) {
  const { source } = useContext(DetailContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const fetch = useRef<AbortableFetch | null>(null);
  const formValidationSchema = useValidationSchema();

  const createPaymentApiCall = useEventCallback(({ sendEmail, ...formData }) =>
    abortableFetch(`${EvidenceApiUrl.PAYMENT}?sendEmail=${!!sendEmail}`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'POST',
      body: JSON.stringify(formData),
    })
  );

  const handleSubmit = useEventCallback(async (values) => {
    try {
      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = createPaymentApiCall(values);
      const payment = await fetch.current.json();

      if (fetch.current !== null) {
        fetch.current.abort();
      }

      fetch.current = addPaymentApiCall(payment.id);
      await fetch.current.json();
      unstable_batchedUpdates(() => {
        source?.refresh();
        showSnackbar(...Messages.Common.SUCCESS);
      });
    } catch (err) {
      showSnackbar(...Messages.Common.ERROR.UNEXPECTED);

      throw err;
    }
  });

  return {
    submitCallback: handleSubmit,
    formValidationSchema,
    FormFields: (props: CreatePaymentFieldsProps) => (
      <>
        <FormCheckbox name="sendEmail" label="Odoslať email" />
        <FormFields {...props} showSubject={false} />
      </>
    ),
  };
}
