import React from 'react';
import { EmptyComponent, Evidence, getItemFactory } from '@eas/common-web';
import { useAuthoredEvidence } from '@components/evidence/authored-evidence';
import { ParsedPaymentResultDto, PaymentParse } from '@models';
import { EvidenceApiUrl } from '@enums';
import { useParseXmlDialog } from './dialog-actions/parse-xml/parse-xml-hook';
import { useColumns } from './payment-parse-columns';
import { Fields } from './payment-parse-fields';

export function PaymentsParse() {
  const columns = useColumns();

  const { ParseXmlButton } = useParseXmlDialog();

  const evidence = useAuthoredEvidence<PaymentParse>({
    version: 2,
    identifier: 'PAYMENT_PARSE',
    apiProps: {
      url: `${EvidenceApiUrl.PAYMENT_PARSE}/logs`,
      getItem: getItemFactory({
        postProcess: (item: PaymentParse) => {
          const parsedData = item?.parsedData
            ? JSON.parse(item.parsedData)
            : {};

          return {
            ...item,
            payments: parsedData.payments.map((p: ParsedPaymentResultDto) => {
              const pairedPaymentId =
                item?.confirmedPaymentMap?.[p.rowIdentifier!];

              return {
                ...p,
                pairedPayment: item?.confirmedPayments?.find(
                  (p) => p.id === pairedPaymentId
                ),
              };
            }),
          };
        },
      }),
    },
    tableProps: {
      columns,
      tableName: 'Párovanie platieb',
      showBulkActionButton: false,
      showReportButton: false,
    },
    detailProps: {
      toolbarProps: {
        title: 'Párovanie',
        showBtn: (btn) => {
          return ['NEW', 'REFRESH', 'SHARE'].includes(btn);
        },
        NewButton: ParseXmlButton,
      },
      FieldsComponent: Fields,
      GeneralFieldsComponent: EmptyComponent,
    },
  });

  return <Evidence {...evidence} />;
}
