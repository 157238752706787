import React, { useContext } from 'react';
import {
  DetailActionButton,
  DetailContext,
  DetailHandle,
  PrimaryDetailActionbarButton,
} from '@eas/common-web';
import { AuditRegistration, Payment } from '@models';
import { PaymentState } from '@enums';
import { useAddPaymentDialog } from './dialog-actions/add-payment-hook';
import { useConfirmPaymentDialog } from './dialog-actions/confirm-payment-hook';

export function ActionBar() {
  const { source } = useContext<DetailHandle<AuditRegistration>>(DetailContext);

  const { showAddPaymentBtn, ...addPaymentProps } = useAddPaymentDialog();
  const { showConfirmPaymentBtn, ...confirmPaymentProps } =
    useConfirmPaymentDialog();

  return (
    <>
      {showConfirmPaymentBtn && (
        <DetailActionButton
          promptKey="CONFIRM_PAYMENT"
          buttonLabel="Potvrdiť platbu"
          dialogTitle="Potvrdenie platby"
          dialogText="Skutočne chcete potvrdiť platbu pre vybranú registráciu?"
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogDisableBackdrop
          {...confirmPaymentProps}
        />
      )}
      {showAddPaymentBtn && (
        <DetailActionButton
          promptKey="ADD_PAYMENT"
          buttonLabel="Generovať platbu"
          dialogTitle="Generovanie platby"
          dialogText={null}
          ButtonComponent={PrimaryDetailActionbarButton}
          dialogWidth={800}
          dialogDisableBackdrop
          formInitialValues={
            {
              state: PaymentState.NEW,
              subject: source.data?.owner?.subject,
              year: new Date().getFullYear(),
              email: source.data?.owner?.subject?.mainEmail,
            } as Payment
          }
          {...addPaymentProps}
        />
      )}
    </>
  );
}
