import { useContext } from 'react';
import {
  DetailContext,
  DetailHandle,
  UserContext,
  abortableFetch,
  useEventCallback,
} from '@eas/common-web';
import { AuditNotice } from '@models';
import { AuditNoticeState, EvidenceApiUrl, Permission } from '@enums';

export function useConfirmPaymentDialog() {
  const { hasPermission } = useContext(UserContext);
  const { source } = useContext<DetailHandle<AuditNotice>>(DetailContext);

  const apiCall = useEventCallback((id: string) =>
    abortableFetch(`${EvidenceApiUrl.AUDIT_NOTICE}/${id}/confirm-payment`, {
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    })
  );

  const showBtn =
    hasPermission(Permission.BO_AUDIT_NOTICE_MANAGE) &&
    source?.data?.state === AuditNoticeState.INCORRECT_PAYMENT_AMOUNT;

  return { showConfirmPaymentBtn: showBtn, apiCall };
}
