import * as Yup from 'yup';
import { Stakeholder, Subject } from '@models';
import { StakeholderType } from '@enums';

export function useValidationSchema() {
  return Yup.object<Stakeholder>().shape({
    subject: Yup.mixed<Subject>().nullable().required(),
    types: Yup.array<StakeholderType>().nullable().required(),
  }) as Yup.Schema<Stakeholder>;
}
