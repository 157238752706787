import React from 'react';
import { InsuranceField } from '@composite/insurances/insurance-field';

export function InsuranceFields() {
  return (
    <>
      <InsuranceField />
    </>
  );
}
