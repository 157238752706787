import {
  DictionaryObject,
  useAutocompleteSource,
  useFetch,
} from '@eas/common-web';
import { ChargeAutocomplete } from '@models';
import { EvidenceApiUrl } from '@enums';

export function useCharges(apiUrl?: EvidenceApiUrl) {
  return useAutocompleteSource<ChargeAutocomplete>({
    url: `${EvidenceApiUrl.CHARGE}/autocomplete`,
    apiUrl,
  });
}

export function usePaymentCategories() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.CHARGE}/categories/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function usePaymentMainSpecifications() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.CHARGE}/main-specifications/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function usePaymentEconomicClassifications() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.CHARGE}/economic-classifications/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}

export function usePaymentAdditionalSpecifications() {
  return useFetch<DictionaryObject[]>(
    `${EvidenceApiUrl.CHARGE}/additional-specifications/list`,
    {
      method: 'GET',
      cacheResponse: true,
    }
  );
}
